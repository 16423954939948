import { CSSProperties } from "react";
import styled, { css } from "styled-components";
import AreaLock from "../../assets/svg/AreaLock";
import ArrowIcon from "../../assets/svg/ArrowIcon";
import BackIcon from "../../assets/svg/BackIcon";
import BustIcon from "../../assets/svg/BustIcon";
import Camera360FilledIcon from "../../assets/svg/Camera360FilledIcon";
import CameraFilledIcon from "../../assets/svg/CameraFilledIcon";
import CameraIcon from "../../assets/svg/CameraIcon";
import ChampagneGlassFilledIcon from "../../assets/svg/ChampagneGlassFilledIcon";
import ChampagneGlassIcon from "../../assets/svg/ChampagneGlassIcon";
import ChatFilledIcon from "../../assets/svg/ChatFilledIcon";
import ChatIcon from "../../assets/svg/ChatIcon";
import CheckIcon from "../../assets/svg/CheckIcon";
import ChevronIcon from "../../assets/svg/ChevronIcon";
import CinematicFilledIcon from "../../assets/svg/CinematicFilledIcon";
import CircleFilledIcon from "../../assets/svg/CircleFilledIcon";
import CircleInfoIcon from "../../assets/svg/CircleInfoIcon";
import CircleMicrophoneIcon from "../../assets/svg/CircleMicrophoneIcon";
import CircleStarIcon from "../../assets/svg/CircleStarIcon";
import ClapIcon from "../../assets/svg/ClapIcon";
import ClipboardIcon from "../../assets/svg/Clipboard";
import CloudFilledIcon from "../../assets/svg/CloudFilledIcon";
import CloudRainFilledIcon from "../../assets/svg/CloudRainFilledIcon";
import ControllerIcon from "../../assets/svg/ControllerIcon";
import CrossIcon from "../../assets/svg/CrossIcon";
import CrosshairIcon from "../../assets/svg/CrosshairIcon";
import CryIcon from "../../assets/svg/CryIcon";
import DownloadIcon from "../../assets/svg/DownloadIcon";
import FacebookIcon from "../../assets/svg/FacebookIcon";
import FireFilled from "../../assets/svg/FireFilledIcon";
import FullscreenIcon from "../../assets/svg/FullScreenIcon";
import GearFilledIcon from "../../assets/svg/GearFilledIcon";
import GearIcon from "../../assets/svg/GearIcon";
import GemIcon from "../../assets/svg/GemIcon";
import GlobeIcon from "../../assets/svg/GlobeIcon";
import HandWaveFilledIcon from "../../assets/svg/HandWaveFilledIcon";
import HandWaveIcon from "../../assets/svg/HandWaveIcon";
import HeartFilledIcon from "../../assets/svg/HeartFilledIcon";
import HeartIcon from "../../assets/svg/HeartIcon";
import HeartStarsFilledIcon from "../../assets/svg/HeartStarsFilledIcon";
import HeartsFilledIcon from "../../assets/svg/HeartsFilledIcon";
import InstagramIcon from "../../assets/svg/InstagramIcon";
import LaughingFilledIcon from "../../assets/svg/LaughingFilledIcon";
import LaughingIcon from "../../assets/svg/LaughingIcon";
import LaughingRollingFilledIcon from "../../assets/svg/LaughingRollingFilledIcon";
import LaughingTearsFilledIcon from "../../assets/svg/LaughingTearsFilledIcon";
import LayerPlusFilledIcon from "../../assets/svg/LayerPlusFilledIcon";
import LinkedInIcon from "../../assets/svg/LinkedInIcon";
import MagnifyingGlassIcon from "../../assets/svg/MagnifyingGlassIcon";
import MapFilledIcon from "../../assets/svg/MapFilledIcon";
import MapIcon from "../../assets/svg/MapIcon";
import MicrophoneOffFilledIcon from "../../assets/svg/MicrophoneOffFilledIcon";
import MicrophoneOffThinIcon from "../../assets/svg/MicrophoneOffThinIcon";
import MicrophoneOnFilledIcon from "../../assets/svg/MicrophoneOnFilledIcon";
import MicrophoneOnIcon from "../../assets/svg/MicrophoneOnIcon";
import MicrophoneOnThinIcon from "../../assets/svg/MicrophoneOnThinIcon";
import MindBlownFilledIcon from "../../assets/svg/MindBlownFilledIcon";
import MinusIcon from "../../assets/svg/MinusIcon";
import MoonFilledIcon from "../../assets/svg/MoonFilledIcon";
import MountainSunFilledIcon from "../../assets/svg/MountainSunFilledIcon";
import NftIcon from "../../assets/svg/NftIcon";
import OneHundredFilled from "../../assets/svg/OneHundredFilledIcon";
import PaletteFilledIcon from "../../assets/svg/PaletteFilledIcon";
import PartyPopperFilledIcon from "../../assets/svg/PartyPopperFilledIcon";
import PartyPopperIcon from "../../assets/svg/PartyPopperIcon";
import PeopleIcon from "../../assets/svg/PeopleIcon";
import PersonSlidingFilled from "../../assets/svg/PersonSlidingFilledIcon";
import PersonSlidingIcon from "../../assets/svg/PersonSlidingIcon";
import PersonTeleportFilledIcon from "../../assets/svg/PersonTeleportFilledIcon";
import PersonTeleportIcon from "../../assets/svg/PersonTeleportIcon";
import PersonWalkingFilledIcon from "../../assets/svg/PersonWalkingFilledIcon";
import PersonWalkingIcon from "../../assets/svg/PersonWalkingIcon";
import PinIcon from "../../assets/svg/PinIcon";
import PlayIcon from "../../assets/svg/PlayIcon";
import PlusIcon from "../../assets/svg/PlusIcon";
import PollIcon from "../../assets/svg/PollIcon";
import PurpleMicrophoneIcon from "../../assets/svg/PurpleMicrophoneIcon";
import PurpleScreenShareIcon from "../../assets/svg/PurpleScreenShareIcon";
import PurpleVideoIcon from "../../assets/svg/PurpleVideoIcon";
import QuestionMarkIcon from "../../assets/svg/QuestionMarkcon";
import RocketFilledIcon from "../../assets/svg/RocketFilledIcon";
import RocketIcon from "../../assets/svg/RocketIcon";
import SadCryFilledIcon from "../../assets/svg/SadCryFilledIcon";
import SadFilledIcon from "../../assets/svg/SadFilledIcon";
import SadIcon from "../../assets/svg/SadIcon";
import SadTearFilledIcon from "../../assets/svg/SadTearFilledIcon";
import ScreenShareFilledIcon from "../../assets/svg/ScreenShareFilledIcon";
import ScreenShareIcon from "../../assets/svg/ScreenShareIcon";
import SeatFilledIcon from "../../assets/svg/SeatFilledIcon";
import ShareIcon from "../../assets/svg/ShareIcon";
import ShootingStarFilledIcon from "../../assets/svg/ShootingStarFilledIcon";
import SphereIcon from "../../assets/svg/SphereIcon";
import SquareInfoIcon from "../../assets/svg/SquareInfoIcon";
import StarFilledIcon from "../../assets/svg/StarFilledIcon";
import StarIcon from "../../assets/svg/StarIcon";
import StarsIcon from "../../assets/svg/StarsIcon";
import SunFilledIcon from "../../assets/svg/SunFilledIcon";
import SurprisedFilledIcon from "../../assets/svg/SurprisedFilledIcon";
import SurprisedHandsFilledIcon from "../../assets/svg/SurprisedHandsFilledIcon";
import SurprisedIcon from "../../assets/svg/SurprisedIcon";
import TeleportToIcon from "../../assets/svg/TeleportToIcon";
import ThumbsDownIcon from "../../assets/svg/ThumbsDownIcon";
import ThumbsUpFilledIcon from "../../assets/svg/ThumbsUpFilledIcon";
import ThumbsUpIcon from "../../assets/svg/ThumbsUpIcon";
import TireFilledIcon from "../../assets/svg/TireFilledIcon";
import TwitterXIcon from "../../assets/svg/TwitterXIcon";
import VideoFilledIcon from "../../assets/svg/VideoFilledIcon";
import VideoOffIcon from "../../assets/svg/VideoOffIcon";
import VideoOnIcon from "../../assets/svg/VideoOnIcon";
import VideoOnThinIcon from "../../assets/svg/VideoOnThinIcon";
import VideoStopRecordingIcon from "../../assets/svg/VideoStopRecordingIcon";
import VolumeIcon from "../../assets/svg/VolumeIcon";
import VolumeOffIcon from "../../assets/svg/VolumeOffIcon";
import WalletIcon from "../../assets/svg/WalletIcon";
import WarningIcon from "../../assets/svg/WarningIcon";
import WaterFilledIcon from "../../assets/svg/WaterFilledIcon";
import WaterIcon from "../../assets/svg/WaterIcon";
import WheelIcon from "../../assets/svg/WheelIcon";
import YammerIcon from "../../assets/svg/YammerIcon";
import RipplesAnimatedIcon from "../../assets/svg/animated/RipplesAnimatedIcon";
import SpinningAnimatedIcon from "../../assets/svg/animated/SpinningAnimatedIcon";
import { SvgProps } from "../../types/svg";
import { PrefixWith$ } from "../../types/typescript";

// TO ADD A NEW ICON, consult the docs docs/ui/icon.md

export const availableIcons = {
  AreaLock: AreaLock,
  Arrow: ArrowIcon,
  Back: BackIcon,
  Bust: BustIcon,
  CameraFilled: CameraFilledIcon,
  Camera: CameraIcon,
  ChampagneGlass: ChampagneGlassIcon,
  ChampagneGlassFilled: ChampagneGlassFilledIcon,
  ChatFilled: ChatFilledIcon,
  Chat: ChatIcon,
  Check: CheckIcon,
  Chevron: ChevronIcon,
  CircleFilled: CircleFilledIcon,
  CircleInfo: CircleInfoIcon,
  CircleMicrophone: CircleMicrophoneIcon,
  CircleStar: CircleStarIcon,
  Clap: ClapIcon,
  Clipboard: ClipboardIcon,
  Controller: ControllerIcon,
  Cross: CrossIcon,
  Crosshair: CrosshairIcon,
  Cry: CryIcon,
  Download: DownloadIcon,
  Facebook: FacebookIcon,
  FireFilled: FireFilled,
  Fullscreen: FullscreenIcon,
  GearFilled: GearFilledIcon,
  Gear: GearIcon,
  Gem: GemIcon,
  Globe: GlobeIcon,
  HandWaveFilled: HandWaveFilledIcon,
  HandWave: HandWaveIcon,
  Heart: HeartIcon,
  HeartFilled: HeartFilledIcon,
  HeartsFilled: HeartsFilledIcon,
  HeartStarsFilled: HeartStarsFilledIcon,
  Instagram: InstagramIcon,
  LaughingFilled: LaughingFilledIcon,
  Laughing: LaughingIcon,
  LaughingRollingFilled: LaughingRollingFilledIcon,
  LaughingTearsFilled: LaughingTearsFilledIcon,
  Linkedin: LinkedInIcon,
  MagnifyingGlass: MagnifyingGlassIcon,
  MapFilled: MapFilledIcon,
  Map: MapIcon,
  MicrophoneOn: MicrophoneOnIcon,
  MicrophoneOnFilled: MicrophoneOnFilledIcon,
  MicrophoneOffFilled: MicrophoneOffFilledIcon,
  MicrophoneOnThin: MicrophoneOnThinIcon,
  MicrophoneOffThin: MicrophoneOffThinIcon,
  MindBlownFilled: MindBlownFilledIcon,
  Minus: MinusIcon,
  Nft: NftIcon,
  OneHundredFilled: OneHundredFilled,
  PartyPopper: PartyPopperIcon,
  PartyPopperFilled: PartyPopperFilledIcon,
  People: PeopleIcon,
  PersonSlidingFilled: PersonSlidingFilled,
  PersonSliding: PersonSlidingIcon,
  PersonTeleport: PersonTeleportIcon,
  PersonTeleportFilled: PersonTeleportFilledIcon,
  PersonWalkingFilled: PersonWalkingFilledIcon,
  PersonWalking: PersonWalkingIcon,
  Play: PlayIcon,
  Pin: PinIcon,
  Plus: PlusIcon,
  Poll: PollIcon,
  PurpleScreenshare: PurpleScreenShareIcon,
  PurpleVideo: PurpleVideoIcon,
  PurpleMicrophone: PurpleMicrophoneIcon,
  QuestionMark: QuestionMarkIcon,
  RipplesAnimated: RipplesAnimatedIcon,
  Rocket: RocketIcon,
  RocketFilled: RocketFilledIcon,
  SadFilled: SadFilledIcon,
  Sad: SadIcon,
  SadCryFilled: SadCryFilledIcon,
  SadTearFilled: SadTearFilledIcon,
  ScreenShare: ScreenShareIcon,
  ScreenShareFilled: ScreenShareFilledIcon,
  Share: ShareIcon,
  ShootingStarFilled: ShootingStarFilledIcon,
  SpinningAnimated: SpinningAnimatedIcon,
  SquareInfo: SquareInfoIcon,
  Star: StarIcon,
  Stars: StarsIcon,
  StarFilled: StarFilledIcon,
  SurprisedFilled: SurprisedFilledIcon,
  Surprised: SurprisedIcon,
  SurprisedHandsFilled: SurprisedHandsFilledIcon,
  ThumbsDown: ThumbsDownIcon,
  ThumbsUpFilled: ThumbsUpFilledIcon,
  ThumbsUp: ThumbsUpIcon,
  TeleportTo: TeleportToIcon,
  TwitterX: TwitterXIcon,
  VideoStopRecording: VideoStopRecordingIcon,
  VideoOnThin: VideoOnThinIcon,
  VideoOn: VideoOnIcon,
  VideoOff: VideoOffIcon,
  Volume: VolumeIcon,
  VolumeOff: VolumeOffIcon,
  Water: WaterIcon,
  WaterFilled: WaterFilledIcon,
  Wallet: WalletIcon,
  Warning: WarningIcon,
  Yammer: YammerIcon,
  CloudFilled: CloudFilledIcon,
  CloudRainFilled: CloudRainFilledIcon,
  SunFilled: SunFilledIcon,
  MoonFilled: MoonFilledIcon,
  VideoFilled: VideoFilledIcon,
  MountainSunFilled: MountainSunFilledIcon,
  CinematicFilled: CinematicFilledIcon,
  Camera360Filled: Camera360FilledIcon,
  LayerPlusFilled: LayerPlusFilledIcon,
  PaletteFilled: PaletteFilledIcon,
  SeatFilled: SeatFilledIcon,
  TireFilled: TireFilledIcon,
  Wheel: WheelIcon,
  Sphere: SphereIcon,
} as const;

export type IconName = keyof typeof availableIcons;

type Flip = "x" | "y" | "xy";

const flipToScale: Record<Flip, string> = {
  x: "scale(-1, 1)",
  y: "scale(1, -1)",
  xy: "scale(-1, -1)",
} as const;

type IconWrapperProps = {
  inheritColor?: boolean;
  color?: string;
  strokeWidth?: string;
  size?: string;
  shiftX?: string;
  shiftY?: string;
  rotate?: string;
  flip?: Flip;
  scaleStroke?: boolean;
};

const IconWrapper = styled.div<PrefixWith$<IconWrapperProps>>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(p) => p.$size ?? "30px"};
  height: ${(p) => p.$size ?? "30px"};

  /* This is needed to avoid weird flex grow/shrink limitation. The "column" direction allow better rescaling. */
  flex-direction: column;
  svg {
    flex-grow: 1;
  }

  ${(p) =>
    p.$strokeWidth &&
    `svg {
    stroke-width: ${p.$strokeWidth};
  }`}
  ${(p) =>
    `svg {
      transform: ${p.$flip ? flipToScale[p.$flip] : ""} rotate(${
        p.$rotate ?? "0deg"
      }) translate(${p.$shiftX ?? "0px"}, ${p.$shiftY ?? "0px"});
    }`}

    ${(p) =>
    !p.$inheritColor &&
    css`
      svg .fill-color,
      svg.fill-color {
        transition: fill 100ms linear;
        fill: ${p.$color || p.theme.colorAbove4};
      }
      svg .stroke-color,
      svg.stroke-color {
        stroke: ${p.$color || p.theme.colorAbove4};
      }
    `}

  svg .stroke-color,
  svg.stroke-color {
    transition: stroke 80ms ease-in-out;
    vector-effect: ${(p) => (p.$scaleStroke ? "unset" : "non-scaling-stroke")};
  }
`;

export type BaseIconProps = {
  type?: IconName;
  /** Uses the text color for its parent.. */
  inheritColor?: boolean;
  /** A valid CSS color string. */
  color?: string;
  /** The weight of the svg strokes. A valid CSS size.*/
  strokeWidth?: string;
  /** A valid CSS size. */
  size?: string;
  /** A valid CSS angle. */
  rotate?: string;
  flip?: Flip;
  /** A valid CSS length */
  shiftX?: string;
  /** A valid CSS length */
  shiftY?: string;
  /** Allow the stroke to scale up/down with the icon. */
  scaleStroke?: boolean;
  /** Custom styles for the SVG wrapper. */
  style?: CSSProperties;
  /** Props passed directly to the SVG element. */
  svgProps?: SvgProps;
  testId?: string;
};

export const BaseIcon: React.FC<
  BaseIconProps & React.ComponentPropsWithoutRef<"div">
> = ({ type, svgProps, ...props }) => {
  if (!type) return null;
  const Icon = availableIcons[type];
  const {
    inheritColor,
    color,
    strokeWidth,
    size,
    shiftX,
    shiftY,
    rotate,
    flip,
    scaleStroke,
    testId,
    ...rest
  } = props;
  return (
    <IconWrapper
      $inheritColor={inheritColor}
      $color={color}
      $strokeWidth={strokeWidth}
      $size={size}
      $shiftX={shiftX}
      $shiftY={shiftY}
      $rotate={rotate}
      $flip={flip}
      $scaleStroke={scaleStroke}
      data-testid={testId}
      {...rest}
    >
      <Icon {...svgProps} />
    </IconWrapper>
  );
};

// Below is a cheeky way to give the Icon component this sexy API: <Icon.Cross />
const iconsNameList = Object.keys(availableIcons);

const iconsArray = iconsNameList.map((name) => {
  const iconName = name as IconName;
  const specificIcon: React.FC<BaseIconProps> = (props) => (
    <BaseIcon {...props} type={iconName} />
  );
  return [iconName, specificIcon];
});

const Icon = Object.fromEntries(iconsArray) as Record<
  IconName,
  typeof BaseIcon
>;

export default Icon;
