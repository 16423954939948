import loadable from "@loadable/component";
import { useEnvironmentContext } from "../../../app/EnvironmentDataProvider";

const Web3Provider = loadable(() => import("./Web3.provider"));

type Props = {
  children?: React.ReactNode;
};

const Web3ProviderLazy: React.FC<Props> = ({ children }) => {
  const { environment } = useEnvironmentContext();
  const shouldUseWeb3 = environment?.enableWeb3;

  if (!shouldUseWeb3) return <>{children}</>;

  return <Web3Provider>{children}</Web3Provider>;
};

export default Web3ProviderLazy;
