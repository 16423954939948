import { styled } from "styled-components";
import Column from "../../componentsLibrary/atoms/Column";
import HeaderBadge from "../../componentsLibrary/atoms/HeaderBadge";
import Row from "../../componentsLibrary/atoms/Row";
import Space from "../../componentsLibrary/atoms/Space";
import Spatial from "../../componentsLibrary/atoms/Spatial";
import Typo from "../../componentsLibrary/atoms/Typo";
import Card from "../../componentsLibrary/molecules/Card";
import { useText } from "../language/language.hook";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
`;

export type Props = {
  isInactive: boolean;
  isClosing: boolean;
  countDown: number;
};

const EndingUiNormal: React.FC<Props> = ({
  isInactive,
  isClosing,
  countDown,
}) => {
  const t = useText();

  return (
    <Container>
      <Spatial rim glare mouseZoom mouseTiltX mouseTiltY>
        <Card>
          <Column
            textAlign="center"
            justify="center"
            align="center"
            gap={4}
            style={{
              padding: "24px",
              boxSizing: "border-box",
            }}
          >
            <Space h={2} />
            <Row justify="center">
              <HeaderBadge>
                <Typo.Title>{countDown}</Typo.Title>
              </HeaderBadge>
            </Row>
            {isInactive && (
              <>
                <Typo.Title>{t("inactivity_title")}</Typo.Title>
                <Row width="90%">
                  <Typo.Body
                    style={{
                      textAlign: "center",
                      flex: 1,
                    }}
                  >
                    {t("inactivity_description_desktop", { countDown })}
                  </Typo.Body>
                </Row>
              </>
            )}
            {isClosing && (
              <Typo.Body>{t("calendar_event_over", { countDown })}</Typo.Body>
            )}
            <Space h={2} />
          </Column>
        </Card>
      </Spatial>
    </Container>
  );
};

export default EndingUiNormal;
