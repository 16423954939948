/* eslint-disable @typescript-eslint/no-explicit-any */
import { Connection, Session, Stream, Subscriber } from "@opentok/client";
import {
  ActorRefFrom,
  assign,
  createMachine,
  enqueueActions,
  fromPromise,
} from "xstate";
import { useStore } from "../../../app/store";
import { log, logError } from "../../../common/util/logger";
import { VonageStateMachineEvents } from "../types/videoConferenceMachine.types";
import { onAudioLevelUpdated } from "../utils/util.core";

type ParticipantStateMachineContext = {
  userId: string;
  subscriber: Subscriber | null;
  stream: Stream | null;
  session: Session;
  parent: any;
};

type SubscribeToStreamInput = {
  userId: string;
  session: Session;
  stream: Stream | null;
  peerParticipantActor: ActorRefFrom<any>;
};
const peerParticipantMachine = createMachine(
  {
    types: {} as {
      context: ParticipantStateMachineContext;
      input: {
        userId: string;
        session: Session;
        connection: Connection;
        parent: any;
      };
      events: VonageStateMachineEvents;
    },
    context: ({ input }) => ({
      userId: input.userId,
      subscriber: null,
      stream: null,
      parent: input.parent,
      session: input.session,
    }),
    id: "peerParticipantStateMachine",
    on: {
      "conference.streamDestroyed": {
        actions: ["unsubscribe", "STORE_unsubscribeVideo"],
        target: ".Inactive",
      },
      "conference.streamCreated": {
        target: ".Active",
        actions: "setUpParticipantStream",
      },
      "lugia.startParticipantVideo": {
        actions: "startVideo",
      },
      "lugia.stopParticipantVideo": {
        actions: "stopVideo",
      },
    },
    initial: "Inactive",
    states: {
      hist: {
        type: "history",
        history: "shallow",
      },
      Inactive: {
        entry: "logInfo",
        on: {
          "conference.streamCreated": {
            actions: "setUpParticipantStream",
            target: "Active",
          },
        },
      },
      Active: {
        entry: "logInfo",
        on: {
          "conference.localParticipantJoined": {
            target: "Subscribing",
          },
        },
        always: [
          {
            target: "Subscribing",
            guard: "isLocalParticipantActive",
          },
        ],
      },
      Subscribing: {
        entry: "logInfo",
        on: {
          "conference.peerVideoStreamReady": {
            actions: "STORE_setPeerParticipant",
          },
          "conference.peerParticipantVideoDisabled": {
            actions: "STORE_setPeerParticipantVideoDisabled",
          },
          "conference.peerParticipantVideoEnabled": {
            actions: "STORE_setPeerParticipantVideoEnabled",
          },
        },
        invoke: {
          id: "streamSubscriber",
          src: "streamSubscriber",
          input: ({ context, self }) => {
            return {
              stream: context.stream,
              session: context.session,
              peerParticipantActor: self,
              userId: context.userId,
            };
          },
          onDone: {
            target: "Subscribed",
            actions: assign({
              subscriber: ({ event }) => event.output,
            }),
          },
          onError: {
            target: "Inactive",
          },
        },
      },
      Subscribed: {
        on: {
          "conference.peerVideoStreamReady": {
            actions: "STORE_setPeerParticipant",
          },
          "conference.peerParticipantVideoDisabled": {
            actions: "STORE_setPeerParticipantVideoDisabled",
          },
          "conference.peerParticipantVideoEnabled": {
            actions: "STORE_setPeerParticipantVideoEnabled",
          },

          "conference.streamCreated": {
            target: "Subscribing",
            actions: enqueueActions(({ event, self, context, enqueue }) => {
              enqueue.assign(() => {
                if (event.type === "conference.streamCreated") {
                  const snap = self.getSnapshot();
                  if (snap.status === "active") {
                    if (context.stream != event.data.stream) {
                      if (context.subscriber) {
                        context.session.unsubscribe(context.subscriber);
                      }
                      return {
                        ...context,
                        stream: event.data.stream,
                      };
                    }
                  }
                }
                return context;
              });
            }),
          },
        },
      },
      Error: {},
      Disconnected: {
        type: "final",
      },
    },
  },
  {
    actors: {
      streamSubscriber: fromPromise<Subscriber | null, SubscribeToStreamInput>(
        async ({ input }) => {
          const { stream, session, peerParticipantActor, userId } = input;
          if (!stream) return null;
          return new Promise<Subscriber>((resolve, reject) => {
            const _subscriber = session.subscribe(
              stream,
              undefined,
              {
                subscribeToVideo: true,
                insertDefaultUI: false,
              },
              (error) => {
                if (error) {
                  reject(error);
                }
                resolve(_subscriber);
              }
            );
            _subscriber.on("videoDisabled", (event) => {
              log(
                "VOICE/VIDEO",
                `Video disabled for ${userId}. Reason: ${event.reason}`
              );
              // We need to check if the reason is not subscribeToVideo
              // To avoid infinite loops, since we are already handling this case elsewhere
              if (event.reason === "quality") {
                peerParticipantActor.send({
                  type: "conference.peerParticipantVideoDisabled",
                  data: event,
                });
              }
            });
            _subscriber.on("videoEnabled", (event) => {
              log(
                "VOICE/VIDEO",
                `Video enabled for ${userId}. Reason: ${event.reason}`
              );
              if (event.reason === "quality") {
                peerParticipantActor.send({
                  type: "conference.peerParticipantVideoEnabled",
                  data: event,
                });
              }
            });
            _subscriber.on("audioLevelUpdated", (event) => {
              onAudioLevelUpdated(
                userId,
                event,
                useStore.getState().videoConference.setParticipantSpeaking
              );
            });
            _subscriber.on("videoElementCreated", (event) => {
              peerParticipantActor.send({
                type: "conference.peerVideoStreamReady",
                data: event,
              });
            });
          });
        }
      ),
    },
    guards: {
      isLocalParticipantActive: ({ context }) => {
        const snapshot = context.parent.getSnapshot();
        return snapshot.matches("ConnectedToConference");
      },
    },
    actions: {
      unsubscribe: assign(({ context }) => {
        if (context.subscriber) {
          context.subscriber.off("videoDisabled");
          context.subscriber.off("videoEnabled");
          context.subscriber.off("audioLevelUpdated");
          context.subscriber.off("videoElementCreated");
          context.session.unsubscribe(context.subscriber);
        }
        return {
          ...context,
          stream: null,
          subscriber: null,
        };
      }),
      STORE_unsubscribeVideo: ({ context, event }) => {
        if (event.type === "conference.streamDestroyed") {
          const userId = context.userId;
          if (!userId) return;
          useStore
            .getState()
            .videoConference.setUpdateParticipantStatus(userId, {
              streams: [] as MediaStream[],
              isPublishing: true,
              isAudioOn: false,
              isVideoOn: false,
              isSpeaking: false,
              isVideoHidden: false,
            });
        }
      },
      setUpParticipantStream: enqueueActions(({ context, event, enqueue }) => {
        if (event.type === "conference.streamCreated") {
          enqueue.assign({
            stream: event.data.stream,
          });

          try {
            const streamUserIdObj = JSON.parse(
              event.data.stream?.connection?.data
            );
            const streamUserId = streamUserIdObj?.externalId;
            if (streamUserId === context.userId) {
              useStore
                .getState()
                .videoConference.setUpdateParticipantStatus(streamUserId, {
                  isPublishing: true,
                  isAudioOn: event.data.stream.hasAudio,
                  isVideoOn: event.data.stream.hasVideo,
                });
            }
          } catch (e) {
            logError("VOICE/VIDEO", "cannot parse user Id from stream");
          }
        }
      }),
      stopVideo: ({ context }) => {
        if (context.subscriber?.stream?.hasVideo) {
          context.subscriber.subscribeToVideo(false);
        }
      },
      startVideo: ({ context }) => {
        if (context.subscriber?.stream?.hasVideo) {
          context.subscriber.subscribeToVideo(true);
        }
      },
      STORE_setPeerParticipantVideoEnabled: ({ context }) => {
        useStore
          .getState()
          .videoConference.setUpdateParticipantStatus(context.userId, {
            isVideoOn: true,
          });
      },
      STORE_setPeerParticipantVideoDisabled: ({ context }) => {
        useStore
          .getState()
          .videoConference.setUpdateParticipantStatus(context.userId, {
            isVideoOn: false,
          });
      },
      STORE_setPeerParticipant: ({ event, context }) => {
        if (event.type === "conference.peerVideoStreamReady") {
          const userId = context.userId;
          const eventData = event.data as any;
          if (!userId) return;
          const videoElement = eventData.element as HTMLVideoElement;
          const videoStream = videoElement.srcObject;
          const subscriberStream = event.data.target.stream as Stream;

          useStore
            .getState()
            .videoConference.setUpdateParticipantStatus(userId, {
              streams: [videoStream] as MediaStream[],
              isPublishing: true,
              isAudioOn: subscriberStream.hasAudio,
              isVideoOn: subscriberStream.hasVideo,
            });
          videoElement.onplay = () => {
            if (videoStream !== videoElement.srcObject) {
              useStore
                .getState()
                .videoConference.setUpdateParticipantStatus(userId, {
                  streams: [videoElement.srcObject] as MediaStream[],
                  isPublishing: true,
                  isAudioOn: subscriberStream.hasAudio,
                  isVideoOn: subscriberStream.hasVideo,
                });
            }
          };
        }
      },
      logInfo: ({ event }) => {
        log("VOICE/VIDEO", event);
      },
    },
  }
);

export default peerParticipantMachine;
