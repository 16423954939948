import { styled } from "styled-components";
import { useStore } from "../../app/store";
import { PanelTestIds } from "../../common/constants/testIds.constant";
import Hide from "../../componentsLibrary/atoms/Hide";
import { BaseLayout } from "../_layout/PanelsLayout";
import VideoAvatarsLogic from "./VideoAvatars.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  height: 100%;
  box-sizing: border-box;
  display: inline-block;
  padding-top: 26px;
  padding-left: 26px;
`;

const VideoAvatarsPanel: React.FC = () => {
  const videoAvatarsVisible = useStore(
    (s) => s.layout.panels.videoAvatars.visible
  );
  const playersPanelVisible = useStore(
    (s) => s.layout.panels.social.subpage === "social/players"
  );
  const screenSharingVisible = useStore(
    (s) => s.layout.panels.screenSharing.visible
  );
  const screenSharingMinimized = useStore(
    (s) => s.layout.panels.screenSharing.options?.minimized
  );

  // TODO we will clean this up in a future PR when we refactor the layout
  const hideAvatars = !screenSharingVisible
    ? false
    : screenSharingVisible && !screenSharingMinimized;
  const visible = videoAvatarsVisible && !playersPanelVisible && !hideAvatars;
  return (
    <Layout
      id="panel-videoAvatars"
      data-testid={PanelTestIds.videoAvatars.rootLayout}
    >
      <Hide width="100%" height="100%" hide={!visible} speed={400}>
        <VideoAvatarsLogic />
      </Hide>
    </Layout>
  );
};

export default VideoAvatarsPanel;
