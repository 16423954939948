import { isMobile } from "../../common/constants/flags.constant";
import { useWindowSize } from "../../common/hooks/ui";
import PanelsLayout from "../_layout/PanelsLayout";
import ActionBarPanelLayout from "../actionBarPanel/ActionBarPanelLayout";
import ActionElementsPanel from "../actionElementsPanel/ActionElements.panel";
import CinematicModePanelLayout from "../cinematicModePanel/CinematicModePanelLayout";
import DevOptionsPanel from "../devOptions/DevOptions.panel";
import ForceLandscapePanel from "../forceLandscape/ForceLandscape.panel";
import FullscreenVideoPanelLayout from "../fullscreenVideo/FullscreenVideo.panel";
import HintPanel from "../hint/Hint.panel";
import InfoCardPanel from "../infoCard/InfoCard.panel";
import LanguagePanel from "../language/Language.panel";
import MapPanel from "../map/Map.panel";
import MediaSharePanel from "../mediaCapture/mediaShare/MediaShare.panel";
import PhotoCapturePanel from "../mediaCapture/photoCapture/PhotoCapture.panel";
import VideoCapturePanel from "../mediaCapture/videoCapture/VideoCapture.panel";
import PollPanel from "../poll/Poll.panel";
import PopupPanel from "../popup/Popup.panel";
import PresentationBarPanel from "../presentationBar/PresentationBar.panel";
import ProfilePanel from "../profile/Profile.panel";
import { PROFILE_MAX_HEIGHT } from "../profile/lib/constants";
import QuestPanel from "../quest/Quest.panel";
import QuestHintPanel from "../questHint/QuestHint.panel";
import ReportPanel from "../report/Report.panel";
import ScreenSharingPanel from "../screenSharing/ScreenSharing.panel";
import SettingsPanel from "../settings/Settings.panel";
import SocialPanel from "../social/Social.panel";
import StatsPanel from "../stats/Stats.panel";
import TextChatPreview from "../textChatPreview/TextChatPreview.panel";
import TopLogoPanelLayout from "../topLogoPanel/TopLogoPanelLayout";
import VideoAvatarsPanel from "../videoConference/VideoAvatars.panel";
import WalletConnectModalPanel from "../web3Wallet/WalletConnectModal.panel";

const PanelSystem: React.FC = () => {
  const { height } = useWindowSize();
  const isShortDesktopScreen = !isMobile && height < PROFILE_MAX_HEIGHT;

  return (
    <PanelsLayout
      panels={[
        {
          component: <ActionBarPanelLayout />,
          name: "actionBar",
          normal: {
            rules: ["experienceOnly"],
            zone: "bottom",
          },
        },
        {
          component: <TopLogoPanelLayout />,
          name: "logo",
          normal: {
            rules: ["experienceOnly", "ghost"],
            zone: "top",
          },
        },
        {
          component: <QuestHintPanel />,
          name: "questHint",
          normal: {
            rules: ["experienceOnly", "stubborn"],
            zone: "top",
          },
          small: {
            rules: ["experienceOnly"],
            zone: "top",
          },
        },
        {
          component: <HintPanel />,
          name: "hint",
          normal: {
            rules: ["experienceOnly", "stubborn"],
            zone: "top",
          },
          small: {
            rules: ["experienceOnly"],
            zone: "top",
          },
        },
        {
          component: <ProfilePanel />,
          name: "profile",
          normal: {
            // Profile panel is fullscreen on short desktop screens
            // until we decide on panel responsiveness
            zone: isShortDesktopScreen ? "overlay" : "right",
            rules: ["experienceOnly", "solo"],
          },
          small: {
            rules: ["experienceOnly", "solo"],
            zone: "overlay",
          },
        },
        {
          component: <SettingsPanel />,
          name: "settings",
          normal: {
            zone: "right",
            rules: ["experienceOnly", "ghost"],
          },
          small: {
            rules: ["experienceOnly", "solo"],
            zone: "overlay",
          },
        },
        {
          component: <MapPanel />,
          name: "map",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <PhotoCapturePanel />,
          name: "photo",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <VideoCapturePanel />,
          name: "videoCapture",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <MediaSharePanel />,
          name: "mediaShare",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <PopupPanel />,
          name: "popup",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <SocialPanel />,
          name: "social",
          normal: {
            zone: "left",
            rules: ["experienceOnly"],
          },
          small: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <InfoCardPanel />,
          name: "infocard",
          normal: {
            zone: "right",
            rules: ["experienceOnly"],
          },
          small: {
            zone: "overlay",
            rules: ["experienceOnly"],
          },
        },

        {
          component: <CinematicModePanelLayout />,
          name: "cinematicView",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo", "stubborn"],
          },
        },
        {
          component: <StatsPanel />,
          name: "stats",
          normal: {
            zone: "extra",
            rules: ["global", "ghost"],
          },
        },

        {
          component: <DevOptionsPanel />,
          name: "devOptions",
          normal: {
            zone: "video",
            rules: ["experienceOnly", "ghost"],
          },
        },
        {
          component: <ForceLandscapePanel />,
          name: "forceLandscape",
          normal: {
            zone: "overlay",
            rules: ["global", "ghost", "solo", "stubborn"],
          },
        },
        {
          component: <FullscreenVideoPanelLayout />,
          name: "fullscreenVideo",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo", "stubborn"],
          },
        },
        {
          component: <ScreenSharingPanel />,
          name: "screenSharing",
          normal: {
            zone: "video",
            rules: ["experienceOnly"],
          },
        },
        {
          component: <VideoAvatarsPanel />,
          name: "videoAvatars",
          normal: {
            rules: ["experienceOnly", "ghost"],
            zone: "top",
          },
        },
        {
          component: <PollPanel />,
          name: "poll",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
          small: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <TextChatPreview />,
          name: "textChatPreview",
          normal: {
            zone: "left",
            rules: ["experienceOnly"],
          },
        },
        {
          component: <WalletConnectModalPanel />,
          name: "walletConnect",
          normal: {
            zone: "overlay",
            rules: ["experienceOnly", "solo"],
          },
        },
        {
          component: <PresentationBarPanel />,
          name: "presentationBar",
          normal: {
            rules: ["experienceOnly", "ghost"],
            zone: "overlay",
          },
        },
        {
          component: <LanguagePanel />,
          name: "language",
          normal: {
            rules: ["global"],
            zone: "overlay",
          },
        },
        {
          component: <ReportPanel />,
          name: "report",
          normal: {
            rules: ["global"],
            zone: "overlay",
          },
        },
        {
          component: <QuestPanel />,
          name: "quest",
          normal: {
            rules: ["experienceOnly"],
            zone: "right",
          },
          small: {
            rules: ["experienceOnly"],
            zone: "overlay",
          },
        },
        {
          component: <ActionElementsPanel />,
          name: "actionElements",
          normal: {
            rules: ["experienceOnly"],
            zone: "overlay",
          },
        },
      ]}
    />
  );
};

export default PanelSystem;
