import { useMemo } from "react";
import * as Select from "@radix-ui/react-select";
import styled, { useTheme } from "styled-components";
import { zIndex } from "../../app/style/theme";
import { alphabeticalSorting } from "../../common/util/string";
import Icon from "../atoms/Icon";
import Typo from "../atoms/Typo";
import ButtonText from "./ButtonText";

const SelectRoot = styled(Select.Root)``;
const SelectTrigger = styled(Select.Trigger)<{ $noPadding: boolean }>`
  background: transparent;
  border: none;
  padding: ${(p) => (p.$noPadding ? "0px" : "0px 12px")};
`;
const SelectValue = styled(Select.Value)``;
const SelectIcon = styled(Select.Icon)``;
export const SelectPortal = styled(Select.Portal)``;
export const SelectContent = styled(Select.Content)`
  background-color: ${(p) => p.theme.colorBelow2};
  border-radius: ${(p) => p.theme.radiusSmall};
  z-index: ${zIndex.popupMenues};
`;
export const SelectScrollUpButton = styled(Select.ScrollUpButton)`
  display: flex;
  justify-content: center;
  padding: 3px;
`;
export const SelectViewport = styled(Select.Viewport)`
  padding: 5px;
`;
export const SelectItem = styled(Select.Item)`
  border-radius: ${(p) => p.theme.radiusTiny};
  padding: 5px;
  user-select: none;
  cursor: pointer;
  color: ${(p) => p.theme.colorAbove5};

  &[data-state="checked"] {
    color: ${(p) => p.theme.colorAbove2};
  }
  &[data-highlighted] {
    outline: none;
    color: ${(p) => p.theme.colorAboveBrand};
    background-color: ${(p) => p.theme.colorBelowBrand};
  }
`;
const SelectItemText = styled(Select.ItemText)``;
const SelectScrollDownButton = styled(Select.ScrollDownButton)`
  display: flex;
  justify-content: center;
  padding: 3px;
`;

export type Props = {
  disabled?: boolean;
  selected: string;
  options: { label?: string; value: string }[];
  onOpen?: () => void;
  onSelect?: (value: string) => void;
  bright?: boolean;
  dontSort?: boolean;
  dontPad?: boolean;
  className?: string;
};

const SelectText: React.FC<Props> = ({
  disabled,
  selected,
  options,
  onOpen,
  onSelect,
  bright,
  dontSort,
  dontPad,
  className,
}) => {
  const theme = useTheme();
  const sortedOptions = useMemo(() => {
    return dontSort
      ? options
      : [...options].sort((a, b) => alphabeticalSorting(a.value, b.value));
  }, [dontSort, options]);

  return (
    <SelectRoot
      onOpenChange={onOpen}
      onValueChange={onSelect}
      disabled={disabled}
      value={selected}
    >
      <SelectTrigger
        $noPadding={Boolean(dontPad)}
        asChild
        className={className}
      >
        <ButtonText
          bright={bright}
          disabled={disabled}
          iconRight={
            <SelectIcon>
              <Typo.Note color="inherit">
                <Icon.Chevron flip="y" inheritColor size="10px" />
              </Typo.Note>
            </SelectIcon>
          }
        >
          <SelectValue />
        </ButtonText>
      </SelectTrigger>
      <SelectPortal /* className={className} */>
        <SelectContent side="bottom" align="end">
          <SelectScrollUpButton>
            <Icon.Chevron color={theme.colorAbove2} size="10px" />
          </SelectScrollUpButton>
          <SelectViewport>
            {sortedOptions.map((it) => (
              <SelectItem key={it.value} value={it.value}>
                <SelectItemText>
                  <Typo.Note color="inherit">{it.label ?? it.value}</Typo.Note>
                </SelectItemText>
              </SelectItem>
            ))}
          </SelectViewport>
          <SelectScrollDownButton>
            <Icon.Chevron flip="y" color={theme.colorAbove2} size="10px" />
          </SelectScrollDownButton>
        </SelectContent>
      </SelectPortal>
    </SelectRoot>
  );
};

export default SelectText;
