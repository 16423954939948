import { styled } from "styled-components";
import Column from "../../../componentsLibrary/atoms/Column";
import Hide from "../../../componentsLibrary/atoms/Hide";
import Markdown from "../../../componentsLibrary/atoms/Markdown";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";
import CardPartialPortrait from "../../../componentsLibrary/molecules/CardPartialPortrait";
import { Props } from "./MapCard.ui.normal";
import MapCardImage from "./MapCardImage";
import TeleportButton from "./TeleportButton";

// Same background color of the Popup Overlay
const Background = styled.div<{ $dark?: boolean }>`
  width: 100dvw;
  height: 100dvh;
  background-color: #0000008b;
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const CardWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 2;
`;

/*
 * Card that shows the Map point information when in small sized screens on portrait orientation
 * This component should be kept as similar as possible to InfoCardPanelSmallPortraitUi (InfoCard.ui.small.portrait)
 */
const MapCardUiLandscape: React.FC<Props> = ({
  selectedPoint,
  isCurrentLocation,
  show,
  onTeleport,
  onClose,
}) => {
  const { image, title, description, spawnPoint } = selectedPoint;

  return (
    <>
      <CardWrapper>
        <Hide hide={!show} unMount height="100dvh" width="100dvw" shiftY="20px">
          <CardPartialPortrait
            topSectionHeight={image ? "200px" : "160px"}
            topSection={
              <MapCardImage
                image={image}
                isCurrentLocation={isCurrentLocation}
              />
            }
            onClose={onClose}
            brightCloseButton={Boolean(image)}
          >
            <Column gap={3}>
              {title && <Typo.Title align="center">{title}</Typo.Title>}
              {description && (
                <Markdown content={description} textAlign="start" />
              )}
              {spawnPoint !== undefined && (
                <>
                  <Space h={0} />
                  <TeleportButton
                    onTeleport={() => onTeleport(selectedPoint)}
                  />
                </>
              )}
              <Space h={1} />
            </Column>
          </CardPartialPortrait>
        </Hide>
      </CardWrapper>
      <BackgroundWrapper>
        <Hide hide={!show} unMount>
          <Background />
        </Hide>
      </BackgroundWrapper>
    </>
  );
};

export default MapCardUiLandscape;
