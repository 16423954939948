import { useStore } from "../../../app/store";
import { useVideoConferenceControlsContext } from "../../videoConference/providers/VideoConference.provider";
import { useReactionsContext } from "../providers/Reactions.provider";
import AttendeeBarUi from "./AttendeeBar.ui";

type Props = {
  isOpen: boolean;
};

const AttendeeBarLogic: React.FC<Props> = ({ isOpen }) => {
  const { toggleLocalAudio, toggleVideo, toggleScreenShare } =
    useVideoConferenceControlsContext();

  const micMuted = useStore((s) => s.userMedia.micMuted);
  const webcamMuted = useStore((s) => s.userMedia.webcamMuted);
  const activeScreenShare = useStore(
    (s) => s.videoConference.activeScreenShare
  );
  const { ReactionButtons, handleReaction } = useReactionsContext();
  const amIScreenSharer = useStore((s) => s.videoConference.amIScreenSharer());
  const screenshareDisabled = Boolean(activeScreenShare) && !amIScreenSharer;
  const isVideoConferenceInitialized = useStore((s) =>
    s.videoConference.isConferenceInitialized()
  );

  return (
    <AttendeeBarUi
      items={ReactionButtons}
      handleReaction={handleReaction}
      isOpen={isOpen}
      micMuted={micMuted}
      isVideoConferenceInitialized={isVideoConferenceInitialized}
      toggleLocalAudio={toggleLocalAudio}
      webcamMuted={webcamMuted}
      toggleVideo={toggleVideo}
      activeScreenShare={Boolean(activeScreenShare)}
      toggleScreenShare={toggleScreenShare}
      screenshareDisabled={screenshareDisabled}
    />
  );
};

export default AttendeeBarLogic;
