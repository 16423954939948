import { styled } from "styled-components";
import { useStore } from "../../app/store";
import { zIndex } from "../../app/style/theme";
import { useIsSmallScreen } from "../../common/hooks/ui";
import Transition from "../../componentsLibrary/atoms/Transition";
import { BaseLayout } from "../_layout/PanelsLayout";
import SettingsLogic from "./Settings.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  z-index: ${zIndex.panels};
`;

const SettingsPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.settings.visible);
  const subpage = useStore((s) => s.layout.panels.settings.subpage);
  const isSmallscreen = useIsSmallScreen();

  return (
    <Layout id="panel-settings">
      <Transition
        height="100%"
        width="100%"
        watch={[visible, subpage]}
        slideIn={isSmallscreen ? undefined : "right"}
        slideOut={isSmallscreen ? undefined : "right"}
        justify="flex-end"
        align="flex-start"
      >
        {visible && <SettingsLogic subpage={subpage} />}
      </Transition>
    </Layout>
  );
};

export default SettingsPanel;
