import EnvironmentMetadata from "../../app/EnvironmentMetadata";
import { DevLandingPageTestIds } from "../../common/constants/testIds.constant";
import Column from "../../componentsLibrary/atoms/Column";
import Card from "../../componentsLibrary/molecules/Card";
import SpacePageUi from "../utilityPages/SpacePageUi";

type Props = {
  content: React.ReactNode;
};

const DataExternalPageUi: React.FC<Props> = ({ content }) => {
  return (
    <>
      <EnvironmentMetadata />
      <SpacePageUi testId={DevLandingPageTestIds.root}>
        <Card
          height="600px"
          width="1024px"
          style={{ padding: "40px" }}
          maxHeight="520px"
        >
          <Column width="auto" height="auto">
            {content}
          </Column>
        </Card>
      </SpacePageUi>
    </>
  );
};

export default DataExternalPageUi;
