import { useMemo } from "react";
import { AvatarResponseDto } from "@journee-live/gyarados/axios/esm";
import { css, styled } from "styled-components";
import RpmImgSrc from "../../../assets/img/RpmDefaultPreview.png";
import { PanelTestIds } from "../../../common/constants/testIds.constant";
import { getImageUrl } from "../../../common/util/gyarados.util";
import Row from "../../../componentsLibrary/atoms/Row";
import Space from "../../../componentsLibrary/atoms/Space";
import Spatial from "../../../componentsLibrary/atoms/Spatial";
import Typo from "../../../componentsLibrary/atoms/Typo";
import { BackgroundImageWrapper, ImgBackground } from "./AvatarCarousel";
import CustomAvatarButton from "./CustomAvatarButton";

const Avatar = styled.div<{
  $active: boolean;
  $bgColor?: string;
}>`
  height: 160px;
  flex: 1;
  min-width: 0;
  will-change: transform;
  border-radius: ${({ theme }) => theme.radiusSmall};
  border: 1px solid ${(p) => p.theme.colorAbove0};
  display: flex;
  justify-content: center;
  ${(p) =>
    p.$bgColor &&
    css`
      background-color: ${p.$bgColor};
    `}

  &:hover {
    cursor: pointer;
  }

  transition:
    transform 0.3s 0.1s ease-in-out,
    box-shadow 0.3s ease-in-out,
    height 0.3s ease-in-out;

  ${(p) =>
    p.$active &&
    css`
      height: 175px;
      box-shadow: 0px 30px 30px ${(p) => p.theme.colorShadow};
    `}
`;

const Img = styled.img<{ $active: boolean }>`
  object-fit: contain;
  transform-origin: center bottom;
  transition:
    transform 0.3s ease-in-out,
    bottom 0.3s ease-in-out;
  position: absolute;
  bottom: -1px;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  ${(p) => {
    return (
      p.$active &&
      css`
        transform: scale(1.2);
      `
    );
  }}
`;

const BackGroundGradient = styled.div`
  width: 100%;
  height: 100%;
  /* The exact same border radius makes the border in the corners slightly wider */
  border-radius: calc(${({ theme }) => theme.radiusSmall} - 1px);
  position: absolute;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  flex: 1;
  min-width: 0;
`;

const AvatarLabel = styled.div`
  position: absolute;
  bottom: -35px;
`;

type Props = {
  options: AvatarResponseDto[];
  initialAvatarId: string;
  onSelect: (avatarId: string) => void;
  openReadyPlayerMeEditor: () => void;
  isLandscape?: boolean;
};

const AvatarGrid: React.FC<Props> = ({
  options,
  initialAvatarId,
  onSelect,
  openReadyPlayerMeEditor,
  isLandscape,
}) => {
  const isRpmOnly =
    options.length === 1 && options[0].avatarId === "readyplayerme";
  const width = useMemo(() => {
    if (isRpmOnly) {
      return "320px";
    }

    if (isLandscape) {
      return "400px";
    }

    return "inherit";
  }, [isLandscape, isRpmOnly]);

  const getElement = (option: AvatarResponseDto) => {
    const active = option.avatarId === initialAvatarId;
    const isReadyPlayerMe = option.avatarId === "readyplayerme";
    const imageUrl =
      isReadyPlayerMe && !option.avatarImage
        ? RpmImgSrc
        : getImageUrl(option.avatarImage, 200, 200);

    return (
      <AvatarWrapper
        key={`${option.avatarId}-${option.name}`}
        data-testid={PanelTestIds.profile.common.gridAvatarChoice(
          option.avatarId
        )}
      >
        <Spatial mouseZoom>
          <Avatar
            onClick={() => {
              onSelect(option.avatarId);
            }}
            $active={active}
            $bgColor={option.colors.backgroundColor}
          >
            {!option.colors.backgroundColor ? (
              <BackgroundImageWrapper>
                <ImgBackground $backgroundUrl={imageUrl} />
              </BackgroundImageWrapper>
            ) : (
              <BackGroundGradient />
            )}

            <Img src={imageUrl} alt={option.name} $active={active} />
            <CustomAvatarButton
              testId={PanelTestIds.profile.common.customAvatarBtn(
                option.avatarId
              )}
              hide={!isReadyPlayerMe || !active}
              onClick={(e) => {
                e.preventDefault();
                openReadyPlayerMeEditor();
              }}
            />
          </Avatar>
        </Spatial>
        <AvatarLabel>
          <Typo.Body>{option.name}</Typo.Body>
        </AvatarLabel>
      </AvatarWrapper>
    );
  };

  return (
    <>
      <Space h={6} />
      <input
        name="avatarId"
        type="hidden"
        value={initialAvatarId}
        data-testid={PanelTestIds.profile.common.avatarHiddenInput}
      />
      <Row
        gap={3}
        height="200px"
        width={width}
        testId={PanelTestIds.profile.common.gridAvatarPicker}
      >
        {options.map((option) => getElement(option))}
      </Row>
      <Space h={3} />
    </>
  );
};

export default AvatarGrid;
