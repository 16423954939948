import { SvgProps } from "../../types/svg";

const CloudRainFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 4c-.02 0-.035.008-.05.008A2.496 2.496 0 0 0 10.5 1a2.48 2.48 0 0 0-1.906.898A3.48 3.48 0 0 0 5.5 0 3.5 3.5 0 0 0 2 3.5c0 .227.023.445.066.664A2.98 2.98 0 0 0 0 7a3 3 0 0 0 3 3h10a3 3 0 0 0 0-6m-2 9.25a.75.75 0 0 0-.75.75v1.25a.75.75 0 0 0 1.5 0V14a.75.75 0 0 0-.75-.75M14 11a.75.75 0 0 0-.75.75V13a.75.75 0 0 0 1.5 0v-1.25A.75.75 0 0 0 14 11m-9 2.25a.75.75 0 0 0-.75.75v1.25a.75.75 0 0 0 1.5 0V14a.75.75 0 0 0-.75-.75M2 11a.75.75 0 0 0-.75.75V13a.75.75 0 0 0 1.5 0v-1.25A.75.75 0 0 0 2 11m6 0a.75.75 0 0 0-.75.75V13a.75.75 0 0 0 1.5 0v-1.25A.75.75 0 0 0 8 11"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default CloudRainFilledIcon;
