import { ActionElementDto } from "@journee-live/gyarados/axios/esm";
import { sendGameMessage } from "../../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { IconName } from "../../../componentsLibrary/atoms/Icon";
import DynamicButton from "../../../componentsLibrary/molecules/DynamicButton";
import ActionElementIcon from "./ActionElementIcon";

export const ActionElementButton: React.FC<{
  actionElementConfig: ActionElementDto;
}> = ({ actionElementConfig }) => {
  return (
    <DynamicButton
      focused={false}
      onClick={() => {
        sendGameMessage({
          type: "ActionElementEvent",
          actionElementId: actionElementConfig.actionElementId,
          event: "click",
        });
      }}
    >
      {
        <ActionElementIcon
          name={actionElementConfig.icon as IconName}
          size="24px"
        />
      }
    </DynamicButton>
  );
};

export default ActionElementButton;
