import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TOptions } from "i18next/typescript/options";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import i18nKeys from "./language.types";

type TFunctionWrapper = (
  key: keyof typeof i18nKeys,
  options?: TOptions | undefined
) => string;

const reLocaleDivider = /[-_]/;

/** This is the main way to consume text/copy in the app.
 *  It returns both the correct text, in the correct language and
 *  with the optional overwrites from the CMS.
 *  @todo add copywriting feature support */
export const useText = (): TFunctionWrapper => {
  const { t } = useTranslation();
  return t as TFunctionWrapper;
};

function getMatchingLocale(
  i18nLocales: readonly string[],
  environmentLocale?: string | null
) {
  if (!environmentLocale) return "en";
  // Exact match
  if (i18nLocales.includes(environmentLocale)) return environmentLocale;
  // Try to match raw locale otherwise
  const [rawEnvironmentLocale] = environmentLocale.split(reLocaleDivider);
  return i18nLocales.find((locale) => {
    const [rawLocale] = locale.split(reLocaleDivider);
    return rawLocale === rawEnvironmentLocale;
  });
}

export function useCopywriting() {
  const { i18n } = useTranslation();
  const { environment } = useEnvironmentContext();

  useEffect(() => {
    if (!i18n?.isInitialized) return;
    const locale = getMatchingLocale(i18n.languages, environment.localeCode);
    // If the locale is not supported by the i18n, we don't add resources
    if (!locale) return;
    if (!environment.copywriting?.length) return;

    i18n.addResources(
      locale,
      "translation",
      environment.copywriting.reduce(
        (acc, { stringId, value }) => {
          acc[stringId] = value;
          return acc;
        },
        {} as Record<string, string>
      )
    );
  }, [
    i18n,
    i18n?.isInitialized,
    i18n?.languages,
    environment?.copywriting,
    environment?.localeCode,
  ]);
}
