import { useCallback, useState } from "react";
import { sendGameMessage } from "../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { useGetInfoCardBySlug } from "../../app/hooks/gyarados.hook";
import { useStore } from "../../app/store";
import { PanelTestIds } from "../../common/constants/testIds.constant";
import { logError } from "../../common/util/logger";
import InfoCardUi from "./InfoCard.ui";

export type Props = {
  infoCardId?: string;
};

const InfoCardLogic: React.FC<Props> = ({ infoCardId }) => {
  const closePanel = useStore((s) => s.layout.closePanel);
  const setCurrentMintEventId = useStore(
    (s) => s.minting.setCurrentMintEventId
  );
  const [previewMode, setPreviewMode] = useState(true);
  const data = useGetInfoCardBySlug(infoCardId);

  const handleDismiss = useCallback(() => {
    closePanel("infocard", { slug: infoCardId });
  }, [closePanel, infoCardId]);

  const onButtonClick = useCallback(() => {
    if (data?.infoCardId)
      window.analytics?.track("click", {
        type: "click",
        name: "info_card",
        detail: `${data.infoCardId}_primary`,
      });
    if (data?.mintEventId) {
      setCurrentMintEventId(data.mintEventId);
      closePanel("infocard", { slug: infoCardId });
    }
    if (data?.teleportPoint != null) {
      sendGameMessage({
        type: "TeleportPlayer",
        spawnPoint: data.teleportPoint,
      });
      closePanel("infocard", { slug: infoCardId });
    }
  }, [
    data?.infoCardId,
    setCurrentMintEventId,
    infoCardId,
    closePanel,
    data?.mintEventId,
    data?.teleportPoint,
  ]);

  const onSecondaryButtonClick = useCallback(() => {
    if (data?.infoCardId)
      window.analytics?.track("click", {
        type: "click",
        name: "info_card",
        detail: `${data.infoCardId}_secondary`,
      });
  }, [data?.infoCardId]);

  if (!data)
    logError(
      "GENERIC",
      `Infocard with id ${infoCardId} wants to be shown but it has no data (probably the infocard doesn't exist in the CMS).`
    );

  return data ? (
    <InfoCardUi
      previewMode={previewMode}
      setPreviewMode={setPreviewMode}
      handleDismiss={handleDismiss}
      onButtonClick={onButtonClick}
      onSecondaryButtonClick={onSecondaryButtonClick}
      data={data}
      testId={PanelTestIds.infoCard.root}
    />
  ) : null;
};

export default InfoCardLogic;
