import {
  ReactElement,
  cloneElement,
  isValidElement,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useTheme } from "styled-components";
import { isTouch } from "../../common/constants/flags.constant";
import { logError } from "../../common/util/logger";
import SpatialEffect, { SpatialConfig } from "./util/spatialEffect";

type DivWithSpatialEffect = HTMLDivElement & { _spatial: SpatialEffect };

export type Props = {
  deactivated?: boolean;
  children: ReactElement;
} & SpatialConfig;

/** An extended version of the Glass component that enables a lot of 3D effect.
 *  The main material for our panels.
 */
const Spatial: React.FC<Props> = ({
  deactivated,
  children,
  ...spatialConfig
}) => {
  const ref = useRef<DivWithSpatialEffect>(null);
  const theme = useTheme();

  // If we are on a touch device we automatically remove all mouse interactions.
  // TODO: This forces us to have `config3D` memoized
  // otherwise it creates a new object triggering the useEffect below.
  const parsedConfigs3D = useMemo(() => {
    const config = { ...spatialConfig };
    if (isTouch) {
      config.mouseZoom = false;
      config.mouseTiltX = false;
      config.mouseTiltY = false;
    }
    return config;
  }, [spatialConfig]);

  useEffect(() => {
    const element = ref.current;
    if (!theme.effectSpatial) return;
    if (!element) return;
    if (deactivated) {
      if (element._spatial) {
        element._spatial.destroy();
      }
    } else if (!element._spatial) {
      SpatialEffect.init(element, parsedConfigs3D);
    }
    return () => {
      if (element?._spatial) {
        element._spatial.destroy();
      }
    };
  }, [parsedConfigs3D, deactivated, theme.effectSpatial]);

  if (!isValidElement(children)) {
    logError(
      "GENERIC",
      `You can only pass one child and it has to be a React Element (divs,
        spans, etc) OR a forwarded component (forwardRef)`
    );
    return null;
  }

  const child = children as ReactElement;
  return cloneElement(child, {
    ...child.props,
    ref,
  });
};

export default Spatial;
