import styled from "styled-components";
import { useStore } from "../../app/store";
import { zIndex } from "../../app/style/theme";
import ActionElementsLogic from "./ActionElements.logic";

const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: ${zIndex.panels};
`;

const ActionElementsPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.actionElements.visible);
  return (
    <Layout id="layout-action-elements">
      {visible && <ActionElementsLogic />}
    </Layout>
  );
};

export default ActionElementsPanel;
