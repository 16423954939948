import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "./style/theme";

type Props = {
  children?: ReactNode;
};
const DefaultTheme: React.FC<Props> = ({ children }) => {
  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>;
};

export default DefaultTheme;
