import { CustomEventsSchema } from "../../types/events";

/** A simpler typesafe syntax for an event-bus
 *  based on native CustomEvents API. */

export type CustomEventName = keyof CustomEventsSchema;

export type CustomEventCallback<T extends CustomEventName> = (
  payload: CustomEventsSchema[T]
) => void;

export const emit = <T extends CustomEventName>(
  name: T,
  payload: CustomEventsSchema[T]
) => {
  window.dispatchEvent(
    new CustomEvent(name, payload ? { detail: payload } : undefined)
  );
};

export const listen = <T extends CustomEventName>(
  name: T,
  callback: CustomEventCallback<T>
) => {
  window.addEventListener(name, (e) => {
    callback((e as CustomEvent).detail);
  });
};

export const unlisten = <T extends CustomEventName>(
  name: T,
  callback: CustomEventCallback<T>
) => {
  window.removeEventListener(name, (e) => {
    callback((e as CustomEvent).detail);
  });
};
