import { PanelTestIds } from "../../../common/constants/testIds.constant";
import { useIsSmallScreen } from "../../../common/hooks/ui";
import Button from "../../../componentsLibrary/atoms/Button";
import Column from "../../../componentsLibrary/atoms/Column";
import Space from "../../../componentsLibrary/atoms/Space";
import { useText } from "../../language/language.hook";

type Props = {
  isReadyPlayerMeEditorOpen: boolean;
  setIsReadyPlayerMeEditorOpen: (open: boolean) => void;
  setHasConfirmed: (hasConfirmed: boolean) => void;
};

const ProfileBottomSection: React.FC<Props> = ({
  isReadyPlayerMeEditorOpen,
  setIsReadyPlayerMeEditorOpen,
  setHasConfirmed,
}) => {
  const t = useText();
  const isSmallScreen = useIsSmallScreen();

  return (
    <>
      {isSmallScreen && <Space h={2} />}
      <Column width="100%" align="center" justify="center">
        {isReadyPlayerMeEditorOpen ? (
          <Button.Primary
            buttonType="button"
            onClick={(e) => {
              e.preventDefault();
              setIsReadyPlayerMeEditorOpen(false);
            }}
          >
            {t("action_panel_back")}
          </Button.Primary>
        ) : (
          <Button.Primary
            testId={PanelTestIds.profile.common.confirm}
            buttonType="submit"
            onClick={() => setHasConfirmed(true)}
          >
            {t("profile_save")}
          </Button.Primary>
        )}
      </Column>
    </>
  );
};

export default ProfileBottomSection;
