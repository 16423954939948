import React, { forwardRef, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useBreakpoint } from "../../../common/hooks/ui";
import Button from "../../../componentsLibrary/atoms/Button";
import Column from "../../../componentsLibrary/atoms/Column";
import Glass from "../../../componentsLibrary/atoms/Glass";
import Hide from "../../../componentsLibrary/atoms/Hide";
import Icon from "../../../componentsLibrary/atoms/Icon";
import Row from "../../../componentsLibrary/atoms/Row";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";
import { getSafeStepsSpacing } from "../../../componentsLibrary/atoms/util/ui";
import LocalVideoAvatarCircle from "../../../componentsLibrary/molecules/avatarCircle/LocalVideoAvatarCircle";
import PeerVideoAvatarCircle from "../../../componentsLibrary/molecules/avatarCircle/PeerVideoAvatarCircle";
import TitleBreakoutFloatingAvatars from "../../../componentsLibrary/molecules/avatarCircle/TitleBreakoutFloatingAvatars";
import { Ring } from "../../_login/component/StartButton";
import { useText } from "../../language/language.hook";
import { getLocalPlayerName } from "../../profile/profileSlice";
import { Props } from "../VideoAvatars.ui";
import { VideoConferenceParticipant } from "../types/videoConference.types";

const Container = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-end;
  pointer-events: all;
`;

const ControlsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const Controls = styled.div<{ $visible: boolean; $gap: number }>`
  opacity: ${(p) => (p.$visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 33px;
  z-index: 1;
  > * + * {
    ${(p) => p.$gap && `margin-top: ${getSafeStepsSpacing(p.$gap)};`}
  }
`;
const AdditionalParticipants = styled(Hide)`
  position: absolute;
  left: 0px;
  top: 22px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvatarRow = styled.div<{
  $isHovering: boolean;
  $gap: number;
  $transformOrigin?: React.CSSProperties["transformOrigin"];
}>`
  display: flex;
  position: relative;
  justify-content: flex-start;
  transition: transform 0.2s ease-out;
  transform-origin: ${(p) => p.$transformOrigin};

  > * + * {
    ${(p) => p.$gap && `margin-left: ${getSafeStepsSpacing(p.$gap)};`}
  }
`;

const AvatarsRow = forwardRef<HTMLDivElement, Props>(
  (
    {
      localParticipant,
      transformOrigin = "top left",
      allParticipants,
      activeParticipants,
      nrOfParticipants,
      showControls = true,
      toggleLocalAudio,
      roomId,
      micMuted,
      players,
      toggleVideo,
      openPlayerList,
      screenSharer,
      profileData,
      userId,
    },
    ref
  ) => {
    const { isDesktopWidth, isLargeDesktopWidth } = useBreakpoint();
    const [isPinned, setIsPinned] = useState(
      isDesktopWidth || isLargeDesktopWidth || !showControls
    );
    const [isHovering, setIsHovering] = useState(false);
    const t = useText();
    const theme = useTheme();
    const participantsAsArray = useMemo(() => {
      const publishingParticipants = activeParticipants
        .map(
          (p) =>
            allParticipants.find(
              (ap) => ap.userId === p.userId
            ) as VideoConferenceParticipant
        )
        .filter((p) => Boolean(p))
        .filter((p) => p.isPublishing) as VideoConferenceParticipant[];
      const isPresenterInActiveParticipants = publishingParticipants.find(
        (p) => p.userId === screenSharer?.userId && !p.isLocal
      );
      if (isPresenterInActiveParticipants) {
        const otherParticipants = publishingParticipants.filter(
          (p) => p.userId !== screenSharer?.userId
        );
        const updatedPresenter = allParticipants.find(
          (p) => p.userId === screenSharer?.userId
        ) as VideoConferenceParticipant;
        return [updatedPresenter, ...otherParticipants];
      }
      // Allow for maximum of 4 other participants to be shown
      return [...publishingParticipants];
    }, [activeParticipants, allParticipants, screenSharer]);
    const isFocused = isPinned || isHovering;
    const additionalParticipants = nrOfParticipants - 5;

    if (!roomId) {
      return (
        <Glass>
          <LoaderWrapper>
            <Ring isSpinning strokeWidth="4" reverse size={50} />
            <Space w={3} />
            <Column>
              <Typo.Subtitle>{t("player_joining_room")}</Typo.Subtitle>
              <Typo.Note>{t("player_please_wait")}</Typo.Note>
            </Column>
          </LoaderWrapper>
        </Glass>
      );
    }

    return (
      <Container
        ref={ref}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <AvatarRow
          $gap={2}
          $isHovering={isFocused}
          $transformOrigin={transformOrigin}
        >
          <LocalVideoAvatarCircle
            renderTitleBreakout={(props) => {
              return <TitleBreakoutFloatingAvatars {...props} isLocal />;
            }}
            speakingIndicatorPosition="front"
            micMuted={micMuted}
            playerName={getLocalPlayerName(profileData)}
            avatarColor={profileData?.avatarColor}
            playerKey={userId || null}
            toggleVideo={toggleVideo}
            toggleLocalAudio={toggleLocalAudio}
            key={localParticipant?.id}
            participant={localParticipant}
            hideTitle={!isFocused}
            isSharingScreen={screenSharer?.id === localParticipant?.id}
          />
          {participantsAsArray.map((participant) => {
            const player = players[participant.userId];

            return (
              <PeerVideoAvatarCircle
                renderTitleBreakout={(props) => (
                  <TitleBreakoutFloatingAvatars {...props} isLocal={false} />
                )}
                key={participant.userId}
                speakingIndicatorPosition="back"
                isSharingScreen={screenSharer?.id === participant.id}
                hideTitle={!isFocused}
                playerKey={participant.userId}
                playerName={player?.name}
                avatarColor={player?.avatarColor}
                participant={participant}
              />
            );
          })}
          <Space w={1} />
          <ControlsWrapper>
            <AdditionalParticipants
              hide={isFocused || additionalParticipants <= 0}
            >
              <Button.Glass circular>
                <Typo.Note color={theme.colorAbove3}>
                  +{additionalParticipants}
                </Typo.Note>
              </Button.Glass>
            </AdditionalParticipants>
            {showControls && Boolean(participantsAsArray.length) && (
              <Controls $visible={isFocused} $gap={2}>
                <Row gap={2}>
                  <Button.Glass
                    circular
                    onClick={() => setIsPinned((prev) => !prev)}
                  >
                    {isPinned ? (
                      <Icon.Cross size="15px" inheritColor />
                    ) : (
                      <Icon.Pin size="15px" inheritColor />
                    )}
                  </Button.Glass>
                  <Typo.Body color={theme.colorBelow1} weight="600">
                    {isPinned ? t("avatars_unpin") : t("avatars_pin")}
                  </Typo.Body>
                </Row>
                <Row gap={2}>
                  <Button.Glass circular onClick={openPlayerList}>
                    <Icon.Chevron inheritColor size="16px" rotate="180deg" />
                  </Button.Glass>
                  <Typo.Body color={theme.colorBelow1} weight="600">
                    {t("avatars_player_list", { count: nrOfParticipants })}
                  </Typo.Body>
                </Row>
              </Controls>
            )}
          </ControlsWrapper>
        </AvatarRow>
      </Container>
    );
  }
);

export default AvatarsRow;
