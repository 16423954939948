import React, { useEffect } from "react";
import { useStore } from "../../../app/store";

type Props = {
  setAutocapture: (
    autocapture: "start" | "complete" | "cancel" | undefined
  ) => void;
};

const VideoCaptureLogicPersistent: React.FC<Props> = ({ setAutocapture }) => {
  const openPanel = useStore((s) => s.layout.openPanel);
  const mediaCaptureAction = useStore((s) => s.mediaCapture.mediaCaptureAction);
  const setMediaCaptureAction = useStore(
    (s) => s.mediaCapture.setMediaCaptureAction
  );

  useEffect(() => {
    const isAutocapturing = mediaCaptureAction !== undefined;
    const isVideoCapture = mediaCaptureAction?.mediaType === "video";
    if (isAutocapturing && isVideoCapture) {
      setAutocapture(mediaCaptureAction.action);
      setMediaCaptureAction(undefined);
      openPanel("videoCapture");
    }
  }, [
    mediaCaptureAction,
    mediaCaptureAction?.mediaType,
    mediaCaptureAction?.action,
    openPanel,
    setAutocapture,
    setMediaCaptureAction,
  ]);

  return null;
};

export default VideoCaptureLogicPersistent;
