import { styled } from "styled-components";

export const TopRightBar = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 8px 0;
  top: max(var(--safe-margin-top), 16px);
  right: max(var(--safe-margin-right), 16px);
`;
