import {
  FromGameMessage,
  FromGameMessagesSchema,
} from "../../app/gameConnection/messages/fromGameMessages";
import { SliceCreator } from "../../app/store";
import { logWarn } from "../../common/util/logger";

const sliceName = "actionElements";

export type ActionElementState =
  FromGameMessagesSchema["ActionElementsInfo"]["elements"][number];

export type ActionElementsState = {
  actionElementsState: Record<string, ActionElementState>;
  handleActionElementMessage: (message: FromGameMessage) => void;
};

type State = {
  actionElements: ActionElementsState;
};

export const createActionElementsSlice: SliceCreator<State> = (set) => ({
  actionElements: {
    actionElementsState: {},
    handleActionElementMessage: (message) =>
      set(
        (state) => {
          let existingActionElement;
          switch (message.type) {
            case "ActionElementsInfo":
              state.actionElements.actionElementsState =
                message.elements.reduce(
                  (acc, element) => {
                    acc[element.actionElementId] = element;
                    return acc;
                  },
                  {} as Record<string, ActionElementState>
                );
              break;
            case "ActionElementStatus":
              existingActionElement =
                state.actionElements.actionElementsState[
                  message.actionElementId
                ];
              if (existingActionElement) {
                existingActionElement.value = message.value;
                existingActionElement.hidden = message.hidden;
                existingActionElement.options = message.options;
              } else {
                state.actionElements.actionElementsState[
                  message.actionElementId
                ] = {
                  actionElementId: message.actionElementId,
                  value: message.value,
                  hidden: message.hidden,
                  options: message.options,
                };
              }
              break;
            default:
              logWarn("GENERIC", "Unkown ActionElementMessage", message);
              break;
          }
        },
        false,
        sliceName + "/dispatchActionElementMessage"
      ),
  },
});
