import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useEnvironmentContext } from "../../../app/EnvironmentDataProvider";
import { sendGameMessage } from "../../../app/gameConnection/webrtc/webRtcMessageHandlers";
import {
  getQueryStrings,
  removeQueryString,
} from "../../../app/routing/routingUtils";
import { useStore } from "../../../app/store";
import { READY_PLAYER_ME_AVATAR_URL } from "../../../common/constants/configs.constant";

/**
 * Updates the game with avatar (and profile) data.
 *
 * If there is a custom avatar, it sends the request to load the file and updates the game when loading finishes.
 * Go to https://miro.com/app/board/uXjVOQ629tE=/ (Password: RPMintegration) to see the RPM communication design.
 *
 * !!! This should be only be called once on a top level component so the listeners are always active and not duplicated. !!!
 */
const useInitializeAvatar = () => {
  const { rpmAvatarId } = getQueryStrings();
  const navigate = useNavigate();
  const { environment } = useEnvironmentContext();

  const { profileData, externalAssetLoadStatus } = useStore((s) => s.profile);
  const gameIsReady = useStore((s) => s.gameConnection.gameIsReady);
  const setCustomAvatarUrl = useStore((s) => s.profile.setCustomAvatarUrl);
  const closePanel = useStore((s) => s.layout.closePanel);

  // Load the custom avatar on load if there is one
  useEffect(() => {
    if (gameIsReady) {
      const hasRPM =
        environment.enableRpmAvatar ||
        environment.avatars.some(
          (avatar) => avatar.avatarId === "readyplayerme"
        );

      if (hasRPM && (profileData.customAvatarUrl || rpmAvatarId)) {
        let customAvatarUrl = profileData.customAvatarUrl;
        if (rpmAvatarId) {
          const url = `${READY_PLAYER_ME_AVATAR_URL}${rpmAvatarId}`;
          customAvatarUrl = url;
          setCustomAvatarUrl(url);
          removeQueryString(navigate, "rpmAvatarId");
        }

        sendGameMessage({
          type: "LoadExternalAsset",
          uri: customAvatarUrl,
          provider: "readyplayerme",
          intent: "customAvatar",
        });
      } else {
        sendGameMessage({
          type: "BusinessCard",
          ...profileData,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameIsReady]);

  // Send the avatar data to the game when the avatar is loaded
  useEffect(() => {
    if (
      externalAssetLoadStatus === "loaded" &&
      profileData.avatarId === "readyplayerme"
    ) {
      closePanel("hint");
      sendGameMessage({
        type: "BusinessCard",
        ...profileData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closePanel, externalAssetLoadStatus]);
};

export default useInitializeAvatar;
