import useDynamicButtonMenuMovements from "../../common/hooks/useDynamicButtonMenuMovements";
import DynamicButtonMenu from "../../componentsLibrary/molecules/dynamicButtonMenu/DynamicButtonMenu";

interface Props {
  focused?: boolean;
}

const MovementButton: React.FC<Props> = ({ focused }) => {
  const { movementsOptions, SelectedMovementIcon } =
    useDynamicButtonMenuMovements();

  return (
    <DynamicButtonMenu
      focused={focused}
      items={movementsOptions}
      closeOnSelect
      anchor={<SelectedMovementIcon inheritColor size="24px" />}
    />
  );
};

export default MovementButton;
