import { isTouch } from "../../common/constants/flags.constant";
import { SearchableProfile } from "../../domains/profile/lib/profileTypes";
import { getPlayerKey, parsePlayerKey } from "../../domains/social/socialUtils";
import { AudioQuality } from "../../types/webrtc";
import { SliceCreator } from "../store";
import { DataChannelStatus, StreamingStatus } from "./gameConnectionTypes";
import {
  FromGameMessage,
  FromGameMessagesSchema,
} from "./messages/fromGameMessages";
import {
  NearbyPlayer,
  RegionState,
  StreamingStats,
} from "./messages/sharedDataTypes";
import { ToGameMessagesSchema } from "./messages/toGameMessages";
import { sendGameMessage } from "./webrtc/webRtcMessageHandlers";
import { ClientConfig } from "./webrtc/webRtcTypes";
import { WSStatus } from "./websocket/websocketTypes";

export type QueueStatus = {
  success: boolean;
  available: boolean;
  index: number;
  checked: boolean;
};

const sliceName = "gameConnection";

export interface GameConnectionState {
  gameIsReady: boolean;

  streamId: string | null;
  setStreamId: (streamId: string | null) => void;

  rtcConfig: ClientConfig | null;
  setRtcConfig: (peerConnectionOptions: ClientConfig | null) => void;

  serverVersion: string | null;
  setServerVersion: (serverVersion: string | null) => void;

  playerCount: number;
  setPlayerCount: (playerCount: number) => void;

  wsStatus: WSStatus;
  setWSStatus: (wsStatus: WSStatus) => void;

  queueStatus: QueueStatus;
  setQueueStatus: (queueStatus: QueueStatus) => void;

  videoEncoderQP: number;
  setVideoEncoderQP: (qp: number) => void;

  streamingStats: StreamingStats | null;
  setStreamingStats: (stats: StreamingStats | null) => void;

  streamingStatus: StreamingStatus | null;
  setStreamingStatus: (status: StreamingStatus | null) => void;

  dataChannelStatus: DataChannelStatus | null;
  setDataChannelStatus: (status: DataChannelStatus | null) => void;

  streamingError: Error | null;
  setStreamingError: (error: Error | null) => void;
  clearStreamingError: () => void;

  handleGameConnectionMessage: (message: FromGameMessage) => void;

  performanceStats: Omit<FromGameMessagesSchema["PerformanceStats"], "type">;
  nearbyPlayers: NearbyPlayer[];
  allPlayers: Record<string, SearchableProfile>;
  setAllPlayers: (players: SearchableProfile[]) => void;
  getPlayerKeyFromUserId: (userId: string) => string | null;
  getPlayerByKey: (
    playerId: number,
    roomId: string
  ) => SearchableProfile | null;
  getPlayerByCompositeKey: (compositeKey: string) => SearchableProfile | null;
  getUserIdFromPlayerKey: (playerKey: string) => string | null;
  setPlayer: (player: SearchableProfile, playerKey: string) => void;
  getMyPlayerKey: () => string | null;
  regionState: RegionState | null;

  playerId: number | null;
  roomId: string | null;
  setDevRoomAndPlayerId: (roomId: string, playerId: number) => void;

  instanceUrl: string | null;
  setInstanceUrl: (url: string | null) => void;

  audioQuality: AudioQuality;
  setAudioQuality: (quality: AudioQuality) => void;

  clientInfo: Omit<ToGameMessagesSchema["ClientInfo"], "type"> | null;
  setClientInfo: (
    info: Omit<ToGameMessagesSchema["ClientInfo"], "type">
  ) => void;
  currentMovementType: "Fly" | "Walk" | "Hover";
}

type State = {
  gameConnection: GameConnectionState;
};

export const createGameConnectionSlice: SliceCreator<State> = (set, get) => ({
  gameConnection: {
    streamId: null,
    setStreamId: (streamId) =>
      set(
        (state) => {
          state.gameConnection.streamId = streamId;
        },
        false,
        sliceName + "/setStreamId"
      ),

    rtcConfig: null,
    setRtcConfig: (config) =>
      set(
        (state) => {
          state.gameConnection.rtcConfig = config;
        },
        false,
        sliceName + "/setRtcConfig"
      ),

    serverVersion: null,
    setServerVersion: (version) =>
      set(
        (state) => {
          state.gameConnection.serverVersion = version;
        },
        false,
        sliceName + "/setServerVersion"
      ),

    playerCount: 0,
    setPlayerCount: (count) =>
      set(
        (state) => {
          state.gameConnection.playerCount = count;
        },
        false,
        sliceName + "/setPlayerCount"
      ),

    wsStatus: WSStatus.CLOSED,
    setWSStatus: (status) =>
      set(
        (state) => {
          state.gameConnection.wsStatus = status;
        },
        false,
        sliceName + "/setWSStatus"
      ),

    queueStatus: {
      success: false,
      available: false,
      index: -1,
      checked: false,
    },
    setQueueStatus: (status) =>
      set(
        (state) => {
          state.gameConnection.queueStatus = status;
        },
        false,
        sliceName + "/setQueueStatus"
      ),

    videoEncoderQP: 0,
    setVideoEncoderQP: (qp) =>
      set(
        (state) => {
          state.gameConnection.videoEncoderQP = qp;
        },
        false,
        sliceName + "/setVideoEncoderQP"
      ),

    streamingStats: null,
    setStreamingStats: (stats) =>
      set(
        (state) => {
          state.gameConnection.streamingStats = stats;
        },
        false,
        sliceName + "/setStreamingStats"
      ),

    streamingStatus: null,
    setStreamingStatus: (status) =>
      set(
        (state) => {
          state.gameConnection.streamingStatus = status;
        },
        false,
        sliceName + "/setStreamingStatus"
      ),

    dataChannelStatus: null,
    setDataChannelStatus: (status) =>
      set(
        (state) => {
          state.gameConnection.dataChannelStatus = status;
        },
        false,
        sliceName + "/setDataChannelStatus"
      ),

    streamingError: null,
    setStreamingError: (error) =>
      set(
        (state) => {
          state.gameConnection.streamingError = error;
        },
        false,
        sliceName + "/setStreamingError"
      ),
    clearStreamingError: () =>
      set(
        (state) => {
          state.gameConnection.streamingError = null;
        },
        false,
        sliceName + "/clearStreamingError"
      ),

    performanceStats: {
      cpuUsage: 0,
      gpuUsage: 0,
      fps: 0,
    },

    allPlayers: {},
    setPlayer: (player, playerKey) =>
      set(
        (state) => {
          state.gameConnection.allPlayers[playerKey] = player;
        },
        false,
        sliceName + "/setAllPlayers"
      ),
    setAllPlayers: (players) =>
      set(
        (state) => {
          players.forEach((player) => {
            state.gameConnection.allPlayers[player.userId] = player;
          });
        },
        false,
        sliceName + "/setAllPlayers"
      ),
    getPlayerKeyFromUserId: (userId: string) => {
      const allPlayers = get().gameConnection.allPlayers;
      const player = allPlayers[userId];
      if (player) {
        return getPlayerKey({
          playerId: player.photonPlayerId,
          roomId: player.photonRoomId,
        });
      }
      return null;
    },
    getPlayerByKey: (playerId: number, roomId: string) => {
      const allPlayers = Object.values(get().gameConnection.allPlayers);
      return (
        allPlayers.find(
          (player) =>
            player.photonPlayerId === playerId && player.photonRoomId === roomId
        ) || null
      );
    },
    getMyPlayerKey: () => {
      const playerId = get().gameConnection.playerId;
      const roomId = get().gameConnection.roomId;
      if (playerId && roomId) {
        return getPlayerKey({ playerId, roomId });
      }
      return null;
    },
    getUserIdFromPlayerKey: (playerKey: string) => {
      const result = parsePlayerKey(playerKey);
      if (!result) return null;
      const { playerId, roomId } = result;
      const allPlayers = Object.values(get().gameConnection.allPlayers);
      const player = allPlayers.find(
        (player) =>
          player.photonPlayerId === playerId && player.photonRoomId === roomId
      );
      if (!player && playerId === get().gameConnection.playerId) {
        return get()?.session?.visitorTokenData?.id || null;
      }
      return player?.userId || null;
    },
    getPlayerByCompositeKey: (compositeKey: string) => {
      const result = parsePlayerKey(compositeKey);
      if (!result) return null;
      const { playerId, roomId } = result;
      const allPlayers = Object.values(get().gameConnection.allPlayers);
      return (
        allPlayers.find(
          (player) =>
            player.photonPlayerId === playerId && player.photonRoomId === roomId
        ) || null
      );
    },

    nearbyPlayers: [],

    playerId: null,
    roomId: null,
    setDevRoomAndPlayerId: (roomId: string, playerId: number) => {
      set(
        (state) => {
          state.gameConnection.roomId = roomId;
          state.gameConnection.playerId = playerId;
        },
        false,
        sliceName + "/setDevRoomAndPlayerId"
      );
    },

    gameIsReady: false,

    instanceUrl: null,
    setInstanceUrl: (url) =>
      set(
        (state) => {
          state.gameConnection.instanceUrl = url;
        },
        false,
        sliceName + "/setInstanceUrl"
      ),

    audioQuality: "low",
    setAudioQuality: (quality) =>
      set(
        (state) => {
          state.gameConnection.audioQuality = quality;
        },
        false,
        sliceName + "/setAudioQuality"
      ),

    clientInfo: null,
    setClientInfo: (info) =>
      set(
        (state) => {
          state.gameConnection.clientInfo = info;
        },
        false,
        sliceName + "/setClientInfo"
      ),

    currentMovementType: "Walk",
    regionState: null,
    handleGameConnectionMessage: (message) =>
      set((state) => {
        switch (message.type) {
          case "PerformanceStats":
            state.gameConnection.performanceStats = message;
            break;
          case "NearbyPlayers":
            // I know this is not how its supposed to be used, but keep in mind the following:
            // - This message arrives every second or so
            // - It gives you an array of nearby players
            // - Old array of players is replaced with new one, making it rerender any component that is listening for
            //   even if the array is the same
            // - This is a workaround to prevent rerendering of the same array
            // If we hit a performance bottleneck, keep this in mind for optimization
            // Proper way to do is for unreal to send us only when nearby players change, but that would probably require
            // a lot of refactors on unreal side
            if (
              isCustomEquals(
                state.gameConnection.nearbyPlayers,
                message.players
              )
            ) {
              return;
            }
            state.gameConnection.nearbyPlayers = message.players;
            break;
          case "PhotonPlayerConnected":
            state.gameConnection.playerId = message.playerId;
            state.gameConnection.roomId = message.roomId;
            break;
          case "GameIsReady":
            state.gameConnection.gameIsReady = true;
            sendGameMessage({
              type: "ClientInfo",
              isTouchDevice: isTouch,
              langCode: state.i18n.selectedLocaleCode,
              userAgent: window.navigator?.userAgent,
              ...(state.gameConnection.clientInfo || {}),
            });
            break;
          case "EnterRegion":
            window.analytics?.track("region", {
              type: "region",
              name: message.regionId,
              state: "enter",
            });
            state.gameConnection.regionState = {
              regionId: message.regionId,
              state: "enter",
            };
            break;
          case "ExitRegion":
            window.analytics?.track("region", {
              type: "region",
              name: message.regionId,
              state: "exit",
            });
            state.gameConnection.regionState = {
              regionId: message.regionId,
              state: "leave",
            };
            break;
          case "GameQuiz":
            window.analytics?.track("quiz", {
              type: "quiz",
              name: message.id,
              detail: message.answer,
            });
            break;
          case "AnalyticsEvent":
            window.analytics?.track("game", {
              type: "game",
              name: message.eventName,
            });
            break;
          case "MovementTypeChanged":
            state.gameConnection.currentMovementType = message.movementType;
            break;
          case "ProductSelected":
            window.analytics?.track("item_select", {
              type: "item_select",
              name: message.slug,
              detail: message.variant,
            });
            break;
          case "ItemAdded":
            window.analytics?.track("item_add", {
              type: "item_add",
              name: message.slug,
              amount: message.amount,
            });
            break;
          case "CurrencyChanged":
            window.analytics?.track(
              "game_currency",
              {
                type: "game_currency",
                name: message.currencyId,
                amount: message.newAmount,
              },
              {
                plugins: {
                  // Disable this specific track call for all plugins except Logemon
                  all: false,
                  logemon: true,
                },
              }
            );
            break;
          default:
            break;
        }
      }),
  },
});

function isCustomEquals(
  firstArray: NearbyPlayer[],
  secondArray: NearbyPlayer[]
) {
  if (firstArray.length !== secondArray.length) return false;
  for (let i = 0; i < firstArray.length; i++) {
    if (
      firstArray[i].playerId !== secondArray[i].playerId ||
      firstArray[i].name !== secondArray[i].name
    ) {
      return false;
    }
  }
  return true;
}
