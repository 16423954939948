import React, { useEffect } from "react";
import { useStore } from "../../../app/store";
import { MediaCaptureAction } from "../MediaCapture.slice";

type Props = {
  setAutocapture: (autocapture: MediaCaptureAction | undefined) => void;
};

const PhotoCaptureLogicPersistent: React.FC<Props> = ({ setAutocapture }) => {
  const openPanel = useStore((s) => s.layout.openPanel);
  const isAutocapturing = useStore(
    (s) =>
      s.mediaCapture.mediaCaptureAction?.mediaType === "image" &&
      s.mediaCapture.mediaCaptureAction?.action === "start"
  );
  const setMediaCaptureAction = useStore(
    (s) => s.mediaCapture.setMediaCaptureAction
  );

  useEffect(() => {
    if (isAutocapturing) {
      setAutocapture("start");
      setMediaCaptureAction(undefined);
      openPanel("photo");
    }
  }, [isAutocapturing, openPanel, setAutocapture, setMediaCaptureAction]);

  return null;
};

export default PhotoCaptureLogicPersistent;
