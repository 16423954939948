import { useEffect, useState } from "react";
import { useStore } from "../../../app/store";

/** Currently just a quick fade from black. */
const useLoginIntroStep = () => {
  const completeStep = useStore((s) => s.userFlow.completeStep);
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    let id: number | undefined;
    if (!id)
      id = window.setTimeout(() => {
        completeStep("login:intro");
        setIsDone(true);
      }, 500);
    return () => {
      if (id) window.clearTimeout(id);
    };
  }, [completeStep]);

  return isDone;
};

export default useLoginIntroStep;
