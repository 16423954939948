export type VideoConferenceParticipant = {
  id: string;
  userId: string;
  name?: string;
  isLocal: boolean;
  isPublishing: boolean;
  isAudioOn: boolean;
  isSpeaking: boolean;
  isVideoOn: boolean;
  isVideoHidden: boolean;
  lastActive: number;
  streams: MediaStream[];
};

export const VideoConferenceState = {
  DISCONNECTED: "DISCONNECTED",
  DISCONNECTING: "DISCONNECTING",
  PERMISSION_SETUP: "PERMISSION_SETUP",
  BLOCKED_PERMISSIONS: "BLOCKED_PERMISSIONS",
  NO_DEVICES: "NO_DEVICES",
  SESSION_INITIALIZING: "SESSION_INITIALIZING",
  SESSION_INITIALIZED: "SESSION_INITIALIZED",
  DEVICE_SETUP: "DEVICE_SETUP",
  CONFERENCE_PREVIEW: "CONFERENCE_PREVIEW",
  JOINING: "JOINING",
  JOINED: "JOINED",
  ERROR: "ERROR",
} as const;

export type VideoConferenceStateType =
  (typeof VideoConferenceState)[keyof typeof VideoConferenceState];

export type ConferenceEvent =
  | "participantJoined"
  | "participantLeft"
  | "screenShareStarted"
  | "screenShareStopped";
