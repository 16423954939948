import cloneDeep from "lodash/cloneDeep";
import { DefaultTheme } from "styled-components";
import tinycolor from "tinycolor2";
import { opaqueEquivalent, tint } from "../../common/util/color";

export const zIndex = {
  background: -100,
  experience: -1,
  overExperience: 10,
  panels: 200,
  overlays: 400,
  extras: 500,
  alerts: 600,
  debug: 2000,
  popupMenues: 99999,
} as const;

// Inspired by https://www.radix-ui.com/themes/docs/theme/breakpoints
// Use this as a general guideline. The main separation will be:
// Desktop layout --> width > s
// Mobile layout --> width <= s
export const breakpoints = {
  px: {
    xs: "0px", // Phones
    s: "768px", // Tablets
    m: "1024px", // Laptops
    l: "1280px", // Desktops
    xl: "1640px", // Large desktops
  },
  number: {
    xs: 0, // Phones
    s: 768, // Tablets
    m: 1024, // Laptops
    l: 1280, // Desktops
    xl: 1640, // Large desktops
  },
} as const;

// Base values to ensure consistency across the UI.
export const steps = {
  // Partially taken form here: https://www.radix-ui.com/themes/docs/theme/typography#type-scale
  // Assuming 1rem == 16px
  font: {
    f10: { size: "0.688rem", lineHight: "1rem" }, // 11px, 16px
    f20: { size: "0.813rem", lineHight: "1.25rem" }, // 13px, 20px
    f30: { size: "0.875rem", lineHight: "1.25rem" }, // 14px, 20px
    f40: { size: "1rem", lineHight: "1.5rem" }, // 16px, 24px
    f50: { size: "1.125rem", lineHight: "1.5rem" }, // 18px, 24px
    f60: { size: "1.25rem", lineHight: "1.75rem" }, // 20px, 28px
    f70: { size: "1.5rem", lineHight: "1.875rem" }, // 24px, 30px
    f80: { size: "1.75rem", lineHight: "2.25rem" }, // 28px, 36px
    f90: { size: "2.188rem", lineHight: "2.5rem" }, // 35px, 40px
    f100: { size: "2.5rem", lineHight: "2.75rem" }, // 40px, 44px
  },
  fontWeight: {
    light: 300,
    medium: 500,
    semiBold: 600,
  },
  // Inspired by https://www.radix-ui.com/themes/docs/theme/visual-style#radius
  borderRadius: {
    b0: "0px",
    b10: "4px",
    b20: "8px",
    b30: "16px",
    b40: "24px",
    b100: "9999px",
  },
  // Vaguely based on Robber design. Do higher numbers cost more performance?
  blur: {
    b20: "8px",
    b30: "16px",
    b40: "32px",
  },
  // From Robbert design.
  elementHeight: {
    e10: "32px",
    e20: "40px",
    e30: "48px",
    e40: "52px",
  },
  // https://www.radix-ui.com/themes/docs/theme/layout#spacing-scale
  // Keep as an array for code simplicity.
  spacing: [
    "0px", // 0
    "4px", // 1
    "8px", // 2
    "12px", // 3
    "16px", // 4
    "24px", // 5
    "32px", // 6
    "40px", // 7
    "48px", // 8
    "64px", // 9
  ],
  // From Rorbbert design.
  panelWidth: {
    p10: "216px",
    p20: "320px",
    p30: "416px",
  },
  // Used ainly to dynamically create the default color steps from the root AB colors.
  alpha: {
    a8: 0.08,
    a10: 0.1,
    a20: 0.2,
    a40: 0.4,
    a60: 0.6,
    a75: 0.75,
    a95: 0.95,
    a100: 1,
  },
} as const;

export const layoutMargins = {
  default: {
    marginTop: steps.spacing[3],
    // Aligns all panels visually with the action buttons.
    marginSide: "21px",
    marginBottom: steps.spacing[3],
  },
  smallScreen: {
    marginTop: steps.spacing[2],
    marginSide: steps.spacing[5],
    marginBottom: steps.spacing[2],
  },
} as const;

const defaultBlack = "rgb(17, 17, 17)";
const defaultWhite = "rgb(255, 255, 255)";

export const defaultTheme: DefaultTheme = {
  fontMain: "Manrope",
  fontMainFallback:
    'Manrope, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  fontDisplay: "'Unbounded'",
  fontDisplayFallback:
    '"Unbounded", Manrope, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',

  /*
  The design system used 2 main colors A(bove) and B(low)
  for text-like and background-like elements respectively.
  color A has 0-1-2-3-4-5 alpha shades while color B has 0-1-2 alpha shades.
  
  See ./styled-components.d.ts for more information.
  */

  colorAboveRoot: defaultBlack, // Icons, text, and such.
  colorAbove0: tinycolor(defaultBlack).setAlpha(steps.alpha.a10).toRgbString(), // Usually: Box (section in panel) background, input fields background, Buttons background, hover-selectable-bg, separator lines, border on some images.
  colorAbove1: tinycolor(defaultBlack).setAlpha(steps.alpha.a20).toRgbString(), // Usually: Box (section in panel) background, input fields background, Buttons background, hover-selectable-bg, separator lines, border on some images.
  colorAbove2: tinycolor(defaultBlack).setAlpha(steps.alpha.a40).toRgbString(), // Usually: "note" (secondary) text, input fields labels, Box-over-Box background, close button icon.
  colorAbove3: tinycolor(defaultBlack).setAlpha(steps.alpha.a60).toRgbString(), // Usually: "body" (main) text, Button-light labels, hints labels, hints background.
  colorAbove4: tinycolor(defaultBlack).setAlpha(steps.alpha.a75).toRgbString(), // Usually: Button labels, Icon color.
  colorAbove5: tinycolor(defaultBlack).setAlpha(steps.alpha.a95).toRgbString(), // Usually: Titles, Text in input fields, Alternative to brand color bg, text in hovered selectables.
  colorAboveNoBackground: "rgb(255, 255, 255)", // For icons and text directly above the video streaming.

  colorBelowRoot: defaultWhite, // Backgrounds and panels.
  colorBelow0: tinycolor(defaultWhite).setAlpha(steps.alpha.a60).toRgbString(), // Main panels backgrounds, Floating panels (e.g. tabs) backgrounds, other unstructured floating elements backgrounds.
  colorBelow1: tinycolor(defaultWhite).setAlpha(steps.alpha.a75).toRgbString(), // Button background for on image/video buttons.
  colorBelow2: tinycolor(defaultWhite).setAlpha(steps.alpha.a95).toRgbString(), // Usually: Dropdown background, hover-highlight effects, Alternative to brand color bg label.

  colorBelowBrand: defaultBlack,
  colorAboveBrand: defaultWhite,
  colorInfo: defaultBlack,

  colorLightText: tinycolor(defaultWhite)
    .setAlpha(steps.alpha.a95)
    .toRgbString(),
  colorDarkText: tinycolor(defaultBlack)
    .setAlpha(steps.alpha.a95)
    .toRgbString(),

  colorGradient: {
    purpleBlue: {
      start: "#A961F2",
      end: "#634BF3",
      value: "linear-gradient(180deg, #A961F2 0%, #634BF3 100%)",
    },
  },

  colorShadow: tinycolor(defaultBlack).setAlpha(steps.alpha.a40).toRgbString(),

  colorSuccess: "rgb(58, 223, 0)",
  colorDanger: "rgb(255, 0, 122)",

  isLoginColorInverted: false,

  radiusFull: steps.borderRadius.b100,
  radiusBig: steps.borderRadius.b40,
  radiusSmall: steps.borderRadius.b20,
  radiusTiny: steps.borderRadius.b10,

  blurStrong: steps.blur.b40,
  blurLight: steps.blur.b30,

  effectSpatial: true,

  borderWidth: "1px",
  borderColor: tinycolor(defaultBlack).setAlpha(steps.alpha.a10).toRgbString(),
  topLogoHeight: "24px",

  elements: {
    panel: {
      width: steps.panelWidth.p20,
    },
    field: {
      padding: "0px 16px",
      width: "320px",
      height: steps.elementHeight.e30,
      gap: steps.spacing[2],
    },
    actionButton: {
      size: "44px",
    },
  },
};

export const generateTheme = (args?: {
  presets?: Partial<ThemePreset>;
  overrides?: Partial<DefaultTheme>;
}): DefaultTheme => {
  const theme = cloneDeep(defaultTheme);

  if (!args) return theme;
  if (args.presets) {
    const isDark = args?.presets?.mode === "dark";
    // Dark Mode
    let above = isDark ? defaultWhite : defaultBlack;
    let below = isDark ? defaultBlack : defaultWhite;
    theme.colorBelowBrand = isDark ? defaultWhite : defaultBlack;
    theme.colorAboveBrand = isDark ? defaultBlack : defaultWhite;

    // Tinit
    if (args?.presets?.tintA) above = tint(above, args.presets.tintA);
    if (args?.presets?.tintB) below = tint(below, args.presets.tintB);

    // Contrast
    const aboveAlpha =
      args.presets.contrast === "flat"
        ? [
            steps.alpha.a10,
            steps.alpha.a60,
            steps.alpha.a60,
            steps.alpha.a95,
            steps.alpha.a95,
            steps.alpha.a95,
          ]
        : [
            steps.alpha.a10,
            steps.alpha.a20,
            steps.alpha.a40,
            steps.alpha.a60,
            steps.alpha.a75,
            steps.alpha.a95,
          ];

    const belowAlpha =
      args.presets.contrast === "flat"
        ? [steps.alpha.a60, steps.alpha.a100, steps.alpha.a100]
        : [steps.alpha.a40, steps.alpha.a75, steps.alpha.a100];

    // Coloring
    theme.colorAboveRoot = tinycolor(above).setAlpha(1).toRgbString();
    theme.colorAbove0 = tinycolor(above).setAlpha(aboveAlpha[0]).toRgbString();
    theme.colorAbove1 = tinycolor(above).setAlpha(aboveAlpha[1]).toRgbString();
    theme.colorAbove2 = tinycolor(above).setAlpha(aboveAlpha[2]).toRgbString();
    theme.colorAbove3 = tinycolor(above).setAlpha(aboveAlpha[3]).toRgbString();
    theme.colorAbove4 = tinycolor(above).setAlpha(aboveAlpha[4]).toRgbString();
    theme.colorAbove5 = tinycolor(above).setAlpha(aboveAlpha[5]).toRgbString();

    theme.colorBelowRoot = tinycolor(below).setAlpha(1).toRgbString();
    theme.colorBelow0 = tinycolor(below).setAlpha(belowAlpha[0]).toRgbString();
    theme.colorBelow1 = tinycolor(below).setAlpha(belowAlpha[1]).toRgbString();
    theme.colorBelow2 = tinycolor(below).setAlpha(belowAlpha[2]).toRgbString();

    if (args.presets.transparency === "opaque") {
      theme.blurLight = "0px";
      theme.blurString = "0px";

      const idealBackground = isDark ? defaultBlack : defaultWhite;
      theme.colorBelow0 = opaqueEquivalent(theme.colorBelow0, idealBackground);

      theme.colorBelow1 = opaqueEquivalent(theme.colorBelow1, idealBackground);
      theme.colorBelow2 = opaqueEquivalent(theme.colorBelow2, idealBackground);
      // colorBelow0 is the most common backdrop color for panels.
      theme.colorAbove0 = opaqueEquivalent(
        theme.colorAbove0,
        theme.colorBelow0
      );
      theme.colorAbove1 = opaqueEquivalent(
        theme.colorAbove1,
        theme.colorBelow0
      );
      theme.colorAbove2 = opaqueEquivalent(
        theme.colorAbove2,
        theme.colorBelow0
      );
      theme.colorAbove3 = opaqueEquivalent(
        theme.colorAbove3,
        theme.colorBelow0
      );
      theme.colorAbove4 = opaqueEquivalent(
        theme.colorAbove4,
        theme.colorBelow0
      );
      theme.colorAbove5 = opaqueEquivalent(
        theme.colorAbove5,
        theme.colorBelow0
      );
    }

    theme.borderColor = theme.colorAbove0;

    // Border radius
    switch (args.presets.borderRadius) {
      case "medium":
        theme.radiusFull = steps.borderRadius.b20;
        theme.radiusBig = steps.borderRadius.b20;
        theme.radiusSmall = steps.borderRadius.b10;
        theme.radiusTiny = steps.borderRadius.b10;
        break;
      case "none":
        theme.radiusFull = steps.borderRadius.b0;
        theme.radiusBig = steps.borderRadius.b0;
        theme.radiusSmall = steps.borderRadius.b0;
        theme.radiusTiny = steps.borderRadius.b0;
        break;
      case "full":
      default:
        break;
    }

    if (args.presets.effects3D === "none") {
      theme.effectSpatial = false;
    }
  }

  // Apply overrides.
  if (args.overrides) {
    for (const key in args.overrides) {
      if (Object.prototype.hasOwnProperty.call(args.overrides, key)) {
        const override = args.overrides[key];
        if (override !== undefined && override !== null) theme[key] = override;
      }
    }
  }

  return theme;
};

export const DEFAULT_THEME_PRESETS: ThemePreset = {
  mode: "light",
  contrast: "soft",
  transparency: "glass",
  effects3D: "all",
  borderRadius: "medium",
  tintA: "rgb(10, 10, 10)",
  tintB: "white",
};

export type ThemePreset = {
  mode: "dark" | "light";
  contrast: "soft" | "flat";
  transparency: "glass" | "opaque";
  effects3D: "none" | "all";
  borderRadius: "none" | "medium" | "full";
  tintA: React.CSSProperties["color"];
  tintB: React.CSSProperties["color"];
};

// TODO: TO REDEFINE, ignore for now
export type ThemeElements =
  | "title"
  | "subtitle"
  | "subtitleSecondary"
  | "paragraph"
  | "paragraphSecondary"
  | "bicolorIcon"
  | "button"
  | "buttonSubtle"
  | "buttonCircular"
  | "buttonCircularSubtle"
  | "buttonPrimary"
  | "buttonPrimarySubtle"
  | "buttonPrimaryCircular"
  | "buttonExtra"
  | "buttonCard"
  | "buttonAction"
  | "buttonActionGroup"
  | "hint"
  | "userBubble"
  | "linkMinimal"
  | "dropdownMinimal"
  | "tabs"
  | "fieldText"
  | "fieldDropdown"
  | "toggle"
  | "ButtonRadio"
  | "headerIconBox"
  | "headerLogoBox"
  | "scrollIndicator";
