/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActorRef, assign, createMachine, fromPromise } from "xstate";
import { ConferenceError } from "../../../common/hooks/useConferenceErrors";
import MediaDevicesService from "../lib/mediaDevices";
import { VonageStateMachineEvents } from "../types/videoConferenceMachine.types";

export type CameraCheckerContext = {
  rootActor: ActorRef<any, any>;
  videoInputSource: MediaDeviceInfo | null;
  videoInputDevices: MediaDeviceInfo[];
  mediaPermissions: { camera: boolean; microphone: boolean };
};

export const cameraCheckerMachine = createMachine(
  {
    id: "cameraChecker",
    types: {} as {
      context: CameraCheckerContext;
      input: {
        rootActor: any;
      };
      events: VonageStateMachineEvents;
    },
    initial: "Idle",
    context: ({ input }) => ({
      rootActor: input.rootActor,
      videoInputSource: null,
      videoInputDevices: [],
      mediaPermissions: { camera: false, microphone: false },
    }),
    states: {
      Idle: {
        on: {
          "lugia.startCheckingForCamera": {
            target: "Active",
            actions: assign({
              videoInputSource: ({ event }) => event.data.videoInputSource,
              videoInputDevices: ({ event }) => event.data.videoInputDevices,
              mediaPermissions: ({ event }) => event.data.mediaPermissions,
            }),
          },
        },
      },
      Active: {
        on: {
          "lugia.stopCheckingForCamera": "Idle",
        },
        after: {
          1000: "CheckingCamera",
        },
      },
      CheckingCamera: {
        on: {
          "lugia.stopCheckingForCamera": "Idle",
        },
        invoke: {
          id: "cameraFeedChecker",
          src: "cameraFeedChecker",
          input: ({ context }) => {
            return context;
          },
          onDone: [
            {
              guard: ({ event }) => {
                return event.output.length === 0;
              },
              actions: ["republishDevices"],
              target: "Idle",
            },
            {
              target: "Active",
            },
          ],
        },
      },
    },
  },
  {
    actions: {
      republishDevices: ({ context }) => {
        context.rootActor.send({
          type: "conference.republishDevices",
        });
      },
    },
    actors: {
      cameraFeedChecker: fromPromise<ConferenceError[], CameraCheckerContext>(
        async ({ input }) => {
          const hasCameras = input.videoInputDevices.length > 0;
          const hasCameraPermissions = input.mediaPermissions.camera;
          return MediaDevicesService.checkForCameraAvailability(
            hasCameras,
            hasCameraPermissions,
            input.videoInputSource?.deviceId
          );
        }
      ),
    },
  }
);
