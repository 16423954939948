import useDynamicButtonMenuEmojis from "../../common/hooks/useDynamicButtonMenuEmojis";
import Icon from "../../componentsLibrary/atoms/Icon";
import DynamicButton from "../../componentsLibrary/molecules/DynamicButton";
import DynamicButtonMenu from "../../componentsLibrary/molecules/dynamicButtonMenu/DynamicButtonMenu";

interface Props {
  focused?: boolean;
}

const EmojisButton: React.FC<Props> = ({ focused }) => {
  const availableEmojiBarItems = useDynamicButtonMenuEmojis();
  if (availableEmojiBarItems.length === 1) {
    const icon = focused
      ? availableEmojiBarItems[0].component
      : availableEmojiBarItems[0].componentFilled;

    return (
      <DynamicButton
        focused={focused}
        onClick={availableEmojiBarItems[0].onClick}
      >
        {icon}
      </DynamicButton>
    );
  }

  return (
    Boolean(availableEmojiBarItems.length) && (
      <DynamicButtonMenu
        focused={focused}
        items={availableEmojiBarItems}
        alwaysOpen={availableEmojiBarItems.length === 1}
        anchor={<Icon.HandWaveFilled inheritColor size="20px" />}
      />
    )
  );
};

export default EmojisButton;
