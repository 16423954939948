import { styled } from "styled-components";
import { breakpoints, layoutMargins, zIndex } from "../../app/style/theme";
import { useUpdateLayout } from "./layoutHooks";
import { PanelName, PanelSettings } from "./panels.types";

export type PanelLayoutId = `panel-${PanelName}`;

export const BaseLayout = styled.div<{ id: PanelLayoutId }>``;

export const FullAbsoluteLayout = styled(BaseLayout)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const LayoutGrid = styled.div`
  /*
  ! REMEMBER: Is the responsibility of every ui in every panel
  ! to reactivate the pointer events where needed (on clickable ui).
  */
  pointer-events: none;
  overflow: hidden;

  display: grid;
  position: absolute;
  /*
  ! THIS should be the only place in the experience where we set 100dvh and 100dvw
  So that hall height are consistent across all components.
  */
  width: 100dvw;
  height: 100dvh;
  box-sizing: border-box;
  grid-template-rows: [top-bar-top] 60px [top-bar-bottom] 1fr [bottom-bar-top] 80px [bottom-bar-bottom];
  grid-template-columns: [left-edge] 1fr [middle-left] 10px [middle-right] 1fr [right-edge];
`;

const Top = styled.div`
  z-index: ${() => zIndex.panels};
  position: relative;
  grid-column: left-edge / right-edge;
  grid-row: top-bar-top / top-bar-bottom;
`;
const Left = styled.div`
  z-index: ${() => zIndex.panels};
  position: relative;
  grid-column: left-edge / middle-left;
  grid-row: top-bar-bottom / bottom-bar-top;
  min-height: 100%;
  margin-left: ${layoutMargins.default.marginSide};
  @media screen and (max-width: ${breakpoints.px.s}) {
    margin-inline-start: 0px;
  }
`;
const Right = styled.div`
  z-index: ${() => zIndex.panels};
  position: relative;
  grid-column: middle-right / right-edge;
  grid-row: top-bar-bottom / bottom-bar-top;
  min-height: 100%;
  margin-right: ${layoutMargins.default.marginSide};
  @media screen and (max-width: ${breakpoints.px.s}) {
    margin-inline-end: 0px;
  }
`;
const Bottom = styled.div`
  z-index: ${() => zIndex.panels};
  position: relative;
  grid-column: left-edge / right-edge;
  grid-row: bottom-bar-top / bottom-bar-bottom;
`;
const Fullscreen = styled.div`
  position: relative;
  min-width: 100%;
  min-height: 100%;
  grid-column: left-edge / right-edge;
  grid-row: top-bar-top / bottom-bar-bottom;
  /* Safe mergins for fullscreen layouts need to be handled
  by each component as a fullscreen background/overlay/panel does need
  to extend beyound the safe area of a device. */
`;
const Video = styled(Fullscreen)`
  z-index: ${() => zIndex.overExperience};
`;
const Overlays = styled(Fullscreen)`
  z-index: ${() => zIndex.overlays};
`;
const Extras = styled(Fullscreen)`
  z-index: ${() => zIndex.extras};
`;

type Props = {
  panels: PanelSettings[];
};

/**
 * Implements the placing and zIndex side of
 * the layout system for panels (see layoutSlice.ts).
 */
const PanelsLayout: React.FC<Props> = ({ panels: panelsSettings }) => {
  const panels = useUpdateLayout(panelsSettings);

  return (
    <LayoutGrid>
      <Video id="layout-video">{panels.video}</Video>
      <Top id="layout-top">{panels.top}</Top>
      <Bottom id="layout-bottom">{panels.bottom}</Bottom>
      <Left id="layout-left">{panels.left}</Left>
      <Right id="layout-right">{panels.right}</Right>
      <Overlays id="layout-overlays">{panels.overlay}</Overlays>
      <Extras id="layout-extras">{panels.extra}</Extras>
    </LayoutGrid>
  );
};

export default PanelsLayout;
