import styled, { css } from "styled-components";
import Button from "../../componentsLibrary/atoms/Button";
import Icon from "../../componentsLibrary/atoms/Icon";
import Typo from "../../componentsLibrary/atoms/Typo";
import { DivFlex, Props } from "./Hint.ui";
import { ContentContainer, IconContainer } from "./component/CommonHintUi";

const Container = styled.div<{
  $visible?: boolean;
  $hasButton: boolean;
  $noAnimation?: boolean;
}>`
  box-sizing: border-box;
  width: auto;
  height: auto;
  background-color: ${(p) => p.theme.colorBelow0};
  padding: 2px ${(p) => (p.$hasButton ? "2px" : "16px")} 2px 2px;
  border: none;
  border-radius: ${(p) => p.theme.radiusBig};
  backdrop-filter: blur(${(p) => p.theme.blurLight});
  margin: 0 8px;
  position: absolute;
  top: max(var(--safe-margin-top), 16px);
  ${(p) =>
    !p.$noAnimation &&
    css`
      animation: hint-container-${p.$visible ? "enter" : "exit"} 600ms cubic-bezier(0.61, 1, 0.88, 1);
      opacity: ${p.$visible ? 1 : 0};
    `}

  @keyframes hint-container-enter {
    0% {
      transform: translate(0px, 20px);
    }

    50% {
      transform: translate(0px, -1px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }

  @keyframes hint-container-exit {
    0% {
      transform: translate(0px, 0px);
    }

    100% {
      transform: translate(0px, -20px);
    }
  }

  transition:
    opacity 0.3s,
    top 0.3s;
`;

const HintUiSmall: React.FC<Props> = ({
  visible = true,
  content,
  onClick,
  icon,
  noAnimation,
}) => {
  return (
    <Container
      $visible={visible}
      $hasButton={Boolean(onClick)}
      $noAnimation={noAnimation}
    >
      <DivFlex>
        <div>
          <IconContainer $visible={visible} $noAnimation={noAnimation}>
            {icon ?? <Icon.CircleInfo size="20px" inheritColor />}
          </IconContainer>
        </div>
        <ContentContainer $visible={visible} $noAnimation={noAnimation}>
          <Typo.Body>{content}</Typo.Body>
        </ContentContainer>
        {onClick && (
          <Button.Secondary circular onClick={onClick}>
            <Icon.Chevron size="18px" rotate="90deg" />
          </Button.Secondary>
        )}
      </DivFlex>
    </Container>
  );
};

export default HintUiSmall;
