import React from "react";
import styled from "styled-components";
import { ReactionName } from "../../actionBarPanel/lib/ReactionsTypes";
import { useReactionsContext } from "../providers/Reactions.provider";
import ReactionGlow from "./ReactionGlow";

const GLOWS: {
  name: ReactionName;
  color: string;
}[] = [
  {
    name: "thumbsUp",
    color: "#00FF66",
  },
  {
    name: "love",
    color: "#FF007A",
  },
  {
    name: "celebrate",
    color: "#A961F2",
  },
  {
    name: "laughing",
    color: "#634BF3",
  },
  {
    name: "sad",
    color: "#0057FF",
  },
  {
    name: "surprised",
    color: "#4BF3F3",
  },
];

const EmojisContainer = styled.div`
  position: absolute;
  bottom: 0;
  height: 100%;
  pointer-events: none;
`;

const GlowsContainer = styled.div`
  z-index: -1;
  position: absolute;
  bottom: -3px;
`;

export type ReactionItem = {
  value: ReactionName;
  tooltip: string;
  icons: {
    anchor: React.ReactNode;
    stage1: React.ReactNode;
    stage2: React.ReactNode;
    stage3: React.ReactNode;
  };
};

const ReactionsBar: React.FC = () => {
  const { glowData, setGlowData } = useReactionsContext();

  return (
    <>
      <GlowsContainer>
        {GLOWS.map((glow, index) => (
          <ReactionGlow
            key={glow.name}
            position={index}
            color={glow.color}
            data={glowData[glow.name]}
            setData={setGlowData[glow.name]}
          />
        ))}
      </GlowsContainer>
      <EmojisContainer id="emojis-container" />
    </>
  );
};

export default ReactionsBar;
