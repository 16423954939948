import tinycolor from "tinycolor2";
import { GameColor } from "../../app/gameConnection/messages/sharedDataTypes";
import { Color } from "../../app/style/styled-components";

export const getRandomColor = () =>
  tinycolor({ h: 0, s: 1, l: 0.5 })
    .spin((Math.random() * 2 - 1) * 360)
    .toRgb();

export const getRandomColorWithAlpha = () => {
  const color = getRandomColor();
  color.a = 1;
  return color;
};

/**
 * Check and parses a hex color into rgba
 * @param hex string
 * @returns an array of the rgba components [int, int, int, float] or [] if
 * the hex is not valid.
 */

export const hexToRgba = (hex: string) => {
  if (!hex) return [];
  let color = hex.substring(1);

  if (/^#(([A-Fa-f0-9]){3}|([A-Fa-f0-9]){6}|([A-Fa-f0-9]){8})$/.test(hex)) {
    if (color.length === 3) {
      color = color
        .split("")
        .map((hex) => hex + hex)
        .join("");
    }

    const values = [];
    while (color.length > 0) {
      values.push(parseInt(color.substring(0, 2), 16));
      color = color.substring(2);
    }

    const alpha = values.length === 3 ? 1 : values[3] / 255;
    return [...values.splice(0, 3), alpha];
  }

  return [];
};

/**
 * Split a rgb(a) or hex color into an array of values.
 * It doesn't check if the color is correctly formatted.
 * @param str input a css color
 * @returns an array of the rgb(a) components [int, int, int, optional float]
 */
export const parseColor = (str: string) => {
  const input = str.trim();
  if (input.substring(0, 1) == "#") {
    return hexToRgba(str);
  } else
    return input
      .split("(")[1]
      .split(")")[0]
      .split(",")
      .map((x) => +x);
};

/**
 * Simply change the alpha value in a css string.
 *
 * @param color css color (rgb, rgba, hex)
 * @param alpha number from 0 to 1
 * @returns css color in rgba string (null if there is an error)
 */
export const replaceAlpha = (color: string, alpha: number): string => {
  const rgba = parseColor(color);
  return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${alpha ?? 1})`;
};

/**
 * This method parses a css colour and fallback to a specific color
 * if the provided one is just transparent. This also takes care of
 * selecting the right opacity if on Firefox with backdrop filter
 * disabled.
 *
 * @param color css color
 * @returns css color
 */
export const ensureMinimumOpacityLevel = (
  color: string | null | undefined
): string | null => {
  if (color) {
    const rgba = parseColor(color);
    const alpha = Math.max(rgba[3] || 0, 0.2);
    return replaceAlpha(color, alpha);
  }
  return null;
};

export const rgbaToHex = (color: {
  r: number;
  g: number;
  b: number;
  a: number;
}) => {
  const { r, g, b, a } = color;
  return tinycolor({ r, g, b, a }).toHex8String();
};

export const unrealToCSSRGBColor = (c: GameColor) =>
  `rgb(${c.r}, ${c.g}, ${c.b})`;
export const cssToUnrealColor = (c: Color): GameColor => {
  const color = tinycolor(c).toRgb();
  return { r: color.r, g: color.g, b: color.b, a: color.a };
};

export const multiplyAlpha = (c: string | undefined, value: number) => {
  if (!c) return c;
  const color = tinycolor(c);
  const alpha = color.getAlpha();
  return color.setAlpha(alpha * value).toRgbString();
};

/** Return the inverted value, preserving the alpha and ignoring the color. */
export const grayscaleInvert = (color?: string): string => {
  const parsedColor = tinycolor(color);
  const luma = parsedColor.isValid() ? parsedColor.getLuminance() : 0.5;
  const alpha = parsedColor.isValid() ? parsedColor.getAlpha() : 1;
  const inverted = Math.floor((1 - luma) * 255);

  return `rgba(${inverted}, ${inverted}, ${inverted}, ${alpha})`;
};

/** Recolor the base color using the tint color. If the tint color is grey 50% the tint
 * has no effect.
 * Uses css color strings.
 */
export const tint = (base: string, tint: string): string => {
  const baseColor = tinycolor(base).toRgb();
  const tintColor = tinycolor(tint).toRgb();

  const r1 = baseColor.r / 255;
  const g1 = baseColor.g / 255;
  const b1 = baseColor.b / 255;

  const r2 = (tintColor.r / 255 - 0.5) * 2;
  const g2 = (tintColor.g / 255 - 0.5) * 2;
  const b2 = (tintColor.b / 255 - 0.5) * 2;

  const res = {
    r: Math.min(Math.max(0, r1 + r2), 1),
    g: Math.min(Math.max(0, g1 + g2), 1),
    b: Math.min(Math.max(0, b1 + b2), 1),
  };

  res.r = Math.floor(res.r * 255);
  res.g = Math.floor(res.g * 255);
  res.b = Math.floor(res.b * 255);

  return tinycolor(res).toRgbString();
};

/** Find the non alpha equivalent of a color if it was on top of a given background. */
export const opaqueEquivalent = (
  colorWithAlpha: string,
  backgroundColor: string
): string => {
  const c1 = tinycolor(colorWithAlpha).toRgb();
  const c2 = tinycolor(backgroundColor).toRgb();

  const r1 = c1.r / 255;
  const g1 = c1.g / 255;
  const b1 = c1.b / 255;
  const a1 = c1.a;

  const r2 = c2.r / 255;
  const g2 = c2.g / 255;
  const b2 = c2.b / 255;
  const a2 = 1; // We assume the background to be solid to have a non alpha result.

  // Final alpha (should be always 1 in our case).
  const a3 = 1; //a2 + a1 - a2 * a1;

  // Premultiply the color channels with alpha.
  const r1a = r1 * a1;
  const g1a = g1 * a1;
  const b1a = b1 * a1;

  const r2a = r2 * a2;
  const g2a = g2 * a2;
  const b2a = b2 * a2;

  // Composit
  const r3a = r1a + r2a * (1 - a1);
  const g3a = g1a + g2a * (1 - a1);
  const b3a = b1a + b2a * (1 - a1);

  // Unmultiply
  const r3 = r3a / a3;
  const g3 = g3a / a3;
  const b3 = b3a / a3;

  const res = {
    r: Math.floor(Math.min(Math.max(0, r3 * 255), 255)),
    g: Math.floor(Math.min(Math.max(0, g3 * 255), 255)),
    b: Math.floor(Math.min(Math.max(0, b3 * 255), 255)),
  };
  return tinycolor(res).toRgbString();
};

export const isColorBright = (c: string) => {
  // Variables for red, green, blue values
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (c.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    const color = c.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );
    if (!color) return false;

    r = +color[1];
    g = +color[2];
    b = +color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    const withOpacity = c.substring(1); // strip #
    const withoutOpacity = withOpacity.substring(0, 6); // strip alpha channel
    const color = +`0x${withoutOpacity}`;

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  // Using the HSP value, determine whether the color is light or dark
  return hsp > 150.5;
};
