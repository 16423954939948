import Column from "../../../componentsLibrary/atoms/Column";
import HeaderBadge from "../../../componentsLibrary/atoms/HeaderBadge";
import Icon from "../../../componentsLibrary/atoms/Icon";
import Row from "../../../componentsLibrary/atoms/Row";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";

const QuestHeader: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => {
  return (
    <Column align="center">
      <Space h={8} />
      <Row width="100%" align="center" justify="center">
        <HeaderBadge>
          <Icon.Star inheritColor size="32px" />
        </HeaderBadge>
      </Row>
      <Space h={4} />
      <Typo.Title>{title}</Typo.Title>
      <Space h={2} />
      <Typo.Body>{description}</Typo.Body>
    </Column>
  );
};

export default QuestHeader;
