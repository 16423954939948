import { useEffect } from "react";
import { useStore } from "../../../app/store";
import { PropsLoginPageUi } from "../LoginPageUi";

const useLoginIsDoneStep = (
  setLoginPageProps: (props: PropsLoginPageUi) => void
): {
  isLoginDone: boolean;
} => {
  const isStepReached = useStore((s) => s.userFlow.isStepReached);
  const hasExperienceBegun = isStepReached("experience:ready");
  const completeStep = useStore((s) => s.userFlow.completeStep);

  useEffect(() => {
    if (!hasExperienceBegun) return;
    setLoginPageProps({
      isDismissed: true,
      isMainButtonVisible: false,
    });
    completeStep("experience:ready");
  }, [completeStep, hasExperienceBegun, setLoginPageProps]);
  return { isLoginDone: hasExperienceBegun };
};

export default useLoginIsDoneStep;
