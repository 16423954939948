import { useMemo } from "react";
import { useStore } from "../../../../app/store";
import { SearchableProfile } from "../../../profile/lib/profileTypes";
import NearbyPlayerAvatar from "./NearbyPlayerAvatar";

const usePlayerResults = (searchResults: SearchableProfile[]) => {
  const allPlayers = useStore((s) => s.gameConnection.allPlayers);
  const roomId = useStore((s) => s.gameConnection.roomId);
  const openPanel = useStore((s) => s.layout.openPanel);
  return useMemo(() => {
    if (!roomId) return [];
    return searchResults.map((player) => {
      if (!player.photonPlayerId) return null;
      const playerKey = player.userId;
      const fullProfile = allPlayers[playerKey];
      if (!fullProfile) return null;
      const avatarColor = fullProfile?.avatarColor;
      return (
        <NearbyPlayerAvatar
          size={68}
          openPanel={openPanel}
          avatarColor={avatarColor}
          playerKey={playerKey}
          playerName={fullProfile.name}
          key={playerKey}
          playerId={player.photonPlayerId}
          roomId={roomId}
        />
      );
    });
  }, [roomId, searchResults, allPlayers, openPanel]);
};

export default usePlayerResults;
