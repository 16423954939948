import { FromGameMessage } from "../../app/gameConnection/messages/fromGameMessages";
import { SliceCreator } from "../../app/store";

export interface MapState {
  /* Active region with category Map */
  activeRegion: string;
  handleMapMessage: (message: FromGameMessage) => void;
}

type State = {
  map: MapState;
};

export const createMapSlice: SliceCreator<State> = (set) => ({
  map: {
    activeRegion: "",
    handleMapMessage: (message: FromGameMessage) => {
      set((state) => {
        if (message.type === "ActiveRegion" && message.categoryId === "Map") {
          state.map.activeRegion = message.regionId;
        }
      });
    },
  },
});
