import { ReactNode, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { isTouch } from "../../common/constants/flags.constant";
import { useTimer } from "../../common/hooks/timings";
import { PrefixWith$ } from "../../types/typescript";

const Div = styled.div<PrefixWith$<Omit<ExciteProps, "children">>>`
  border-radius: ${(p) => (p.$squared ? "0px" : "9999px")};
  /* Needed to remove a wierd bottom padding. */
  display: inline-flex;
  /* A dirty sirty trick to align pesky SVGs on circular buttons. */
  transform: scale(1.0001);
  width: ${(p) => p.$width ?? "auto"};
  height: ${(p) => p.$height ?? "auto"};
  padding: ${(p) => p.$padding ?? "0px"};
  > * {
    pointer-events: all;
    transition: transform ${(p) => p.$hoverSpeedMs ?? 300}ms;
    transition-timing-function: ease-in-out;
  }
  > div {
    inline-size: 100%;
  }
  /* Hover Effects */
  ${(p) =>
    !p.$disabled &&
    !isTouch &&
    css`
      .noTouch &:hover {
        > * {
          transition: transform ${p.$hoverSpeedMs ?? 300}ms;
          ${`transform:
      translateX(${p.$hoverShiftX ?? "0px"}) 
      translateY(${p.$hoverShiftY ?? "0px"}) 
      scale(${p.$hoverResize ?? "1"}) 
      rotate(${p.$hoverRotate ?? "0deg"})
      translateZ(0);`}
        }
      }
    `}

  /* Click Effects */
  ${(p) =>
    !p.$disabled &&
    css`
      &.click {
        > * {
          transition: transform ${p.$clickSpeedMs ?? 300}ms;
          ${`transform:
      translateX(${p.$clickShiftX ?? "0px"}) 
      translateY(${p.$clickShiftY ?? "0px"}) 
      scale(${p.$clickResize ?? "1"}) 
      rotate(${p.$clickRotate ?? "0deg"});`}
        }
      }
    `}
`;

export type ExciteProps = {
  /** Width of the parent */
  width?: React.CSSProperties["width"];
  /** Height of the parent */
  height?: React.CSSProperties["height"];
  /** Disable the effect. */
  disabled?: boolean;
  /** Css scale. */
  hoverResize?: string;
  /** Css angle. */
  hoverRotate?: string;
  /** Css dimension. */
  hoverShiftX?: string;
  /** Css dimension. */
  hoverShiftY?: string;
  hoverSpeedMs?: number;

  /** Css scale. */
  clickResize?: string;
  /** Css angle. */
  clickRotate?: string;
  /** Css dimension. */
  clickShiftX?: string;
  /** Css dimension. */
  clickShiftY?: string;
  clickSpeedMs?: number;

  /** Decide if the hitbox should be squared instead of circular */
  squared?: boolean;
  /** Css dimension. Useful to add more "clickable space" around the target. */
  padding?: string;
  children?: ReactNode;
};

/** Utility component to quickly add hover and click flares to elements. */
const Excite: React.FC<ExciteProps> = ({ children, ...props }) => {
  const [click, setClick] = useState(false);

  const { restartTimer, stopTimer } = useTimer({
    callback: () => {
      setClick(false);
    },
  });

  useEffect(() => {
    if (click) {
      restartTimer((props.clickSpeedMs ?? 300) + 100);
    } else stopTimer();
  }, [props.clickSpeedMs, restartTimer, click, stopTimer]);

  return (
    <Div
      $height={props.height}
      $width={props.width}
      className={click ? "click" : ""}
      onClick={() => setClick(true)}
      $disabled={props.disabled}
      $hoverResize={props.hoverResize}
      $hoverRotate={props.hoverRotate}
      $hoverShiftX={props.hoverShiftX}
      $hoverShiftY={props.hoverShiftY}
      $hoverSpeedMs={props.hoverSpeedMs}
      $clickResize={props.clickResize}
      $clickRotate={props.clickRotate}
      $clickShiftX={props.clickShiftX}
      $clickShiftY={props.clickShiftY}
      $clickSpeedMs={props.clickSpeedMs}
      $squared={props.squared}
      $padding={props.padding}
    >
      <div>{children}</div>
    </Div>
  );
};

export default Excite;
