import { useState } from "react";
import { useIsLandscape, useIsSmallScreen } from "../../../common/hooks/ui";
import Button from "../../../componentsLibrary/atoms/Button";
import Column from "../../../componentsLibrary/atoms/Column";
import InputText from "../../../componentsLibrary/atoms/InputText";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";
import { useText } from "../../language/language.hook";

type AuthPasswordFormProps = {
  onAuth: (password?: string) => void;
  error?: React.ReactNode | null;
  captcha?: React.ReactNode | null;
};

const AuthPasswordForm: React.FC<AuthPasswordFormProps> = ({
  onAuth,
  error,
  captcha,
}) => {
  const t = useText();
  const [password, setPassword] = useState("");
  const isSmallScreen = useIsSmallScreen();
  const isLandscape = useIsLandscape();
  const isPortrait = isSmallScreen && !isLandscape;

  return (
    <>
      <Typo.Title>{t("login_password_title")}</Typo.Title>
      <Space h={2} />
      <Typo.Body
        style={{
          padding: !isSmallScreen ? "0 43px" : "inherit",
          textAlign: !isSmallScreen || isPortrait ? "center" : "inherit",
        }}
      >
        {t("login_password_description")}
      </Typo.Body>
      <Space h={4} />
      <Column width="320px" align="stretch">
        <InputText
          noForm
          type="password"
          label={t("login_password_field_label")}
          name="password"
          value={password}
          onChange={(value) => setPassword(value)}
        />
      </Column>
      {error}
      {captcha && (
        <>
          <Space h={5} />
          {captcha}
        </>
      )}
      <Space h={5} />
      <Button.Primary onClick={() => onAuth(password)}>
        {t("login_password_button")}
      </Button.Primary>
    </>
  );
};

export default AuthPasswordForm;
