import { AvatarResponseDto } from "@journee-live/gyarados/axios/esm";
import styled, { useTheme } from "styled-components";
import { PanelTestIds } from "../../../common/constants/testIds.constant";
import ColorPicker from "../../../componentsLibrary/atoms/ColorPicker";
import Column from "../../../componentsLibrary/atoms/Column";
import HeaderBadge from "../../../componentsLibrary/atoms/HeaderBadge";
import Icon from "../../../componentsLibrary/atoms/Icon";
import Row from "../../../componentsLibrary/atoms/Row";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";
import { useText } from "../../language/language.hook";
import { ProfileData } from "../lib/profileTypes";
import { ProfileFieldConfigurations } from "../util/profile";
import AvatarSelectorUi from "./AvatarSelectorUi";
import ProfileBottomSection from "./ProfileBottomSection";
import ProfileFormFields from "./ProfileFormFields";
import ProfileTitle from "./ProfileTitle";
import ReadyPlayerMeEditor from "./ReadyPlayerMeEditor";

type Props = {
  data: ProfileData;
  setCustomAvatarUrl: (url: string) => void;
  settings: ProfileFieldConfigurations;
  onChange: (field: keyof ProfileData, value: string | boolean) => void;
  avatarsOptions?: AvatarResponseDto[];
  isReadyPlayerMeEditorOpen: boolean;
  setIsReadyPlayerMeEditorOpen: (value: boolean) => void;
  testId?: string;
  isLandscape?: boolean;
  hasConfirmed: boolean;
  hasReadyPlayerMe: boolean;
  setHasConfirmed: (value: boolean) => void;
  isMissingAvatar: boolean;
};

const Hide = styled.div<{
  hide: boolean;
  height?: number;
  hideOverflow?: boolean;
  $heightSpeed?: number;
}>`
  height: ${(p) => (p.hide ? "0px" : p.height + "px")};
  opacity: ${(p) => (p.hide ? 0 : 1)};
  ${(p) => p.hideOverflow && "overflow: hidden;"}

  transition:
    height ${(p) => p.$heightSpeed || 700}ms,
    opacity 300ms;
`;

const SmallProfileForm: React.FC<Props> = ({
  data,
  settings,
  onChange,
  avatarsOptions = [],
  isReadyPlayerMeEditorOpen,
  setIsReadyPlayerMeEditorOpen,
  setCustomAvatarUrl,
  isLandscape,
  hasConfirmed,
  setHasConfirmed,
  hasReadyPlayerMe,
  isMissingAvatar,
}) => {
  const t = useText();
  const theme = useTheme();
  const isRpmOnly =
    avatarsOptions.length === 1 &&
    avatarsOptions[0].avatarId === "readyplayerme";
  const showAvatarSelector = isRpmOnly || avatarsOptions.length > 1;
  const currentAvatar = avatarsOptions.find(
    (options) => options.avatarId === data.avatarId
  );
  const showAvatarColorPicker =
    settings.fields.has("avatarColor") && !currentAvatar?.singleColor;

  return (
    <Column
      align="center"
      style={{
        position: "relative",
        padding: "0 7px",
        boxSizing: "border-box",
      }}
    >
      {hasReadyPlayerMe && (
        <ReadyPlayerMeEditor
          setCustomAvatarUrl={setCustomAvatarUrl}
          closeRpmEditor={() => setIsReadyPlayerMeEditorOpen(false)}
          customAvatarUrl={data.customAvatarUrl}
          open={isReadyPlayerMeEditorOpen}
        />
      )}
      {isReadyPlayerMeEditorOpen ? null : (
        <>
          <Space h={6} />
          <Row height="100%" width="100%" align="center" justify="center">
            <HeaderBadge>
              <Icon.Bust inheritColor />
            </HeaderBadge>
          </Row>
        </>
      )}
      <Space h={4} />
      {!isReadyPlayerMeEditorOpen && (
        <>
          <ProfileTitle avatarsOptions={avatarsOptions} />
          <ProfileFormFields
            data={data}
            onChange={onChange}
            settings={settings}
            hasConfirmed={hasConfirmed}
          />
          {showAvatarSelector && (
            <>
              <AvatarSelectorUi
                initialAvatarId={data.avatarId}
                options={avatarsOptions}
                onSelect={(value) => onChange("avatarId", value)}
                openReadyPlayerMeEditor={() =>
                  setIsReadyPlayerMeEditorOpen(true)
                }
                isLandscape={isLandscape}
              />
              <Space h={4} />
              {isMissingAvatar && (
                <Typo.Body
                  color={theme.colorDanger}
                  testId={PanelTestIds.profile.common.avatarErrorMessage}
                >
                  {t("profile_rpm_avatar_not_selected")}
                </Typo.Body>
              )}
            </>
          )}
          <Hide hide={!showAvatarColorPicker} height={40}>
            <Space h={1} />
            <ColorPicker
              length={theme.elements.field.width}
              name="avatarColor"
              direction="horizontal"
              color={data.avatarColor}
              onChange={(value) => {
                onChange("avatarColor", value);
              }}
            />
          </Hide>
          <Space h={4} />
          <ProfileBottomSection
            isReadyPlayerMeEditorOpen={isReadyPlayerMeEditorOpen}
            setIsReadyPlayerMeEditorOpen={setIsReadyPlayerMeEditorOpen}
            setHasConfirmed={setHasConfirmed}
          />
          <Space h={5} />
        </>
      )}
    </Column>
  );
};

export default SmallProfileForm;
