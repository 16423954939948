import { useEffect } from "react";
import { useStore } from "../../app/store";
import { ProfileData } from "./lib/profileTypes";

const profileFieldsInToken = ["firstName", "lastName", "email"] as const;

export const ProfileLogicPersistent: React.FC = () => {
  const profileData = useStore((s) => s.profile.profileData);
  const setProfileData = useStore((s) => s.profile.setProfileData);
  const visitorTokenData = useStore(
    (s) => s.session.visitorTokenData
  ) as Record<string, unknown> | null;

  // We can get some data from the visitor token if it's available.
  // SSO visitors will have their data in the token.
  useEffect(() => {
    const newData: Partial<ProfileData> = {};

    if (visitorTokenData) {
      for (const key of profileFieldsInToken) {
        if (visitorTokenData[key]) {
          newData[key] = visitorTokenData[key] as string;
        }
      }
    }

    setProfileData({
      ...profileData,
      ...newData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default ProfileLogicPersistent;
