import Icon, { IconName } from "../../../componentsLibrary/atoms/Icon";

const ActionElementIcon: React.FC<{
  name: IconName;
  size: string;
}> = ({ name, size }) => {
  const IconComponent = Reflect.has(Icon, name)
    ? Icon[name]
    : Icon["QuestionMark"];
  return <IconComponent inheritColor size={size} />;
};

export default ActionElementIcon;
