import styled, { useTheme } from "styled-components";
import Icon from "../../componentsLibrary/atoms/Icon";
import Typo from "../../componentsLibrary/atoms/Typo";
import DynamicButton from "../../componentsLibrary/molecules/DynamicButton";

type Props = {
  onSocialClick?: () => void;
  focused?: boolean;
  unseenMessagesCount: number;
};

const Outer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const NotificationBadge = styled.div`
  background-color: ${(p) => p.theme.colorAbove2};
  position: absolute;
  padding: 3px 3px;
  border-radius: ${(p) => p.theme.radiusTiny};
  display: flex;
  justify-content: center;
  right: 0px;
  top: 0px;
`;

const SocialButton: React.FC<Props> = ({
  onSocialClick,
  focused,
  unseenMessagesCount,
}) => {
  const theme = useTheme();
  return (
    <Outer>
      <DynamicButton focused={focused} onClick={onSocialClick}>
        <Icon.ChatFilled shiftY="1px" inheritColor size="20px" />
      </DynamicButton>
      {Boolean(unseenMessagesCount) && (
        <NotificationBadge>
          <Typo.Body size="0.5rem" color={theme.colorBelow2} lineHeight={1}>
            {unseenMessagesCount}
          </Typo.Body>
        </NotificationBadge>
      )}
    </Outer>
  );
};

export default SocialButton;
