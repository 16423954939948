// Inspired by https://github.com/micku7zu/vanilla-tilt.js/blob/master/src/vanilla-tilt.json

/** The class below enables various fancy 3D like effects of our UI.
 *  The effects include:
 *  - Showing an element with a 3D tilt.
 *  - Shifting the 3D tilt as the mouse move across the element. [Desktop only].
 *  - Shifting the 3D tilt when the world camera moves around.
 *  - Adding a rim based on the current tilt of the element.
 *  - Resize the element on mouse enter. [Desktop only].
 *  - Add a 3D elevation effect to sub-elements. This is done witha simple parallax effect.
 *  - Add a glare effect based on the game world orientation.
 *    - A rim (backlight) effect on the Panel edges (boxShadow).
 *    - A glare effect (shifting gradient in the background).
 */
import {
  CustomEventCallback,
  listen,
  unlisten,
} from "../../../common/util/bus";
import { remap } from "../../../common/util/math";
import { CustomEventsSchema } from "../../../types/events";

export type SpatialConfig = Partial<SpatialEffects["config"]>;

// ! Touch the carefuly tweaked values in this file at your own peril.

const defaultConfig: SpatialEffects["config"] = {
  dragMotion: false,
  _dragMotionSensitivity: 0.04,
  _dragMotionReactivity: 0.1,
  _dragMotionFalloff: 0.9,

  mouseTiltX: false,
  mouseTiltY: false,
  mouseTiltXStrength: 4.5,
  mouseTiltYStrength: 3,
  _mouseTiltDirection: 1,

  mouseZoom: false,
  mouseZoomStrength: 1.012,

  tiltX: 0,
  tiltY: 0,
  _tiltPerspective: 800,

  _animationEasing: "cubic-bezier(.03,.98,.52,.99)",
  _animationDurationMs: 2000,

  glare: false,
  glareStrength: 1,

  elevation: false,
  elevationStrength: 1,
  _elevationSpread: 0.5,

  rim: false,
  rimStrength: 0.5,
  _rimSize: 1.2,
};

export default class SpatialEffects {
  config: {
    /** Add an inertial like tilt to the panel when the world camera rotation moves. */
    dragMotion: boolean;
    /** How much motion is generated by camera movments. Lower = less sensitive. */
    _dragMotionSensitivity: number;
    /** How sharp is the motion somothing. Lower = smoother & slower. */
    _dragMotionReactivity: number;
    /** How quickly the state resets (The reactivity value will also influence this). Lower = faster reset. */
    _dragMotionFalloff: number;

    /** Apply mouse tilt on the x axis. */
    mouseTiltX: boolean;
    /** Apply mouse tilt on the y axis. */
    mouseTiltY: boolean;
    /** How much extra tilt the mouse create when moving across the panel horizontally (degrees). */
    mouseTiltXStrength: number;
    /** How much extra tilt the mouse create when moving across the panel veritcally (degrees). */
    mouseTiltYStrength: number;
    _mouseTiltDirection: 1 | -1;

    /** Apply zoom on mouse entering the panel. */
    mouseZoom: boolean;
    /** 1 is no zoom, 2 is double size. */
    mouseZoomStrength: number;

    /** Set an X tilt for the panel at rest. */
    tiltX: number;
    /** Set an Y tilt for the panel at rest. */
    tiltY: number;
    /** Perspective for the tilt effect. THe lower the more extrime the prespective. */
    _tiltPerspective: number;

    _animationEasing: string;
    _animationDurationMs: number;

    /** Use glare effect on world-camera-rotation.*/
    glare: boolean;
    /** Opacity coefficient for the clare effect.*/
    glareStrength: number;

    /** Apply the elevation effect for sub-elements (".elevation-1", ".elevation-2", ".elevation-3") through a parallx effect.*/
    elevation: boolean;
    /** How much the elevated elements feel further away from the main panel. Values around 1. */
    elevationStrength: number;
    /** How spread appart are the elements on different elevation levels. between 0-3.*/
    _elevationSpread: number;

    /** Apply the rim effect world-camera-rotation.*/
    rim: boolean;
    /** Opacity coefficient for the rim effect.*/
    rimStrength: number;
    /** Size of the borders of the rim effect (in px). */
    _rimSize: number;
  };

  element: HTMLElement;
  width: number;
  height: number;
  left: number;
  top: number;

  isMouseEntered: boolean;
  mouseMoveEvent: {
    clientX: number;
    clientY: number;
  } | null;
  mouseInitX: number | null;
  mouseInitY: number | null;

  // To allow the element to react to shift in the 3D camera.
  worldOrientationData: CustomEventsSchema["worldOrientation"] | null;

  transitionTimeout: NodeJS.Timeout | null;
  frameRequestId: number | null;

  updateBind: () => void;
  onMouseEnterBind: (event: MouseEvent) => void;
  onMouseMoveBind: (event: MouseEvent) => void;
  onMouseLeaveBind: EventListenerOrEventListenerObject;
  onWorldOrientationBind: CustomEventCallback<"worldOrientation">;

  glareElement: null | HTMLElement;
  elevation1Elements: HTMLElement[];
  elevation2Elements: HTMLElement[];
  elevation3Elements: HTMLElement[];

  // dragMotion is an extra tilit movment that happens when
  // the camera in the 3d game is dragged around.
  // we need a motion of the tilit so simulate some kind of
  // elastic inertia.
  dragMotionX: number;
  dragMotionY: number;
  dragMotionTargetX: number;
  dragMotionTargetY: number;

  constructor(element: HTMLElement, config?: SpatialConfig) {
    if (!(element instanceof Node)) {
      throw new Error(
        "Can't initialize SpatialEffects because " + element + " is not a Node."
      );
    }

    this.config = { ...defaultConfig, ...config };

    this.element = element;
    this.width = 0;
    this.height = 0;
    this.left = 0;
    this.top = 0;

    this.isMouseEntered = false;
    this.mouseMoveEvent = null;
    this.mouseInitX = null;
    this.mouseInitY = null;

    this.worldOrientationData = null;

    this.transitionTimeout = null;
    this.frameRequestId = null;

    this.updateBind = this.update.bind(this);
    this.onMouseEnterBind = this.onMouseEnter.bind(this);
    this.onMouseMoveBind = this.onMouseMove.bind(this);
    this.onMouseLeaveBind = this.onMouseLeave.bind(this);
    this.onWorldOrientationBind = this.onWorldOrientation.bind(this);

    this.glareElement = null;
    this.elevation1Elements = [];
    this.elevation2Elements = [];
    this.elevation3Elements = [];

    this.dragMotionX = 0;
    this.dragMotionY = 0;
    this.dragMotionTargetX = 0;
    this.dragMotionTargetY = 0;

    if (this.config.glare) this.addGlareElements();
    if (this.config.elevation) this.addElevationElements();
    this.addEventListeners();

    this.update();
  }

  static init(element: HTMLElement, config?: SpatialConfig) {
    if (!("_spatial" in element)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (element as any)._spatial = new SpatialEffects(element, config);
    }
  }

  updateTilt(x: number, y: number, scale: number) {
    // 1) The tilt and scale effect
    // For more intuitive result we apply the X tilt to the Y rotation (and viceversa).
    this.element.style.transform = `
    perspective(${this.config._tiltPerspective}px)  
    rotateY(${x}deg)  
    rotateX(${-y}deg)  
    scale3d(${scale}, ${scale}, ${scale})`;

    // 2) The parallax for all elevated subElements
    this.updateParallaxElevation(
      this.elevation1Elements,
      x,
      y,
      this.config.elevationStrength * 0.2 + 1 * this.config._elevationSpread
    );
    this.updateParallaxElevation(
      this.elevation2Elements,
      x,
      y,
      this.config.elevationStrength * 0.2 + 2 * this.config._elevationSpread
    );
    this.updateParallaxElevation(
      this.elevation3Elements,
      x,
      y,
      this.config.elevationStrength * 0.2 + 3 * this.config._elevationSpread
    );
  }

  updateParallaxElevation(
    elements: HTMLElement[],
    x: number,
    y: number,
    ammount: number
  ) {
    elements.forEach(
      (it) =>
        (it.style.transform = `translate(${x * ammount}px, ${y * ammount}px)`)
    );
  }

  update() {
    const mouseMovement = this.getMouseMovment();
    const dragMotion = this.computeDragMotion();
    const scale =
      this.isMouseEntered && this.config.mouseZoom
        ? this.config.mouseZoomStrength
        : 1;

    this.updateTilt(
      this.config.tiltX +
        (this.config.mouseTiltX ? mouseMovement.x : 0) +
        dragMotion.x,
      this.config.tiltY +
        (this.config.mouseTiltY ? mouseMovement.y : 0) +
        dragMotion.y,
      scale
    );

    this.updateLightEffects();

    this.element.dispatchEvent(
      new CustomEvent("tiltChange", {
        detail: mouseMovement,
      })
    );

    if (!dragMotion.finished) this.newFrame();
  }

  updateLightEffects() {
    const yaw = this.worldOrientationData?.yaw ?? 0;
    const pitch = this.worldOrientationData?.pitch ?? 0;

    if (this.glareElement) {
      const strength = remap(Math.abs(yaw), 90, 120, 0, 1);
      const width = remap(Math.abs(yaw), 120, 180, 0, 100);

      const pos = remap(yaw, -180, 180, -100, 100);

      const glareIntensity = 0.1 * strength;
      this.glareElement.style.background = `linear-gradient(90deg,
      rgba(255, 255, 255, ${glareIntensity}) ${-100 + pos}%, 
      rgba(255, 255, 255, ${glareIntensity}) ${-100 + width + pos}%, 
      rgba(255, 255, 255, 0) ${0 + width / 2 + pos}%, 
      rgba(255, 255, 255, 0) ${100 - width / 2 + pos}%, 
      rgba(255, 255, 255, ${glareIntensity}) ${200 - width + pos}%,
      rgba(255, 255, 255, ${glareIntensity}) ${200 + pos}%)
      `;
      this.glareElement.style.opacity = `${this.config.glareStrength}`;
    }

    if (this.config.rim) {
      const size = this.config._rimSize;
      const rimX = remap(yaw, -120, 120, -size, size, true);
      const rimY = remap(pitch, -120, 120, -size, size, true);
      const rimStrengthX = remap(Math.abs(yaw), 110, 0, 0.5, 1);
      const rimStrengthY = remap(Math.abs(pitch), 110, 0, 0.5, 1);
      const rimStrength = rimStrengthX + rimStrengthY;

      this.element.style.boxShadow = `${rimX}px ${rimY}px 0px rgba(255, 255, 255, ${
        rimStrength * this.config.rimStrength
      })`;

      // this.element.style.boxShadow = `${rimX}px ${rimY}px 2px rgba(0, 0, 0, ${
      //   0.75 * rimStrength * this.config.rimStrength
      // }),
      // ${-rimX}px ${-rimY}px 0 rgba(255, 255, 255, ${
      //   rimStrength * this.config.rimStrength
      // })`;
    }
  }

  getMouseMovment() {
    let x = 0;
    let y = 0;
    if (this.mouseMoveEvent) {
      if (this.config.mouseTiltX && this.mouseInitX) {
        x = (this.mouseMoveEvent.clientX - this.mouseInitX) / this.width;
        x = Math.floor(x * 1000) / 1000;
        x *= this.config.mouseTiltXStrength * this.config._mouseTiltDirection;
      }
      if (this.config.mouseTiltY && this.mouseInitY) {
        y = (this.mouseMoveEvent.clientY - this.mouseInitY) / this.height;
        y = Math.floor(y * 1000) / 1000;
        y *= this.config.mouseTiltYStrength * -this.config._mouseTiltDirection;
      }
    }
    /* If the mouse travels across the panel from left to right the X number will go
       from 0 to S. on the opposite it will go from 0 to -S. */
    /* If the mouse travels across the panel from top to bottom the Y number will go
       from 0 to S. on the opposite it will go from 0 to -S. */
    // S = strength.
    return {
      x,
      y,
    };
  }

  computeDragMotion() {
    let finished = true;
    if (
      (this.config.dragMotion && this.dragMotionTargetX) ||
      this.dragMotionX ||
      this.dragMotionTargetY ||
      this.dragMotionY
    ) {
      finished = false;

      if (this.dragMotionX || this.dragMotionTargetX) {
        const delta = this.dragMotionTargetX - this.dragMotionX;
        this.dragMotionX += delta * this.config._dragMotionReactivity;
        if (
          !this.dragMotionTargetX &&
          Math.abs(this.dragMotionX - delta) < 0.01
        ) {
          this.dragMotionX = 0;
        }
      }
      if (this.dragMotionY || this.dragMotionTargetY) {
        const delta = this.dragMotionTargetY - this.dragMotionY;
        this.dragMotionY += delta * this.config._dragMotionReactivity;
        if (
          !this.dragMotionTargetY &&
          Math.abs(this.dragMotionY - delta) < 0.01
        ) {
          this.dragMotionY = 0;
        }
      }

      this.dragMotionTargetX *= this.config._dragMotionFalloff;
      this.dragMotionTargetY *= this.config._dragMotionFalloff;
      if (Math.abs(this.dragMotionTargetX) < 0.1) {
        this.dragMotionTargetX = 0;
      }
      if (Math.abs(this.dragMotionTargetY) < 0.1) {
        this.dragMotionTargetY = 0;
      }
    }

    return {
      x: this.dragMotionX,
      y: this.dragMotionY,
      finished,
    };
  }

  updateElementPosition() {
    const rect = this.element.getBoundingClientRect();

    this.width = this.element.offsetWidth;
    this.height = this.element.offsetHeight;
    this.left = rect.left;
    this.top = rect.top;
  }

  newFrame() {
    if (this.frameRequestId !== null) cancelAnimationFrame(this.frameRequestId);
    this.frameRequestId = requestAnimationFrame(this.updateBind);
  }

  setTransition() {
    if (this.transitionTimeout) clearTimeout(this.transitionTimeout);
    this.element.style.transition =
      this.config._animationDurationMs + "ms " + this.config._animationEasing;
    if (this.glareElement)
      this.glareElement.style.transition = `opacity ${this.config._animationDurationMs}ms ${this.config._animationEasing}`;
    this.elevation1Elements.forEach(
      (it) =>
        (it.style.transition = `transform ${this.config._animationDurationMs}ms ${this.config._animationEasing}`)
    );
    this.elevation2Elements.forEach(
      (it) =>
        (it.style.transition = `transform ${this.config._animationDurationMs}ms ${this.config._animationEasing}`)
    );
    this.elevation3Elements.forEach(
      (it) =>
        (it.style.transition = `transform ${this.config._animationDurationMs}ms ${this.config._animationEasing}`)
    );

    this.transitionTimeout = setTimeout(() => {
      this.element.style.transition = "";
      if (this.glareElement) {
        this.glareElement.style.transition = "";
      }
      this.elevation1Elements.forEach((it) => (it.style.transition = ""));
      this.elevation2Elements.forEach((it) => (it.style.transition = ""));
      this.elevation3Elements.forEach((it) => (it.style.transition = ""));
    }, this.config._animationDurationMs);
  }

  onMouseEnter(event: MouseEvent) {
    this.isMouseEntered = true;
    this.updateElementPosition();
    // To give the initial tilt to the object we trigger a simulated
    // mouse enter (in reset) which do not need to be tracked.
    this.element.style.willChange = "transform";
    this.mouseInitX = event.clientX;
    this.mouseInitY = event.clientY;
    this.setTransition();
  }

  onMouseMove(event: MouseEvent) {
    this.mouseMoveEvent = event;
    this.newFrame();
  }

  onMouseLeave() {
    this.isMouseEntered = false;
    this.setTransition();
    this.mouseInitX = null;
    this.mouseInitY = null;
    this.updateTilt(this.config.tiltX, this.config.tiltY, 1);
  }

  onWorldOrientation(payload: CustomEventsSchema["worldOrientation"]) {
    // When camera triggers the tilt there might be no element available.
    if (!this.element) return;
    this.worldOrientationData = payload;
    if (this.config.dragMotion) {
      this.dragMotionTargetX +=
        (payload.deltaYawn || 0) * this.config._dragMotionSensitivity;
      this.dragMotionTargetY +=
        (payload.deltaPitch || 0) * this.config._dragMotionSensitivity;
    }
    this.newFrame();
  }

  addEventListeners() {
    if (
      this.config.mouseZoom ||
      this.config.mouseTiltX ||
      this.config.mouseTiltY
    ) {
      this.element.addEventListener(
        "mouseenter",
        this.onMouseEnterBind as EventListenerOrEventListenerObject
      );
      this.element.addEventListener("mouseleave", this.onMouseLeaveBind);
      this.element.addEventListener(
        "mousemove",
        this.onMouseMoveBind as EventListenerOrEventListenerObject
      );
    }
    if (this.config.rim || this.config.glare || this.config.dragMotion)
      listen("worldOrientation", this.onWorldOrientationBind);
  }

  removeEventListeners() {
    if (
      this.config.mouseZoom ||
      this.config.mouseTiltX ||
      this.config.mouseTiltY
    ) {
      this.element.removeEventListener(
        "mouseenter",
        this.onMouseEnterBind as EventListenerOrEventListenerObject
      );
      this.element.removeEventListener("mouseleave", this.onMouseLeaveBind);
      this.element.removeEventListener(
        "mousemove",
        this.onMouseMoveBind as EventListenerOrEventListenerObject
      );
    }
    if (this.config.rim || this.config.glare || this.config.dragMotion)
      unlisten("worldOrientation", this.onWorldOrientationBind);
  }

  addElevationElements() {
    this.element.querySelectorAll(".elevation-1").forEach((it) => {
      this.elevation1Elements.push(it as HTMLElement);
    });
    this.element.querySelectorAll(".elevation-2").forEach((it) => {
      this.elevation2Elements.push(it as HTMLElement);
    });
    this.element.querySelectorAll(".elevation-3").forEach((it) => {
      this.elevation3Elements.push(it as HTMLElement);
    });
  }

  addGlareElements() {
    // Create glare element
    const tiltGlare = document.createElement("div");
    tiltGlare.classList.add("tilt-glare");
    this.element.appendChild(tiltGlare);
    this.glareElement = this.element.querySelector(".tilt-glare");

    if (this.glareElement)
      Object.assign(this.glareElement.style, {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "pointer-events": "none",
        "border-radius": "inherit",
        opacity: this.config.glareStrength,
        transition: this.config._animationDurationMs * 2 + "ms",
      });
  }

  destroy() {
    if (this.transitionTimeout) clearTimeout(this.transitionTimeout);
    if (this.frameRequestId !== null) cancelAnimationFrame(this.frameRequestId);

    this.element.style.willChange = "";
    this.element.style.transition = "";
    this.element.style.transform = "";

    this.removeEventListeners();

    if (this.glareElement) this.glareElement.remove();

    this.updateParallaxElevation(this.elevation1Elements, 0, 0, 0);
    this.updateParallaxElevation(this.elevation2Elements, 0, 0, 0);
    this.updateParallaxElevation(this.elevation3Elements, 0, 0, 0);
    this.elevation1Elements = [];
    this.elevation2Elements = [];
    this.elevation3Elements = [];

    // Remove the injected _spatial object.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.element as any)._spatial = null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete (this.element as any)._spatial;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.element as any) = null;
  }
}
