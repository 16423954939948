/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FromGameMessage } from "../../app/gameConnection/messages/fromGameMessages";
import { ToGameMessage } from "../../app/gameConnection/messages/toGameMessages";
import { sanitizeGameMessage } from "../../app/gameConnection/webrtc/helpers/logs";
import { DEFAULT_LOG_SETTINGS } from "../constants/configs.constant";

export type LogType = keyof typeof DEFAULT_LOG_SETTINGS;

// We explicitely omit "log" as that would be reserved for current debugging/development and should not be committed.
const logModes = ["error", "warn", "info", "debug", "table"] as const;
type LogMode = (typeof logModes)[number];

const consoleWrapper = (type: LogType, mode: LogMode, ...args: any[]) => {
  if (!DEFAULT_LOG_SETTINGS[type]) return;
  if (mode === "table") {
    return console.table(...args);
  }
  console[mode](...args);
};

type LogFunction = (type: LogType, ...args: any[]) => void;

export const logGroupStart = (...args: any[]) => console.group(...args);
export const logGroupEnd = () => console.groupEnd();

export const log: LogFunction = (type, ...args) =>
  consoleWrapper(type, "debug", ...args);
export const logError: LogFunction = (type, ...args) =>
  consoleWrapper(type, "error", ...args);
export const logWarn: LogFunction = (type, ...args) =>
  consoleWrapper(type, "warn", ...args);
export const logInfo: LogFunction = (type, ...args) =>
  consoleWrapper(type, "info", ...args);
export const logTable: LogFunction = (type, ...args) =>
  consoleWrapper(type, "table", ...args);

export const logOutgoingMessage = (message: ToGameMessage) => {
  const { type } = message;
  logGroupStart(
    `%c OUTBOX ->: Sending message to Unreal: %c${type}%c:`,
    outgoingStyles.join(";"),
    [...outgoingAccentStyle].join(";"),
    [...outgoingStyles, ...boldStyle]
      .join(";")
      .concat(";border: none;border-left-radius: 0px;")
  );
  logTable("MESSAGE_OUT", sanitizeGameMessage(message));
  logGroupEnd();
};

export const logIncomingMessage = (message: FromGameMessage) => {
  const { type } = message;
  logGroupStart(
    `%c INBOX <-: Received message from Unreal: %c${type}%c:`,
    incomingStyles.join(";"),
    [...incomingAccentStyle].join(";"), // Slightly darker green for accent
    [...incomingStyles, ...boldStyle]
      .join(";")
      .concat(";border: none;border-left-radius: 0px;")
  );
  logTable("MESSAGE_IN", message);
  logGroupEnd();
};

export const outgoingAccentStyle = [
  "background: #E3F2FD", // Light blue background
  "color: #0D47A1", // Dark blue text
  "padding: 4px 6px",
  "font-weight: bold",
];

export const incomingAccentStyle = [
  "background: #E8F5E9", // Light green background
  "color: #1B5E20", // Dark green text
  "padding: 4px 6px",
  "font-weight: bold",
];

export const boldStyle = ["font-weight: bold"];
export const incomingStyles = [
  "background: #004f07", // Dark green background
  "color: #E8F5E9", // Very light green text
  "padding: 4px 6px",
  "border-radius: 3px",
  "border-right-radius: 0px",
  "border-left: 4px solid #43A047", // Medium green border
];

// Styles for outgoing messages
export const outgoingStyles = [
  "background: #37474F", // Dark blue-grey background
  "color: #ECEFF1", // Very light blue-grey text
  "padding: 4px 6px",
  "border-radius: 3px",
  "border-right-radius: 0px",
  "border-left: 4px solid #1E88E5", // Medium blue border
];
