import { useCallback, useState } from "react";
import { GyaradosSDK } from "@journee-live/gyarados";
import debounce from "lodash/debounce";
import { useTheme } from "styled-components";
import { PanelTestIds } from "../../../common/constants/testIds.constant";
import Checkbox from "../../../componentsLibrary/atoms/Checkbox";
import Column from "../../../componentsLibrary/atoms/Column";
import InputText from "../../../componentsLibrary/atoms/InputText";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";
import CountrySelect from "../../../componentsLibrary/molecules/CountrySelect";
import { useText } from "../../language/language.hook";
import i18nKeys from "../../language/language.types";
import { ProfileData } from "../lib/profileTypes";
import {
  ProfileFieldConfigurations,
  getProfileFieldNormalisedHandle,
} from "../util/profile";

type Props = {
  data: ProfileData;
  onChange: (field: keyof ProfileData, value: string | boolean) => void;
  settings: ProfileFieldConfigurations;
  hasConfirmed: boolean;
};

const ProfileFormFields: React.FC<Props> = ({
  data,
  onChange,
  settings,
  hasConfirmed,
}) => {
  const theme = useTheme();
  const t = useText();
  // To the happy developer:
  // This had to be debounce because the path of updating these fields is too long.
  // It has to travel all the way to zustand to parent, and then back to this child
  // This can result in flickering.
  // If you remove this debounce, you might see stuttering inside profile panel
  const [formState, setFormState] = useState<ProfileData>({
    ...data,
  });

  // React is complaining not understanding what debounce is, but we gucci, it's fine.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(debounce(onChange, 200), [onChange]);

  const updateField = (field: keyof ProfileData, value: string | boolean) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    debouncedOnChange(field, value);
  };

  const updateHandleField = (field: keyof ProfileData, value: string) => {
    let normalizedValue = value;
    const configuration = settings.fields.get(field);
    if (normalizedValue == null || !configuration?.linkPattern) {
      updateField(field, normalizedValue);
      return;
    }
    // Remove prefix and/or base url if it's supplied
    normalizedValue = getProfileFieldNormalisedHandle(
      value,
      configuration.linkPattern
    );
    updateField(field, normalizedValue);
  };

  const renderField = (field: GyaradosSDK.ProfileFieldDto) => {
    const labelName = `profile_form_field_${field.label}`;
    const label = Reflect.has(i18nKeys, labelName)
      ? t(labelName as keyof typeof i18nKeys)
      : field.label;
    const name = field.name as keyof ProfileData;
    switch (field.type) {
      case GyaradosSDK.ProfileFieldDtoTypeEnum.Text:
        return (
          <InputText
            key={name}
            name={name}
            autocomplete={name}
            label={label}
            value={formState[name] as string}
            onChange={(value) => updateField(name, value)}
            pattern={field.validation}
            required={field.required}
            validate={hasConfirmed}
            inputTestId={PanelTestIds.profile.common.formField(name)}
            hint={field.hint}
          />
        );
      case GyaradosSDK.ProfileFieldDtoTypeEnum.Email:
        return (
          <InputText
            key={name}
            name={name}
            autocomplete={name}
            label={label}
            value={formState[name] as string}
            required={field.required}
            onChange={(value) => updateField(name, value)}
            type="email"
            validate={hasConfirmed}
            inputTestId={PanelTestIds.profile.common.formField(name)}
            hint={field.hint}
          />
        );
      case GyaradosSDK.ProfileFieldDtoTypeEnum.Number:
        // TODO: Add number input
        return null;
      case GyaradosSDK.ProfileFieldDtoTypeEnum.Link:
        return (
          <InputText
            key={name}
            name={name}
            autocomplete={name}
            label={label}
            value={formState[name] as string}
            required={field.required}
            onChange={(value) => updateField(name, value)}
            type="url"
            validate={hasConfirmed}
            inputTestId={PanelTestIds.profile.common.formField(name)}
            hint={field.hint}
          />
        );
      case GyaradosSDK.ProfileFieldDtoTypeEnum.Handle:
        return (
          <InputText
            key={name}
            name={name}
            autocomplete={name}
            label={label}
            value={formState[name] as string}
            required={field.required}
            onChange={(value) => updateHandleField(name, value)}
            type="text"
            validate={hasConfirmed}
            inputTestId={PanelTestIds.profile.common.formField(name)}
            hint={field.hint}
          />
        );
      case GyaradosSDK.ProfileFieldDtoTypeEnum.Checkbox:
        return (
          <Checkbox
            key={name}
            name={name}
            label={label}
            value="true"
            checked={formState[name] as unknown as boolean}
            hint={field.hint}
            onChange={({ checked }) => updateField(name, checked)}
            width="100%"
            testId={PanelTestIds.profile.common.formField(name)}
          />
        );
      case GyaradosSDK.ProfileFieldDtoTypeEnum.Country:
        return (
          <CountrySelect
            key={name}
            name={name}
            label={label}
            selected={formState[name] as string}
            handleSelect={(value) => updateField(name, value)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Column
      align="center"
      gap={2}
      width={theme.elements.field.width}
      testId={PanelTestIds.profile.common.formFields}
    >
      {Boolean(settings.groups.none.length) &&
        settings.groups.none.map((field) => renderField(field))}
      {Boolean(settings.groups.social.length) && (
        <>
          <Space h={2} />
          <Typo.Body style={{ alignSelf: "baseline" }}>
            {t("profile_form_group_social")}
          </Typo.Body>
          {settings.groups.social.map((field) => renderField(field))}
        </>
      )}
      {Boolean(settings.groups.company.length) && (
        <>
          <Space h={2} />
          <Typo.Body style={{ alignSelf: "baseline" }}>
            {t("profile_form_group_company")}
          </Typo.Body>
          {settings.groups.company.map((field) => renderField(field))}
        </>
      )}
    </Column>
  );
};

export default ProfileFormFields;
