import { steps } from "../../../../app/style/theme";
import { PanelTestIds } from "../../../../common/constants/testIds.constant";
import Button from "../../../../componentsLibrary/atoms/Button";
import Column from "../../../../componentsLibrary/atoms/Column";
import HeaderBadge from "../../../../componentsLibrary/atoms/HeaderBadge";
import Icon from "../../../../componentsLibrary/atoms/Icon";
import Row from "../../../../componentsLibrary/atoms/Row";
import Space from "../../../../componentsLibrary/atoms/Space";
import Typo from "../../../../componentsLibrary/atoms/Typo";
import { useText } from "../../../language/language.hook";
import { Props } from "./SettingsVideoPage.ui";

const SettingsVideoNoDevices: React.FC<Props> = ({
  onJoinAsListener,
  onReloadPage,
}) => {
  const t = useText();

  return (
    <Column
      justify="center"
      align="center"
      testId={PanelTestIds.settings.normal.videoConferenceLandingPanel}
    >
      <Row height="100%" width="100%" align="center" justify="center">
        <HeaderBadge>
          <Icon.Warning size="40px" />
        </HeaderBadge>
      </Row>
      <Space h={4} />
      <Column align="center">
        <Typo.Title align="center">
          {t("settings_no_devices_detected")}
        </Typo.Title>
        <Space h={2} />
        <Typo.Body align="center" style={{ padding: `0 ${steps.spacing[8]}` }}>
          {t("settings_no_devices_detected_description")}
        </Typo.Body>
      </Column>
      <Space h={3} />
      <Button.Primary
        onClick={onJoinAsListener}
        testId={PanelTestIds.settings.common.joinVideoConferenceButton}
      >
        {t("settings_video_join_as_listener")}
      </Button.Primary>
      <Space h={3} />
      <Button.Subtle onClick={onReloadPage}>
        <Typo.Label weight="normal" align="center">
          {t("settings_enable_later")}
        </Typo.Label>
      </Button.Subtle>
      <Space h={7} />
    </Column>
  );
};

export default SettingsVideoNoDevices;
