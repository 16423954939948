import { useReflow } from "../../common/hooks/ui";
import ReportUiLandscape from "./Report.ui.landscape";
import ReportUiNormal from "./Report.ui.normal";
import ReportUiPortrait from "./Report.ui.portrait";

export type Props = {
  formRef: React.RefObject<HTMLFormElement>;
  isLoading: boolean;
  error: string | null;
  onClose?: () => void;
  onSubmit?: () => void;
  isConferenceEnabled: boolean;
};

const ReportUi: React.FC<Props> = (props) => {
  return useReflow({
    normal: <ReportUiNormal {...props} />,
    landscape: <ReportUiLandscape {...props} />,
    portrait: <ReportUiPortrait {...props} />,
  });
};

export default ReportUi;
