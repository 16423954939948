import { styled } from "styled-components";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { useStore } from "../../app/store";
import {
  isInstagramAgent,
  isMobile,
} from "../../common/constants/flags.constant";
import { useIsSmallScreen } from "../../common/hooks/ui";
import Transition from "../../componentsLibrary/atoms/Transition";
import { BaseLayout } from "../_layout/PanelsLayout";
import ForceLandscapeLogic from "./ForceLandscape.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ForceLandscapePanel: React.FC = () => {
  const isSmallScreen = useIsSmallScreen();
  const {
    environment: { disablePortrait },
  } = useEnvironmentContext();
  const currentStep = useStore((s) => s.userFlow.currentStep);

  const visible =
    isMobile &&
    isSmallScreen &&
    disablePortrait &&
    (currentStep == "login:start-action" ||
      currentStep == "experience:ready") &&
    !isInstagramAgent;

  return (
    <Layout id="panel-forceLandscape">
      <Transition watch={[visible]} height="100%">
        {visible && <ForceLandscapeLogic />}
      </Transition>
    </Layout>
  );
};

export default ForceLandscapePanel;
