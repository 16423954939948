import React, { useCallback } from "react";
import { ActionElementDto } from "@journee-live/gyarados/axios/esm";
import { sendGameMessage } from "../../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { IconName } from "../../../componentsLibrary/atoms/Icon";
import DynamicButtonMenu, {
  DynamicButtonMenuItem,
} from "../../../componentsLibrary/molecules/dynamicButtonMenu/DynamicButtonMenu";
import { ActionElementState } from "../ActionElements.slice";
import ActionElementIcon from "./ActionElementIcon";

const ActionElementIconMenu: React.FC<{
  actionElementConfig: ActionElementDto;
  state?: ActionElementState;
}> = ({ actionElementConfig, state }) => {
  const onToggleHandler = useCallback(
    (isOpen: boolean) => {
      sendGameMessage({
        type: "ActionElementEvent",
        actionElementId: actionElementConfig.actionElementId,
        event: isOpen ? "open" : "close",
      });
    },
    [actionElementConfig.actionElementId]
  );

  const environmentOptions = actionElementConfig.options;
  if (!environmentOptions) return null;
  const stateOptions = state?.options?.items;
  // Filter options in Game wants to show only a subset of the options
  const options = stateOptions?.length
    ? environmentOptions.filter((option) => stateOptions.includes(option.value))
    : environmentOptions;

  // Sort options by order
  options.sort((a, b) => a.order - b.order);

  const selectedOption =
    options.find((option) => option.value === state?.value) || options[0];
  const menuOptions: DynamicButtonMenuItem[] = options.map((option) => {
    return {
      tooltip: option.label,
      value: option.value,
      component: (
        <ActionElementIcon name={option.icon as IconName} size="24px" />
      ),
      componentFilled: (
        <ActionElementIcon name={option.icon as IconName} size="24px" />
      ),
      onClick: () => {
        sendGameMessage({
          type: "ActionElementEvent",
          actionElementId: actionElementConfig.actionElementId,
          event: "change",
          value: option.value,
        });
      },
    };
  });
  const direction =
    actionElementConfig.position === "top-right" ||
    actionElementConfig.position === "top-center"
      ? "down"
      : "up";

  return (
    <DynamicButtonMenu
      items={menuOptions}
      closeOnSelect
      anchor={
        <ActionElementIcon name={selectedOption.icon as IconName} size="24px" />
      }
      focused={false}
      direction={direction}
      onToggle={onToggleHandler}
    />
  );
};

export default ActionElementIconMenu;
