import styled, { useTheme } from "styled-components";
import { breakpoints, zIndex } from "../../../app/style/theme";
import ButtonText from "../../../componentsLibrary/molecules/ButtonText";
import LanguageSelectionButton from "../../../componentsLibrary/molecules/LanguageSelectionButton";
import { PanelName } from "../../_layout/panels.types";
import { useText } from "../../language/language.hook";

const BottomBar = styled.div<{ $absolute?: boolean }>`
  ${(p) => (p.$absolute ? "position: absolute" : "width: 95dvw")};
  inset-block-end: 10px;
  flex-wrap: wrap;
  left: var(--safe-margin-left);
  right: var(--safe-margin-right);
  bottom: var(--safe-margin-bottom);
  z-index: ${zIndex.overlays};
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${breakpoints.px.s}) {
    inset-block-end: 0px;
  }
`;

export type Props = {
  selectedLocaleCode?: string;
  locales?: string[];
  links?: { text: string; url: string }[];
  openPanel?: (panel: PanelName) => void;
  isAuthenticated?: boolean;
  isPanel?: boolean;
  absolute?: boolean;
};

export const LoginLinks: React.FC<Props> = ({
  selectedLocaleCode,
  locales,
  links,
  openPanel,
  isAuthenticated,
  isPanel,
  absolute = true,
}) => {
  const t = useText();
  const theme = useTheme();
  const bright = !isPanel && theme.isLoginColorInverted;

  return (
    <BottomBar $absolute={absolute}>
      <LanguageSelectionButton
        openLanguagePanel={() => openPanel?.("language")}
        selectedLocaleCode={selectedLocaleCode}
        locales={locales}
        bright={bright}
      />
      <a href="https://journee.live/imprint" target="_blank" rel="noreferrer">
        <ButtonText bright={bright}>{t("corporate_information")}</ButtonText>
      </a>
      <a href="https://journee.live/privacy" target="_blank" rel="noreferrer">
        <ButtonText bright={bright}>{t("platform_privacy_policy")}</ButtonText>
      </a>

      {isAuthenticated && (
        <ButtonText bright={bright} onClick={() => openPanel?.("report")}>
          {t("report_button")}
        </ButtonText>
      )}
      {Boolean(links?.length) &&
        links?.map((link, id) => (
          <a
            key={`link_${id}`}
            href={link.url}
            target="_blank"
            rel="noreferrer"
          >
            <ButtonText bright={bright}>{link.text}</ButtonText>
          </a>
        ))}
    </BottomBar>
  );
};
