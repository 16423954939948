import { styled } from "styled-components";
import { defaultTheme } from "../../../app/style/theme";
import Excite from "../../../componentsLibrary/atoms/Excite";

const Button = styled.div<{ $size: string; $mode: Props["mode"] }>`
  position: relative;
  width: ${(p) => p.$size};
  height: ${(p) => p.$size};

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  > .circle {
    transition: background-color 0.2s;
    background-color: ${(p) =>
      p.$mode === "photo" ? defaultTheme.colorBelow1 : p.theme.colorDanger};
  }
  .noTouch &:hover > .circle {
    background-color: ${(p) =>
      p.$mode === "photo" ? defaultTheme.colorBelow2 : p.theme.colorDanger};
  }
`;

const Ring = styled.div<{ $size?: string }>`
  position: absolute;
  width: calc(${(p) => p.$size} * 1.2);
  height: calc(${(p) => p.$size} * 1.2);
  border-radius: 9999px;

  border-width: 3px;
  border-style: solid;
  border-color: ${defaultTheme.colorBelow1};

  box-sizing: border-box;
  background: none;
`;

const Circle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
`;

type Props = {
  size: string;
  onClick?: () => void;
  mode?: "photo" | "video";
};

/** A ui button that looks like a big mobile photo capture button. */
const CaptureButton: React.FC<Props> = ({ size, onClick, mode = "photo" }) => {
  return (
    <Excite clickResize="0.9" clickSpeedMs={100} hoverResize="1.1">
      <Button onClick={onClick} $size={size} $mode={mode}>
        <Ring $size={size} />
        <Circle className="circle" />
      </Button>
    </Excite>
  );
};

export default CaptureButton;
