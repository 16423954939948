import { useTheme } from "styled-components";
import Button from "../../../componentsLibrary/atoms/Button";
import Row from "../../../componentsLibrary/atoms/Row";
import { useText } from "../../language/language.hook";
import { QuestButtonFilter } from "../Quest.ui";

const QuestFilter: React.FC<{
  buttonFilters: QuestButtonFilter[];
}> = ({ buttonFilters }) => {
  const t = useText();
  const theme = useTheme();

  return (
    <Row width="100%" align="center" justify="center">
      {buttonFilters.map((buttonFilter) => (
        <Button.Subtle
          key={buttonFilter.type}
          onClick={buttonFilter.onClick}
          override={{
            backgroundColor: buttonFilter.active
              ? theme.colorAbove0
              : "inherit",
          }}
        >
          {t(`quest_panel_filter_${buttonFilter.type}`)}
        </Button.Subtle>
      ))}
    </Row>
  );
};

export default QuestFilter;
