import { useTheme } from "styled-components";
import { formatTime } from "../../../common/util/math";
import Button from "../../../componentsLibrary/atoms/Button";
import Hide from "../../../componentsLibrary/atoms/Hide";
import Icon from "../../../componentsLibrary/atoms/Icon";
import CloseButton from "../../../componentsLibrary/molecules/CloseButton";
import HintUi from "../../hint/Hint.ui";
import { useText } from "../../language/language.hook";
import CaptureButton from "../components/CaptureButton";
import CommonMediaCaptureUi from "../components/CommonMediaCaptureUi";
import MediaTabs from "../components/MediaTabs";
import {
  AbsoluteOverlay,
  CameraFrameBorders,
  CaptureButtonWrapper,
  HintWrapper,
  ReactionsWrapper,
  RecordingCameraFrameBorders,
  TabsWrapper,
  TopRightButton,
} from "../components/Wrappers";
import { Props } from "../videoCapture/VideoCapture.ui";

const VideoCaptureUiNormal: React.FC<Props> = ({
  onCapture,
  onClose,
  reactions,
  error,
  logoUrl,
  nrOfReactions,
  onChangeTab,
  isRecording,
  recordingTime,
  hideUi,
  hideTabs,
}) => {
  const theme = useTheme();
  const t = useText();

  return (
    <AbsoluteOverlay>
      <CommonMediaCaptureUi
        error={error}
        logoUrl={logoUrl}
        hideUi={hideUi}
        hideTabs={hideTabs}
      />
      <Hide hide={isRecording} height="100dvh" width="100dvw">
        <CameraFrameBorders />
      </Hide>
      <Hide
        hide={!isRecording}
        height="100dvh"
        width="100dvw"
        style={{ position: "absolute", top: 0, left: 0 }}
      >
        <RecordingCameraFrameBorders />
      </Hide>
      <Hide
        hide={isRecording}
        style={{ position: "absolute", top: "0", width: "100%" }}
      >
        <TabsWrapper>
          <MediaTabs currentTab={"videoCapture"} onClick={onChangeTab} />
        </TabsWrapper>
      </Hide>
      <HintWrapper $hideTabs={hideTabs}>
        <Hide
          hide={!isRecording}
          width="100%"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <HintUi
            content={t("media_capture_time", {
              time: formatTime(recordingTime),
            })}
            noAnimation
          />
        </Hide>
      </HintWrapper>

      {Boolean(nrOfReactions) && (
        <ReactionsWrapper $isLandscape>{reactions}</ReactionsWrapper>
      )}
      <CaptureButtonWrapper>
        <Hide hide={isRecording} height="80px">
          <CaptureButton
            onClick={onCapture}
            size="80px"
            mode={hideUi ? "video" : "photo"}
          />
        </Hide>
        <Hide
          hide={!isRecording}
          style={{
            position: "absolute",
            bottom: "60px",
          }}
        >
          <Button.Bright
            onClick={onCapture}
            iconLeft={
              <Icon.VideoStopRecording size="14px" color={theme.colorDanger} />
            }
          >
            {t("media_capture_stop_recording")}
          </Button.Bright>
        </Hide>
      </CaptureButtonWrapper>
      <TopRightButton>
        <Hide hide={isRecording}>
          <CloseButton onClick={onClose} isGlass size="48px" />
        </Hide>
      </TopRightButton>
    </AbsoluteOverlay>
  );
};

export default VideoCaptureUiNormal;
