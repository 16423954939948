import { useEffect, useMemo } from "react";
import { NearbyPlayer } from "../../../../app/gameConnection/messages/sharedDataTypes";
import { useStore } from "../../../../app/store";
import NearbyPlayerAvatar from "./NearbyPlayerAvatar";
import useSearchPlayerList from "./useSearchPlayerList";

const useNearbyPlayers = (nearbyPlayers: NearbyPlayer[]) => {
  const allPlayers = useStore((s) => s.gameConnection.allPlayers);
  const { findManyPlayers } = useSearchPlayerList();
  const setAllPlayers = useStore((s) => s.gameConnection.setAllPlayers);
  const getPlayerByKey = useStore((s) => s.gameConnection.getPlayerByKey);
  const roomId = useStore((s) => s.gameConnection.roomId);
  const openPanel = useStore((s) => s.layout.openPanel);
  useEffect(() => {
    if (nearbyPlayers.length && roomId) {
      const newPlayerIds = nearbyPlayers.reduce<number[]>((acc, player) => {
        const existingPlayer = getPlayerByKey(player.playerId, roomId);
        if (!existingPlayer) {
          acc.push(player.playerId);
        }
        return acc;
      }, []);
      if (newPlayerIds.length) {
        (async () => {
          const playerProfiles = await findManyPlayers({
            playerIds: newPlayerIds,
            roomId,
          });
          if (playerProfiles) {
            setAllPlayers(playerProfiles.map((p) => p.document));
          }
        })();
      }
    }
  }, [
    nearbyPlayers,
    allPlayers,
    roomId,
    findManyPlayers,
    setAllPlayers,
    getPlayerByKey,
  ]);
  return useMemo(() => {
    if (!roomId) return [];
    return nearbyPlayers.map((player) => {
      const fullProfile = getPlayerByKey(player.playerId, roomId);
      // We use full profile here instead of nearby player data because nearby players are not
      // refreshed so often. So we might have stale data.
      if (!fullProfile) return null;
      const avatarColor = fullProfile?.avatarColor;
      return (
        <NearbyPlayerAvatar
          size={68}
          openPanel={openPanel}
          avatarColor={avatarColor}
          playerKey={fullProfile.userId}
          playerName={fullProfile.name}
          key={fullProfile.userId}
          playerId={player.playerId}
          roomId={roomId}
        />
      );
    });
  }, [roomId, nearbyPlayers, getPlayerByKey, openPanel]);
};

export default useNearbyPlayers;
