import { ReactNode } from "react";
import { useReflow } from "../../../common/hooks/ui";
import PhotoCaptureUiNormal from "./PhotoCapture.ui.normal";
import PhotoCaptureUiSmall from "./PhotoCapture.ui.small";

export type Props = {
  onCapture?: () => void;
  reactions?: ReactNode;
  error?: string;
  logoUrl?: string;
  nrOfReactions?: number;
  onClose?: () => void;
  onChangeTab: () => void;
  hideUi?: boolean;
  hideTabs?: boolean;
};

const PhotoCaptureUi: React.FC<Props> = (props) => {
  const render = useReflow({
    normal: <PhotoCaptureUiNormal {...props} />,
    small: <PhotoCaptureUiSmall {...props} />,
  });

  return render;
};

export default PhotoCaptureUi;
