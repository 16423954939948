import { styled } from "styled-components";
import { isTouch } from "../../../common/constants/flags.constant";
import ControlsDefaultUi from "./controls/default/ControlsDefault.ui";
import ControlsTouchUi from "./controls/touch/ControlsTouch.ui";

const Content = styled.div``;

const SettingsControlsPageUi: React.FC = () => {
  return (
    <Content>{isTouch ? <ControlsTouchUi /> : <ControlsDefaultUi />}</Content>
  );
};

export default SettingsControlsPageUi;
