import { styled } from "styled-components";
import { zIndex } from "../../app/style/theme";
import Button from "../../componentsLibrary/atoms/Button";
import Column from "../../componentsLibrary/atoms/Column";
import HeaderBadge from "../../componentsLibrary/atoms/HeaderBadge";
import Row from "../../componentsLibrary/atoms/Row";
import Space from "../../componentsLibrary/atoms/Space";
import Typo from "../../componentsLibrary/atoms/Typo";
import CardPartialPortrait from "../../componentsLibrary/molecules/CardPartialPortrait";
import { useText } from "../language/language.hook";

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: ${zIndex.extras};
`;

export type Props = {
  isInactive: boolean;
  isClosing: boolean;
  countDown: number;
};

const EndingUiSmall: React.FC<Props> = ({
  isInactive,
  isClosing,
  countDown,
}) => {
  const t = useText();

  return (
    <Container>
      <CardPartialPortrait
        topSection={
          <>
            <Space h={8} />
            <Row justify="center">
              <HeaderBadge>
                <Typo.Title>{countDown}</Typo.Title>
              </HeaderBadge>
            </Row>
          </>
        }
        topSectionHeight="80px"
      >
        <Column
          textAlign="center"
          justify="center"
          align="center"
          gap={4}
          style={{
            padding: "24px",
            boxSizing: "border-box",
          }}
        >
          {isInactive && (
            <>
              <Typo.Title>{t("inactivity_title")}</Typo.Title>
              <Row>
                <Typo.Body
                  style={{
                    textAlign: "center",
                    flex: 1,
                  }}
                >
                  {t("inactivity_description_mobile", { countDown })}
                </Typo.Body>
              </Row>
              <Row justify="center">
                <Button.Secondary>{t("inactivity_button")}</Button.Secondary>
              </Row>
            </>
          )}
          {isClosing && (
            <Typo.Body>{t("calendar_event_over", { countDown })}</Typo.Body>
          )}
        </Column>
      </CardPartialPortrait>
    </Container>
  );
};

export default EndingUiSmall;
