import { sendGameMessage } from "../../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { useStore } from "../../../app/store";
import { getPlayerKey } from "../../social/socialUtils";
import { useVideoConferenceControlsContext } from "../../videoConference/providers/VideoConference.provider";
import PresenterBarUi from "./PresenterBar.ui";

type Props = {
  isOpen: boolean;
};

const PresenterBarLogic: React.FC<Props> = ({ isOpen }) => {
  const { toggleLocalAudio, toggleVideo, toggleScreenShare } =
    useVideoConferenceControlsContext();
  const micMuted = useStore((s) => s.userMedia.micMuted);
  const webcamMuted = useStore((s) => s.userMedia.webcamMuted);
  const activeScreenShare = useStore(
    (s) => s.videoConference.activeScreenShare
  );
  const playerId = useStore((s) => s.gameConnection.playerId);
  const roomId = useStore((s) => s.gameConnection.roomId);
  const videoConference = useStore((s) => s.videoConference);
  const isVideoConferenceInitialized = useStore((s) =>
    s.videoConference.isConferenceInitialized()
  );

  const stopPresenting = () => {
    const self = videoConference.self();
    const amIScreenSharer = videoConference.amIScreenSharer();
    if (!roomId || !playerId) return;
    if (!self) return;
    const userId = getPlayerKey({
      playerId,
      roomId,
    });
    videoConference.setPresenterPlayerKey(null);
    sendGameMessage({
      type: "VoiceChatUserStateChanged",
      userId,
      isSpeaking: Boolean(self.isSpeaking),
      isMuted: !self.isAudioOn,
      isPresenter: false,
      isVideoSharing: amIScreenSharer,
    });
    sendGameMessage({
      type: "TeleportPlayer",
      spawnPoint: 1000,
    });
  };

  return (
    <PresenterBarUi
      isOpen={isOpen}
      micMuted={micMuted}
      isVideoConferenceInitialized={isVideoConferenceInitialized}
      toggleLocalAudio={toggleLocalAudio}
      webcamMuted={webcamMuted}
      toggleVideo={toggleVideo}
      activeScreenShare={Boolean(activeScreenShare)}
      toggleScreenShare={toggleScreenShare}
      stopPresenting={stopPresenting}
    />
  );
};

export default PresenterBarLogic;
