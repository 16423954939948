import { useMemo } from "react";
import { useStore } from "../../app/store";

export type ConferenceError =
  | "cameraFeedError"
  | "cameraAccessDenied"
  | "noCameraAvailable"
  | "noMicrophoneAvailable"
  | "firefoxSpeakersError"
  | "microphoneAccessDenied";

const useConferenceErrors = () => {
  const {
    permissions,
    audioInputDevices,
    videoInputDevices,
    videoInputFeedError,
  } = useStore((s) => s.userMedia);
  return useMemo(() => {
    const conferenceErrors: ConferenceError[] = [];
    if (audioInputDevices?.length === 0) {
      conferenceErrors.push("noMicrophoneAvailable");
    }
    if (videoInputDevices?.length === 0) {
      conferenceErrors.push("noCameraAvailable");
    }
    if (videoInputFeedError) {
      conferenceErrors.push("cameraFeedError");
    }
    if (!permissions.camera) {
      conferenceErrors.push("cameraAccessDenied");
    }
    if (!permissions.microphone) {
      conferenceErrors.push("microphoneAccessDenied");
    }
    return conferenceErrors;
  }, [
    audioInputDevices?.length,
    permissions.camera,
    permissions.microphone,
    videoInputDevices?.length,
    videoInputFeedError,
  ]);
};

export default useConferenceErrors;
