import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { steps } from "../../app/style/theme";
import { useIsLandscape } from "../../common/hooks/ui";
import { getImageUrl } from "../../common/util/gyarados.util";
import Button from "../../componentsLibrary/atoms/Button";
import CardImage from "../../componentsLibrary/atoms/CardImage";
import Glass from "../../componentsLibrary/atoms/Glass";
import Icon from "../../componentsLibrary/atoms/Icon";
import Markdown from "../../componentsLibrary/atoms/Markdown";
import Spatial from "../../componentsLibrary/atoms/Spatial";
import Typo from "../../componentsLibrary/atoms/Typo";
import CloseButton from "../../componentsLibrary/molecules/CloseButton";
import { Props } from "./InfoCard.ui";

export const Grid = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  grid-template-rows: [top-edge] 1fr [middle] 30px [bottom-edge];
  grid-template-columns: [left-edge] 50px [left-inset] 1fr [right-inset] 30px [right-edge];
  column-gap: 8px;
  row-gap: 8px;
  padding-top: 7px;
  padding-bottom: 7px;
`;

export const ImageWrapper = styled.div`
  grid-column: left-edge / left-inset;
  grid-row: top-edge / middle;
`;

export const TextWrapper = styled.div`
  grid-column: left-inset / right-edge;
  grid-row: top-edge / middle;
  place-self: center stretch;
`;

export const ExpandWrapper = styled.div`
  grid-column: left-edge / right-inset;
  grid-row: middle / bottom-edge;
`;

export const CloseWrapper = styled.div`
  grid-column: right-inset / right-edge;
  grid-row: middle / bottom-edge;
`;

export const MarkdownWrapper = styled.div`
  display: flex;
  height: 26px;
  width: 100%;
  * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const ContentWrapper = styled.div<{ $isLandscape: boolean }>`
  top: max(var(--safe-margin-top), 16px);
  right: max(var(--safe-margin-right), 16px);
  left: max(var(--safe-margin-left), 16px);
  bottom: max(var(--safe-margin-bottom), 0px);
  position: absolute;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  > span {
    line-height: 1rem;
  }
  color: ${(p) => p.theme.colorAbove5};
`;

type ExtraProps = {
  onExpandClick: () => void;
};

const InfoCardUiPreview: React.FC<Props & ExtraProps> = ({
  handleDismiss,
  onExpandClick,
  data,
}) => {
  const isLandscape = useIsLandscape();
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Spatial rim glare dragMotion mouseTiltX mouseTiltY mouseZoom>
      <ContentWrapper $isLandscape={isLandscape}>
        <Glass
          height="120px"
          width={isLandscape ? "352px" : "100%"}
          overflow="hidden"
          style={{
            display: "flex",
            float: "right",
          }}
        >
          <Grid>
            <ImageWrapper>
              <CardImage
                src={getImageUrl(data.image, 48, 48)}
                width="48px"
                height="48px"
                styleOverrides={{
                  borderRadius: theme.radiusSmall,
                  border: `1px solid ${theme.colorAbove0}`,
                }}
                orientation="preview"
              />
            </ImageWrapper>
            <TextWrapper>
              <div>
                {data.title && (
                  <TitleWrapper>
                    <Typo.Title size={steps.font.f20.size}>
                      {data.title}
                    </Typo.Title>
                  </TitleWrapper>
                )}
                {data.description && (
                  <MarkdownWrapper>
                    <Markdown content={data.description} textAlign="left" />
                  </MarkdownWrapper>
                )}
              </div>
            </TextWrapper>
            <ExpandWrapper>
              <Button.Secondary
                override={{
                  width: "100%",
                  height: "32px",
                }}
                onClick={onExpandClick}
                iconLeft={<Icon.Plus size="20px" inheritColor />}
              >
                {t("info_card_expand")}
              </Button.Secondary>
            </ExpandWrapper>
            <CloseWrapper>
              <CloseButton onClick={handleDismiss} />
            </CloseWrapper>
          </Grid>
        </Glass>
      </ContentWrapper>
    </Spatial>
  );
};

export default InfoCardUiPreview;
