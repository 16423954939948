import { useTheme } from "styled-components";
import { steps } from "../../app/style/theme";
import { useIsSmallScreen } from "../../common/hooks/ui";
import { clipString } from "../../common/util/string";
import Clickable from "../../componentsLibrary/atoms/Clickable";
import Row from "../../componentsLibrary/atoms/Row";
import Tag from "../../componentsLibrary/atoms/Tag";
import Typo from "../../componentsLibrary/atoms/Typo";
import { TextChatMessage } from "../social/subpages/chat/textChatTypes";

type Props = {
  onClick: () => void;
  message?: TextChatMessage;
};
const TextChatPreviewUi: React.FC<Props> = ({ message, onClick }) => {
  const text = message?.content || "";
  const sender = message?.sender;
  const theme = useTheme();
  const isSmallScreen = useIsSmallScreen();

  return (
    <Clickable
      border="0px solid transparent"
      padding="0px"
      backgroundColor="transparent"
      height="auto"
      onClick={onClick}
    >
      <Row gap={3} style={{ paddingLeft: isSmallScreen ? "16px" : 0 }}>
        <Tag text={clipString(text, 40)} />
        <Typo.Body size={steps.font.f10.size} color={theme.colorBelow0}>
          {sender}
        </Typo.Body>
      </Row>
    </Clickable>
  );
};

export default TextChatPreviewUi;
