import { GyaradosSDK } from "@journee-live/gyarados";
import { useEnvironmentContext } from "../../../../app/EnvironmentDataProvider";
import { useStore } from "../../../../app/store";
import { useText } from "../../../language/language.hook";
import { TextChatChannel, TextChatMessageGroup } from "./textChatTypes";

const startTime = Date.now();

type CurrentTextChatChannel = TextChatChannel & {
  fixedMessages: TextChatMessageGroup[];
};

/** Gets the best match for the most relevant chat panel state.
 * In order of priority: SmartChats > ChatAreas > public chat.
 */
export const useCurrentTextChatChannel = (): CurrentTextChatChannel => {
  const t = useText();
  const { environment } = useEnvironmentContext();
  const activeChannel = useStore((s) => s.textChat.activeChannel);
  const currentChannel = useStore((s) =>
    activeChannel
      ? s.textChat.channels[activeChannel]
      : s.textChat.channels.public
  );

  const result: CurrentTextChatChannel = {
    ...currentChannel,
    fixedMessages: [],
  };

  if (result.type === "smartChat") {
    const chatData = environment.smartChats?.find(
      (chat) => chat.slug === activeChannel
    );
    result.thread = result.thread || [];
    result.name =
      (chatData
        ? (chatData.visualSettings as GyaradosSDK.VisualSettingsDto).name
        : activeChannel) || "";
    result.fixedMessages?.push({
      messages: [
        {
          content: t("chat_meet_npc", {
            npcName: activeChannel || undefined,
          }),
          sender: t("chat_host"),
          senderId: -1,
          timestamp: startTime,
        },
      ],
      sender: t("chat_host"),
      senderId: -1,
      timestamp: startTime,
    });
    result.lastTyping = currentChannel?.lastTyping;
  } else if (result.type === "public") {
    result.thread = result.thread || [];
    result.fixedMessages?.push({
      messages: [
        {
          content: t("chat_welcome"),
          sender: "Guide",
          senderId: -1,
          timestamp: startTime,
        },
      ],
      sender: "Guide",
      senderId: -1,
      timestamp: startTime,
    });
  }

  return result;
};
