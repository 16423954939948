import React, { useEffect } from "react";
import { useStore } from "../../app/store";

type Props = {
  isVisible: boolean;
};

const ReportLogicPersistent: React.FC<Props> = ({ isVisible }) => {
  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "KeyP" && event.ctrlKey && event.altKey) {
        if (!isVisible) {
          openPanel("report");
        } else {
          closePanel("report");
        }
      }
    };
    window.addEventListener("keydown", listener);
    return () => window.removeEventListener("keydown", listener);
  }, [isVisible, openPanel, closePanel]);

  return null;
};

export default ReportLogicPersistent;
