import Column from "../../../componentsLibrary/atoms/Column";
import Icon from "../../../componentsLibrary/atoms/Icon";
import Row from "../../../componentsLibrary/atoms/Row";
import Typo from "../../../componentsLibrary/atoms/Typo";
import { useText } from "../../language/language.hook";
import { Props } from "../Quest.ui";
import QuestCard from "./QuestCard";

const QuestList: React.FC<Omit<Props, "handleDismiss" | "buttonFilters">> = ({
  isLoadingQuests,
  quests,
}) => {
  const t = useText();

  return (
    <>
      <Column align="center" gap={1}>
        {isLoadingQuests ? (
          <Row width="100%" justify="center">
            <Icon.RipplesAnimated />
          </Row>
        ) : (
          quests.map((quest) => <QuestCard key={quest.id} quest={quest} />)
        )}
      </Column>
      {quests.length === 0 && (
        <Column
          align="center"
          justify="center"
          style={{ padding: "32px 0 48px 0" }}
        >
          <Typo.Body>{t("quest_panel_no_quests")}</Typo.Body>
        </Column>
      )}
    </>
  );
};

export default QuestList;
