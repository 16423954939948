import { useReflow } from "../../common/hooks/ui";
import LanguagePanelSmallLandscapeUi from "./Language.ui.landscape";
import LanguageUiNormal from "./Language.ui.normal";
import LanguagePanelSmallPortraitUi from "./Language.ui.portrait";

export type Props = {
  handleDismiss: () => void;
  locales: string[];
  currentLocale?: string;
  onLanguageSelected: (locale: string) => void;
};

const LanguageUi: React.FC<Props> = (props) => {
  return useReflow({
    normal: <LanguageUiNormal {...props} />,
    landscape: <LanguagePanelSmallLandscapeUi {...props} />,
    portrait: <LanguagePanelSmallPortraitUi {...props} />,
  });
};

export default LanguageUi;
