import { useStore } from "../../../app/store";
import Transition from "../../../componentsLibrary/atoms/Transition";
import { FullAbsoluteLayout } from "../../_layout/PanelsLayout";
import MediaShareLogic from "./MediaShare.logic";

const MediaSharePanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.mediaShare.visible);

  return (
    <FullAbsoluteLayout id="panel-mediaShare">
      <Transition watch={[visible]} height="100%">
        {visible && <MediaShareLogic />}
      </Transition>
    </FullAbsoluteLayout>
  );
};

export default MediaSharePanel;
