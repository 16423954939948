import { SvgProps } from "../../types/svg";

const SphereIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="10"
      cy="10"
      r="7.25"
      strokeWidth="1.5"
      stroke="currentColor"
      className="stroke-color"
    />
    <path
      d="M17.5 10C17.5 10.8132 16.8278 11.6839 15.4332 12.3812C14.0686 13.0635 12.1483 13.5 10 13.5C7.85165 13.5 5.93141 13.0635 4.56675 12.3812C3.17219 11.6839 2.5 10.8132 2.5 10C2.5 9.18684 3.17219 8.31607 4.56675 7.61879C5.93141 6.93646 7.85165 6.5 10 6.5C12.1483 6.5 14.0686 6.93646 15.4332 7.61879C16.8278 8.31607 17.5 9.18684 17.5 10Z"
      stroke="currentColor"
      className="stroke-color"
    />
    <path
      d="M13.5 10C13.5 11.8621 13.0676 13.5233 12.3943 14.7017C11.7129 15.8942 10.8477 16.5 10 16.5C9.15229 16.5 8.28711 15.8942 7.60569 14.7017C6.93236 13.5233 6.5 11.8621 6.5 10C6.5 8.1379 6.93236 6.47666 7.60569 5.29832C8.28711 4.10584 9.15229 3.5 10 3.5C10.8477 3.5 11.7129 4.10584 12.3943 5.29832C13.0676 6.47666 13.5 8.1379 13.5 10Z"
      stroke="currentColor"
      className="stroke-color"
    />
  </svg>
);
export default SphereIcon;
