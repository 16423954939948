import { useReflow } from "../../common/hooks/ui";
import { AllPanelSubPagesNames } from "../_layout/panels.types";
import SettingsPanelSmallLandscapeUi from "./Settings.ui.landscape";
import SettingsUiNormal from "./Settings.ui.normal";
import SettingsPanelSmallPortraitUi from "./Settings.ui.portrait";
import { SettingName } from "./subpages/home/SettingsHomePage.ui";

export type Props = {
  children: React.ReactNode;
  title: string;
  isMuted: boolean;
  subpage?: AllPanelSubPagesNames;
  subPageStyles?: {
    card?: React.CSSProperties;
    title?: React.CSSProperties;
  };
  bottomBarChildren?: React.ReactNode;
  onClose?: () => void;
  onPrevious?: () => void;
  handleSettingClick: (setting: SettingName) => void;
  links?: { text: string; url: string }[];
  onShowStats?: () => void;
  selectedLocaleCode?: string;
  openLanguagePanel: () => void;
  openReportPanel: () => void;
  locales?: string[];
};

const SettingsUi: React.FC<Props> = (props) => {
  return useReflow({
    normal: <SettingsUiNormal {...props} />,
    landscape: <SettingsPanelSmallLandscapeUi {...props} />,
    portrait: <SettingsPanelSmallPortraitUi {...props} />,
  });
};

export default SettingsUi;
