import { PanelTestIds } from "../../common/constants/testIds.constant";
import { isColorBright } from "../../common/util/color";
import CardFullPortrait from "../../componentsLibrary/molecules/CardFullPortrait";
import { Props, generateGradientFromColor } from "./Social.ui";

const SocialUiPortrait: React.FC<Props> = ({ onClose, tabs, selectedTab }) => {
  return (
    <CardFullPortrait
      floatingSection={tabs}
      bottomSection={selectedTab.drawer}
      onClose={onClose}
      hideScrollButton
      noPadding
      testId={PanelTestIds.social.portrait.root}
      style={{
        background: generateGradientFromColor(selectedTab.backgroundColor),
      }}
      showFade={selectedTab.showFade}
      brightCloseButton={
        selectedTab.backgroundColor
          ? !isColorBright(selectedTab.backgroundColor)
          : false
      }
    >
      {selectedTab.content}
    </CardFullPortrait>
  );
};

export default SocialUiPortrait;
