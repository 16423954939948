import { defaultTheme } from "../../../../app/style/theme";
import { SvgProps } from "../../../../types/svg";

/** @todo: Add gradient prop to icons */
const PurpleVideoOnIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className="gradient-svg"
  >
    <defs>
      <linearGradient id="purpleGradient" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop
          offset="0%"
          stopColor={defaultTheme.colorGradient.purpleBlue.start}
        />
        <stop
          offset="100%"
          stopColor={defaultTheme.colorGradient.purpleBlue.end}
        />
      </linearGradient>
    </defs>
    <path
      d="M17.5222 5.52094C17.2281 5.36384 16.8731 5.38458 16.6014 5.58107L13.7458 7.64471C13.5444 7.78972 13.4958 8.07583 13.6364 8.28294C13.7528 8.49036 14.0528 8.53906 14.2556 8.39583L17.1111 6.33333V13.6638L14.2556 11.6042C14.0537 11.4596 13.7772 11.5093 13.6367 11.717C13.4961 11.9242 13.5447 12.2103 13.7461 12.3552L16.6016 14.4171C16.7544 14.5272 16.9333 14.5829 17.1122 14.5829C17.2524 14.5829 17.3933 14.5484 17.5227 14.479C17.8167 14.3198 18 13.9846 18 13.6409V6.33333C18 5.98958 17.8167 5.68021 17.5222 5.52094ZM10.8889 4.5H3.77778C2.79583 4.5 2 5.3207 2 6.33333V13.6667C2 14.6793 2.79583 15.5 3.77778 15.5H10.8889C11.8708 15.5 12.6667 14.6793 12.6667 13.6667V6.33333C12.6667 5.3207 11.8694 4.5 10.8889 4.5ZM11.7778 13.6667C11.7778 14.172 11.3789 14.5833 10.8889 14.5833H3.77778C3.28778 14.5833 2.88889 14.172 2.88889 13.6667V6.33333C2.88889 5.82802 3.28778 5.41667 3.77778 5.41667H10.8889C11.3789 5.41667 11.7778 5.82802 11.7778 6.33333V13.6667Z"
      fill="url(#purpleGradient)"
    />
  </svg>
);
export default PurpleVideoOnIcon;
