import styled from "styled-components";
import Space from "../../../../componentsLibrary/atoms/Space";
import CardList from "./CardList";
import { Props } from "./SettingsHomePage.ui";

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SettingsHomePageUiNormal: React.FC<Props> = (props) => {
  return (
    <Flex>
      <CardList {...props} itemHeight="73px" />
      <Space h={5} />
    </Flex>
  );
};

export default SettingsHomePageUiNormal;
