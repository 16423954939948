import { useEffect, useMemo, useRef, useState } from "react";
import { throttle } from "lodash";
import { StreamingStats } from "../../../app/gameConnection/messages/sharedDataTypes";
import { useGetHintBySlug } from "../../../app/hooks/gyarados.hook";
import { useStore } from "../../../app/store";

export type NetworkConditions = {
  latency: number;
  packetLoss: number;
};

const THRESHOLD_PACKET_LOSS_PERCENT = 4;
// Above 250ms, the latency starts to hurt even for
// casual exploration experiences, but the warning was
// showing too often in KSA, so we increased it to 300ms.
const THRESHOLD_LATENCY_MS = 300;

export const useNetworkConditionsWarning = () => {
  const networkWarningHint = useGetHintBySlug("network_warning");
  const hasNetworkWarning = Boolean(networkWarningHint);
  const closePanel = useStore((s) => s.layout.closePanel);
  const openPanel = useStore((s) => s.layout.openPanel);
  const streamingStats = useStore((s) => s.gameConnection.streamingStats);
  const [measurements, setMeasurements] = useState<StreamingStats[]>([]);
  const conditions = useRef<NetworkConditions>({
    latency: 0,
    packetLoss: 0,
  });

  // Collect the last 20 measurements, measurements are taken every second
  useEffect(() => {
    // If the network warning is not enabled, we don't need to collect measurements
    if (!hasNetworkWarning) return;
    if (streamingStats) {
      setMeasurements((prev) => [streamingStats, ...prev.slice(0, 19)]);
    }
  }, [hasNetworkWarning, streamingStats, setMeasurements]);
  // If we don't have enough measurements, we don't need to calculate the average
  // and we shouldn't show hints yet.
  const ready = measurements.length >= 20;

  // Calculate rolling averages
  useEffect(() => {
    if (!ready) return;

    const sum = measurements.reduce<NetworkConditions>(
      (result, current) => {
        // If the current measurement is missing some values, we use the last known average
        const latency =
          current.currentRoundTripTime ?? conditions.current.latency;
        const packetLoss =
          current.currentPacketLostPercent ?? conditions.current.packetLoss;
        return {
          // If latency is undefined (as in Firefox), it will be set to
          // 0, which is ok for our purposes, but still not explicitely handled.
          latency: result.latency + latency,
          packetLoss: result.packetLoss + packetLoss,
        };
      },
      {
        latency: 0,
        packetLoss: 0,
      }
    );
    conditions.current = {
      latency: sum.latency / measurements.length,
      packetLoss: sum.packetLoss / measurements.length,
    };
  }, [measurements, ready]);

  // Show a hint if the network conditions are bad.
  // The hint is shown every 60 seconds for 8 seconds
  const showNetworkWarningHint = useMemo(
    () =>
      throttle(
        () => {
          openPanel("hint", {
            slug: "network_warning",
          });
          setTimeout(() => {
            closePanel("hint", { slug: "network_warning" });
          }, 8000);
        },
        60000,
        { leading: true }
      ),
    [closePanel, openPanel]
  );

  useEffect(() => {
    if (!ready) return;

    const hasPacketLoss =
      conditions.current.packetLoss > THRESHOLD_PACKET_LOSS_PERCENT;
    const hasLatency = conditions.current.latency > THRESHOLD_LATENCY_MS;

    if (hasPacketLoss || hasLatency) {
      showNetworkWarningHint();
    } else {
      showNetworkWarningHint.cancel();
    }
  }, [measurements, ready, showNetworkWarningHint]);
};
