import { styled } from "styled-components";
import Column from "../../../../../componentsLibrary/atoms/Column";
import Row from "../../../../../componentsLibrary/atoms/Row";
import ButtonsSchema from "./ButtonsSchema";
import GesturesSchema from "./GesturesSchema";

const Content = styled.div`
  padding-right: max(var(--safe-margin-right), 64px);
`;

const ControlsTouchUiLandscape: React.FC = () => {
  return (
    <Content>
      <Row justify="space-between" align="flex-start" gap={6}>
        <Column gap={2} width="auto" style={{ flex: 1 }}>
          <ButtonsSchema />
        </Column>
        <Column gap={3} width="auto" style={{ flex: 1 }}>
          <GesturesSchema />
        </Column>
      </Row>
    </Content>
  );
};

export default ControlsTouchUiLandscape;
