import Column from "../../componentsLibrary/atoms/Column";
import Scroll from "../../componentsLibrary/atoms/Scroll";
import Space from "../../componentsLibrary/atoms/Space";
import CardPartialPortrait from "../../componentsLibrary/molecules/CardPartialPortrait";
import { useText } from "../language/language.hook";
import { Props } from "./Quest.ui";
import QuestFilter from "./component/QuestFilter";
import QuestHeader from "./component/QuestHeader";
import QuestList from "./component/QuestList";

const QuestUiPortrait: React.FC<Props> = ({
  handleDismiss,
  buttonFilters,
  isLoadingQuests,
  quests,
}) => {
  const t = useText();

  return (
    <CardPartialPortrait
      testId={"normal"}
      onClose={handleDismiss}
      padding="0 0 16px 0"
    >
      <Scroll hideScrollToBottom>
        <Column
          style={{
            padding: "0 8px",
            boxSizing: "border-box",
          }}
        >
          <QuestHeader
            title={t("quest_panel_title")}
            description={t("quest_panel_description")}
          />
          <Space h={4} />
          <QuestFilter buttonFilters={buttonFilters} />
          <Space h={4} />

          <QuestList isLoadingQuests={isLoadingQuests} quests={quests} />
        </Column>
      </Scroll>
    </CardPartialPortrait>
  );
};

export default QuestUiPortrait;
