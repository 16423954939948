import { Shared } from "@journee-live/mew";
import { decodeJWTPayload } from "../../common/util/encoding";

export type VisitorTokenData = Shared.VisitorPayload & { exp: number };
export type CalendarEvent = { from: string; to: string };

export const getSSOUrl = (
  environmentId: string,
  providerId: string
): string => {
  return `${
    import.meta.env.VITE_GYARADOS_URL
  }/v1/visitors/sso/login/${environmentId}/${providerId}/${encodeURIComponent(
    window.location.origin + window.location.pathname
  )}`;
};

export const getVisitorTokenData = (
  token?: string | null
): VisitorTokenData | null => {
  if (!token) return null;
  try {
    return decodeJWTPayload(token);
  } catch (error) {
    return null;
  }
};

export const isValidVisitorTokenData = (
  data: VisitorTokenData | null,
  environmentId: string | null
): data is VisitorTokenData => {
  if (!data || !environmentId) return false;
  if (data.eid !== environmentId) return false;
  if (data.exp < Date.now() / 1000) return false;
  return true;
};

export const isCalendarOpen = (
  calendarEvents: CalendarEvent[] | null | undefined
) => {
  if (!calendarEvents || calendarEvents.length === 0) return true;
  const now = Date.now();
  return Boolean(
    calendarEvents.find(({ from, to }) => {
      return now >= new Date(from).getTime() && now <= new Date(to).getTime();
    })
  );
};

export const getNextCalendarEvent = (
  calendarEvents: CalendarEvent[],
  now = Date.now()
): CalendarEvent | undefined => {
  // Assumes that calendarEvents are sorted by `from` date.
  return calendarEvents.find(({ from }) => {
    return now <= new Date(from).getTime();
  });
};
