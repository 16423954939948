import { styled } from "styled-components";
import { useStore } from "../../app/store";
import { BaseLayout } from "../_layout/PanelsLayout";
import HintLogic from "./Hint.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const HintPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.hint.visible);
  const id = useStore((s) => s.layout.panels.hint?.slug);
  const content = useStore((s) => s.layout.panels.hint?.options?.content);

  return (
    <Layout id="panel-hint">
      <HintLogic id={id} visible={visible} content={content} />
    </Layout>
  );
};

export default HintPanel;
