import Space from "../../../../../componentsLibrary/atoms/Space";
import ButtonsSchema from "./ButtonsSchema";
import KeysSchema from "./KeysSchema";

const ControlsDefaultUiPortrait: React.FC = () => {
  return (
    <>
      <ButtonsSchema />
      <Space h={6} />
      <KeysSchema />
      <Space h={5} />
    </>
  );
};

export default ControlsDefaultUiPortrait;
