import React from "react";
import Column from "../../../componentsLibrary/atoms/Column";
import HeaderBadge from "../../../componentsLibrary/atoms/HeaderBadge";
import Icon from "../../../componentsLibrary/atoms/Icon";
import Row from "../../../componentsLibrary/atoms/Row";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";
import { useText } from "../../language/language.hook";

const RestrictionMessageUi: React.FC = () => {
  const t = useText();
  return (
    <>
      <Space h={7} />
      <Row height="100%" width="100%" align="center" justify="center">
        <HeaderBadge>
          <Icon.CircleInfo inheritColor size="40px" />
        </HeaderBadge>
      </Row>
      <Space h={4} />
      <Column align="center">
        <Typo.Title>{t("login_error_restricted_title")}</Typo.Title>
        <Space h={2} />
        <Typo.Body style={{ textAlign: "center" }}>
          {t("login_error_restricted_description")}
        </Typo.Body>
        <Space h={7} />
      </Column>
    </>
  );
};

export default RestrictionMessageUi;
