import { zIndex } from "../../../app/style/theme";
import { LoginPageTestIds } from "../../../common/constants/testIds.constant";
import { useIsLandscape, useIsSmallScreen } from "../../../common/hooks/ui";
import Column from "../../../componentsLibrary/atoms/Column";
import Hide from "../../../componentsLibrary/atoms/Hide";
import Image from "../../../componentsLibrary/atoms/Image";
import Row from "../../../componentsLibrary/atoms/Row";
import Space from "../../../componentsLibrary/atoms/Space";

export const layoutDefault = {
  logoHeight: "70px",
} as const;

const layoutSmall = {
  logoHeight: "30px",
} as const;

const layoutSmallLandscape = {
  logoHeight: "23px",
} as const;

export type Props = {
  logoUrl?: string;
  showLogo?: boolean;
};

export const LoginLogo: React.FC<Props> = ({ logoUrl, showLogo }) => {
  const isSmallScreen = useIsSmallScreen();
  const isLandscape = useIsLandscape();

  const layout = isSmallScreen
    ? isLandscape
      ? layoutSmallLandscape
      : layoutSmall
    : layoutDefault;

  return (
    <Column
      align="center"
      style={{ position: "absolute", top: 0, zIndex: zIndex.overlays }}
    >
      <Space h={4} />
      <Row justify="center" align="center">
        <Hide hide={!showLogo}>
          {logoUrl && (
            <Image
              testId={LoginPageTestIds.logo}
              minimalPlaceholder
              src={logoUrl}
              width="200px"
              height={layout.logoHeight}
            />
          )}
        </Hide>
      </Row>
    </Column>
  );
};
