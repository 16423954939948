import { ReactNode, createContext, useContext, useEffect, useId } from "react";
import { GyaradosSDK } from "@journee-live/gyarados";
import { HttpStatusCode } from "@journee-live/mew";
import { logInfo } from "../common/util/logger";
import { useGetEnvironmentBySlug } from "./hooks/gyarados.hook";
import { useEnvironmentPath } from "./hooks/routing.hook";
import { Errors } from "./monitoring/tracking/errors";
import { getQueryStrings } from "./routing/routingUtils";
import { useStore } from "./store";

/**
 * Important: this is a high level context.
 * Place here only values that are not supposed to change
 * without re-rendering all the UI.
 */
export type EnvironmentContextType = {
  environmentSlug: string;
  organisationSlug?: string;
  environmentId: string;
  environment: GyaradosSDK.EnvironmentConfigResponseDto;
};

const EnvironmentContext = createContext<EnvironmentContextType>(
  {} as EnvironmentContextType
);

export const EnvironmentProvider = EnvironmentContext.Provider;
export const useEnvironmentContext = () => {
  const context = useContext(EnvironmentContext);
  if (!context) {
    throw new Error("useEnvironmentContext must be within EnvironmentProvider");
  }
  return context;
};

type Props = {
  /** What to render instead if the environment is loading. */
  loadingPage: React.ComponentType;
  /** What to render instead if the environment is not found. */
  notFoundPage: React.ComponentType;
  /** What to render instead if the environment failed to load for some generic reason. */
  errorPage: React.ComponentType;
  children?: ReactNode;
};
/**
 * Makes sure that the environment data is correctly loaded and available (via SWR).
 * Uses the route params to find the correct environment.
 * Below this component the environment id is assumed to be known.
 */
const EnvironmentDataProvider: React.FC<Props> = ({
  loadingPage: LoadingPage,
  errorPage: ErrorPage,
  notFoundPage: NotFoundPage,
  children,
}) => {
  const { envSlug, orgSlug } = useEnvironmentPath();
  const { lang: queryRequestedLocale } = getQueryStrings();
  const selectedLocaleCode = useStore((s) => s.i18n.selectedLocaleCode);
  const locale = selectedLocaleCode ?? queryRequestedLocale;
  // We already populate the SWR cache with environment data, so that subsequent calls
  // can immediately return data and just revalidate.
  // In future, we will first fetch the environment ID from the backend, and then use that
  // to fetch the environment data.
  // If rerendering is a problem, splitting up the environment into part might be a solution
  // For now, the app is pretty snappy, so we don't need to do that.
  const {
    isLoading,
    data: environment,
    error,
    mutate,
  } = useGetEnvironmentBySlug(envSlug, orgSlug, locale);

  // If the locale changes, we need to revalidate the environment data.
  useEffect(() => {
    mutate();
  }, [locale, mutate]);

  const isNonExisting =
    !isLoading &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    error?.response?.status === HttpStatusCode.NotFound;

  // Report environment that failed to load.
  const id = useId();
  useEffect(() => {
    if (isNonExisting) {
      logInfo(
        "GENERIC",
        Errors.ENVIRONMENT_FAILED_TO_LOAD,
        orgSlug,
        envSlug,
        error
      );
    }
  }, [envSlug, error, id, isNonExisting, isLoading, orgSlug, environment]);

  useEffect(() => {
    if (error && !isNonExisting) {
      logInfo(
        "GENERIC",
        Errors.ENVIRONMENT_DATA_NOT_AVAILABLE,
        error,
        environment
      );
    }
  }, [environment, error, isNonExisting]);

  if (isLoading) return <LoadingPage />;
  if (isNonExisting || !envSlug) return <NotFoundPage />;
  if (error) return <ErrorPage />;

  return (
    <EnvironmentProvider
      // Generally not a good idea, but there is no way of typescript to know that
      // the environment is defined here. We know it is, because we check for it above.
      value={{
        environment: environment as GyaradosSDK.EnvironmentConfigResponseDto,
        environmentId: environment?.id as string,
        environmentSlug: envSlug as string,
        organisationSlug: orgSlug,
      }}
    >
      {children}
    </EnvironmentProvider>
  );
};

export default EnvironmentDataProvider;
