import { ReactNode } from "react";
import { useReflow } from "../../../common/hooks/ui";
import VideoCaptureUiNormal from "./VideoCapture.ui.normal";
import VideoCaptureUiSmall from "./VideoCapture.ui.small";

export type Props = {
  onCapture?: () => void;
  hideCaptureButton?: boolean;
  logoUrl?: string;
  reactions?: ReactNode;
  error?: string;
  nrOfReactions?: number;
  capturedVideo?: Blob;
  onClose?: () => void;
  onChangeTab: () => void;
  isRecording: boolean;
  recordingTime: number;
  hideUi?: boolean;
  hideTabs?: boolean;
};

const VideoCaptureUi: React.FC<Props> = (props) => {
  const render = useReflow({
    normal: <VideoCaptureUiNormal {...props} />,
    small: <VideoCaptureUiSmall {...props} />,
  });

  return render;
};

export default VideoCaptureUi;
