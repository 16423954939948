import { useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { GameColor } from "../../../../app/gameConnection/messages/sharedDataTypes";
import { steps, zIndex } from "../../../../app/style/theme";
import { rgbaToHex } from "../../../../common/util/color";
import Column from "../../../../componentsLibrary/atoms/Column";
import Glass from "../../../../componentsLibrary/atoms/Glass";
import Row from "../../../../componentsLibrary/atoms/Row";
import Typo from "../../../../componentsLibrary/atoms/Typo";
import { stringToColour } from "../../../../componentsLibrary/molecules/avatarCircle/LocalVideoAvatarCircle";
import PlayerAvatarWithInitial from "../../../../componentsLibrary/molecules/avatarCircle/PlayerAvatarWithInitials";
import { SocialPanelSubPageName } from "../../../_layout/panels.types";
import { useText } from "../../../language/language.hook";
import NoNameAvatarText from "../../../videoConference/components/NoNameAvatarText";
import { getPlayerKey } from "../../socialUtils";

const Outer = styled.div<{
  $size: number;
}>`
  transition:
    opacity 0.3s ease-in-out,
    color 0.3s ease-in-out;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 4px;
  max-height: 30px;
  height: 30px;
  width: ${(p) => p.$size}px;
  text-overflow: ellipsis;
  * {
    user-select: none;
    transition: color 0.1s ease-in-out;
  }
`;

const SubtleBorder = styled.div<{ $size: number }>`
  border: 1px solid ${(p) => p.theme.colorAbove0};
  border-radius: 50%;
  position: absolute;
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
`;

const AvatarWrapper = styled.div`
  &:hover {
    .camera-hover-overlay {
      opacity: 1;
    }
  }
`;

const RelativeWrapper = styled.div<{
  $size: number;
}>`
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
  border-radius: 50%;
  z-index: 1;
  position: relative;
`;

const Container = styled.div<{ $size: number }>`
  display: flex;
  position: relative;
  align-items: center;
  flex: 0;
  pointer-events: all;
  justify-content: center;
  width: ${(p) => p.$size}px;
  flex-direction: column;
  box-sizing: border-box;
  z-index: ${zIndex.extras};
  * {
    box-sizing: content-box;
  }
`;

export type Props = {
  playerKey: string;
  playerName: string | null;
  playerId: number;
  roomId: string;
  openPanel: (panelName: SocialPanelSubPageName) => void;
  avatarColor?: GameColor;
  size?: number;
};

const NearbyPlayerAvatar: React.FC<Props> = ({
  roomId,
  playerId,
  openPanel,
  playerKey,
  playerName,
  avatarColor,
  size = 92,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const t = useText();
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const onClick = () => {
    if (!playerName) return;
    const externalId = getPlayerKey({
      roomId,
      playerId,
    });
    openPanel(`social/playerProfile/${externalId}`);
  };

  const playerAvatarColor =
    (avatarColor && rgbaToHex(avatarColor)) ||
    stringToColour(playerKey || "random-seed");

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <Container ref={wrapperRef} $size={size}>
      <AvatarWrapper>
        <Glass
          borderRadius="50%"
          style={{
            padding: "0px",
            border: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SubtleBorder $size={size} />
          <RelativeWrapper $size={size}>
            <PlayerAvatarWithInitial
              size={`${size}px`}
              color={playerAvatarColor}
              playerName={playerName}
              onClick={onClick}
              onMouseEnter={onMouseEnter}
              onMouseOut={onMouseOut}
            />
          </RelativeWrapper>
        </Glass>
      </AvatarWrapper>

      <Outer
        $size={size}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseOut}
        onClick={onClick}
      >
        <Column align="center">
          {!playerName ? (
            <NoNameAvatarText />
          ) : (
            <Typo.Title
              fontFamily="Manrope"
              title={playerName}
              size={steps.font.f20.size}
              noWrap
              weight={600}
              lineHeight={steps.font.f20.lineHight}
              align="center"
              style={{
                width: size,
                display: "block",
                textOverflow: "ellipsis",
                overflow: "hidden",
                pointerEvents: "none",
              }}
            >
              {playerName}
            </Typo.Title>
          )}
          {playerName && (
            <Row
              justify="center"
              onMouseEnter={onMouseEnter}
              onMouseOut={onMouseOut}
              style={{ pointerEvents: "none" }}
            >
              <>
                <Typo.Note align="center">
                  &nbsp;{t("player_open_profile")}&nbsp;
                </Typo.Note>
                {isHovered && (
                  <Typo.Note align="center" color={theme.colorAbove2}>
                    {">"}
                  </Typo.Note>
                )}
              </>
            </Row>
          )}
        </Column>
      </Outer>
    </Container>
  );
};

export default NearbyPlayerAvatar;
