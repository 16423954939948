import { SvgProps } from "../../types/svg";

const PaletteFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 7.972c0 .056-.028.084-.028.112.016 1.141-1.022 1.888-2.163 1.888H10.75c-.828 0-1.5.7-1.5 1.528q.001.16.031.31c.069.318.203.6.34.934.188.431.379.86.379 1.312 0 .997-.675 1.897-1.669 1.938Q8.165 15.999 8 16a8 8 0 0 1-8-8 8 8 0 0 1 8-8 8 8 0 0 1 8 8zm-13 0c-.552 0-1 .475-1 1 0 .581.448 1 1 1 .553 0 1-.419 1-1 0-.525-.447-1-1-1m1-2c.553 0 1-.419 1-1 0-.525-.447-1-1-1s-1 .475-1 1c0 .581.447 1 1 1m4-4c-.553 0-1 .476-1 1 0 .581.447 1 1 1s1-.419 1-1c0-.524-.447-1-1-1m4 4c.553 0 1-.419 1-1 0-.525-.447-1-1-1s-1 .475-1 1c0 .581.447 1 1 1"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default PaletteFilledIcon;
