import styled from "styled-components";
import { PanelTestIds } from "../../common/constants/testIds.constant";
import { getImageUrl } from "../../common/util/gyarados.util";
import CardImage from "../../componentsLibrary/atoms/CardImage";
import Markdown from "../../componentsLibrary/atoms/Markdown";
import Space from "../../componentsLibrary/atoms/Space";
import Spatial from "../../componentsLibrary/atoms/Spatial";
import Typo from "../../componentsLibrary/atoms/Typo";
import Card from "../../componentsLibrary/molecules/Card";
import { Props } from "./Popup.ui";
import ButtonWrapper from "./component/ButtonWrapper";
import { getButtons } from "./lib/popupUtils";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Body = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    text-align: center;
    flex: 1;
    min-inline-size: 0;
  }
  min-width: 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  /* Set the width to center long content (Popup width - side padding) */
  width: calc(416px - 90px);
  padding-bottom: 40px;
`;

const PopupUi: React.FC<Props> = ({
  onClose,
  popup,
  link,
  handleExternalLink,
  handleTeleport,
  extraText,
}) => {
  const popupImage = popup?.image;
  const buttons = getButtons({
    popup,
    link,
    handleExternalLink,
    handleTeleport,
    onClose,
  });

  return (
    <Spatial rim glare dragMotion mouseTiltX mouseTiltY mouseZoom>
      <Card
        testId={PanelTestIds.popup.root}
        onClose={popup?.dismissible ? onClose : undefined}
        topSection={
          <CardImage
            testId={PanelTestIds.popup.image}
            height={popupImage ? "100%" : "200px"}
            width="100%"
            src={getImageUrl(popupImage, 700, 700)}
            orientation="portrait"
          />
        }
        topSectionHeight={popupImage ? "200px" : "120px"}
        brightCloseButton={Boolean(popup?.image)}
      >
        <Wrapper>
          <Body>
            <Space h={popupImage ? 6 : 4} />
            {popup?.title && (
              <Typo.Title testId={PanelTestIds.popup.title}>
                {popup.title}
              </Typo.Title>
            )}
            <Space h={2} />
            {popup?.text && <Markdown content={popup.text} />}
            {Boolean(extraText) && (
              <>
                <Space h={2} />
                <Typo.Body>{extraText}</Typo.Body>
              </>
            )}
            <Space h={5} />
            <ButtonWrapper>{...buttons}</ButtonWrapper>
          </Body>
        </Wrapper>
      </Card>
    </Spatial>
  );
};

export default PopupUi;
