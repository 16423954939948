import { useCallback } from "react";
import useIframeAdapter from "../../common/hooks/useIframeAdapter";
import { log, logIncomingMessage } from "../../common/util/logger";
import { useStore } from "../store";
import { FromGameMessage } from "./messages/fromGameMessages";

const useGameMessage = () => {
  const { handleGameConnectionMessage } = useStore((s) => s.gameConnection);
  const { sendGameMessageToParent } = useIframeAdapter();
  const handleTextChatMessage = useStore(
    (s) => s.textChat.handleTextChatMessage
  );
  const handleVideoConferenceMessage = useStore(
    (s) => s.videoConference.handleVideoConferenceMessage
  );
  const handleUiMessage = useStore((s) => s.layout.handleUiMessage);
  const handleSessionMessage = useStore((s) => s.session.handleSessionMessage);
  const handleSocialMessage = useStore((s) => s.social.handleSocialMessage);
  const handleProfileMessage = useStore((s) => s.profile.handleProfileMessage);
  const handleLoadLevelControllerMessage = useStore(
    (s) => s.loadLevelController.handleLoadLevelControllerMessage
  );
  const handleMapMessage = useStore((s) => s.map.handleMapMessage);
  const handleMediaCaptureMessage = useStore(
    (s) => s.mediaCapture.handleMediaCaptureMessage
  );
  const handleActionElementMessage = useStore(
    (s) => s.actionElements.handleActionElementMessage
  );
  const handleQuestMessage = useStore((s) => s.quest.handleQuestMessage);

  return useCallback(
    (message: FromGameMessage) => {
      // If in iframe, the game message will be forwarded to parent
      sendGameMessageToParent?.(message);
      const { type } = message;

      // Log only the relevant messages
      switch (type) {
        case "PerformanceStats":
        case "NearbyPlayers":
        case "PhotonPlayerConnected":
          break;
        default:
          if (type === "UnrealStateUpdate")
            log("MESSAGE_IN_VERBOSE", `<- GameMessage ${type}:`, message);
          else {
            logIncomingMessage(message);
          }
          break;
      }

      switch (message.type) {
        // Game connection slice.
        case "PerformanceStats":
        case "NearbyPlayers":
        case "PhotonPlayerConnected":
        case "GameIsReady":
        case "EnterRegion":
        case "ExitRegion":
        case "GameQuiz":
        case "AnalyticsEvent":
        case "ProductSelected":
        case "ItemAdded":
        case "CurrencyChanged":
          handleGameConnectionMessage(message);
          break;
        case "LoadingLevelStart":
        case "LoadingLevelEnd":
          handleLoadLevelControllerMessage(message);
          break;
        // Text Chat message slice.
        case "ReceivedChatMessage":
        case "OnChatMessageDeleted":
        case "SmartChatAction":
        case "SmartChatEngineReply":
        case "SmartChatSubscriptionUpdate":
          handleTextChatMessage(message);
          break;
        // Session Slice
        case "EndSession":
          handleSessionMessage(message);
          break;
        // Video Conference Slice
        case "SetIsPresenter":
        case "GetScreenSharingStatus":
        case "ScreenSharing":
          handleVideoConferenceMessage(message);
          break;
        // Social Slice
        case "ShowBusinessCard":
        case "Reaction":
          handleSocialMessage(message);
          break;
        // Profile Slice
        case "ExternalAssetLoadStatus":
          handleProfileMessage(message);
          break;
        // Panels Slice
        case "UiAction":
        case "ActionPanel":
        case "InfoCard":
        case "Poll":
          handleUiMessage(message);
          break;
        // Map Slice
        case "ActiveRegion":
          handleMapMessage(message);
          break;
        case "MediaCaptureAction":
        case "ShareMedia":
          handleMediaCaptureMessage(message);
          break;
        case "QuestsInfo":
        case "QuestProgress":
          handleQuestMessage(message);
          break;
        case "ActionElementStatus":
        case "ActionElementsInfo":
          handleActionElementMessage(message);
          break;
        // Cry in your bedroom Slice
        default:
          log("WEBRTC", "Unknown game message: ", message);
          break;
      }
    },
    [
      handleMediaCaptureMessage,
      sendGameMessageToParent,
      handleGameConnectionMessage,
      handleLoadLevelControllerMessage,
      handleTextChatMessage,
      handleSessionMessage,
      handleVideoConferenceMessage,
      handleSocialMessage,
      handleProfileMessage,
      handleUiMessage,
      handleMapMessage,
      handleActionElementMessage,
      handleQuestMessage,
    ]
  );
};

export default useGameMessage;
