import { styled } from "styled-components";
import { Props } from "./ActionElements.ui";
import { BottomCenterBar } from "./components/BottomCenterBar";
import { TopCenterBar } from "./components/TopCenterBar";
import { TopRightBar } from "./components/TopRightBar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const ActionElementsUiSmall: React.FC<Props> = ({
  topCenterElements,
  topRightElements,
  bottomCenterElements,
}) => {
  return (
    <Container>
      {Boolean(topCenterElements.length) && (
        <TopCenterBar>{topCenterElements}</TopCenterBar>
      )}
      {Boolean(topRightElements.length) && (
        <TopRightBar>{topRightElements}</TopRightBar>
      )}
      {Boolean(bottomCenterElements.length) && (
        <BottomCenterBar>{bottomCenterElements}</BottomCenterBar>
      )}
    </Container>
  );
};

export default ActionElementsUiSmall;
