import Button from "../../componentsLibrary/atoms/Button";
import Column from "../../componentsLibrary/atoms/Column";
import Hide from "../../componentsLibrary/atoms/Hide";
import Space from "../../componentsLibrary/atoms/Space";
import { useText } from "../language/language.hook";

type Props = {
  onSkipClick: () => void;
  showSkipButton?: boolean;
};

const CinematicModePanelUi: React.FC<Props> = ({
  onSkipClick,
  showSkipButton,
}) => {
  const t = useText();

  return (
    <Hide hide={!showSkipButton} width="100%" height="100%">
      <Column justify="flex-end" align="center" height="100%">
        <Button.Bright onClick={onSkipClick}>
          {t("cinematic_view_skip_button")}
        </Button.Bright>
        <Space h={4} />
      </Column>
    </Hide>
  );
};
export default CinematicModePanelUi;
