import { GyaradosSDK } from "@journee-live/gyarados";
import { AvatarResponseDto } from "@journee-live/gyarados/axios/esm";
import tinycolor from "tinycolor2";
import { getRandomColor } from "../../../common/util/color";
import { ProfileData } from "../lib/profileTypes";
import { getSafeRandomUserName } from "./names";

export const initialiseProfile = (
  data: ProfileData,
  avatars: AvatarResponseDto[],
  shouldGenerateRandomName = false
): ProfileData => {
  let newData = { ...data };
  // As visitors start the experience without providing a name we always give one to them.
  if ((!data || !data.firstName) && shouldGenerateRandomName) {
    newData = {
      ...newData,
      firstName: getSafeRandomUserName(),
    };
  }

  if (
    !data ||
    !data.avatarColor ||
    (data.avatarColor.r === 0 &&
      data.avatarColor.g === 0 &&
      data.avatarColor.b === 0)
  ) {
    const color = getRandomColor();
    color.a = 100;
    newData = {
      ...newData,
      avatarColor: color,
    };
  }

  if (avatars.length && !data.avatarId) {
    newData = {
      ...newData,
      avatarId: avatars[0].avatarId,
    };
  }
  // If we have an avatar persisted but it's not in the list of avatars we have in
  // the current environment, we fallback to the first one.
  if (
    avatars.length &&
    data.avatarId &&
    !avatars.find((avatar) => avatar.avatarId === data.avatarId)
  ) {
    newData = {
      ...newData,
      avatarId: avatars[0].avatarId,
    };
  }

  return newData;
};

export const parseProfileForWeb = (data: ProfileData): ProfileData => {
  // game alpha is [0-100] while web uses [0-1]
  const color = tinycolor(data.avatarColor).toRgb();
  color.a = 1;
  return { ...data, avatarColor: color };
};

export const parseProfileForGame = (data: ProfileData): ProfileData => {
  // game alpha is [0-100] while web uses [0-1]
  const color = tinycolor(data.avatarColor).toRgb();
  color.a = 100;
  // Add guestEmail for legacy reasons
  return {
    ...data,
    avatarColor: color,
    guestEmail: data.email,
  };
};

// TODO: fragile types here below
export const getProfileDataFromFormEvent = (
  event: React.FormEvent<HTMLFormElement>
): ProfileData => {
  const data = new FormData(event.target as HTMLFormElement);
  const formattedData: Record<string, unknown> = {};
  data.forEach((value, key) => {
    formattedData[key] = value;
  });
  return formattedData as ProfileData;
};

export const getRpmSubdomain = (
  customRpmUrl: string | null
): string | undefined => {
  return customRpmUrl?.split("//")[1].split(".")[0];
};

export type ProfileFieldConfiguration = GyaradosSDK.ProfileFieldDto & {
  linkPattern?: RegExp;
};

export function getProfileFieldConfiguration(
  name: string,
  configuration: Partial<GyaradosSDK.ProfileFieldDto>
): ProfileFieldConfiguration {
  const linkPattern = configuration.link
    ? getProfileFieldHandleRegex(configuration.link, configuration.prefix)
    : undefined;
  return {
    name,
    label: name,
    type: "text",
    group: "none",
    ...configuration,
    linkPattern,
  };
}
// https://models.readyplayer.me/rpmAvatarId.glb
export function getRpmAvatarIdFromUrl(avatarUrl: string) {
  const match = /\/([^/]+)\.glb$/i.exec(avatarUrl);
  return match?.[1];
}
export type ProfileFieldConfigurations = {
  fields: Map<string, ProfileFieldConfiguration>;
  groups: Record<
    GyaradosSDK.ProfileFieldDtoGroupEnum,
    ProfileFieldConfiguration[]
  >;
};

export function getProfileFieldConfigurations(
  environment: GyaradosSDK.EnvironmentConfigResponseDto
): ProfileFieldConfigurations {
  const fields = new Map<string, ProfileFieldConfiguration>();

  // Default fields
  if (environment.businessCard.showFirstName) {
    fields.set(
      "firstName",
      getProfileFieldConfiguration("firstName", {
        validation: ".{1,22}",
        required: true,
      })
    );
  }

  if (environment.businessCard.showLastName) {
    fields.set(
      "lastName",
      getProfileFieldConfiguration("lastName", {
        validation: ".{1,22}",
      })
    );
  }

  if (environment.businessCard.showAge) {
    fields.set(
      "age",
      getProfileFieldConfiguration("age", {
        type: "number",
      })
    );
  }

  if (environment.businessCard.showGuestEmail) {
    fields.set(
      "email",
      getProfileFieldConfiguration("email", {
        type: "email",
        required: true,
      })
    );
  }

  if (environment.businessCard.showCity) {
    fields.set("city", getProfileFieldConfiguration("city", {}));
  }

  if (environment.businessCard.showCountry) {
    fields.set(
      "country",
      getProfileFieldConfiguration("country", {
        type: "country",
      })
    );
  }

  // Company fields
  if (environment.businessCard.showJobTitle) {
    fields.set(
      "jobTitle",
      getProfileFieldConfiguration("jobTitle", {
        group: "company",
      })
    );
  }

  if (environment.businessCard.showCompany) {
    fields.set(
      "company",
      getProfileFieldConfiguration("company", {
        group: "company",
      })
    );
  }

  if (environment.businessCard.showOrgCode) {
    fields.set(
      "orgCode",
      getProfileFieldConfiguration("orgCode", {
        group: "company",
      })
    );
  }

  if (environment.businessCard.showWebsite) {
    fields.set(
      "website",
      getProfileFieldConfiguration("website", {
        type: "link",
        group: "company",
      })
    );
  }

  // Default social fields
  if (environment.businessCard.showTwitter) {
    fields.set(
      "twitter",
      getProfileFieldConfiguration("twitter", {
        type: "handle",
        prefix: "@",
        link: "https://twitter.com/{input}",
        group: "social",
      })
    );
  }

  if (environment.businessCard.showInstagram) {
    fields.set(
      "instagram",
      getProfileFieldConfiguration("instagram", {
        type: "handle",
        prefix: "@",
        link: "https://instagram.com/{input}",
        group: "social",
      })
    );
  }

  if (environment.businessCard.showFacebook) {
    fields.set(
      "facebook",
      getProfileFieldConfiguration("facebook", {
        type: "handle",
        link: "https://facebook.com/{input}",
        group: "social",
      })
    );
  }

  if (environment.businessCard.showLinkedin) {
    fields.set(
      "linkedin",
      getProfileFieldConfiguration("linkedin", {
        type: "handle",
        link: "https://linkedin.com/in/{input}",
        group: "social",
      })
    );
  }

  if (environment.businessCard.showXing) {
    fields.set(
      "xing",
      getProfileFieldConfiguration("xing", {
        type: "handle",
        link: "https://www.xing.com/profile/{input}",
        group: "social",
      })
    );
  }

  if (environment.businessCard.showMsTeams) {
    fields.set(
      "msTeamsEmail",
      getProfileFieldConfiguration("msTeamsEmail", {
        type: "email",
        group: "social",
      })
    );
  }

  // Add custom fields
  for (const configuration of environment.profileFields) {
    fields.set(
      configuration.name,
      getProfileFieldConfiguration(configuration.name, configuration)
    );
  }

  // Group fields for rendering
  const groups: Record<
    GyaradosSDK.ProfileFieldDtoGroupEnum,
    ProfileFieldConfiguration[]
  > = { none: [], social: [], company: [] };

  for (const field of fields.values()) {
    groups[field.group].push(field);
  }

  // We only add the field to the Map to render the slider
  // We don't add it to the groups to avoid showing it in the form
  if (environment.businessCard.showColor) {
    const color: GyaradosSDK.ProfileFieldDto = {
      name: "avatarColor",
      label: "avatarcolor",
      type: "text",
      required: true,
      group: "none",
    };
    fields.set(color.name, color);
  }

  return {
    fields,
    groups,
  };
}

export function getProfileFieldHandleUrl(
  pattern: string,
  value: string
): string {
  return pattern.replace("{input}", value);
}

export function getProfileFieldHandleRegex(
  pattern: string,
  prefix?: string
): RegExp {
  if (prefix) {
    return new RegExp(
      `(^${prefix}(?<prefixed>.+))|((https?://)?` +
        pattern.replace("{input}", `(?<handle>.+)`) +
        ")"
    );
  }
  return new RegExp(
    "(https?://)?" + pattern.replace("{input}", "(?<handle>.+)")
  );
}

export function getProfileFieldNormalisedHandle(
  value: string,
  pattern: RegExp
): string {
  const match = pattern.exec(value);
  return match?.groups?.prefixed ?? match?.groups?.handle ?? value;
}
