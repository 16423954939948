/* eslint-disable @typescript-eslint/no-explicit-any */
import { FromGameMessage } from "../../app/gameConnection/messages/fromGameMessages";
import { sendGameMessage } from "../../app/gameConnection/webrtc/webRtcMessageHandlers";
import {
  fromGameWhitelist,
  toGameWhitelist,
  toParentLogBlacklist,
} from "../../common/constants/unrealMessagesWhitelist.constant";
import { log, logWarn } from "../../common/util/logger";

export const isThirdPartyMessage = (e: MessageEvent | CustomEvent): boolean => {
  let data;
  if (e instanceof CustomEvent) {
    data = e.detail;
  } else {
    data = e.data;
  }
  const source = data?.source;
  const type = data?.type;
  // Ignore dev tool messages
  if (source === "react-devtools-content-script") return true;
  if (source === "@devtools-page") return true;
  if (source === "react-devtools-bridge") return true;
  if (source === "react-devtools-backend-manager") return true;
  if (source === "@devtools-extension") return true;
  if (type === "__REACT_CONTEXT_DEVTOOL_GLOBAL_HOOK_EVENT") return true;
  if (data?.wappalyzer) return true;

  return false;
};

const EVENT_TYPE_MESSAGE = "message";
class IframeAdapter extends EventTarget {
  static instance: IframeAdapter | undefined;
  constructor() {
    super();
    log("IFRAME", "[iframe] Messages from react devtools will be suppressed.");
    this.subscribeToGameMessagesFromParent();
  }
  public static clearInstance(): void {
    IframeAdapter.instance = undefined;
  }

  public static getInstance(): IframeAdapter {
    if (!IframeAdapter.instance) {
      IframeAdapter.instance = new IframeAdapter();
    }
    return IframeAdapter.instance;
  }

  subscribeToGameMessagesFromParent = () => {
    window.addEventListener(EVENT_TYPE_MESSAGE, this.onMessage);
    log("IFRAME", "[iframe] Listening to game messages from parent.");
  };

  onMessage = (e: MessageEvent) => {
    // We don't want to handle third party messages
    if (isThirdPartyMessage(e)) return;

    log("IFRAME", "[parent => iframe] Received message:", e.data);

    try {
      const message = JSON.parse(e.data);
      this.sendMessageToSubscribers(message);
      this.sendMessageToGame(message);
    } catch (error) {
      log("IFRAME", "[=> iframe] Can't parse message.");
    }
  };

  sendMessageToSubscribers = (message: any) => {
    log(
      "IFRAME",
      "[iframe => subscribers] Forwarded message to subscribers in app."
    );
    this.dispatchEvent(new CustomEvent("message", { detail: message }));
  };

  sendMessageToGame = (message: any) => {
    if (!message?.data?.type) {
      log(
        "IFRAME",
        "[parent => iframe] Will not forward message because it has no type."
      );
      return;
    }

    if (toGameWhitelist.includes(message.data.type)) {
      sendGameMessage(message.data);
      log("IFRAME", "[iframe => game] Forwarded message to game.");
    }
  };

  public subscribeToParentMessages = (
    handler: (message: CustomEvent) => void
  ): {
    unsubscribeFromParentMessages: () => void;
  } => {
    this.addEventListener(EVENT_TYPE_MESSAGE, { handleEvent: handler });
    return {
      unsubscribeFromParentMessages: () => {
        this.unsubscribeFromParentMessages(handler);
      },
    };
  };

  public unsubscribeFromParentMessages = (
    handler: (message: CustomEvent) => void
  ): void => {
    this.removeEventListener(EVENT_TYPE_MESSAGE, { handleEvent: handler });
  };

  // TODO This is temporary. We should correct names for message types, currently we have
  // lower and upper case versions of the same message type.
  isMessageWhitelisted(message: FromGameMessage) {
    return fromGameWhitelist.some((whitelistedMessage) => {
      return whitelistedMessage.toLowerCase() === message.type.toLowerCase();
    });
  }

  public sendGameMessageToParent = (message: FromGameMessage) => {
    if (this.isMessageWhitelisted(message)) {
      this.sendMessageToParent({
        ...message,
        source: "Journee",
      });
    }
  };

  public sendMessageToParent = (message: any) => {
    if (!window.parent) {
      logWarn(
        "IFRAME",
        "[iframe => parent] The iframe parent is not defined. Skipping message."
      );
      return;
    }
    const shouldMessageBeLogged = !(
      toParentLogBlacklist.length > 0 &&
      toParentLogBlacklist.includes(message.type)
    );
    if (shouldMessageBeLogged)
      log("IFRAME", "[iframe => parent] Sending message to parent:", message);

    window.parent.postMessage(message, "*");
  };
}

export default IframeAdapter;
