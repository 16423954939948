import { useCallback, useEffect, useRef, useState } from "react";
import styled, { css, keyframes, useTheme } from "styled-components";
import tinycolor from "tinycolor2";
import { useFirefoxFix } from "../../__hacks/useFirefoxFix";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { useWebsocketConnection } from "../../app/gameConnection/gameConnectionHooks";
import {
  getFitStreamInViewport,
  startVideo,
} from "../../app/gameConnection/gameConnectionUtils";
import useAudio from "../../app/gameConnection/useAudio";
import useKeyboard from "../../app/gameConnection/useKeyboard";
import useScreenResize from "../../app/gameConnection/useScreenResize";
import { registerMouseEvents } from "../../app/gameConnection/webrtc/helpers/inputs";
import { useWebRtcPlayer } from "../../app/gameConnection/webrtc/webRtcConnection";
import { sendGameMessage } from "../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { useStore } from "../../app/store";
import { zIndex } from "../../app/style/theme";
import { isTouch } from "../../common/constants/flags.constant";
import { useIsLandscape } from "../../common/hooks/ui";
import useInitializeAvatar from "../profile/component/useInitializeAvatar";
import { useIsAutoplayBlockedHook } from "./lib/useIsAutoplayBlocked.hook";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }`;

const Player = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: ${zIndex.experience};
  display: flex;
  align-items: center;
`;

const Video = styled.video<{
  $connected?: boolean;
  $applyFilter: boolean;
  $fitViewport: boolean;
}>`
  ${(p) =>
    p.$fitViewport
      ? "width: 100dvw;"
      : css`
          width: 100%;
          height: 100%;
          object-fit: cover;
        `};

  /* Firefox fix: aka I almost cry for one week.
   It fixes the shuffled frames on the video stream in Firefox windows issue
   https://bugzilla.mozilla.org/show_bug.cgi?id=1861895. */
  ${(p) =>
    p.$applyFilter
      ? css`
          filter: hue-rotate(360deg);
        `
      : ""}
  transition:
    opacity 0.6s,
    filter 0.4s,
    transform 0.4s;

  opacity: 0;

  /* We reveal the streaming with a delay, to avoid an abrupt switch between
  login background video and video streaming on fast loads. */

  ${(p) =>
    p.$connected &&
    css`
      animation: ${fadeIn} 1s ease-out 2s 1 forwards;
    `}
`;

const ClickRipples = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
`;

const VideoWrapper = styled.div<{
  $fitViewport: boolean;
}>`
  ${(p) =>
    p.$fitViewport
      ? "width: 100dvw;"
      : css`
          width: 100%;
          height: 100%;
        `};
`;

const GamePlayer: React.FC = () => {
  const hasInteracted = useRef(false);
  const [playerElement, setPlayerEl] = useState<HTMLDivElement | null>(null);
  const [videoElement, setVideoEl] = useState<HTMLVideoElement | null>(null);
  const [playing, setPlaying] = useState(false);
  const [hasVideoLoaded, setHasVideoLoaded] = useState(false);
  const isBlocked = useIsAutoplayBlockedHook();
  const gameIsReady = useStore((s) => s.gameConnection.gameIsReady);
  const setClientInfo = useStore((s) => s.gameConnection.setClientInfo);
  const theme = useTheme();

  const streamAudio = useAudio(videoElement);
  useWebsocketConnection();
  const {
    environment: { forceTurnRelay, fitViewport: fitViewportConfig },
  } = useEnvironmentContext();
  const isLandscape = useIsLandscape();
  const fitViewport = !isLandscape && getFitStreamInViewport(fitViewportConfig);

  useWebRtcPlayer(
    playerElement,
    videoElement,
    streamAudio,
    forceTurnRelay || false
  );
  // CAREFUL. These 2 hooks must await for gameIsReady to function properly.
  // This because we need a video element with a given size to start registering mouse or resize events.
  useScreenResize(playerElement, videoElement, gameIsReady, hasVideoLoaded);
  useEffect(() => {
    if (!gameIsReady) return;
    if (!videoElement) return;
    registerMouseEvents(videoElement);
  }, [gameIsReady, videoElement]);

  useInitializeAvatar();
  useKeyboard();

  // Save theme colors to the store to send in the client info
  useEffect(() => {
    setClientInfo({
      isTouchDevice: isTouch,
      webBackgroundColor: tinycolor(theme.colorBelow2).toHexString(),
      webTextColor: tinycolor(theme.colorAbove5).toHexString(),
    });
  }, [theme.colorAbove5, theme.colorBelow2, setClientInfo]);

  const handleVideoClick = useCallback(() => {
    videoElement?.focus();
    if (playing && !hasInteracted.current) {
      hasInteracted.current = true;
      if (streamAudio.paused) {
        window.dispatchEvent(new CustomEvent("play-experience-audio"));
      }
    }
  }, [playing, streamAudio, videoElement]);

  const handlePlay = useCallback(async () => {
    startVideo(videoElement).then((isPlaying) => {
      sendGameMessage({
        type: "OnStreamIsShown",
      });
      window.analytics?.track("flow", {
        type: "flow",
        name: "connected",
      });
      setPlaying(isPlaying);
      sendGameMessage({
        type: "RequestQuestsInfo",
      });
    });
  }, [videoElement]);

  /**
   * If autoplay is blocked, we need to listen for a custom event
   * to start the video.
   */
  useEffect(() => {
    if (!isBlocked) return;
    window.addEventListener("play-experience-video", handlePlay, {
      once: true,
    });
    return () => {
      window.removeEventListener("play-experience-video", handlePlay);
    };
  }, [isBlocked, handlePlay]);

  /**
   * If autoplay is not blocked, we start the video.
   */
  useEffect(() => {
    if (!gameIsReady) return;
    if (!hasVideoLoaded) return;
    if (playing) return;
    if (isBlocked) return;
    handlePlay();
  }, [
    hasVideoLoaded,
    gameIsReady,
    playing,
    videoElement,
    isBlocked,
    handlePlay,
  ]);

  // Firefox fix, bug reported on the next link https://bugzilla.mozilla.org/show_bug.cgi?id=1861895
  const { canvasRef, applyFilter } = useFirefoxFix();

  return (
    <Player ref={setPlayerEl}>
      {/* TODO: add this to the id dictionary */}
      {/* This canvas element gets removed after we check the vendor and renderer from the webgl api */}
      <canvas width={0} height={0} ref={canvasRef} id="canvas" />
      <VideoWrapper $fitViewport={fitViewport}>
        <ClickRipples id="clickRipples" />
        <Video
          tabIndex={-1}
          onClick={handleVideoClick}
          id="streamingVideo"
          muted
          playsInline
          controls={false}
          ref={setVideoEl}
          $connected={playing}
          disablePictureInPicture
          $applyFilter={applyFilter}
          onLoadedMetadata={() => setHasVideoLoaded(true)}
          $fitViewport={fitViewport}
        />
      </VideoWrapper>
    </Player>
  );
};

export default GamePlayer;
