import { SvgProps } from "../../types/svg";

const BackIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.2501 8.73632L16.9864 12.4442M16.9864 12.4442L13.2785 16.1806M16.9864 12.4442L6.75057 12.4833C5.7634 12.4871 4.81516 12.0986 4.11446 11.4032C3.41376 10.7078 3.01799 9.76258 3.01422 8.77541C3.01045 7.78824 3.39899 6.84 4.09436 6.1393C4.78973 5.4386 5.73497 5.04283 6.72214 5.03906L7.65268 5.03551"
      strokeWidth="2"
      stroke="currentColor"
      className="stroke-color"
    />
  </svg>
);
export default BackIcon;
