import { CSSProperties } from "react";
import ReactMarkdown from "react-markdown";
import { HeadingProps, LiProps } from "react-markdown/lib/ast-to-react";
import { NormalComponents } from "react-markdown/lib/complex-types";
import styled, { useTheme } from "styled-components";
import { steps } from "../../app/style/theme";
import Typo, { TypographyNames } from "./Typo";

const StyledMarkdown = styled(ReactMarkdown)<{ $textAlign?: string }>`
  word-break: break-all;
  text-align: ${(p) => p.$textAlign ?? "left"};
  a {
    text-decoration: none;
    :hover {
      text-decoration: underline;
      text-decoration-color: ${(p) => p.theme.colorAboveBrand};
    }
  }
  ul,
  ol {
    font-size: ${steps.font.f20.size};
    color: ${(p) => p.theme.colorAbove3};
  }
  hr {
    border: none;
    border-block-end: 1px solid ${(p) => p.theme.colorAbove0};
  }
  span {
    display: block;
  }
  span + span {
    margin-block-start: 10px;
  }
  em,
  b {
    font-weight: 600;
  }
`;

const H1Markdown: React.FC<HeadingProps> = ({ children }) => (
  <h1>
    <Typo.Title>{children}</Typo.Title>
  </h1>
);
const H2Markdown: React.FC<HeadingProps> = ({ children }) => (
  <h2>
    <Typo.Title>{children}</Typo.Title>
  </h2>
);
const H3Markdown: React.FC<HeadingProps> = ({ children }) => (
  <h3>
    <Typo.Subtitle>{children}</Typo.Subtitle>
  </h3>
);
const H4Markdown: React.FC<HeadingProps> = ({ children }) => (
  <h4>
    <Typo.Subtitle>{children}</Typo.Subtitle>
  </h4>
);
const H5Markdown: React.FC<HeadingProps> = ({ children }) => (
  <h5>
    <Typo.Label>{children}</Typo.Label>
  </h5>
);
const LiMarkdown: React.FC<LiProps> = ({ children }) => (
  <li>
    <Typo.Body>{children}</Typo.Body>
  </li>
);
const PMarkdown: NormalComponents["p"] = ({ children }) => (
  <Typo.Body>{children}</Typo.Body>
);
const PNoteMarkdown: NormalComponents["p"] = ({ children }) => (
  <Typo.Note>{children}</Typo.Note>
);
const AMarkdown: NormalComponents["a"] = ({ children, ...p }) => {
  const theme = useTheme();
  return (
    <a target="_blank" rel="noopener noreferrer" {...p}>
      <Typo.Body color={theme.colorAboveBrand}>{children}</Typo.Body>
    </a>
  );
};

export type Props = {
  textAlign?: CSSProperties["textAlign"];
  content: string;
  BodyTypographyStyle?:
    | (typeof TypographyNames)["Body"]
    | (typeof TypographyNames)["Note"];
  testId?: string;
};

/** Render some markdown content using the correct theme and fromatting. */
const Markdown: React.FC<Props> = ({
  content,
  textAlign,
  testId,
  BodyTypographyStyle,
}) => {
  return (
    <StyledMarkdown
      data-testid={testId}
      $textAlign={textAlign}
      components={{
        h1: H1Markdown,
        h2: H2Markdown,
        h3: H3Markdown,
        h4: H4Markdown,
        h5: H5Markdown,
        li: LiMarkdown,
        p:
          BodyTypographyStyle && BodyTypographyStyle === TypographyNames.Note
            ? PNoteMarkdown
            : PMarkdown,
        a: AMarkdown,
      }}
    >
      {content}
    </StyledMarkdown>
  );
};

export default Markdown;
