import { useCallback, useEffect, useMemo, useState } from "react";
import { styled, useTheme } from "styled-components";
import { useEnvironmentContext } from "../../../../app/EnvironmentDataProvider";
import { NearbyPlayer } from "../../../../app/gameConnection/messages/sharedDataTypes";
import { useStore } from "../../../../app/store";
import { steps } from "../../../../app/style/theme";
import Column from "../../../../componentsLibrary/atoms/Column";
import HeaderBadge from "../../../../componentsLibrary/atoms/HeaderBadge";
import Hide from "../../../../componentsLibrary/atoms/Hide";
import Icon from "../../../../componentsLibrary/atoms/Icon";
import Space from "../../../../componentsLibrary/atoms/Space";
import Typo from "../../../../componentsLibrary/atoms/Typo";
import ButtonText from "../../../../componentsLibrary/molecules/ButtonText";
import { Ring } from "../../../_login/component/StartButton";
import { useText } from "../../../language/language.hook";
import { SearchableProfile } from "../../../profile/lib/profileTypes";
import { VideoConferenceParticipant } from "../../../videoConference/types/videoConference.types";
import PlayerListSection from "./PlayerListSection";
import PurpleVideoOnIcon from "./PurpleVideoOnIcon";
import SearchPlayers from "./SearchPlayers";
import useNearbyPlayers from "./useNearbyPlayers";
import usePlayerResults from "./usePlayerResults";
import useSearchPlayerList from "./useSearchPlayerList";
import useVoiceChatParticipants from "./useVoiceChatParticipants";

type PlayerListSection = {
  shouldRender: boolean;
  sectionName: string;
  players: (JSX.Element | null)[];
  title?: string;
  noContent: React.ReactNode;
};

function findPublishingVideoConferenceParticipantFromPlayerId(
  participants: VideoConferenceParticipant[],
  allParticipants: SearchableProfile[],
  playerId: number
): VideoConferenceParticipant | undefined {
  return participants.find((p) => {
    const userId = p.userId;
    const participantPlayerId = allParticipants.find(
      (p) => p.userId === userId
    )?.photonPlayerId;
    if (!p.isPublishing) return false;
    if (!participantPlayerId) return false;

    return participantPlayerId === playerId;
  });
}

function filterOutParticipantsFromNearbyPlayers(
  participants: VideoConferenceParticipant[],
  allParticipants: SearchableProfile[],
  nearbyPlayers: NearbyPlayer[]
): NearbyPlayer[] {
  return nearbyPlayers
    .filter((np) => {
      const playerId = np.playerId;
      if (!playerId) return true;
      const participant = findPublishingVideoConferenceParticipantFromPlayerId(
        participants,
        allParticipants,
        playerId
      );
      return !participant;
    })
    .filter((np) => np != null);
}

const Wrapper = styled.div`
  padding-top: 84px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 20px;
  height: 100%;
`;

const TitleRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
`;

const AbsoluteHide = styled(Hide)`
  position: absolute;
  top: 0;
`;

const JoinVideoChatContaioner = styled.div`
  border-radius: ${steps.borderRadius.b30};
  background: ${(p) => p.theme.colorAbove0};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  height: 325px;
`;

const BoldButtonText = styled(ButtonText)`
  span {
    font-size: ${steps.font.f20.size};
    line-height: ${steps.font.f20.lineHight};
    color: ${(p) => p.theme.colorAbove4};

    &:hover {
      color: ${(p) => p.theme.colorAbove5};
    }
  }
`;

const PlayerList: React.FC = () => {
  const theme = useTheme();
  const t = useText();
  const videoConferenceParticipants = useStore(
    (s) => s.videoConference.participants
  );
  const allParticipantProfiles = useStore((s) => s.gameConnection.allPlayers);
  const nearbyPlayers = useStore((s) => s.gameConnection.nearbyPlayers);
  const roomId = useStore((s) => s.gameConnection.roomId);
  const openPanel = useStore((s) => s.layout.openPanel);
  const [showLoading, setShowLoading] = useState(false);
  const [showPlayerList, setShowPlayerList] = useState(false);
  const { searchPlayerList } = useSearchPlayerList();
  const [searchResults, setSearchResults] = useState<SearchableProfile[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const { environment } = useEnvironmentContext();

  const hasVoiceChat = environment.voiceOn || environment.voiceVideoOn;

  useEffect(() => {
    setShowLoading(!roomId);
    setShowPlayerList(Boolean(roomId));
  }, [roomId, setShowLoading, setShowPlayerList]);

  const onClear = useCallback(() => {
    setSearchResults([]);
    setSearchValue("");
  }, [setSearchResults]);

  const onSearch = useCallback(
    async (search: string) => {
      const results = await searchPlayerList(search);
      setSearchValue(search);
      if (search === "") {
        return setSearchResults([]);
      }
      if (results) {
        setSearchResults(results.map((r) => r.document));
      }
    },
    [searchPlayerList]
  );

  const filteredNearbyPlayers = filterOutParticipantsFromNearbyPlayers(
    Object.values(videoConferenceParticipants),
    Object.values(allParticipantProfiles),
    nearbyPlayers
  );
  const mappedNearbyPlayers = useNearbyPlayers(filteredNearbyPlayers);
  const searchResultsMapped = usePlayerResults(searchResults);
  const voiceChatParticipantsMapped = useVoiceChatParticipants();
  const numberOfPlayers = Object.values(videoConferenceParticipants).filter(
    (p) => p.isPublishing
  ).length;

  const playerListSections: PlayerListSection[] = useMemo(
    () => [
      {
        sectionName: "In Chat",
        shouldRender: searchValue === "" && hasVoiceChat,
        players: voiceChatParticipantsMapped,
        title: t("players_in_chat"),
        noContent: (
          <JoinVideoChatContaioner>
            <Space h={5} />
            <HeaderBadge width="48px" height="48px">
              <PurpleVideoOnIcon height="20px" width="20px" />
            </HeaderBadge>
            <Space h={2} />
            <BoldButtonText
              onClick={() => openPanel("settings/video")}
              padding="0"
              height="auto"
            >
              {t("settings_join_video_chat")}&gt;
            </BoldButtonText>
            <Space h={1} />
            <Typo.Body style={{ padding: "0 16px" }}>
              {numberOfPlayers === 1
                ? t("player_join_chat_invitation_one_person")
                : t("player_join_chat_invitation", {
                    numberOfPlayers,
                  })}
            </Typo.Body>
            <Space h={4} />
          </JoinVideoChatContaioner>
        ),
      },
      {
        sectionName: "Others Nearby",
        shouldRender: searchValue === "",
        players: mappedNearbyPlayers,
        title: t("players_nearby"),
        noContent: (
          <>
            <Space h={3} />
            <Icon.Bust size="34px" />
            <Space h={4} />
            <Typo.Body style={{ padding: "0 16px" }}>
              {t("players_nearby_empty")}
            </Typo.Body>
          </>
        ),
      },
      {
        sectionName: "Search Results",
        shouldRender: searchValue !== "",
        players: searchResultsMapped,
        // title: t("players_results"),
        noContent: (
          <>
            <Icon.Warning size="40px" />
            <Space h={4} />
            <Typo.Subtitle>{t("players_results_empty_title")}</Typo.Subtitle>
            <Space h={2} />
            <Typo.Body style={{ padding: "0 16px" }}>
              {t("players_results_empty")}
            </Typo.Body>
          </>
        ),
      },
    ],
    [
      searchValue,
      hasVoiceChat,
      voiceChatParticipantsMapped,
      t,
      numberOfPlayers,
      mappedNearbyPlayers,
      searchResultsMapped,
      openPanel,
    ]
  );

  return (
    <div>
      <AbsoluteHide
        hide={!showLoading}
        unMount
        height="100%"
        width="100%"
        speed={600}
      >
        <Wrapper>
          <Column justify="center" align="center">
            <Typo.Subtitle>{t("player_joining_room")}</Typo.Subtitle>
            <Typo.Note>{t("player_please_wait")}</Typo.Note>
            <Space h={5} />
            <Ring
              isSpinning
              strokeWidth="4"
              reverse
              size={100}
              color={theme.colorAbove3}
            />
          </Column>
        </Wrapper>
      </AbsoluteHide>
      <Hide
        hide={!showPlayerList}
        unMount
        height="100%"
        width="100%"
        speed={600}
      >
        <Wrapper>
          <TitleRow>
            <SearchPlayers onSearch={onSearch} onClear={onClear} />
          </TitleRow>
          <Space h={4} />
          <ContentWrapper>
            {playerListSections.map((playerListSection) => {
              if (playerListSection.shouldRender) {
                const renderableSections = playerListSections.filter(
                  (pls) => pls.shouldRender
                );
                return (
                  <PlayerListSection
                    key={playerListSection.sectionName}
                    isAlone={renderableSections.length <= 1}
                    title={playerListSection.title}
                    players={playerListSection.players}
                    NoContentElement={playerListSection.noContent}
                  />
                );
              }
            })}
          </ContentWrapper>
        </Wrapper>
      </Hide>
    </div>
  );
};

export default PlayerList;
