import { log, logError, logWarn } from "../../../common/util/logger";
import { WebsocketMessage } from "./websocketTypes";

export let ws: WebSocket | null = null;

const isWSOpen = (webSocket: WebSocket | null | undefined) => {
  return Boolean(webSocket && webSocket.readyState === 1);
};

export const sendWSMessage = (message: string) => {
  if (isWSOpen(ws)) ws?.send(message);
  else logWarn("WS", "WS closed. Could not send msg:", message);
};

export const initiateWsConnection = (
  url: string,
  onOpen?: (ws: WebSocket | null) => void,
  onMessage?: (message: WebsocketMessage, data: string) => void,
  onError?: (error: string) => void,
  onClose?: (event: CloseEvent) => void
) => {
  ws = new WebSocket(url);

  ws.onopen = () => {
    log("WS", `WS opened: ${url}`);
    if (onOpen) onOpen(ws);
  };

  ws.onmessage = (event: MessageEvent<string>) => {
    const message: WebsocketMessage = JSON.parse(event.data);
    log("WS", `<- WS of type "${message.type}"`);
    if (onMessage) onMessage(message, event.data);
  };

  /**
   * The WebSocket error event is not very useful. The onclose event
   * has slightly more information.
   * Source: https://stackoverflow.com/a/18804298
   */
  ws.onerror = (event) => {
    const msg = JSON.stringify(event);
    logError("WS", `WS error: ${msg}`, event);
    if (onError) onError(msg);
  };

  ws.onclose = (event) => {
    log("WS", `WS closed: ${JSON.stringify(event.code)} - ${event.reason}`);

    ws = null;
    if (onClose) onClose(event);
  };

  return {
    closeConnection: () => {
      log("WS", "WS closing: ", url);
      if (ws) ws.close(1000, "app exiting");
      ws = null;
    },
    isConnected: isWSOpen(ws),
  };
};
