// A freeze-frame is a still JPEG image shown instead of the video.
import { log, logWarn } from "../../../../common/util/logger";

/**
 * How FreezeFrame more or less works:
 * receives first ToClientMessageType.FreezeFrame event => set receive = true
 * as long as receive === true, every message is appended to the JPEG string
 * when the size matches the announced size, set receiving = false
 * Probably when the stream starts, the server sends UnfreezeFrame => hide it.
 */

export const freezeFrame = {
  receiving: false,
  size: 0,
  jpeg: undefined,
  height: 0,
  width: 0,
  valid: false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;

export const receiveFreezeFrameData = (view: Uint8Array) => {
  const jpeg = new Uint8Array(freezeFrame.jpeg.length + view.length);
  jpeg.set(freezeFrame.jpeg, 0);
  jpeg.set(view, freezeFrame.jpeg.length);
  freezeFrame.jpeg = jpeg;
  if (freezeFrame.jpeg.length === freezeFrame.size) {
    freezeFrame.receiving = false;
    freezeFrame.valid = true;
    log("GENERIC", `received complete freeze frame ${freezeFrame.size}`);
    showFreezeFrame();
  } else if (freezeFrame.jpeg.length > freezeFrame.size) {
    // This happened quite a bit (dozens of times) according to LogRocket.
    logWarn(
      "GENERIC",
      `received bigger freeze frame than advertised: ${freezeFrame.jpeg.length}/${freezeFrame.size}`
    );
    freezeFrame.jpeg = undefined;
    freezeFrame.receiving = false;
  } else {
    log(
      "GENERIC",
      `received next chunk (${view.length} bytes) of freeze frame: ${freezeFrame.jpeg.length}/${freezeFrame.size}`
    );
  }
};

export const startFreezeFrame = (view: Uint8Array) => {
  freezeFrame.size = new DataView(view.slice(1, 5).buffer).getInt32(0, true);
  freezeFrame.jpeg = view.slice(1 + 4);
  if (freezeFrame.jpeg.length < freezeFrame.size) {
    log(
      "GENERIC",
      `received first chunk of freeze frame: ${freezeFrame.jpeg.length}/${freezeFrame.size}`
    );
    freezeFrame.receiving = true;
  } else {
    log(
      "GENERIC",
      `received complete freeze frame: ${freezeFrame.jpeg.length}/${freezeFrame.size}`
    );
    showFreezeFrame();
  }
};

export const showFreezeFrame = () => {
  log("GENERIC", "TODO: showFreezeFrame()");
  // let base64 = btoa(
  //   freezeFrame.jpeg.reduce(
  //     (data: string, byte: any) => data + String.fromCharCode(byte),
  //     ""
  //   )
  // );
  // freezeFrameOverlay.src = "data:image/jpeg;base64," + base64;
  // freezeFrameOverlay.onload = function () {
  //   freezeFrame.height = freezeFrameOverlay.naturalHeight;
  //   freezeFrame.width = freezeFrameOverlay.naturalWidth;
  //   resizeFreezeFrameOverlay();
  //   if (shouldShowPlayOverlay) {
  //     showPlayOverlay();
  //     resizePlayerStyle();
  //   } else {
  //     showFreezeFrameOverlay();
  //   }
  // };
};

export const setupFreezeFrameOverlay = () => {
  log("GENERIC", "TODO: setupFreezeFrameOverlay()");
  // freezeFrameOverlay = document.createElement("img");
  // freezeFrameOverlay.id = "freezeFrameOverlay";
  // freezeFrameOverlay.style.display = "none";
  // freezeFrameOverlay.style.pointerEvents = "none";
  // freezeFrameOverlay.style.position = "absolute";
  // freezeFrameOverlay.style.zIndex = "30";
};

export const showFreezeFrameOverlay = () => {
  log("GENERIC", "TODO: showFreezeFrameOverlay()");
  if (freezeFrame.valid) {
    // freezeFrameOverlay.style.display = "block";
  }
};

export const invalidateFreezeFrameOverlay = () => {
  log("GENERIC", "TODO: invalidateFreezeFrameOverlay()");
  // freezeFrameOverlay.style.display = "none";
  freezeFrame.valid = false;
};

export const resizeFreezeFrameOverlay = () => {
  log("GENERIC", "TODO: resizeFreezeFrameOverlay()");
  if (freezeFrame.width !== 0 && freezeFrame.height !== 0) {
    // let displayWidth = 0;
    // let displayHeight = 0;
    // let displayTop = 0;
    // let displayLeft = 0;
    // let checkBox = document.getElementById(
    //   "enlarge-display-to-fill-window-tgl"
    // ) as HTMLInputElement;
    // if (checkBox !== null && checkBox.checked) {
    //   let windowAspectRatio = window.innerWidth / window.innerHeight;
    //   let videoAspectRatio = freezeFrame.width / freezeFrame.height;
    //   if (windowAspectRatio < videoAspectRatio) {
    //     displayWidth = window.innerWidth;
    //     displayHeight = Math.floor(window.innerWidth / videoAspectRatio);
    //     displayTop = Math.floor((window.innerHeight - displayHeight) * 0.5);
    //     displayLeft = 0;
    //   } else {
    //     displayWidth = Math.floor(window.innerHeight * videoAspectRatio);
    //     displayHeight = window.innerHeight;
    //     displayTop = 0;
    //     displayLeft = Math.floor((window.innerWidth - displayWidth) * 0.5);
    //   }
    // } else {
    //   displayWidth = freezeFrame.width;
    //   displayHeight = freezeFrame.height;
    //   // displayTop = 0;
    //   displayLeft = 0;
    // }
    // freezeFrameOverlay.style.width = displayWidth + "px";
    // freezeFrameOverlay.style.height = displayHeight + "px";
    // freezeFrameOverlay.style.left = displayLeft + "px";
    // freezeFrameOverlay.style.top = displayTop + "px";
  }
};
