import { FromGameMessage } from "../../app/gameConnection/messages/fromGameMessages";
import { SliceCreator } from "../../app/store";
import { defaultProfileData } from "./lib/constants";
import { ProfileData } from "./lib/profileTypes";

export function getLocalPlayerName(profileData?: ProfileData) {
  if (!profileData) return "";
  if (!profileData.lastName) return profileData.firstName;
  if (!profileData.firstName) return null;
  return `${profileData.firstName} ${profileData.lastName}`;
}

const sliceName = "profile";

/* Here we store the visitor profile data (the old business card).
   The data is kept in the local state of the component (VisitorProfilePanel) as the visitor
   is editing/filling it. When submitted and validated, it is sent to this slice.
   
   From here it is:
   1. Sent to the unreal experience (for multiplayer stuff).
   2. Sent to services (for the visitors search system + checkout other visitors profile system).
   3. Persisted on localstorage (for refreshes and returning visitors).
   Note that some values (e.g. firstName, avatarId, customAvatar, avatarColor) {r:0, g: 0, b: 0} are immediately sent
   to unreal directly from the form (VisitorProfilePanel) because the visitors needs to see the effect of
   these changes into the 3D world in real time.
*/

export interface VisitorProfileState {
  profileData: ProfileData;
  setProfileData: (profile: ProfileData) => void;
  setProfileField: <T extends keyof ProfileData>(
    key: T,
    value: ProfileData[T]
  ) => void;
  setCustomAvatarUrl: (customAvatarUrl: string) => void;
  externalAssetLoadStatus: string | null;
  handleProfileMessage: (message: FromGameMessage) => void;
}

type State = {
  profile: VisitorProfileState;
};

export const createVisitorProfileSlice: SliceCreator<State> = (set) => ({
  profile: {
    profileData: defaultProfileData,
    setProfileData: (profileData) => {
      set(
        (state) => {
          state.profile.profileData = profileData;
        },
        false,
        `${sliceName}/setProfileData`
      );
    },
    setProfileField: (key, value) => {
      set(
        (state) => {
          state.profile.profileData[key] = value;
        },
        false,
        `${sliceName}/setProfileField`
      );
    },
    setCustomAvatarUrl: (url) => {
      set(
        (state) => {
          state.profile.profileData.customAvatarUrl = url;
          state.profile.profileData.avatarId = "readyplayerme";
        },
        false,
        `${sliceName}/setCustomAvatarUrl`
      );
    },
    externalAssetLoadStatus: null,
    handleProfileMessage: (message) => {
      set(
        (state) => {
          if (message.type === "ExternalAssetLoadStatus") {
            state.profile.externalAssetLoadStatus = message.status;
          }
        },
        false,
        `${sliceName}/handleProfileMessage`
      );
    },
  },
});
