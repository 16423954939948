import styled, { DefaultTheme, useTheme } from "styled-components";
import {
  cssToUnrealColor,
  isColorBright,
  rgbaToHex,
} from "../../../../../common/util/color";
import Button from "../../../../../componentsLibrary/atoms/Button";
import HeaderBadge from "../../../../../componentsLibrary/atoms/HeaderBadge";
import Icon from "../../../../../componentsLibrary/atoms/Icon";
import Image from "../../../../../componentsLibrary/atoms/Image";
import Row from "../../../../../componentsLibrary/atoms/Row";
import Space from "../../../../../componentsLibrary/atoms/Space";
import Typo from "../../../../../componentsLibrary/atoms/Typo";
import { getSafeStepsSpacing } from "../../../../../componentsLibrary/atoms/util/ui";
import { SpeakingIndicatorWrapper } from "../../../../../componentsLibrary/molecules/avatarCircle/LocalVideoAvatarCircle";
import SpeakingWidget from "../../../../../componentsLibrary/molecules/avatarCircle/SpeakingWidget";
import VideoAvatarCircle from "../../../../../componentsLibrary/molecules/avatarCircle/VideoAvatarCircle";
import { INDICATOR_SIZE } from "../../../../videoConference/lib/videoAvatars.constants";
import { AvailableSocials, Props } from "./PlayerProfile.ui";
import TeleportDrawer from "./TeleportDrawer";

const BASE_URL = "https://models.readyplayer.me";

function renderStrongColor(color: "light" | "dark", theme: DefaultTheme) {
  if (color === "light") {
    return theme.colorBelow2;
  }
  return theme.colorAbove5;
}

function renderWeakColor(color: "light" | "dark", theme: DefaultTheme) {
  if (color === "light") {
    return theme.colorBelow2;
  }
  return theme.colorAbove4;
}

function getSocialsLink(socialHandle: keyof AvailableSocials, social: string) {
  switch (socialHandle) {
    case "twitter":
      return `https://twitter.com/${social}`;
    case "linkedin":
      return `https://www.linkedin.com/in/${social}`;
    case "instagram":
      return `https://www.instagram.com/${social}`;
    case "facebook":
      return `https://www.facebook.com/${social}`;
    default:
      return null;
  }
}

const SocialsWrap = styled.div<{ $smallGrid: boolean }>`
  display: grid;
  grid-template-columns: ${(p) =>
    p.$smallGrid ? "repeat(3, 1fr)" : "repeat(4, 1fr)"};
  gap: ${getSafeStepsSpacing(1)};
`;

const ProfileContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 72px;
  height: 100%;
`;

export const BackButtonPositioner = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1;
`;

const AvatarContainer = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translate(45%);
  overflow: hidden;
  right: 0;
  z-index: -1;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  padding-left: 24px;
`;

const iconMap = {
  twitter: Icon.TwitterX,
  linkedin: Icon.Linkedin,
  instagram: Icon.Instagram,
  facebook: Icon.Facebook,
  yammer: Icon.Yammer,
};

const FullPlayerProfileUiNormal: React.FC<Props> = ({
  playerName,
  playerTitle,
  socials = {},
  avatarId,
  playerCompany,
  avatarColor,
  playerKey,
  participant,
  isStillConnected,
  onTeleport,
  onPrevious,
}) => {
  const theme = useTheme();

  const parts = playerName.split(" ");
  const hasTwoNames = parts.length > 1;
  let firstName = playerName;
  let lastName = "";
  if (hasTwoNames) {
    firstName = parts[0];
    lastName = parts.slice(1).join(" ");
  }

  const textColor = isColorBright(rgbaToHex(avatarColor)) ? "dark" : "light";

  const speakingIndicator = (
    <SpeakingIndicatorWrapper $size={INDICATOR_SIZE.SMALL}>
      <SpeakingWidget
        micHidden
        size={INDICATOR_SIZE.SMALL}
        isSpeaking={participant?.isSpeaking || false}
        isMuted={!participant?.isAudioOn || false}
        toggleMute={() => {}}
      />
    </SpeakingIndicatorWrapper>
  );

  const CloseButton = isColorBright(rgbaToHex(avatarColor))
    ? Button.Tertiary
    : Button.Glass;

  return (
    <ProfileContainer>
      <BackButtonPositioner>
        <CloseButton onClick={onPrevious} circular>
          <Icon.Chevron size="1rem" rotate="-90deg" shiftY="-0.1rem" />
        </CloseButton>
      </BackButtonPositioner>
      <AvatarContainer>
        <Image
          src={`${BASE_URL}/${avatarId}.png?size=1024&scene=fullbody-posture-v1-transparent&blendShapes%5BWolf3D_Head%5D%5BmouthSmile%5D=0.8`}
          width="100%"
          minimalPlaceholder
          styleOverrides={{
            transform: "scale(1.3) translateY(12%)",
            objectFit: "cover",
          }}
        />
      </AvatarContainer>
      <DetailsContainer>
        <Space h={5} />
        <Space h={9} />
        <Row height="76px" width="76px" justify="center">
          <HeaderBadge
            noBackground
            noShadow
            rippleColor={renderStrongColor(textColor, theme)}
          >
            <VideoAvatarCircle
              speakingIndicatorPosition="front"
              renderTitleBreakout={() => null}
              isSharingScreen={false}
              hideTitle={false}
              playerKey={playerKey}
              playerName={playerName}
              avatarColor={cssToUnrealColor(theme.colorBelow2)}
              size={76}
              participant={participant}
              noBorder
              speakingIndicator={speakingIndicator}
            />
          </HeaderBadge>
        </Row>
        <Space h={2} />
        <Typo.Title color={renderStrongColor(textColor, theme)}>
          {firstName}
        </Typo.Title>
        {lastName && (
          <Typo.Title color={renderStrongColor(textColor, theme)}>
            {lastName}
          </Typo.Title>
        )}{" "}
        <Space h={2} />
        {playerTitle && (
          <Typo.Body weight={500} color={renderWeakColor(textColor, theme)}>
            {playerTitle},
          </Typo.Body>
        )}
        {playerCompany && (
          <Typo.Body weight={500} color={renderWeakColor(textColor, theme)}>
            {playerCompany}
          </Typo.Body>
        )}
        <Space h={2} />
        <SocialsWrap $smallGrid={Boolean(avatarId)}>
          {Object.entries(socials as AvailableSocials).map(([key, value]) => {
            const Icon = iconMap[key as keyof typeof iconMap];
            const link = getSocialsLink(key as keyof AvailableSocials, value);
            return (
              <Button.Secondary
                circular
                override={{
                  width: "48px",
                  height: "48px",
                }}
                key={key}
                onClick={() => {
                  link && window.open(link, "_blank");
                }}
              >
                <Icon size="16px" color={textColor} />
              </Button.Secondary>
            );
          })}
        </SocialsWrap>
      </DetailsContainer>
      <TeleportDrawer
        disabled={!isStillConnected}
        color={renderStrongColor(textColor, theme)}
        onTeleport={onTeleport}
        style={{
          paddingLeft: "24px",
          marginBottom: "24px",
        }}
      />
    </ProfileContainer>
  );
};

export default FullPlayerProfileUiNormal;
