import {
  isIFrame,
  isMobile,
  isTouch,
} from "../../common/constants/flags.constant";
import { log, logWarn } from "../../common/util/logger";
import { debouncedPlayerSizeChanged } from "./webrtc/helpers/inputs";

export const getFitStreamInViewport = (cmsFitInViewport?: boolean): boolean => {
  return Boolean(cmsFitInViewport) && (isMobile || isIFrame);
};

export type InstanceInfo = {
  instanceId: string;
  region: string;
};

export const getInstanceInfoFromUrl = (instanceUrl: string): InstanceInfo => {
  const reInstanceUrl =
    /https:\/\/(?<region>[a-z0-9-]+)-i-(?<instanceId>\w+)\./;
  const match = instanceUrl.match(reInstanceUrl);
  return match
    ? (match.groups as InstanceInfo)
    : { instanceId: "", region: "" };
};

export const startVideo = async (videoElement?: HTMLVideoElement | null) => {
  const timeStart = Date.now();
  const checkPlayTimeoutId = setTimeout(() => {
    logWarn("VIDEOPLAY", "VIDEOPLAY: Play did not finish after 6sec.");
  }, 6000);
  if (!videoElement) {
    logWarn(
      "VIDEOPLAY",
      "VIDEOPLAY: videoRef isn't ready! The video won't start."
    );
    return false;
  }

  // Unreal 4.27+ uses a separate element for audio:
  log("VIDEOPLAY", "VIDEOPLAY: start playback");

  return videoElement
    .play()
    .then(() => {
      // Depending on the streamer setup, a different element must be
      // checked for autoplay success/failure.
      const stream: HTMLMediaElement = videoElement;
      if (stream == null)
        throw new Error("Missing stream! Has srcObject been removed?");

      if (stream.paused) {
        logWarn("VIDEOPLAY", `VIDEOPLAY: Video is paused!`);
        log("VIDEOPLAY", `VIDEOPLAY: The above warning is expected on Safari.`);
        return false;
      } else {
        log("VIDEOPLAY", `VIDEOPLAY: Video is playing!`);

        // Initialize playerElementClientRect for mobile devices
        if (isTouch) debouncedPlayerSizeChanged(videoElement);

        return true;
      }
    })
    .catch((e) => {
      logWarn("VIDEOPLAY", e);
      if (videoElement) {
        debouncedPlayerSizeChanged(videoElement);
      } else {
        logWarn(
          "VIDEOPLAY",
          "VIDEOPLAY: Missing video element ref! Can't setupNormalizeAndQuantize()"
        );
      }
      return false;
    })
    .finally(() => {
      const timeDiff = Date.now() - timeStart;
      if (timeDiff > 6000) {
        // TODO: This should be a transaction.
      }
      clearTimeout(checkPlayTimeoutId);
    });
};
