import styled from "styled-components";
import { PanelTestIds } from "../../../common/constants/testIds.constant";

const Wrapper = styled.div`
  display: block;
  text-align: center;

  & > div {
    margin: 0 6px 4px;
  }
`;

type Props = {
  children: React.ReactNode;
};

const ButtonWrapper: React.FC<Props> = ({ children }) => {
  return <Wrapper data-testid={PanelTestIds.popup.actions}>{children}</Wrapper>;
};

export default ButtonWrapper;
