import { SliceCreator } from "../../app/store";
import { log } from "../../common/util/logger";
import { UserFlowStep, userFlow, userFlowPhases } from "./userFlow";

const sliceName = "userFlow";

export type UserFlowState = {
  currentStep: UserFlowStep;
  registerCleanupHook: (name: string, hook: () => void) => void;
  cleanupHooks: Record<string, () => void>;
  completeStep: (step: UserFlowStep) => void;
  __unsafelySetCurrentStep: (step: UserFlowStep) => void;
  isStepCompleted: (step: UserFlowStep) => boolean;
  isStepReached: (step: UserFlowStep) => boolean;
  isLoginPhase: () => boolean;
  isEnteringPhase: () => boolean;
  isExperiencePhase: () => boolean;
};

type State = {
  userFlow: UserFlowState;
};

export const createUserFlowSlice: SliceCreator<State> = (set, get) => ({
  userFlow: {
    currentStep: userFlow[0],
    cleanupHooks: {},
    registerCleanupHook: (name, hook) =>
      set(
        (state) => {
          state.userFlow.cleanupHooks[name] = hook;
        },
        false,
        sliceName + "/registerCleanupHook"
      ),
    completeStep: (step) =>
      set(
        (state) => {
          // If the completed step is the current one, go to the next one.
          if (state.userFlow.currentStep === step) {
            state.userFlow.currentStep = getNextStep(step);
          }
          log("USER_FLOW", `FLOW ${step} --> ${state.userFlow.currentStep}`);
        },
        false,
        sliceName + "/completeStep"
      ),
    __unsafelySetCurrentStep: (step) =>
      set(
        (state) => {
          state.userFlow.currentStep = step;
          log("USER_FLOW", `FLOW OVERRIDE --> ${step}`);
        },
        false,
        sliceName + "/__unsafelySetCurrentStep"
      ),
    isStepCompleted: (step) => {
      const currentIndex = userFlow.indexOf(get().userFlow.currentStep);
      const stepIndex = userFlow.indexOf(step);
      return stepIndex < currentIndex;
    },
    isStepReached: (step) => {
      const currentIndex = userFlow.indexOf(get().userFlow.currentStep);
      const stepIndex = userFlow.indexOf(step);
      return stepIndex <= currentIndex;
    },
    isLoginPhase: () => {
      return get().userFlow.currentStep.startsWith(userFlowPhases.login);
    },
    isEnteringPhase: () => {
      return get().userFlow.currentStep.startsWith(userFlowPhases.entering);
    },
    isExperiencePhase: () => {
      return get().userFlow.currentStep.startsWith(userFlowPhases.experience);
    },
  },
});

/** Following the userFlow, get the next step that come after the provided step. */
const getNextStep = (doneStep: UserFlowStep): UserFlowStep => {
  const index = userFlow.indexOf(doneStep);
  if (index >= userFlow.length - 1) return doneStep;
  if (index === -1) return doneStep;
  else return userFlow[index + 1];
};
