import styled from "styled-components";
import { getSafeStepsSpacing } from "./util/ui";

const Div = styled.div<{ $w: string; $h: string }>`
  width: ${(p) => p.$w};
  height: ${(p) => p.$h};
  flex-grow: 0;
  flex-shrink: 0;
`;

export type Props = {
  /** A geometric spacing scale https://www.radix-ui.com/themes/docs/theme/layout#spacing-scale */
  w?: number;
  /** A geometric spacing scale https://www.radix-ui.com/themes/docs/theme/layout#spacing-scale */
  h?: number;
};

/** A quick utility to add horizontal/vertical spacing in the UI. */
const Space: React.FC<Props> = ({ w, h }) => {
  return <Div $w={getSafeStepsSpacing(w)} $h={getSafeStepsSpacing(h)} />;
};

export default Space;
