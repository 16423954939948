import styled from "styled-components";
import { steps } from "../../../app/style/theme";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";
import {
  Dot,
  ThreeDots,
} from "../../../componentsLibrary/molecules/avatarCircle/TitleBreakoutPlayerList";
import { useText } from "../../language/language.hook";
import { TITLE_HEIGHT } from "../lib/videoAvatars.constants";

const NoNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoNameAvatarText = () => {
  const t = useText();

  return (
    <NoNameWrapper>
      <Typo.Note
        fontFamily="Manrope"
        title={t("player_entering")}
        size={steps.font.f10.size}
        noWrap
        lineHeight={steps.font.f20.lineHight}
        style={{
          width: "45px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontStyle: "italic",
          textOverflow: "ellipsis",
          overflow: "hidden",
          pointerEvents: "none",
          height: TITLE_HEIGHT,
        }}
      >
        {t("player_entering")}
      </Typo.Note>
      <Space w={1} />
      <ThreeDots>
        <Dot />
      </ThreeDots>
    </NoNameWrapper>
  );
};

export default NoNameAvatarText;
