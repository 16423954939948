import { ReactNode } from "react";
import styled, {
  StyleSheetManager,
  ThemeProvider,
  createGlobalStyle,
  css,
} from "styled-components";
import { isChrome } from "../common/constants/flags.constant";
import {
  useBodyFont,
  useCurrentEnvironmentTheme,
  useDisplayFont,
} from "./hooks/gyarados.hook";
import { ThemePreset, generateTheme } from "./style/theme";

type Props = {
  children?: ReactNode;
};

function getFontSrc(fontUrl?: string) {
  if (!fontUrl) {
    return "";
  }
  const fontExtension = fontUrl.split(".").pop();
  if (fontExtension === "woff2") {
    return `url(${fontUrl}) format('woff2')`;
  } else if (fontExtension === "woff") {
    return `url(${fontUrl}) format('woff')`;
  } else if (fontExtension === "eot") {
    return `url(${fontUrl}) format('eot')`;
  } else if (fontExtension === "otf") {
    return `url(${fontUrl}) format('opentype')`;
  } else {
    throw new Error(
      `Unknown font extension ${fontExtension} for font url ${fontUrl}`
    );
  }
}

function isFontHostedInHouse(fontUrl?: string) {
  if (!fontUrl) {
    return false;
  }

  return (
    fontUrl.includes(import.meta.env.VITE_MEDIAS_BUCKET_URL) ||
    fontUrl.includes("https://next.journee.live")
  );
}

function getFontDescriptor(
  fontName: string | undefined,
  fontUrl: string | undefined,
  fontWeight: number,
  fontStyle: string
): ReturnType<typeof css> {
  if (!fontName) {
    return css``;
  }

  if (fontUrl && isFontHostedInHouse(fontUrl)) {
    return css`
      @font-face {
        font-family: ${fontName};
        src: ${getFontSrc(fontUrl)};
        font-weight: ${fontWeight};
        font-style: ${fontStyle};
        font-display: swap;
      }
    `;
  }

  if (fontUrl) {
    return css`
      @import url(${fontUrl});
    `;
  }

  return css``;
}

export const GlobalBlurElement = styled.div`
  width: 1px;
  height: 1px;
  backdrop-filter: blur(16px);
  position: fixed;
`;
export const GlobalStyle = createGlobalStyle`
  ${(p) =>
    getFontDescriptor(
      p.theme.fontMain,
      p.theme.fontMainRegularLink,
      500,
      "normal"
    )}


    ${(p) =>
      getFontDescriptor(
        p.theme.fontMain,
        p.theme.fontMainBoldLink,
        600,
        "normal"
      )}

  
  ${(p) =>
    getFontDescriptor(
      p.theme.fontDisplay,
      p.theme.fontDisplayRegularLink,
      400,
      "normal"
    )}
  
  body {
    font-family: ${(p) => p.theme.fontMain}, ${(p) => p.theme.fontMainFallback};
  }
`;

const EnvironmentTheme: React.FC<Props> = ({ children }) => {
  const { bodyFontFamily, bodyFontRegularLink, bodyFontBoldLink } =
    useBodyFont();
  const { displayFontFamily, displayFontRegularLink } = useDisplayFont();
  const { presets, overrides } = useCurrentEnvironmentTheme();

  const theme = generateTheme({
    presets: presets as Partial<ThemePreset>,
    overrides: {
      fontMain: bodyFontFamily,
      fontMainRegularLink: bodyFontRegularLink,
      fontMainBoldLink: bodyFontBoldLink,
      fontDisplay: displayFontFamily,
      fontDisplayRegularLink: displayFontRegularLink,
      ...overrides,
    },
  });

  return (
    <StyleSheetManager enableVendorPrefixes>
      <ThemeProvider theme={theme}>
        {isChrome && <GlobalBlurElement />}
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </StyleSheetManager>
  );
};

export default EnvironmentTheme;
