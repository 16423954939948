import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Omanyte, ServicesApi } from "@journee-live/mew";
import * as Sentry from "@sentry/react";
import cuid from "cuid";
import { log, logError, logInfo, logWarn } from "../../common/util/logger";
import { useEnvironmentContext } from "../EnvironmentDataProvider";
import { useGetLogoutPath } from "../hooks/routing.hook";
import { getQueryStrings } from "../routing/routingUtils";
import { useStore } from "../store";
import { StreamingStatus } from "./gameConnectionTypes";
import { useConnectionAnalytics } from "./useConnectionAnalytics";
import {
  killWebRtcPlayer,
  onWebRtcAnswer,
  onWebRtcIceCandidateFromSignaling,
  onWebRtcOffer,
} from "./webrtc/webRtcConnection";
import { WSStatus } from "./websocket/websocketTypes";
import { initiateWsConnection } from "./websocket/websocketUtils";

export const useQueue = (environmentId: string) => {
  const navigate = useNavigate();
  const logoutPath = useGetLogoutPath();
  const completeStep = useStore((s) => s.userFlow.completeStep);
  const isConnecting = useStore((s) =>
    s.userFlow.isStepReached("login:availability")
  );
  const visitorToken = useStore((s) => s.session.visitorToken);
  const { setQueueStatus, queueStatus } = useStore((s) => s.gameConnection);
  // TODO: Should we merge these two state into a single global one?
  const setStoreInstanceUrl = useStore((s) => s.gameConnection.setInstanceUrl);
  const [instanceUrl, setInstanceUrl] = useState<string | undefined>(undefined);
  const polling = useRef(false);

  // Handle local and direct connection modes
  const { instancePort, instanceUrl: queryInstanceUrl } = getQueryStrings();
  const isLocalExperience = window.location.pathname.startsWith("/_local/");

  useEffect(() => {
    // Queue needs to start after the auth check step is completed.
    if (!isConnecting) return;
    if (!visitorToken) return;
    if (!environmentId) return;
    if (queueStatus.success) return;
    if (polling.current) return;

    // If we are in local mode or directly connecting to an instance,
    // we don't need to poll for an instance.
    const isDirectConnection = Boolean(queryInstanceUrl);
    if (isLocalExperience || isDirectConnection) {
      const url = isLocalExperience
        ? `http://localhost${instancePort ? `:${instancePort}` : ""}`
        : queryInstanceUrl || "";
      setInstanceUrl(url);
      setStoreInstanceUrl(url);
      setQueueStatus({
        success: true,
        available: true,
        index: 0,
        checked: true,
      });

      return;
    }
    polling.current = true;

    let interval: number | undefined = undefined;

    const poll = async () => {
      try {
        const response = await ServicesApi.getQueue(environmentId, {
          headers: { Authorization: `Bearer ${visitorToken}` },
        });

        const instanceUrl = response.success
          ? response.instanceUrl ||
            `https://${response.instance?.id}.${environmentId}.go.journee.live`
          : undefined;
        logInfo("GENERIC", `Instance Id: ${response.instance?.id}`);
        setQueueStatus({
          success: response.success,
          available: response.available,
          index: response.index,
          checked: true,
        });

        if (instanceUrl) {
          clearInterval(interval);
          setInstanceUrl(instanceUrl);
          setStoreInstanceUrl(instanceUrl);
          polling.current = false;
        }
      } catch (error: unknown) {
        setQueueStatus({
          success: false,
          available: false,
          index: -1,
          checked: true,
        });

        // TODO: Expose AxiosError type from @journee-live/mew
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isUnauthorized = (error as any).response?.status === 401;
        if (isUnauthorized) {
          navigate(`${logoutPath}?logout_cause=unauthorized`);
        }
      }
    };

    poll();
    interval = window.setInterval(poll, 5000);
  }, [
    navigate,
    completeStep,
    environmentId,
    isConnecting,
    queueStatus.success,
    setStoreInstanceUrl,
    setQueueStatus,
    visitorToken,
    logoutPath,
    isLocalExperience,
    instancePort,
    queryInstanceUrl,
  ]);

  return { instanceUrl };
};

export const useInstanceWsUrl = (baseUrl?: string, userId?: string) => {
  const { streamId, setStreamId } = useStore((s) => s.gameConnection);
  const visitorToken = useStore((s) => s.session.visitorToken);

  const newStreamId = useMemo(() => {
    if (!streamId) {
      return cuid();
    }
    return streamId;
  }, [streamId]);

  useEffect(() => {
    setStreamId(newStreamId);
  }, [setStreamId, newStreamId]);

  const url = useMemo(() => {
    if (!baseUrl) {
      return;
    }
    const url = new URL(baseUrl);
    url.protocol = url.protocol.replace("http", "ws");
    if (visitorToken) {
      url.searchParams.set("token", visitorToken);
    }
    // TODO: Remove this when the Omanyte is updated
    if (userId) {
      url.searchParams.set("userId", userId);
    }
    url.searchParams.set("streamId", newStreamId);
    return url;
  }, [baseUrl, newStreamId, visitorToken, userId]);
  return url?.toString();
};

export const useWebsocketConnection = () => {
  const { environment } = useEnvironmentContext();
  const setStreamingStatus = useStore(
    (s) => s.gameConnection.setStreamingStatus
  );
  const setServerVersion = useStore((s) => s.gameConnection.setServerVersion);
  const setPlayerCount = useStore((s) => s.gameConnection.setPlayerCount);
  const setRtcConfig = useStore((s) => s.gameConnection.setRtcConfig);
  const { setWSStatus, setQueueStatus, queueStatus } = useStore(
    (s) => s.gameConnection
  );
  const setAudioQuality = useStore((s) => s.gameConnection.setAudioQuality);
  const visitorTokenData = useStore((s) => s.session.visitorTokenData);

  /**
   * Set the audio quality to high if the environment has the highQualityAudio
   *  this is only done one time at the beginning of the experience.
   */
  useEffect(() => {
    if (environment.highQualityAudio) {
      setAudioQuality("high");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * On load of the component, we want to reset the websocket status and queue
   * status. This is because we want to start fresh every time and not take
   * into consideration values stored in the localstorage.
   */
  useEffect(() => {
    setWSStatus(WSStatus.CLOSED);
    setQueueStatus({
      success: false,
      available: false,
      index: -1,
      checked: false,
    });
  }, [setQueueStatus, setWSStatus]);

  /**
   * Start polling for free instances. This will fetch the first instance url it
   * will find and return it.
   */
  const { instanceUrl } = useQueue(environment.id);

  // Track the connection events
  useConnectionAnalytics();
  /**
   * In order to connect to the websocket, we need to format the instance url
   * into a websocket url.
   */
  const instanceWsUrl = useInstanceWsUrl(instanceUrl, visitorTokenData?.id);
  const environmentRef = useRef(environment);
  environmentRef.current = environment;
  const instanceWsUrlRef = useRef(instanceWsUrl);
  instanceWsUrlRef.current = instanceWsUrl;
  const queueStatusRef = useRef(queueStatus);
  queueStatusRef.current = queueStatus;

  const onOpen = useCallback(
    (ws: WebSocket | null) => {
      if (!ws) {
        Sentry.captureException(
          new Error("WS connection opened but ws is null"),
          {
            extra: {
              queueStatus: queueStatusRef.current,
              instanceWsUrl: instanceWsUrlRef.current,
              environment: environmentRef.current,
            },
          }
        );
        logError("GENERIC", "WS connection opened but ws is null");
        return;
      }
      return setWSStatus(ws.readyState);
    },
    [setWSStatus]
  );

  const onMessage = useCallback(
    (message: Omanyte.WSClientMessage, data: string) => {
      switch (message.type) {
        case Omanyte.WSClientMessageType.ClientConfig:
          setRtcConfig(message);
          break;
        case Omanyte.WSClientMessageType.PlayerCount:
          setPlayerCount(message.count);
          break;
        case Omanyte.WSClientMessageType.Offer:
          if (
            !new URLSearchParams(window.location.search).has("offerToReceive")
          ) {
            onWebRtcOffer(message);
          }
          break;
        case Omanyte.WSClientMessageType.Answer:
          onWebRtcAnswer(message);
          log("WEBRTC", `<- WS: ${data}`);
          break;
        case Omanyte.WSClientMessageType.ICECandidate:
          onWebRtcIceCandidateFromSignaling(message.candidate);
          log("WEBRTC", `<- WS: ${data}`);
          break;
        case Omanyte.WSClientMessageType.ServerInfo:
          setServerVersion(message.version);
          break;
        default:
          logWarn("WEBRTC", `invalid WS message type: ${message.type}`);
          log("WEBRTC", `<- WS: ${data}`);
          return;
      }
    },
    [setPlayerCount, setRtcConfig, setServerVersion]
  );

  const onError = useCallback(() => {}, []);

  const onClose = useCallback(
    (event: CloseEvent) => {
      setWSStatus(WSStatus.CLOSED);
      // https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
      if (event.code === 1006 || event.code === 4001) {
        window.analytics?.track("connection", {
          type: "connection",
          name: "instance_disconnected",
          detail: String(event.code),
        });
        logError(
          "USER_FLOW",
          `WS connection closed ${event.reason}; code: ${JSON.stringify(event.code)}`
        );
        setStreamingStatus(StreamingStatus.ERROR);
      }

      killWebRtcPlayer();
    },
    [setWSStatus, setStreamingStatus]
  );

  useEffect(() => {
    if (!instanceWsUrl) return;
    const { closeConnection } = initiateWsConnection(
      instanceWsUrl,
      onOpen,
      onMessage,
      onError,
      onClose
    );
    return () => closeConnection();
  }, [instanceWsUrl, onOpen, onMessage, onError, onClose]);
};
