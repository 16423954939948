import { GyaradosSDK } from "@journee-live/gyarados";
import styled from "styled-components";
import { useIsSmallScreen } from "../../../common/hooks/ui";
import Button from "../../../componentsLibrary/atoms/Button";
import Space from "../../../componentsLibrary/atoms/Space";

const getMediaUrl = (s3Path?: string | null): string | undefined => {
  if (s3Path) return `${import.meta.env.VITE_MEDIAS_BUCKET_URL}/${s3Path}`;
};

export function getActionBooleans(data: GyaradosSDK.InfoCardResponseDto) {
  const {
    buttonType,
    buttonText,
    downloadFile,
    secondaryButtonType,
    secondaryButtonText,
    secondaryDownloadFile,
    mintEventId,
    teleportPoint,
  } = data;
  const hasPrimaryMintButton = Boolean(buttonType === "mint" && mintEventId);
  const hasPrimaryTeleportButton = Boolean(
    buttonType === "teleport" && teleportPoint
  );
  const hasPrimaryLinkButton = Boolean(buttonType === "link" && !!buttonText);
  const hasPrimaryDownloadButton = Boolean(
    buttonType === "download" && buttonText && downloadFile
  );
  const hasSecondaryLinkButton = Boolean(
    secondaryButtonType === "link" && Boolean(secondaryButtonText)
  );
  const hasSecondaryDownloadButton = Boolean(
    secondaryButtonType === "download" &&
      secondaryButtonText &&
      secondaryDownloadFile
  );
  return {
    hasPrimaryMintButton,
    hasPrimaryTeleportButton,
    hasPrimaryLinkButton,
    hasPrimaryDownloadButton,
    hasSecondaryLinkButton,
    hasSecondaryDownloadButton,
    hasActions:
      hasPrimaryMintButton ||
      hasPrimaryTeleportButton ||
      hasPrimaryLinkButton ||
      hasPrimaryDownloadButton ||
      hasSecondaryLinkButton ||
      hasSecondaryDownloadButton,
  };
}

const A = styled.a.attrs({ target: "_blank", rel: "noopener noreferrer" })`
  text-decoration: none;
`;

type Props = {
  data: GyaradosSDK.InfoCardResponseDto;
  onButtonClick?: () => void;
  onSecondaryButtonClick: () => void;
};

const InfoCardActions: React.FC<Props> = ({
  data,
  onButtonClick,
  onSecondaryButtonClick,
}) => {
  const isSmallScreen = useIsSmallScreen();

  const {
    buttonText,
    secondaryButtonLink,
    secondaryButtonText,
    secondaryDownloadFile,
    buttonLink,
    downloadFile,
  } = data;

  const {
    hasPrimaryMintButton,
    hasPrimaryLinkButton,
    hasPrimaryDownloadButton,
    hasSecondaryLinkButton,
    hasSecondaryDownloadButton,
    hasPrimaryTeleportButton,
  } = getActionBooleans(data);

  const primaryButton = (
    <Button.Primary large={isSmallScreen}>{buttonText}</Button.Primary>
  );
  const secondaryButton = (
    <Button.Subtle large={isSmallScreen}>{secondaryButtonText}</Button.Subtle>
  );

  return (
    <>
      {(hasPrimaryMintButton || hasPrimaryTeleportButton) && (
        <Button.Primary large={isSmallScreen} onClick={onButtonClick}>
          {buttonText}
        </Button.Primary>
      )}
      {hasPrimaryLinkButton && (
        <A href={buttonLink || ""} onClick={onButtonClick}>
          {primaryButton}
        </A>
      )}
      {hasPrimaryDownloadButton && (
        <A
          download={downloadFile?.originalName}
          href={getMediaUrl(downloadFile?.s3Path)}
          onClick={onButtonClick}
          onAuxClick={onButtonClick}
        >
          {primaryButton}
        </A>
      )}
      {hasSecondaryLinkButton && (
        <>
          <Space w={2} />
          <A
            href={secondaryButtonLink || ""}
            onClick={onSecondaryButtonClick}
            onAuxClick={onSecondaryButtonClick}
          >
            {secondaryButton}
          </A>
        </>
      )}
      {hasSecondaryDownloadButton && (
        <>
          <Space w={2} />
          <A
            download={secondaryDownloadFile?.originalName}
            href={getMediaUrl(secondaryDownloadFile?.s3Path)}
            onClick={onSecondaryButtonClick}
            onAuxClick={onSecondaryButtonClick}
          >
            {secondaryButton}
          </A>
        </>
      )}
    </>
  );
};

export default InfoCardActions;
