import styled from "styled-components";
import Button from "../../../componentsLibrary/atoms/Button";
import Icon from "../../../componentsLibrary/atoms/Icon";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";
import { useText } from "../../language/language.hook";
import { SettingName } from "../subpages/home/SettingsHomePage.ui";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(p) => p.theme.colorAbove3};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
`;

type Props = {
  isFullscreen: boolean;
  isMuted: boolean;
  handleSettingClick: (setting: SettingName) => void;
};

const VolumeFullScreenTogglesNormal: React.FC<Props> = ({
  isFullscreen,
  isMuted,
  handleSettingClick,
}) => {
  const t = useText();
  return (
    <Wrapper>
      <Row>
        <Button.Secondary
          onClick={() => handleSettingClick("volume")}
          circular
          override={{
            width: "48px",
            height: "48px",
          }}
        >
          {isMuted ? (
            <Icon.VolumeOff size="22px" />
          ) : (
            <Icon.Volume size="22px" />
          )}
        </Button.Secondary>
        <Space w={2} />
        <Typo.Label color="inherit">{t("settings_volume")}</Typo.Label>
      </Row>
      <Space w={6} />
      <Row>
        <Button.Secondary
          onClick={() => handleSettingClick("fullscreen")}
          circular
          override={{
            width: "48px",
            height: "48px",
          }}
        >
          <Icon.Fullscreen size="16px" />
        </Button.Secondary>
        <Space w={2} />
        <Typo.Label color="inherit">
          {isFullscreen
            ? t("settings_exit_full_screen")
            : t("settings_full_screen")}
        </Typo.Label>
      </Row>
    </Wrapper>
  );
};

export default VolumeFullScreenTogglesNormal;
