import { VISITOR_PROFILE_NAMES_MAX_LENGTH } from "../../../common/constants/configs.constant";

const randomArrayElement = <T = unknown>(array: T[]) => {
  return array[Math.floor(Math.random() * array.length)];
};

const roles = [
  "Adventurer",
  "Drifter",
  "Excursionist",
  "Explorer",
  "Globetrotter",
  "Hitchhiker",
  "Journeyer",
  "Nomad",
  "Observer",
  "Outrider",
  "Pilgrim",
  "Pioneer",
  "Pirate",
  "Privateer",
  "Ranger",
  "Rider",
  "Roamer",
  "Runner",
  "Scout",
  "Sightseer",
  "Stranger",
  "Stray",
  "Stroller",
  "Tourer",
  "Vacationer",
  "Vanguard",
  "Venturer",
  "Visitor",
  "Voyager",
  "Wanderer",
  "Wayfarer",
];

const modes = [
  "Agile",
  "Alate",
  "Apt",
  "Astute",
  "Bold",
  "Brave",
  "Bright",
  "Brisk",
  "Careful",
  "Cheery",
  "Crafty",
  "Cunning",
  "Daring",
  "Dashing",
  "Fiery",
  "Hardy",
  "Intrepid",
  "Jolly",
  "Keen",
  "Lively",
  "Natural",
  "Nimble",
  "Prudent",
  "Ready",
  "Savvy",
  "Sharp",
  "Sly",
  "Steady",
  "Sturdy",
  "Swift",
  "Valiant",
  "Winged",
  "Wise",
  "Zealous",
  "Cyber",
  "Rocky",
];

const materials = [
  "Azure",
  "Ivory",
  "Pearl",
  "Amber",
  "Bronze",
  "Ruby",
  "Scarlet",
  "Coral",
  "Arctic",
  "Chrome",
  "Emerald",
  "Jade",
  "Iron",
  "Copper",
  "Silver",
  "Tin",
  "Platinum",
  "Gold",
  "Mercury",
  "Cobalt",
  "Celeste",
  "Dolomite",
  "Metal",
  "Steel",
  "Onyx",
  "Obsidian",
  "Opal",
  "Topaz",
  "Diamond",
  "Zircon",
  "Stone",
  "Marble",
];

const elements = [
  "Moon",
  "Sun",
  "Water",
  "Fire",
  "Air",
  "Wind",
  "Star",
  "Space",
  "Earth",
];

const getRandomUserName = () => {
  let start = "";
  let middle = "";
  const end = randomArrayElement(roles);

  const longName = Math.random() < 0.1;

  if (longName) {
    start = randomArrayElement(modes) + " ";
    middle =
      randomArrayElement([
        randomArrayElement(materials),
        randomArrayElement(elements),
      ]) + " ";
  } else {
    start =
      randomArrayElement([
        randomArrayElement(modes),
        randomArrayElement(modes),
        randomArrayElement(modes),
        randomArrayElement(modes),
        randomArrayElement(materials),
        randomArrayElement(elements),
      ]) + " ";
  }

  return start + middle + end;
};

export const getSafeRandomUserName = () => {
  let tries = 100;
  let valid = false;
  let name = "";
  while (!valid && tries > 0) {
    name = getRandomUserName();
    if (name.length < VISITOR_PROFILE_NAMES_MAX_LENGTH) valid = true;
    tries--;
  }
  if (!valid) {
    name = name.substring(0, VISITOR_PROFILE_NAMES_MAX_LENGTH - 1);
  }

  return name;
};
