import {
  FromGameMessage,
  FromGameMessagesSchema,
} from "../../app/gameConnection/messages/fromGameMessages";
import { sendGameMessage } from "../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { SliceCreator } from "../../app/store";
import { logWarn } from "../../common/util/logger";

const sliceName = "quests";

export type QuestsState = {
  quests: FromGameMessagesSchema["QuestsInfo"]["quests"];
  getQuestsInfo: () => void;
  currentQuestProgress:
    | FromGameMessagesSchema["QuestProgress"]["quest"]
    | undefined;
  handleQuestMessage: (message: FromGameMessage) => void;
};

type State = {
  quest: QuestsState;
};

export const createQuestsSlice: SliceCreator<State> = (set) => ({
  quest: {
    quests: [],
    currentQuestProgress: undefined,
    getQuestsInfo: () =>
      set(
        () => {
          sendGameMessage({
            type: "RequestQuestsInfo",
          });
        },
        false,
        sliceName + "/getQuestsInfo"
      ),

    handleQuestMessage: (message) =>
      set(
        (state) => {
          let questIndex;
          switch (message.type) {
            case "QuestsInfo":
              state.quest.quests = message.quests;
              break;
            case "QuestProgress":
              questIndex = state.quest.quests.findIndex(
                (quest) => quest.slug === message.quest.slug
              );
              if (
                questIndex !== undefined &&
                questIndex !== -1 &&
                message.quest
              ) {
                state.quest.quests[questIndex] = message.quest;
                if (message.hasProgressUI) {
                  state.quest.currentQuestProgress = message.quest;
                }
              }
              break;
            default:
              logWarn("GENERIC", "Unkown QuestMessage", message);
              break;
          }
        },
        false,
        sliceName + "/handleQuestMessage"
      ),
  },
});
