import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { iconMenuTestIds } from "../../../common/constants/testIds.constant";
import useOnClickOutside from "../../../common/hooks/useOnClickOutside";
import DynamicButton from "../DynamicButton";
import DynamicButtonMenuDrawer from "./DynamicButtonMenuDrawer";

const Wrapper = styled.div<{ $isFocused: boolean }>`
  position: relative;
  border-radius: 24px;
  pointer-events: auto;
  transition: ease-in background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
`;

export type DynamicButtonMenuItem = {
  component: React.ReactNode;
  componentFilled?: React.ReactNode;
  value: string;
  onClick?: () => void;
  tooltip?: string;
};

export type DynamicButtonMenuProps = {
  focused?: boolean;
  items?: DynamicButtonMenuItem[];
  anchor: React.ReactNode;
  closeOnSelect?: boolean;
  alwaysOpen?: boolean;
  direction?: "up" | "down" | "left" | "right";
  onToggle?: (isOpen: boolean) => void;
};

const IconMenu: React.FC<DynamicButtonMenuProps> = ({
  items,
  focused = true,
  closeOnSelect,
  anchor,
  alwaysOpen = false,
  direction = "up",
  onToggle,
}) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(alwaysOpen);

  const onClickOutside = useCallback(() => {
    if (!alwaysOpen) setIsOpen(false);
  }, [alwaysOpen]);
  useOnClickOutside<HTMLDivElement>(rootRef, onClickOutside);

  const onMenuClick: React.MouseEventHandler = () => {
    if (!alwaysOpen) setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    onToggle?.(isOpen);
  }, [isOpen, onToggle]);

  return (
    <Wrapper
      $isFocused={focused}
      ref={rootRef}
      data-testid={iconMenuTestIds.root}
    >
      <DynamicButtonMenuDrawer
        focused={focused && isOpen}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        items={items}
        closeOnSelect={closeOnSelect}
        direction={direction}
      />
      <DynamicButton
        testId={iconMenuTestIds.anchor}
        onClick={onMenuClick}
        focused={focused}
        style={{
          opacity: isOpen ? 0 : 1,
          pointerEvents: isOpen ? "none" : "all",
        }}
      >
        {anchor}
      </DynamicButton>
    </Wrapper>
  );
};

export default IconMenu;
