import { SvgProps } from "../../types/svg";

const CinematicFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.0938 3.75V5.3125C16.0938 5.43583 15.9883 5.53571 15.8594 5.53571H14.4531C14.3237 5.53571 14.2188 5.43577 14.2188 5.3125V3.75H5.78125V5.3125C5.78125 5.43583 5.67578 5.53571 5.54688 5.53571H4.14062C4.01113 5.53571 3.90625 5.43583 3.90625 5.3125V3.75C3.12959 3.75 2.5 4.34961 2.5 5.08929V14.9107C2.5 15.6501 3.12959 16.25 3.90625 16.25V14.6875C3.90625 14.5647 4.01113 14.4643 4.14062 14.4643H5.54688C5.67631 14.4643 5.78125 14.5642 5.78125 14.6875V16.25H14.2188V14.6875C14.2188 14.5642 14.3237 14.4643 14.4531 14.4643H15.8594C15.9888 14.4643 16.0938 14.5642 16.0938 14.6875V16.25C16.8704 16.25 17.5 15.6504 17.5 14.9107V5.08929C17.5 4.34961 16.8701 3.75 16.0938 3.75ZM5.78125 12.4554C5.78125 12.5781 5.67578 12.6786 5.54688 12.6786H4.14062C4.01119 12.6786 3.90625 12.5786 3.90625 12.4554V11.1161C3.90625 10.9933 4.01113 10.8929 4.14062 10.8929H5.54688C5.67578 10.8929 5.78125 10.9933 5.78125 11.1161V12.4554ZM5.78125 8.88393C5.78125 9.0067 5.67578 9.10714 5.54688 9.10714H4.14062C4.01113 9.10714 3.90625 9.0067 3.90625 8.88393V7.54464C3.90625 7.42188 4.01113 7.32143 4.14062 7.32143H5.54688C5.67578 7.32143 5.78125 7.42188 5.78125 7.54464V8.88393ZM16.0938 12.4554C16.0938 12.5786 15.9888 12.6786 15.8594 12.6786H14.4531C14.3237 12.6786 14.2188 12.5786 14.2188 12.4554V11.1161C14.2188 10.9933 14.3242 10.8929 14.4531 10.8929H15.8594C15.9888 10.8929 16.0938 10.9928 16.0938 11.1161V12.4554ZM16.0938 8.88393C16.0938 9.0067 15.9883 9.10714 15.8594 9.10714H14.4531C14.3237 9.10714 14.2188 9.0072 14.2188 8.88393V7.54464C14.2188 7.42188 14.3242 7.32143 14.4531 7.32143H15.8594C15.9888 7.32143 16.0938 7.42137 16.0938 7.54464V8.88393Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default CinematicFilledIcon;
