import { isDesktop, isFirefox } from "../../../common/constants/flags.constant";

export const getDefaultLocalMicrophone = (params: {
  microphonePermission: boolean;
  audioInputDevices: MediaDeviceInfo[];
}) => {
  const { microphonePermission, audioInputDevices } = params;
  if (microphonePermission) {
    const devices = audioInputDevices;
    /*
     * in case of mobile we cannot determine default mic based on deviceId which is uuid.
     * We also need to check if deviceId exists  since some first calls to get devices
     * returns generic 'audioinput' without deviceId  as  index 0 device
     */
    if (devices.length && !isDesktop && devices[0].deviceId) {
      return devices[0];
    }
    if (devices.length === 0) {
      return null;
    }

    return devices[0].deviceId ? devices[0] : null;
  }
  return null;
};

export const getDefaultLocalSpeaker = (params: {
  audioOutputDevices: MediaDeviceInfo[];
}) => {
  const { audioOutputDevices } = params;
  if (isFirefox) {
    return null;
  }
  const devices = audioOutputDevices;
  for (const element of devices) {
    const device = element;

    if (device.deviceId === "default") {
      return device;
    }
  }
  if (devices.length > 0 && devices[0].deviceId) {
    return devices[0];
  }
  return null;
};

export const getDefaultLocalCamera = (params: {
  cameraPermission: boolean;
  videoInputDevices: MediaDeviceInfo[];
}) => {
  const { cameraPermission, videoInputDevices } = params;
  if (!cameraPermission) {
    return null;
  }
  const devices = videoInputDevices;
  if (!isDesktop && devices.length) {
    return devices[0];
  }

  if (devices.length > 1) {
    /*
     * For sake of using external cameras as default we need to exclude FaceTime from default cameras pool ,
     * Safari integration is picking FaceTime cameras (always labelled FaceTime) as default
     */
    const devicesWithoutFaceTimeCameras = devices.filter(
      (device) => !device.label.includes("FaceTime")
    );
    return devicesWithoutFaceTimeCameras[0];
  }
  return devices[0] || null;
};

export function filterEmptyDevices(devices: MediaDeviceInfo[]) {
  return devices.filter((device) => device.deviceId !== "");
}
