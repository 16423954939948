import { FromGameMessagesSchema } from "../../app/gameConnection/messages/fromGameMessages";
import { ToGameMessagesSchema } from "../../app/gameConnection/messages/toGameMessages";

export const fromGameWhitelist: (keyof typeof FromGameMessagesSchema)[] = [
  "InfoCard",
  // "ChatArea",
  "OpenBusinessCardEditor",
  "GameIsReady",
  "PhotonPlayerConnected",
  "PhotonPlayerDisconnected",
  "RoomTeleportStart",
  "RoomTeleportEnd",
  "Poll",
  "DisplayMap",
  "EnterRegion",
  "ExitRegion",
  "ActionPanel",
  "PauseMode",
  // "TimeTravel",
  "SetPointerScheme",
  "LoadingLevelStart",
  "LoadingLevelEnd",
  "TakeScreenshot",
  "EndSession",
  // "TeleportTo",
  "OnChatMessageDeleted",
  // "HighlightFeature",
  "ExternalAssetLoadStatus",
  "MouseEnterClickableSpot",
  "MouseExitClickableSpot",
  "SmartChatAction",
  "SmartChatEngineReply",
  "SmartChatSubscriptionUpdate",
  "ShareMedia",
  "CustomMessage",
  "ProductSelected",
  "ItemAdded",
];

export const toGameWhitelist: (keyof typeof ToGameMessagesSchema)[] = [
  "DeleteMessage",
  "SendEmoji",
  "TeleportPlayer",
  "ScreenSize",
  "DidFakeTouch",
  // "ClientVideoIsPlaying",
  "EditingBusinessCard",
  "UIElementCoords",
  // "PauseStream",
  "PhotoCaptureEvent",
  // "ResumeStream",
  // "ReadyPlayerMeUri",
  "LoadExternalAsset",
  // "CurrentDate",
  "WebRtcStreamingStats",
  "SmartChatUserPrompt",
  "SmartChatUserAction",
  // "AnalyticsMetadata",
  "CustomMessage",
  // "ClientInfo",
  "PollResultSubmitted",
];

export const toParentLogBlacklist: string[] = [];
