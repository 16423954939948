import { useCallback, useRef } from "react";
import { ActorRefFrom } from "xstate";
import { useEnvironmentContext } from "../../../app/EnvironmentDataProvider";
import { sendGameMessage } from "../../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { useStore } from "../../../app/store";
import { ScreenShareProviderInterface } from "../lib/screenShareProviderInterface";
import vonageVideoConferenceStateMachine from "../stateMachines/vonageVideoConferenceState.machine";
import {
  EnvironmentState,
  getEnvironmentState,
  updateEnvironmentState,
} from "../utils/util.core";

type ConferenceControls = (params: {
  screenShareProvider: ScreenShareProviderInterface;
  sendEvent: ActorRefFrom<typeof vonageVideoConferenceStateMachine>["send"];
}) => {
  toggleLocalAudio: () => Promise<void>;
  startVideo: () => Promise<void>;
  stopLocalAudio: () => Promise<void>;
  startLocalAudio: () => Promise<void>;
  stopVideo: () => Promise<void>;
  toggleVideo: () => Promise<void>;
  stopScreenShare: () => Promise<void>;
  startScreenShare: () => Promise<void>;
  toggleScreenShare: () => Promise<void>;
  selectMicrophone: (device: MediaDeviceInfo) => void;
  selectSpeakers: (device: MediaDeviceInfo) => void;
  selectCamera: (device: MediaDeviceInfo) => void;
  setScreenSharingStatus: (
    isSharing: boolean
  ) => Promise<EnvironmentState | null>;
  notifyGameAboutScreenSharing: (isSharing: boolean) => void;
  startRemoteParticipantVideo: (participantId: string) => void;
  stopRemoteParticipantVideo: (participantId: string) => void;
};

const useConferenceControlsHook: ConferenceControls = ({
  screenShareProvider,
  sendEvent,
}) => {
  const { environmentId } = useEnvironmentContext();
  const visitorToken = useStore((s) => s.session.visitorToken);
  const videoConferenceStore = useStore((s) => s.videoConference);
  const videoConferenceStoreRef = useRef(videoConferenceStore);
  const getPlayerKeyByUserId = useStore(
    (s) => s.gameConnection.getPlayerKeyFromUserId
  );
  const userMedia = useStore((s) => s.userMedia);
  const userMediaRef = useRef(userMedia);
  const addInactivityException = useStore(
    (s) => s.session.addInactivityException
  );
  const removeInactivityException = useStore(
    (s) => s.session.removeInactivityException
  );
  userMediaRef.current = userMedia;

  const selectMicrophone = useCallback(
    (device: MediaDeviceInfo) => {
      sendEvent({ type: "lugia.selectMicrophone", data: device });
    },
    [sendEvent]
  );

  const selectCamera = useCallback(
    (device: MediaDeviceInfo) => {
      sendEvent({ type: "lugia.selectCamera", data: device });
    },
    [sendEvent]
  );

  const selectSpeakers = useCallback(
    (device: MediaDeviceInfo) => {
      sendEvent({ type: "lugia.selectSpeaker", data: device });
    },
    [sendEvent]
  );

  const toggleLocalAudio = useCallback(async () => {
    if (userMediaRef.current.micMuted) {
      sendEvent({ type: "lugia.startMicrophone" });
      userMedia.setMicMuted(false);
    } else {
      userMedia.setMicMuted(true);
      sendEvent({ type: "lugia.stopMicrophone" });
    }
  }, [sendEvent, userMedia]);

  const startRemoteParticipantVideo = useCallback(
    (participantId: string) => {
      sendEvent({
        type: "lugia.startParticipantVideo",
        data: { participantId },
      });
    },
    [sendEvent]
  );

  const stopRemoteParticipantVideo = useCallback(
    (participantId: string) => {
      sendEvent({
        type: "lugia.stopParticipantVideo",
        data: { participantId },
      });
    },
    [sendEvent]
  );

  const startVideo = useCallback(async () => {
    sendEvent({ type: "lugia.startCamera" });
  }, [sendEvent]);
  const stopVideo = useCallback(async () => {
    sendEvent({ type: "lugia.stopCamera" });
  }, [sendEvent]);
  const stopLocalAudio = useCallback(async () => {
    sendEvent({ type: "lugia.stopMicrophone" });
  }, [sendEvent]);
  const startLocalAudio = useCallback(async () => {
    sendEvent({ type: "lugia.startMicrophone" });
  }, [sendEvent]);

  const toggleVideo = useCallback(async () => {
    if (userMediaRef.current.webcamMuted) {
      sendEvent({ type: "lugia.startCamera" });
      userMedia.setWebcamMuted(false);
    } else {
      sendEvent({ type: "lugia.stopCamera" });
      userMedia.setWebcamMuted(true);
    }
  }, [userMedia, sendEvent]);

  const notifyGameAboutScreenSharing = useCallback(
    (isSharing: boolean) => {
      const self = videoConferenceStoreRef.current.self();
      if (!self || !visitorToken) return;
      sendGameMessage({
        type: "ScreenSharing",
        participantId: self.userId,
        isSharing: isSharing,
        broadcast: true,
      });
      const playerKey = getPlayerKeyByUserId(self.userId);
      sendGameMessage({
        type: "VoiceChatUserStateChanged",
        userId: playerKey as string,
        isSpeaking: self.isSpeaking,
        isMuted: !self.isAudioOn,
        isVideoSharing: isSharing,
      });
    },
    [visitorToken, getPlayerKeyByUserId, videoConferenceStoreRef]
  );

  const setScreenSharingStatus = useCallback(
    async (isSharing: boolean) => {
      const self = videoConferenceStoreRef.current.self();
      if (!self || !visitorToken) return null;
      const response = await updateEnvironmentState(
        environmentId,
        visitorToken,
        isSharing,
        self.userId
      );
      if (response) {
        notifyGameAboutScreenSharing(isSharing);
      }
      return response;
    },
    [visitorToken, notifyGameAboutScreenSharing, environmentId]
  );

  const stopScreenShare = useCallback(async () => {
    await setScreenSharingStatus(false);
    await screenShareProvider.stopScreenShare();
  }, [screenShareProvider, setScreenSharingStatus]);

  const startScreenShare = useCallback(async () => {
    // Note: I don't like the implementation of this function. It's too long and does too much.
    // I would refactor this function to be more readable and maintainable.
    await screenShareProvider.cleanUpScreensharing();
    if (!environmentId || !visitorToken) return;
    const stream = await screenShareProvider.startScreenShare({
      onEnded: (shouldUpdatePresenter) => {
        videoConferenceStoreRef.current.setActiveScreenShare(null);
        videoConferenceStoreRef.current.setScreenSharer(null);
        removeInactivityException("screenShare");
        shouldUpdatePresenter && setScreenSharingStatus(false);
      },
    });
    // if start screenshare returns null, we have to clean up
    if (!stream) {
      await screenShareProvider.stopScreenShare(false);
      const freshEnvironmentState = await getEnvironmentState(
        environmentId,
        visitorToken
      );
      freshEnvironmentState &&
        videoConferenceStoreRef.current.setScreenSharing(
          freshEnvironmentState?.presenterId
        );
      return;
    }

    const response = await setScreenSharingStatus(true);
    if (!response) {
      await screenShareProvider.stopScreenShare(false);
      const freshEnvironmentState = await getEnvironmentState(
        environmentId,
        visitorToken
      );
      freshEnvironmentState &&
        videoConferenceStoreRef.current.setScreenSharing(
          freshEnvironmentState?.presenterId
        );
    } else {
      addInactivityException("screenShare");
      videoConferenceStoreRef.current.setScreenSharing(response?.presenterId);
    }
  }, [
    environmentId,
    visitorToken,
    screenShareProvider,
    setScreenSharingStatus,
    addInactivityException,
    removeInactivityException,
  ]);

  const toggleScreenShare = useCallback(async () => {
    if (videoConferenceStoreRef.current.activeScreenShare) {
      await stopScreenShare();
    } else {
      await startScreenShare();
    }
  }, [startScreenShare, stopScreenShare]);

  return {
    toggleLocalAudio,
    selectMicrophone,
    selectSpeakers,
    selectCamera,
    startVideo,
    stopLocalAudio,
    startLocalAudio,
    stopVideo,
    toggleVideo,
    stopScreenShare,
    startScreenShare,
    toggleScreenShare,
    setScreenSharingStatus,
    notifyGameAboutScreenSharing,
    startRemoteParticipantVideo,
    stopRemoteParticipantVideo,
  };
};

export default useConferenceControlsHook;
