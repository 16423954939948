import { ReactNode } from "react";
import { USE_ANALYTICS_IN_DEVELOPMENT } from "../common/constants/configs.constant";
import LanguageProvider from "../domains/language/providers/Language.provider";
import DefaultTheme from "./DefaultTheme";
import EnvironmentDataProvider from "./EnvironmentDataProvider";
import EnvironmentMetadata from "./EnvironmentMetadata";
import EnvironmentTheme from "./EnvironmentTheme";
import ProfileWrapper from "./ProfileWrapper";
import EnvironmentTracking from "./monitoring/tracking/EnvironmentTracking";

type Props = {
  /** What to render instead if the environment is not found. */
  loadingPage: React.ComponentType;
  notFoundPage: React.ComponentType;
  errorPage: React.ComponentType;
  children?: ReactNode;
};

/** This component mounts all the necessary environment wrappers to enable the core. */
const EnvironmentProvidersWrapper: React.FC<Props> = ({
  loadingPage: LoadingPage,
  notFoundPage: NotFoundPage,
  errorPage: ErrorPage,
  children,
}) => {
  const hasAnalytics =
    import.meta.env.MODE !== "development" || USE_ANALYTICS_IN_DEVELOPMENT;

  return (
    <DefaultTheme>
      <EnvironmentDataProvider
        loadingPage={LoadingPage}
        notFoundPage={NotFoundPage}
        errorPage={ErrorPage}
      >
        <EnvironmentTheme>
          <LanguageProvider>
            <EnvironmentMetadata>
              <EnvironmentTracking sentry logrocket analytics={hasAnalytics}>
                <ProfileWrapper>{children}</ProfileWrapper>
              </EnvironmentTracking>
            </EnvironmentMetadata>
          </LanguageProvider>
        </EnvironmentTheme>
      </EnvironmentDataProvider>
    </DefaultTheme>
  );
};

export default EnvironmentProvidersWrapper;
