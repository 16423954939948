import React, { useEffect } from "react";
import { useStore } from "../../app/store";
import { useNetworkConditionsWarning } from "./hooks/statsHooks";

const StatsLogicPersistent: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.stats.visible);
  const closePanel = useStore((s) => s.layout.closePanel);
  const openPanel = useStore((s) => s.layout.openPanel);
  useNetworkConditionsWarning();

  // Open the panel through keyboard shortcut ALP+P
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "KeyP" && event.altKey && !event.ctrlKey) {
        if (visible) {
          closePanel("stats");
        } else {
          openPanel("stats");
        }
      }
    };
    window.addEventListener("keydown", listener);
    return () => window.removeEventListener("keydown", listener);
  }, [visible, closePanel, openPanel]);

  return null;
};

export default StatsLogicPersistent;
