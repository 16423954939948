import { useCallback, useMemo, useRef } from "react";
import { t } from "i18next";
import { sendGameMessage } from "../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { useGetPopupBySlug } from "../../app/hooks/gyarados.hook";
import { useStore } from "../../app/store";
import { isIFrame } from "../../common/constants/flags.constant";
import PopupUi from "./Popup.ui";

type Props = {
  popupId?: string;
  // A custom link to replace CMS link when triggered by Lugia
  link?: string;
  // An additional message to display in the popup
  extraText?: string;
};

const PopupLogic: React.FC<Props> = ({ popupId, link, extraText }) => {
  const closePanel = useStore((s) => s.layout.closePanel);
  const clearPanelExtraOptions = useStore(
    (state) => state.layout.clearPanelExtraOptions
  );
  const data = useGetPopupBySlug(popupId);

  const timeout = useRef<ReturnType<typeof window.setTimeout> | null>(null);

  const handleDismiss = useCallback(() => {
    clearPanelExtraOptions("popup");
    closePanel("popup", { slug: popupId });
  }, [closePanel, popupId, clearPanelExtraOptions]);

  const handleTeleport = useCallback(() => {
    if (typeof data?.spawnPoint === "number") {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
      handleDismiss();
      sendGameMessage({
        type: "TeleportPlayer",
        spawnPoint: data.spawnPoint,
      });
    }
  }, [data?.spawnPoint, handleDismiss]);

  const buttonTextValue = useMemo(() => {
    if (!data) return "";
    if (data.buttonText) return data.buttonText;
    return data.exitExperience
      ? t("action_panel_leave")
      : t("action_panel_visit");
  }, [data]);

  const handleExternalLink = useCallback(() => {
    if (!data) return;
    const externalLink = link ?? data.externalUrl;
    if (!externalLink) return;
    handleDismiss();
    if (data.exitExperience) {
      const target = isIFrame ? window.top || window.parent : window;
      target.location.href = externalLink;
    } else {
      window.open(externalLink);
    }
  }, [data, handleDismiss, link]);

  return (
    <PopupUi
      onClose={handleDismiss}
      handleExternalLink={handleExternalLink}
      handleTeleport={handleTeleport}
      link={link}
      extraText={extraText}
      popup={{ ...data, buttonText: buttonTextValue }}
    />
  );
};

export default PopupLogic;
