import { AvatarResponseDto } from "@journee-live/gyarados/axios/esm";
import * as Form from "@radix-ui/react-form";
import { isMobile } from "../../common/constants/flags.constant";
import { useReflow, useWindowSize } from "../../common/hooks/ui";
import ProfileUiLandscape from "./Profile.ui.landscape";
import ProfileUiNormal from "./Profile.ui.normal";
import ProfileUiPortrait from "./Profile.ui.portrait";
import { PROFILE_MAX_HEIGHT } from "./lib/constants";
import { ProfileData } from "./lib/profileTypes";
import { ProfileFieldConfigurations } from "./util/profile";

export type Props = {
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  data: ProfileData;
  settings: ProfileFieldConfigurations;
  onChange: (field: keyof ProfileData, value: string | boolean) => void;
  avatarsOptions?: AvatarResponseDto[];
  isReadyPlayerMeEditorOpen: boolean;
  setIsReadyPlayerMeEditorOpen: (value: boolean) => void;
  setCustomAvatarUrl: (url: string) => void;
  hasConfirmed: boolean;
  hasReadyPlayerMe: boolean;
  setHasConfirmed: (value: boolean) => void;
  isMissingAvatar: boolean;
};

const ProfileUi: React.FC<Props> = (props) => {
  const { height } = useWindowSize();
  const isShortDesktopScreen = !isMobile && height < PROFILE_MAX_HEIGHT;

  const content = useReflow({
    portrait: <ProfileUiPortrait {...props} />,
    landscape: <ProfileUiLandscape {...props} />,
    normal: <ProfileUiNormal {...props} />,
    extra: [
      {
        condition: isShortDesktopScreen,
        component: <ProfileUiLandscape {...props} />,
      },
    ],
  });

  return <Form.Root onSubmit={props.onSubmit}>{content}</Form.Root>;
};

export default ProfileUi;
