import { css, styled } from "styled-components";

export const AbsoluteOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`;

export const CameraFrameBorders = styled.div<{ $small?: boolean }>`
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-style: solid;
  border-width: 32px;
  border-color: ${(p) => p.theme.colorBelow1};
  border-radius: ${(p) => (p.theme.radiusFull === "0px" ? "0px" : "50px")};
  opacity: 0.9;
  z-index: 1;
  pointer-events: none;

  bottom: ${(p) => (p.$small ? "-24px" : "-16px")};
  left: ${(p) => (p.$small ? "-24px" : "-16px")};
  width: ${(p) => `calc(100% + ${p.$small ? "48px" : "32px"})`};
  height: ${(p) => `calc(100% + ${p.$small ? "48px" : "32px"})`};
`;

export const RecordingCameraFrameBorders = styled.div`
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-style: solid;
  border-width: 24px;
  border-color: ${(p) => p.theme.colorDanger};
  border-radius: ${(p) => (p.theme.radiusFull === "0px" ? "0px" : "50px")};
  opacity: 0.9;
  z-index: 1;
  pointer-events: none;
  bottom: -16px;
  left: -16px;
  width: calc(100% + 32px);
  height: calc(100% + 32px);
  @keyframes example {
    0% {
      box-shadow: inset 0px 0px 0px 0px ${(p) => p.theme.colorDanger};
    }
    50% {
      box-shadow: inset 0px 0px 20px 0px ${(p) => p.theme.colorDanger};
    }
    100% {
      box-shadow: inset 0px 0px 0px 0px ${(p) => p.theme.colorDanger};
    }
  }
  animation: example 2s infinite;
`;

export const CaptureButtonWrapper = styled.div<{ $small?: boolean }>`
  position: absolute;
  bottom: ${(p) => (p.$small ? 32 : 64)}px;
  width: 100%;
  height: auto;
  padding: 30px 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReactionsWrapper = styled.div<{
  $isLandscape: boolean;
}>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;

  top: max(var(--safe-margin-top), 10px);
  bottom: max(var(--safe-margin-bottom), 10px);
  left: max(
    var(--safe-margin-left),
    ${(p) => (p.$isLandscape ? "64px" : "40px")}
  );
  pointer-events: all;
`;

export const TopLeftButton = styled.div<{ $small?: boolean }>`
  position: absolute;
  ${(p) => css`
    ${p.$small ? "top: 16px; left: 16px;" : "top: 32px; left: 32px;"}
  `}
`;

export const TopRightButton = styled.div<{ $small?: boolean }>`
  position: absolute;
  ${(p) => css`
    ${p.$small ? "top: 16px; right: 16px;" : "top: 32px; right: 32px;"}
  `}
`;

export const ErrorWrapper = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
`;

export const LogoWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 40px;
`;

export const TabsWrapper = styled.div<{ $small?: boolean }>`
  position: absolute;
  width: 100%;
  ${(p) => css`
    ${p.$small ? "top: max(var(--safe-margin-top), 16px);" : "top: 48px;"}
  `}

  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  z-index: 1;
`;

export const HintWrapper = styled.div<{
  $small?: boolean;
  $hideTabs?: boolean;
}>`
  position: absolute;
  display: flex;
  justify-content: center;
  top: ${(p) => (p.$small && !p.$hideTabs ? "52px" : "var(--safe-margin-top)")};
  width: 100%;
`;
