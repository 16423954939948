import { useTheme } from "styled-components";
import { CardTestIds } from "../../common/constants/testIds.constant";
import Column from "./Column";
import HeaderBadge from "./HeaderBadge";
import Icon from "./Icon";
import Image from "./Image";
import Row from "./Row";
import Space from "./Space";

type Props = {
  width: React.CSSProperties["width"];
  height: React.CSSProperties["height"];
  styleOverrides?: React.CSSProperties;
  src?: HTMLImageElement["src"];
  orientation?: Orientation;
  testId?: string;
};

type Orientation = "preview" | "landscape" | "portrait";

const CardImage: React.FC<Props> = ({
  width,
  height,
  styleOverrides,
  src,
  orientation,
  testId,
}) => {
  const theme = useTheme();
  if (src)
    return (
      <Image
        alt=""
        testId={testId ?? CardTestIds.cardImage.root}
        src={src}
        width={width}
        height={height}
        styleOverrides={styleOverrides}
        cover
      />
    );

  switch (orientation) {
    case "preview":
      return (
        <Row
          testId={CardTestIds.cardImage.preview}
          style={{
            backgroundColor: theme.colorAbove0,
            borderRadius: theme.radiusSmall,
          }}
          height="100%"
          width="100%"
          align="center"
          justify="center"
        >
          <Icon.CircleInfo size="16px" />
        </Row>
      );
    case "landscape":
      return (
        <Row
          height="100%"
          width="100%"
          align="center"
          justify="center"
          testId={testId ?? CardTestIds.cardImage.landscape}
        >
          <HeaderBadge>
            <Icon.SquareInfo inheritColor size="32px" />
          </HeaderBadge>
        </Row>
      );
    case "portrait":
      return (
        <Column
          height="100%"
          width="100%"
          align="center"
          justify="center"
          testId={testId ?? CardTestIds.cardImage.portrait}
        >
          <Space h={8} w={8} />
          <HeaderBadge>
            <Icon.SquareInfo inheritColor size="32px" />
          </HeaderBadge>
        </Column>
      );
    default:
      return (
        <Icon.CircleInfo
          data-testid={CardTestIds.cardImage.default}
          size="16px"
        />
      );
  }
};

export default CardImage;
