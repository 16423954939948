import { useMemo } from "react";
import { useParams } from "react-router-dom";
import DataExternalPageUi from "../../../domains/dataExternal/DataExternalPageUi";
import EyVersePrivacyNotice from "../../../domains/dataExternal/orgs/EyVersePrivacyNotice";
import NotFoundPageUi from "../../../domains/utilityPages/NotFoundPageUi";
import DefaultTheme from "../../DefaultTheme";

const DataExternalRoute: React.FC = () => {
  const { orgSlug } = useParams();
  const content = useMemo(() => {
    switch (orgSlug) {
      case "ey":
        return <EyVersePrivacyNotice />;
      default:
        return null;
    }
  }, [orgSlug]);

  return (
    <DefaultTheme>
      {!content ? <NotFoundPageUi /> : <DataExternalPageUi content={content} />}
    </DefaultTheme>
  );
};

export default DataExternalRoute;
