/**
 * Utility function to compute the position and size of the video so that
 * it fills the screen (like object-fit: cover).
 * @param width target width
 * @param height target height
 * @param vWidth video width
 * @param vHeight video height
 * @param fitStreamInViewPort if true, the video will be scaled to fit the viewport
 * @returns an object with the computed values
 */
export const computeObjectFit = (
  width: number,
  height: number,
  vWidth = 1280,
  vHeight = 720,
  fitInPortrait: boolean
) => {
  const xRatio = width / vWidth;
  const yRatio = height / vHeight;
  const scalingRatio = Math.max(xRatio, yRatio);
  const targetVideoW = fitInPortrait ? width : vWidth * scalingRatio;
  const targetVideoH = fitInPortrait ? height : vHeight * scalingRatio;
  const positionX = fitInPortrait ? 0 : (width - targetVideoW) / 2;
  const positionY = (height - targetVideoH) / 2;
  // Percentage of the width/height that is cropped one a single side
  // (other sides are assumed to be mirrored since it's centered)
  const croppedX = Math.abs(positionX / targetVideoW);
  const croppedY = Math.abs(positionY / targetVideoH);
  return {
    positionX,
    positionY,
    croppedX,
    croppedY,
    targetVideoW,
    targetVideoH,
  };
};
// TODO: convert ripples function to a wrapper
/**
 * Draw ripples on click
 * @param x position of the click relative to the clickRipples element
 * @param y
 */
export const clickRipples = (parent: HTMLElement, x: number, y: number) => {
  /**
   *  It's super important to not use the huge <canvas> overlay approach,
   * because drawing a (nearly) fullscreen canvas is super expensive.
   * Small canvases could be used, but I think SVG is even faster
   * as the DOM can directly draw the stroke.
   * This is very un-React, but I just want to keep this self-contained.
   * To do this the clean way in React would be way too annoying.
   */
  const img = document.createElement("img");
  img.src = "/assets/ripples_click.svg";
  img.style.position = "absolute";
  img.style.top = y - 22 + "px";
  img.style.left = x - 22 + "px";
  img.style.pointerEvents = "none";
  parent.appendChild(img);
  // The current SVG animation length is 400ms, remove 1 frame before.
  setTimeout(() => img.remove(), 780);
};
