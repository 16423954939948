import { ReactNode } from "react";
import FullScreenLoader from "../../componentsLibrary/molecules/FullScreenLoader";
import ErrorPageUi from "../../domains/utilityPages/ErrorPageUi";
import NotFoundPageUi from "../../domains/utilityPages/NotFoundPageUi";
import EnvironmentProvidersWrapper from "../EnvironmentProvidersWrapper";

type Props = {
  children: ReactNode;
};

/** A wrapper shared among all routes. */
const WithEnvironment: React.FC<Props> = ({ children }) => {
  return (
    <EnvironmentProvidersWrapper
      loadingPage={FullScreenLoader}
      notFoundPage={NotFoundPageUi}
      errorPage={ErrorPageUi}
    >
      {children}
    </EnvironmentProvidersWrapper>
  );
};

export default WithEnvironment;
