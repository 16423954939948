import React, { useEffect } from "react";
import styled from "styled-components";
import { useWindowSize } from "../../../common/hooks/ui.js";

const GLOW_LIFE = 400;

const Glow = styled.div<{
  width: number;
  scale: number;
  left: number;
  color: string;
  opacity: number;
}>`
  width: ${(p) => p.width}px;
  height: 90px;
  scale: ${(p) => p.scale};
  background-color: ${(p) => p.color};
  filter: blur(20px);
  mix-blend-mode: plus-lighter;
  border-radius: 50%;
  position: absolute;
  bottom: -60px;
  left: ${(p) => p.left}px;
  opacity: ${(p) => p.opacity};
  transition:
    scale 0.3s ease-out,
    opacity 0.3s;
`;

type GlowData = {
  hide: boolean;
  stage: number;
  opacity: number;
};

type Props = {
  position: number;
  color: string;
  data: GlowData;
  setData: (data: GlowData) => void;
};

const ReactionGlow: React.FC<Props> = ({ position, color, data, setData }) => {
  const { width } = useWindowSize();
  const offset = 250;
  const glowContainerWidth = width / 6;
  const glowWidth = width / 6 + offset;
  const scale = data.stage === 0 ? 1 : data.stage === 1 ? 1.3 : 1.5;

  useEffect(() => {
    let id: NodeJS.Timeout | null = null;
    if (data.hide && data.opacity > 0) {
      id = setTimeout(() => {
        const newOpacity = +(data.opacity - 0.2).toFixed(1);
        setData({
          ...data,
          opacity: newOpacity < 0 ? 0 : newOpacity,
        });
      }, 200);
    }

    if (!data.hide)
      setTimeout(() => {
        setData({
          ...data,
          hide: true,
          stage: 0,
        });
      }, GLOW_LIFE);

    return () => {
      id && clearTimeout(id);
    };
  }, [data, setData]);

  return (
    <Glow
      width={glowWidth}
      scale={scale}
      left={glowContainerWidth * position - offset / 2}
      color={color}
      opacity={data.opacity}
    />
  );
};

export default ReactionGlow;
