import merge from "lodash/merge";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { StateCreator } from "zustand/vanilla";
import {
  STORE_LOCAL_STORAGE_KEY,
  STORE_LOCAL_STORAGE_VERSION,
} from "../common/constants/configs.constant";
import { LayoutState, createLayoutSlice } from "../domains/_layout/layoutSlice";
import { UiState, createUiSlice } from "../domains/_layout/uiSlice";
import {
  SessionState,
  createSessionSlice,
} from "../domains/_login/sessionSlice";
import {
  UserFlowState,
  createUserFlowSlice,
} from "../domains/_login/userFlowSlice";
import {
  ActionElementsState,
  createActionElementsSlice,
} from "../domains/actionElementsPanel/ActionElements.slice";
import {
  LoadLevelControllerState,
  createLoadLevelControllerSlice,
} from "../domains/fullscreenVideo/loadLevelControllerSlice";
import {
  I18nState,
  createLanguageSlice,
} from "../domains/language/languageSlice";
import { MapState, createMapSlice } from "../domains/map/mapSlice";
import {
  MediaCaptureState,
  createMediaCaptureSlice,
} from "../domains/mediaCapture/MediaCapture.slice";
import {
  VisitorProfileState,
  createVisitorProfileSlice,
} from "../domains/profile/profileSlice";
import { QuestsState, createQuestsSlice } from "../domains/quest/questsSlice";
import { SocialState, createSocialSlice } from "../domains/social/socialSlice";
import {
  TextChatState,
  createTextChatSlice,
} from "../domains/social/subpages/chat/textChatSlice";
import {
  UserMediaState,
  createUserMediaSlice,
} from "../domains/videoConference/userMediaSlice";
import {
  VideoConferenceState,
  createVideoConferenceSlice,
} from "../domains/videoConference/videoConferenceSlice";
import {
  MintingState,
  createMintingSlice,
} from "../domains/web3Wallet/mintingSlice";
import {
  GameConnectionState,
  createGameConnectionSlice,
} from "./gameConnection/gameConnectionSlice";

export type CombinedState = {
  gameConnection: GameConnectionState;
  session: SessionState;
  userFlow: UserFlowState;
  profile: VisitorProfileState;
  i18n: I18nState;
  layout: LayoutState;
  textChat: TextChatState;
  social: SocialState;
  loadLevelController: LoadLevelControllerState;
  ui: UiState;
  map: MapState;
  userMedia: UserMediaState;
  minting: MintingState;
  videoConference: VideoConferenceState;
  mediaCapture: MediaCaptureState;
  quest: QuestsState;
  actionElements: ActionElementsState;
};

// This is currently the only way of properly type the state
export type SliceCreator<Slice> = StateCreator<
  CombinedState,
  [
    ["zustand/devtools", never],
    ["zustand/persist", unknown],
    ["zustand/immer", never],
  ],
  [],
  Slice
>;

// Due to typescript quirks, this has to all be in one giant object for inference
export const useStore = create<CombinedState>()(
  // Allows us to use Redux devtools in the browser
  devtools(
    // Persists the state to the localStorage in the browser
    persist(
      //// Logs each action to the console
      ////logger(
      // Wraps Immer around the set function
      immer((...args) => ({
        // Add the slices here
        ...createSessionSlice(...args),
        ...createUserFlowSlice(...args),
        ...createLanguageSlice(...args),
        ...createLayoutSlice(...args),
        ...createGameConnectionSlice(...args),
        ...createTextChatSlice(...args),
        ...createVisitorProfileSlice(...args),
        ...createSocialSlice(...args),
        ...createLoadLevelControllerSlice(...args),
        ...createUiSlice(...args),
        ...createMapSlice(...args),
        ...createUserMediaSlice(...args),
        ...createMintingSlice(...args),
        ...createVideoConferenceSlice(...args),
        ...createMediaCaptureSlice(...args),
        ...createQuestsSlice(...args),
        ...createActionElementsSlice(...args),
      })),
      ////   Logger options
      ////   {
      ////     enable: import.meta.env.MODE === "development",
      ////   }
      //// ),
      // Persist options
      {
        name: STORE_LOCAL_STORAGE_KEY,
        version: STORE_LOCAL_STORAGE_VERSION,
        // Since we have nested states, we need to deep-merge the objects
        merge: (persistedState, currentState) =>
          merge(currentState, persistedState),
        // Filter which slices to be persisted.
        partialize: (state) => ({
          session: {
            clientPersistedId: state.session.clientPersistedId,
            visitorToken: state.session.visitorToken,
            visitorTokenData: state.session.visitorTokenData,
          },
          profile: state.profile,
          userMedia: {
            micMuted: state.userMedia.micMuted,
            webcamMuted: state.userMedia.webcamMuted,
            selectedCamera: state.userMedia.selectedCamera,
            selectedMicrophone: state.userMedia.selectedMicrophone,
            selectedSpeakers: state.userMedia.selectedSpeakers,
          },
        }),
      }
    ),
    // Devtools options
    {
      name: STORE_LOCAL_STORAGE_KEY,
    }
  )
);
