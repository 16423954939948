import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Button from "../../../componentsLibrary/atoms/Button";
import Icon from "../../../componentsLibrary/atoms/Icon";
import Space from "../../../componentsLibrary/atoms/Space";
import { useText } from "../../language/language.hook";
import PresentationBarItem from "../component/PresentationBarItem";

const Wrapper = styled.div<{
  $isOpen: boolean;
  $nrOfChildren: number;
  $overflow: React.CSSProperties["overflow"];
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: ${(p) => p.theme.radiusFull};

  width: 0px;
  height: calc(${(p) => p.theme.elements.actionButton.size});
  pointer-events: none;
  opacity: 0;
  ${(p) =>
    p.$isOpen &&
    css`
      width: calc(
        ${p.$nrOfChildren} * ${p.theme.elements.actionButton.size} + 150px
      );
      pointer-events: all;
      opacity: 1;
      padding: 2px;
      background-color: ${(p) => p.theme.colorBelow1};
      backdrop-filter: blur(${p.theme.blurLight});
    `}

  overflow: ${(p) => p.$overflow};
  transform-origin: center;
  transition:
    background-color 0.3s,
    opacity 0.3s,
    width 0.3s,
    height 0.3s;
`;

type Props = {
  isOpen: boolean;
  micMuted: boolean;
  isVideoConferenceInitialized: boolean;
  toggleLocalAudio: () => void;
  webcamMuted: boolean;
  toggleVideo: () => void;
  activeScreenShare: boolean;
  toggleScreenShare: () => void;
  stopPresenting: () => void;
};

const PresenterBarUiNormal: React.FC<Props> = ({
  isOpen,
  micMuted,
  isVideoConferenceInitialized,
  toggleLocalAudio,
  webcamMuted,
  toggleVideo,
  activeScreenShare,
  toggleScreenShare,
  stopPresenting,
}) => {
  const t = useText();
  const [overflow, setOverflow] = useState("hidden");

  useEffect(() => {
    if (!isOpen) {
      setOverflow("hidden");
    } else {
      setTimeout(() => {
        setOverflow("visible");
      }, 300);
    }
  }, [isOpen]);

  return (
    <Wrapper $isOpen={isOpen} $nrOfChildren={3} $overflow={overflow}>
      <PresentationBarItem
        active={!micMuted}
        disabled={!isVideoConferenceInitialized}
        error={false}
        onIcon={<Icon.PurpleMicrophone inheritColor size="20px" />}
        offIcon={<Icon.MicrophoneOffThin inheritColor size="20px" />}
        onClick={toggleLocalAudio}
      />
      <PresentationBarItem
        active={!webcamMuted}
        disabled={!isVideoConferenceInitialized}
        error={false}
        onIcon={<Icon.PurpleVideo inheritColor size="20px" />}
        offIcon={<Icon.VideoOff inheritColor size="20px" />}
        onClick={toggleVideo}
      />
      <PresentationBarItem
        active={Boolean(activeScreenShare)}
        disabled={!isVideoConferenceInitialized}
        error={false}
        onIcon={<Icon.PurpleScreenshare size="20px" />}
        offIcon={<Icon.ScreenShare inheritColor size="20px" />}
        onClick={toggleScreenShare}
      />

      <Button.Primary
        onClick={stopPresenting}
        override={{
          excite: {
            height: "32px",
          },
          padding: "6px 16px",
          height: "32px",
        }}
      >
        {t("presenter_stop")}
      </Button.Primary>
      <Space w={1} />
    </Wrapper>
  );
};

export default PresenterBarUiNormal;
