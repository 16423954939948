import { SvgProps } from "../../types/svg";

const TireFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 8a.999.999 0 1 1-2 0 .999.999 0 1 1 2 0m.684 1.075 1.935.631a4 4 0 0 1-3.147 2.263l.025-2.06a1.85 1.85 0 0 0 1.187-.834M10 8c0-.481-.172-.925-.453-1.269l1.194-1.647a4 4 0 0 1 1.187 3.672l-1.931-.631C9.972 8.085 10 8.044 10 8M8.738 6.14a2 2 0 0 0-1.475 0L6.069 4.498A3.96 3.96 0 0 1 7.972 4c.728 0 1.387.181 1.96.497zm-2.285.591a2 2 0 0 0-.45 1.394l-1.931.631A4 4 0 0 1 4 7.972a3.98 3.98 0 0 1 1.26-2.888zM7.497 9.91l-.025 2.06a4 4 0 0 1-3.09-2.263l1.934-.631c.265.422.687.734 1.18.834M0 8a8 8 0 0 1 8-8 8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8m8 5c2.762 0 5-2.238 5-5s-2.238-5-5-5-5 2.238-5 5 2.238 5 5 5"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default TireFilledIcon;
