import { CSSProperties, useEffect, useRef } from "react";
import styled, { css, useTheme } from "styled-components";
import { zIndex } from "../../../app/style/theme";
import { isTouch } from "../../../common/constants/flags.constant";
import { LoginPageTestIds } from "../../../common/constants/testIds.constant";
import { useIsSmallScreen } from "../../../common/hooks/ui";
import { grayscaleInvert } from "../../../common/util/color";
import Hide from "../../../componentsLibrary/atoms/Hide";
import Transition from "../../../componentsLibrary/atoms/Transition";
import Typo from "../../../componentsLibrary/atoms/Typo";
import MouseFollower from "./mouseFollower";

type DivWithFollower = HTMLDivElement & { _follower: MouseFollower };

const Wrapper = styled.div<{
  $width: string;
  $height: string;
  $enablePointerEvents?: boolean;
}>`
  width: ${(p) => p.$width};
  height: ${(p) => p.$height};
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: ${zIndex.extras};
  cursor: ${(p) => (p.$enablePointerEvents ? "pointer" : "unset")};
  pointer-events: ${(p) => (p.$enablePointerEvents ? "all" : "none")};
  user-select: none;
`;
const Follower = styled.div<{ $scale: number }>`
  position: absolute;
  top: calc(50% - ${(p) => (p.$scale * 150) / 2}px);
  left: calc(50% - ${(p) => (p.$scale * 150) / 2}px);
  width: ${(p) => p.$scale * 150}px;
  height: ${(p) => p.$scale * 150}px;
  pointer-events: none;
`;
const Centering = styled.div<{
  $enablePointerEvents?: boolean;
  $pointer?: boolean;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(p) => (p.$pointer ? "pointer" : "unset")};
  pointer-events: ${(p) => (p.$enablePointerEvents ? "all" : "none")};
`;

const TextWrapper = styled.div<{
  $x: number;
  $y: number;
  $textAlignedBelow: boolean;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  left: ${(p) => (p.$textAlignedBelow ? "0" : p.$x)}px;
  white-space: nowrap;
  justify-content: ${(p) => (p.$textAlignedBelow ? "flex-end" : "flex-start")};
  flex-direction: ${(p) => (p.$textAlignedBelow ? "column" : "row")};
  text-align: ${(p) => (p.$textAlignedBelow ? "center" : "inherit")};
  align-items: ${(p) => (p.$textAlignedBelow ? "center" : "flex-start")};
  display: flex;
  ${(p) =>
    p.$textAlignedBelow
      ? css`
          bottom: 15px;
        `
      : css`
          top: ${p.$y}px;
        `};

  transition:
    top 0.3s ease-in-out,
    left 0.3s ease-in-out;
`;

const Shadow = styled.div<{ $color: string; $scale: number }>`
  position: absolute;
  width: ${(p) => p.$scale * 300}px;
  height: ${(p) => p.$scale * 300}px;
  border-radius: 100%;
  background-color: ${(p) => p.$color};
  filter: blur(80px);
  opacity: 0.25;
  z-index: -1;
  transform: translate(-50%, -50%);
`;

const Dot = styled.div<{ $color: string; $scale: number }>`
  width: ${(p) => p.$scale * 7}px;
  height: ${(p) => p.$scale * 7}px;
  border-radius: 100%;
  background-color: ${(p) => p.$color};
`;

export type Props = {
  label?: string;
  hasShadow?: boolean;
  isLoading?: boolean;
  shouldFollowMouse?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  width?: string;
  height?: string;
  inverted?: boolean;
  cursorScale?: number;
};

/**
 * 1. The StartButton is first shown at login:queue-and-connect step (see useQueueCheckStep).
 * 2. If clicked during login:queue-and-connect step, it will reveal a spinner-loader, and the login text (experience title and description) is permanently dismissed.
 * 3. At login:start-action step (see useStartActionCheckStep) the button has an updated text and follows the mouse. If clicked, it completes the main flow and starts the experience.
 * 4. During this login:start-action step, the game may send us some messages (UiAction "close"/"open") to temporarily hide the StartButton during his cinematic sequence.
 * @todo: The StartButton could be split into two components:
 *         • QueueButton - always visible, can load, but never follows the mouse
 *         • StartButton - can be visible, never loads and always follows the mouse
 */
const StartButton: React.FC<Props> = ({
  label,
  hasShadow,
  isLoading,
  shouldFollowMouse,
  onClick,
  width = "100%",
  height = "100%",
  cursorScale = 1,
  inverted,
}) => {
  const theme = useTheme();
  const sourceRef = useRef<HTMLDivElement>(null);
  const dotRef = useRef<DivWithFollower>(null);
  const moonRef = useRef<DivWithFollower>(null);
  const noteRef = useRef<DivWithFollower>(null);
  const isSmallScreen = useIsSmallScreen();
  const isFollowingMouse = shouldFollowMouse && !isTouch;

  const handleClick = (e?: React.MouseEvent<HTMLDivElement>) => {
    if (!isLoading) onClick?.(e);
  };

  const mainColor = inverted
    ? grayscaleInvert(theme.colorAbove5)
    : (theme.colorAbove5 as string);
  const shadowColor = grayscaleInvert(mainColor);

  useEffect(() => {
    const dotEl = dotRef?.current;
    const moonEl = moonRef?.current;
    const noteEl = noteRef?.current;

    MouseFollower.init({
      mouseEventSourceElement: sourceRef.current,
      followerElement: dotEl,
      reactivity: 0.3,
    });
    MouseFollower.init({
      mouseEventSourceElement: sourceRef.current,
      followerElement: moonEl,
      reactivity: 0.07,
    });
    MouseFollower.init({
      mouseEventSourceElement: sourceRef.current,
      followerElement: noteEl,
      reactivity: 0.1,
    });

    return () => {
      dotEl?._follower?.destroy();
      moonEl?._follower?.destroy();
      noteEl?._follower?.destroy();
    };
  }, []);

  // Only add mouse movement when the experience is ready.
  useEffect(() => {
    const dotEl = dotRef?.current;
    const moonEl = moonRef?.current;
    const noteEl = noteRef?.current;
    let delay: NodeJS.Timeout;

    if (isFollowingMouse) {
      // We need a delay to prevent awkward micro movements when the StartButton is told
      // to follow/unfollow in rapid succession. This happens in particular when, in the login flow,
      // the stream is ready (StartButton should follow) but a few milliseconds later the Game
      // tells that it's playing a cinematic (StartButton should not follow).
      delay = setTimeout(() => {
        dotEl?._follower?.follow();
        moonEl?._follower?.follow();
        noteEl?._follower?.follow();
      }, 700);
    }

    return () => {
      dotEl?._follower?.unfollow();
      moonEl?._follower?.unfollow();
      noteEl?._follower?.unfollow();
      clearTimeout(delay);
    };
  }, [isFollowingMouse]);

  const ringConfiguration = isSmallScreen
    ? {
        cutoff: 85,
        rotation: 90,
      }
    : undefined;

  return (
    <Wrapper
      ref={sourceRef}
      $width={width}
      $height={height}
      // The user can tap anywhere to start if the experience is ready.
      $enablePointerEvents={isFollowingMouse}
      onClick={handleClick}
    >
      <Follower ref={moonRef} $scale={cursorScale}>
        <Centering>
          <Hide hide={!hasShadow}>
            <Shadow $color={shadowColor} $scale={cursorScale} />
          </Hide>
        </Centering>
        <Centering>
          <Ring
            isSpinning={isLoading}
            color={mainColor}
            strokeWidth="3"
            size={isLoading ? "100px" : "230px"}
            {...ringConfiguration}
          />
        </Centering>
        <Centering
          // The user can only click on the Ring if the experience is not yet ready.
          $enablePointerEvents={!isFollowingMouse}
          $pointer={!isLoading}
          data-testid={LoginPageTestIds.mainBtn}
          onClick={handleClick}
        >
          <Hide hide={!isLoading}>
            <Ring
              isSpinning
              color={mainColor}
              strokeWidth="3"
              reverse
              size="60px"
            />
          </Hide>
        </Centering>
      </Follower>
      <Follower ref={dotRef} $scale={cursorScale}>
        <Centering>
          <Hide hide={isLoading}>
            <Dot $color={mainColor} $scale={cursorScale} />
          </Hide>
        </Centering>
      </Follower>
      <Follower ref={noteRef} $scale={cursorScale}>
        <TextWrapper
          $textAlignedBelow={isSmallScreen}
          $x={isLoading ? 130 : 95}
          $y={isLoading ? 70 : 85}
        >
          <Transition
            height="20px"
            watch={label}
            width="300px"
            justify={isSmallScreen ? "center" : "flex-start"}
          >
            <Typo.Note size={isSmallScreen ? "12px" : "14px"} color={mainColor}>
              {label}
            </Typo.Note>
          </Transition>
        </TextWrapper>
      </Follower>
    </Wrapper>
  );
};

export default StartButton;

const StyledSvg = styled.svg<{ $animation?: string; $rotation: number }>`
  animation-name: ${(p) => (p.$animation ? p.$animation : "unset")};
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  transform: ${(p) => `rotate(${p.$rotation}deg)`};

  transition:
    width 0.3s ease-in-out,
    height 0.3s ease-in-out;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
  @keyframes spin-back {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(-1980deg);
    }
  }
`;

type PropsRing = {
  color?: CSSProperties["color"];
  strokeWidth?: CSSProperties["strokeWidth"];
  size?: CSSProperties["width"];
  isSpinning?: boolean;
  reverse?: boolean;
  cutoff?: number;
  rotation?: number;
};

const DesktopRingCutOffPercentage = 67;
const DesktopRingRotationDegrees = 43;

export const Ring: React.FC<PropsRing> = ({
  color,
  strokeWidth,
  size,
  isSpinning,
  reverse,
  cutoff,
  rotation,
}) => {
  const animation = reverse ? "spin-back" : "spin";
  const defaultCutoff = DesktopRingCutOffPercentage;
  const defaultRotation = DesktopRingRotationDegrees;

  return (
    <StyledSvg
      $animation={isSpinning ? animation : undefined}
      $rotation={rotation ? rotation : defaultRotation}
      width={size}
      height={size}
      viewBox="0 0 153 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="76"
        cy="77"
        r="63.25"
        stroke="url(#paint0_linear_155_412)"
        strokeWidth={strokeWidth}
      />
      <defs>
        <linearGradient
          id="paint0_linear_155_412"
          x1={"0%"}
          x2={`${
            cutoff && cutoff <= 100 && cutoff > 0 ? cutoff : defaultCutoff
          }%`}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
      </defs>
    </StyledSvg>
  );
};
