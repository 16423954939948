import { sendGameMessage } from "../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { useAvailableEmojis } from "../../app/hooks/gyarados.hook";
import { EmojiName } from "../../componentsLibrary/atoms/Emoji";
import Icon from "../../componentsLibrary/atoms/Icon";
import { DynamicButtonMenuItem } from "../../componentsLibrary/molecules/dynamicButtonMenu/DynamicButtonMenu";

const useDynamicButtonMenuEmojis = () => {
  const enabledEmojis = useAvailableEmojis();
  const availableEmojiBarItems: DynamicButtonMenuItem[] = [];
  enabledEmojis.forEach((emoji) => {
    const reaction = emojiOptions[emoji];
    const isAvailable = Boolean(reaction?.value);
    if (isAvailable) {
      availableEmojiBarItems.push(reaction);
    }
  });
  return availableEmojiBarItems;
};

export const emojiOptions: {
  [key in EmojiName]: DynamicButtonMenuItem;
} = {
  wave: {
    value: "Greet",
    tooltip: "Greet",
    component: <Icon.HandWave inheritColor size="20px" />,
    componentFilled: <Icon.HandWaveFilled inheritColor size="20px" />,
    onClick: () => {
      sendGameMessage({
        type: "SendEmoji",
        emoji: "wave",
      });
      window.analytics?.track("emoji", {
        type: "emoji",
        name: "wave",
      });
    },
  },
  joy: {
    value: "Wave",
    tooltip: "Do the wave",
    component: <Icon.Water inheritColor size="20px" />,
    componentFilled: <Icon.WaterFilled inheritColor size="20px" />,
    onClick: () => {
      sendGameMessage({
        type: "SendEmoji",
        emoji: "joy",
      });
      window.analytics?.track("emoji", {
        type: "emoji",
        name: "joy",
      });
    },
  },
  tada: {
    value: "Joy",
    tooltip: "Jump for joy",
    component: <Icon.PartyPopper inheritColor size="18px" />,
    componentFilled: <Icon.PartyPopperFilled inheritColor size="18px" />,
    onClick: () => {
      sendGameMessage({
        type: "SendEmoji",
        emoji: "tada",
      });
      window.analytics?.track("emoji", {
        type: "emoji",
        name: "tada",
      });
    },
  },
  rocket: {
    value: "Yeah",
    tooltip: "Let's go!",
    component: <Icon.Rocket inheritColor size="18px" shiftY="1px" />,
    componentFilled: (
      <Icon.RocketFilled inheritColor size="18px" shiftY="1px" />
    ),
    onClick: () => {
      sendGameMessage({
        type: "SendEmoji",
        emoji: "rocket",
      });
      window.analytics?.track("emoji", {
        type: "emoji",
        name: "rocket",
      });
    },
  },
  clinking_glasses: {
    value: "Fancy",
    tooltip: "Fancy-dancy",
    component: <Icon.ChampagneGlass inheritColor size="18px" shiftY="1px" />,
    componentFilled: (
      <Icon.ChampagneGlassFilled inheritColor size="18px" shiftY="1px" />
    ),
    onClick: () => {
      sendGameMessage({
        type: "SendEmoji",
        emoji: "clinking_glasses",
      });
      window.analytics?.track("emoji", {
        type: "emoji",
        name: "clinking_glasses",
      });
    },
  },
  heart: {
    component: <Icon.Heart inheritColor size="18px" />,
    componentFilled: <Icon.HeartFilled inheritColor size="18px" />,
    tooltip: "Heart",
    value: "Heart",
    onClick: () => {
      sendGameMessage({
        type: "SendEmoji",
        emoji: "heart",
      });
      window.analytics?.track("emoji", {
        type: "emoji",
        name: "heart",
      });
    },
  },
  // TODO: add the rest of the emojis
  thumbs_up: {
    component: <div />,
    value: "",
  },
  clap: {
    component: <div />,
    value: "",
  },
  grinning: {
    component: <div />,
    value: "",
  },
  thinking_face: {
    component: <div />,
    value: "",
  },
  upside_down_face: {
    component: <div />,
    value: "",
  },
  "star-struck": {
    component: <div />,
    value: "",
  },
  trophy: {
    component: <div />,
    value: "",
  },
  "man-with-bunny-ears-partying": {
    component: <div />,
    value: "",
  },
  unicorn_face: {
    component: <div />,
    value: "",
  },
  blue_heart: {
    component: <div />,
    value: "",
  },
  battery: {
    component: <div />,
    value: "",
  },
  black_heart: {
    component: <div />,
    value: "",
  },
};

export default useDynamicButtonMenuEmojis;
