import styled, { useTheme } from "styled-components";
import { steps } from "../../../app/style/theme";
import { PanelTestIds } from "../../../common/constants/testIds.constant";
import { useText } from "../../../domains/language/language.hook";
import NoNameAvatarText from "../../../domains/videoConference/components/NoNameAvatarText";
import { TITLE_HEIGHT } from "../../../domains/videoConference/lib/videoAvatars.constants";
import Icon from "../../atoms/Icon";
import SpeakingDancingBars from "../../atoms/SpeakingDancingBars";
import Typo from "../../atoms/Typo";

// @todo: Merge MovingHide with other moving Hides in poll and profile
const MovingHide = styled.div<{
  $hide: boolean;
  $width?: number;
  $widthSpeed?: number;
}>`
  width: ${(p) => (p.$hide ? "0px" : p.$width + "px")};
  opacity: ${(p) => (p.$hide ? 0 : 1)};

  transition:
    width ${(p) => p.$widthSpeed || 500}ms,
    opacity 300ms;
`;

const Outer = styled.div<{
  $size: number;
  $hidden: boolean;
  $darkText: boolean;
  $isLocal: boolean;
}>`
  opacity: ${(p) => (p.$hidden ? 0 : 1)};
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 4px;
  width: ${(p) => p.$size}px;
  text-overflow: ellipsis;

  * {
    user-select: none;
  }

  & > span {
    user-select: none;
    transition: color 0.1s ease-in-out;
    color: ${(p) => {
      if (p.$darkText) {
        return p.theme.colorDarkText;
      } else {
        return p.theme.colorLightText;
      }
    }};
    font-weight: 600;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  transition:
    opacity 0.2s ease-in-out,
    color 0.3s ease-in-out,
    width 0.3s ease-out;
`;

type Props = {
  size?: number;
  name: string | null;
  isMuted?: boolean;
  isLocal?: boolean;
  isSpeaking?: boolean;
  hideTitle?: boolean;
  darkText?: boolean;
};

const TitleBreakoutFloatingAvatars: React.FC<Props> = ({
  size = 92,
  name,
  isMuted = false,
  isLocal = false,
  isSpeaking = false,
  hideTitle = false,
  darkText = false,
}) => {
  const theme = useTheme();
  const t = useText();

  return (
    <Outer
      $isLocal={isLocal}
      $size={size}
      $hidden={hideTitle}
      $darkText={darkText}
    >
      {!name ? (
        <NoNameAvatarText />
      ) : (
        <>
          <Typo.Title
            fontFamily="Manrope"
            title={name}
            size={steps.font.f10.size}
            noWrap
            lineHeight="14px"
            style={{
              height: TITLE_HEIGHT,
              display: "flex",
              alignItems: "center",
            }}
          >
            {isLocal ? t("player_you") : name}
          </Typo.Title>
          {!isLocal && (
            <>
              <MovingHide
                $hide={!isMuted}
                $width={20}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Icon.MicrophoneOffFilled
                  testId={
                    PanelTestIds.videoAvatars.videoAvatarCircle.titleMuteIcon
                  }
                  size="15px"
                  color={darkText ? theme.colorDarkText : theme.colorLightText}
                />
              </MovingHide>
              <MovingHide
                $hide={isMuted || !isSpeaking}
                $width={20}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <SpeakingDancingBars
                  size={30}
                  color={theme.colorGradient.purpleBlue.start}
                />
              </MovingHide>
            </>
          )}
        </>
      )}
    </Outer>
  );
};

export default TitleBreakoutFloatingAvatars;
