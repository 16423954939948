import { ReactNode } from "react";
import { useReflow } from "../../common/hooks/ui";
import { TabData } from "./Social.logic";
import SocialUiLandscape from "./Social.ui.landscape";
import SocialUiNormal from "./Social.ui.normal";
import SocialUiPortrait from "./Social.ui.portrait";

export function generateGradientFromColor(color?: string) {
  if (!color) return color;
  return `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(0deg, ${color} 0%, ${color} 100%), #FFF`;
}
export type Props = {
  onClose?: () => void;
  tabs: ReactNode;
  selectedTab: TabData;
};

const SocialUi: React.FC<Props> = (props) => {
  return useReflow({
    landscape: <SocialUiLandscape {...props} />,
    portrait: <SocialUiPortrait {...props} />,
    normal: <SocialUiNormal {...props} />,
  });
};

export default SocialUi;
