import styled, { useTheme } from "styled-components";
import tinycolor from "tinycolor2";
import Icon from "../../../componentsLibrary/atoms/Icon";
import Typo from "../../../componentsLibrary/atoms/Typo";

const IncompletedProgress = styled.circle<{
  $strokeColor: string;
  $fillPercentage: number;
  $size: number;
  $strokeWidth: number;
}>`
  --size: ${(p) => p.$size}px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: ${(p) => p.$strokeWidth}px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --dash: calc((${(p) => 1 - p.$fillPercentage} * var(--circumference)));
  --circumference: calc(var(--radius) * pi * 2);
  --rot: calc(-90deg + calc(360deg * ${(p) => p.$fillPercentage}));

  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  transform: rotate(var(--rot));
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  stroke: ${(p) => p.$strokeColor};
`;

const CompletedProgress = styled.circle<{
  $strokeColor: string;
  $fillPercentage: number;
  $size: number;
  $strokeWidth: number;
}>`
  --size: ${(p) => p.$size}px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: ${(p) => p.$strokeWidth}px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * pi * 2);
  --dash: calc((${(p) => p.$fillPercentage} * var(--circumference)));

  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  stroke: ${(p) => p.$strokeColor};
`;

const ProgressLabel = styled.div`
  position: absolute;
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  margin-left: auto;
`;

const CompletedCheck = styled.div<{ $isHovering: boolean }>`
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid
    ${(p) => (p.$isHovering ? p.theme.colorBelow2 : p.theme.colorAbove3)};
  margin-left: auto;
`;

type ProgressCircleProps = {
  isHovering: boolean;
  collectedAmount: number;
  requiredAmount: number;
  size?: number;
  borderWidth?: number;
};

const QuestProgressCircle: React.FC<ProgressCircleProps> = ({
  isHovering,
  collectedAmount,
  requiredAmount,
  size = 48,
  borderWidth = 1,
}) => {
  const theme = useTheme();
  const percentage = Math.round((collectedAmount / requiredAmount) * 100) / 100;
  const hasCompleted = collectedAmount >= requiredAmount;
  return hasCompleted ? (
    <CompletedCheck $isHovering={isHovering}>
      <Icon.Check
        size="18px"
        color={isHovering ? theme.colorBelow2 : theme.colorAbove3}
      />
    </CompletedCheck>
  ) : (
    <Wrapper>
      <ProgressLabel>
        <Typo.Note color={isHovering ? theme.colorBelow0 : theme.colorAbove2}>
          {collectedAmount}/{requiredAmount}
        </Typo.Note>
      </ProgressLabel>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <IncompletedProgress
          $fillPercentage={percentage}
          $strokeColor={tinycolor(
            isHovering ? theme.colorBelow0 : theme.colorAbove1
          ).toRgbString()}
          $strokeWidth={borderWidth}
          $size={size}
        />
        <CompletedProgress
          $fillPercentage={percentage}
          $strokeColor={tinycolor(
            isHovering ? theme.colorBelow2 : theme.colorAbove3
          ).toRgbString()}
          $strokeWidth={borderWidth}
          $size={size}
        />
      </svg>
    </Wrapper>
  );
};

export default QuestProgressCircle;
