import { sendGameMessage } from "../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { SliceCreator } from "../../app/store";

const sliceName = "i18n";

export interface I18nState {
  selectedLocaleCode: string | undefined;
  setSelectedLocaleCode: (code: string) => void;
}

type State = {
  i18n: I18nState;
};

export const createLanguageSlice: SliceCreator<State> = (set) => ({
  i18n: {
    selectedLocaleCode: undefined,

    setSelectedLocaleCode: (code) =>
      set(
        (state) => {
          state.i18n.selectedLocaleCode = code;
          sendGameMessage({ type: "LanguageSelected", langCode: code });
        },
        false,
        sliceName + "/setSelectedLocaleCode"
      ),
  },
});
