import {
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import * as Form from "@radix-ui/react-form";
import styled, { css, useTheme } from "styled-components";
import { steps } from "../../app/style/theme";
import useOnClickOutside from "../../common/hooks/useOnClickOutside";
import { multiplyAlpha } from "../../common/util/color";
import { useText } from "../../domains/language/language.hook";
import Column from "./Column";
import Icon from "./Icon";
import InputHint from "./InputHint";
import Row from "./Row";
import Space from "./Space";

const FormField = styled(Form.Field)`
  width: 100%;
  font-family: ${(p) => p.theme.fontMain};
`;

export const Label = styled.label<{
  $isFloating: boolean;
  $hasError?: boolean;
}>`
  font-family: ${(p) => p.theme.fontMain}, ${(p) => p.theme.fontMainFallback};
  font-size: ${(p) =>
    p.$isFloating ? steps.font.f10.size : steps.font.f20.size};
  color: ${(p) => (p.$hasError ? p.theme.colorDanger : p.theme.colorAbove4)};
  height: 15px;
  padding-top: 8px;
  position: relative;
  top: ${(p) => (p.$isFloating ? "0px" : "9px")};
  transition: all 0.2s ease;
  pointer-events: none;
`;

export const ValidityIconWrapper = styled.div<{ $isFloatingLabel?: boolean }>`
  display: none;
  font-size: ${steps.font.f20.size};
  margin-left: 10px;
  line-height: 0;
  position: relative;
  top: ${(p) => (p.$isFloatingLabel ? "0px" : "-8px")};
  transition: all 0.2s ease;
`;

const TextArea = styled.textarea`
  all: unset;
  font-family: ${(p) => p.theme.fontMain}, ${(p) => p.theme.fontMainFallback};
  font-size: ${steps.font.f20.size};
  color: ${(p) => p.theme.colorAbove5};
  text-align: start;
  width: 100%;
  height: 70px;
  word-wrap: break-word;
  word-break: break-all;
  scrollbar-width: thin;
  scrollbar-color: ${(p) => p.theme.colorAbove2} transparent;
  &::-webkit-scrollbar {
    inline-size: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    inline-size: 6px !important;
    background-color: ${(p) => p.theme.colorAbove2};
    border-radius: ${(p) => p.theme.radiusSmall};
  }

  &::-webkit-scrollbar-thumb {
    inline-size: 6px !important;
    background-color: ${(p) => p.theme.colorAbove2};
    border-radius: ${(p) => p.theme.radiusSmall};
  }
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  padding: 0;
  flex-shrink: 1;

  font-family: ${(p) => p.theme.fontMain}, ${(p) => p.theme.fontMainFallback};
  font-size: ${steps.font.f20.size};
  color: ${(p) => p.theme.colorAbove5};
  text-align: start;
  width: 100%;
  height: 19px;
`;

export const InputContainer = styled.div<{
  $isFocused: boolean;
  $isTextArea: boolean;
  $hasError?: boolean;
  $disabled?: boolean;
  $validate?: boolean;
}>`
  position: relative;
  padding: ${(p) => p.theme.elements.field.padding};
  border-radius: ${(p) => p.theme.radiusTiny};
  height: ${(p) => (p.$isTextArea ? "100px" : p.theme.elements.field.height)};
  background-color: ${(p) =>
    p.$hasError ? "rgba(255, 0, 122, 0.10)" : p.theme.colorAbove0};
  transition: 0.3s ease-in-out;
  .noTouch &:hover {
    background-color: ${(p) =>
      !p.$disabled && multiplyAlpha(p.theme.colorAbove0, 2)};
  }
  box-sizing: border-box;
  width: 100%;

  ${(p) =>
    p.$validate &&
    css`
      &:has(input:invalid) {
        background-color: ${(p) => multiplyAlpha(p.theme.colorDanger, 0.1)};
      }
      &:has(input:invalid) label {
        color: ${(p) => p.theme.colorDanger};
      }
      &:has(input:invalid) ${ValidityIconWrapper} {
        display: block;
      }
    `}

  ${(p) => p.$disabled && `cursor: not-allowed;`}

  ${(p) =>
    p.$isFocused &&
    css`
      background-color: ${(p) => p.theme.colorBelow2};
      .noTouch &:hover {
        background-color: ${(p) => p.theme.colorBelow2};
      }
    `}
    &::after {
    content: "";
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    z-index: -1;
    inline-size: 100%;
    block-size: 100%;
    box-shadow: -8px 16px 24px 0px ${(p) => p.theme.colorShadow};
    opacity: ${(p) => (p.$isFocused ? 1 : 0)};
    border-radius: ${(p) => p.theme.radiusTiny};
    transition: 300ms ease-in-out;
  }
`;

export const PrefixWrapper = styled.div<{ $isFloatingLabel: boolean }>`
  font-size: ${steps.font.f20.size};
  color: ${(p) => (p.$isFloatingLabel ? p.theme.colorAbove5 : "transparent")};
  margin-right: 10px;
  line-height: 0;
  transition: all 0.2s ease;
`;

export const SuffixWrapper = styled.div<{ $isFloatingLabel?: boolean }>`
  font-size: ${steps.font.f20.size};
  color: ${(p) => p.theme.colorAbove5};
  margin-left: 10px;
  line-height: 0;
  position: relative;
  top: ${(p) => (p.$isFloatingLabel ? "0px" : "-8px")};
  transition: all 0.2s ease;
`;

export const ColumnShrink = styled(Column)`
  flex-shrink: 1;
`;

function getValidationMessage(
  input: HTMLInputElement & HTMLTextAreaElement,
  label: string,
  t: ReturnType<typeof useText>
): string | undefined {
  if (input.validity.valueMissing) {
    return t("input_required", { label });
  }
  if (input.validity.patternMismatch || input.validity.typeMismatch) {
    return t("input_invalid_pattern", { label });
  }
}

type Props = {
  name: string;
  label: string;
  type?: InputHTMLAttributes<HTMLInputElement>["type"];
  required?: boolean;
  textarea?: boolean;
  icon?: ReactNode;
  prefix?: string;
  suffix?: string;
  inputTestId?: string;
  /** Removes all Radix form components to convert InputText to a stand alone component */
  noForm?: boolean;
  /** Use value and onChange props to make field a controlled input. */
  value?: string;
  onChange?: (value: string) => void;
  /** Manually pass a validation message to be displayed. */
  hint?: string;
  disabled?: boolean;
  pattern?: string;
  validate?: boolean;
  autocomplete?: string;
};

/**
 * InputText should be placed inside Radix Form.Root to fully utilize
 * Radix' Form functionalities (validation etc.). But it can also be used
 * as a stand alone component by passing the noForm prop.
 */
const InputText: React.FC<Props> = ({
  name,
  label,
  type = "text",
  required,
  textarea,
  icon,
  prefix,
  suffix,
  noForm,
  value,
  onChange,
  inputTestId,
  hint,
  disabled = false,
  pattern,
  validate,
  autocomplete,
}) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  const [localValue, setLocalValue] = useState(value);
  const [hasChanged, setHasChanged] = useState(false);
  const t = useText();
  const hasFixedHeight = !textarea;
  const isFloatingLabel = isFocused || Boolean(localValue);
  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const onInputFieldClicked = () => {
    setIsFocused(true);
    inputRef.current?.focus();
  };

  const onBlur = () => {
    setIsFocused(false);
  };
  const onFocus = () => {
    setIsFocused(true);
  };
  const handleChange = (value: string) => {
    setHasChanged(true);
    setLocalValue(value);
    onChange?.(value);
  };

  useOnClickOutside(containerRef, onBlur);

  // If value is controlled, update localValue when value changes.
  // Example: First Name initialization in VisitorProfile
  useEffect(() => {
    if (value && value !== localValue) {
      setLocalValue(value);
    }
  }, [localValue, value]);

  const isInvalid = validate && inputRef.current?.validity?.valid === false;
  const message = isInvalid
    ? getValidationMessage(inputRef.current, label, t)
    : hint;

  return noForm ? (
    /* * * * * * * * * * * * * * * * * * * * * * */
    /* Stand alone component without Radix Form  */
    /* * * * * * * * * * * * * * * * * * * * * * */
    <>
      <InputContainer
        ref={containerRef}
        $isFocused={isFocused}
        $isTextArea={!!textarea}
        $disabled={disabled}
        onClick={onInputFieldClicked}
      >
        <Row>
          <ColumnShrink
            height={hasFixedHeight ? theme.elements.field.height : undefined}
          >
            <Label $isFloating={isFloatingLabel}>{label}</Label>
            <Row>
              {prefix && (
                <PrefixWrapper $isFloatingLabel={isFloatingLabel}>
                  {prefix}
                </PrefixWrapper>
              )}
              {textarea ? (
                <TextArea
                  ref={inputRef}
                  data-testid={inputTestId}
                  required={required}
                  value={localValue}
                  onChange={(e) => handleChange(e.target.value)}
                  onKeyDownCapture={(e) => e.stopPropagation()}
                  onFocus={onFocus}
                  disabled={disabled}
                />
              ) : (
                <Input
                  ref={inputRef}
                  data-testid={inputTestId}
                  value={localValue}
                  type={type}
                  required={required}
                  onChange={(e) => handleChange(e.target.value)}
                  onKeyDownCapture={(e) => e.stopPropagation()}
                  onFocus={onFocus}
                  disabled={disabled}
                  pattern={pattern}
                  autoComplete={autocomplete}
                />
              )}
              {suffix && (
                <SuffixWrapper $isFloatingLabel={isFloatingLabel}>
                  {suffix}
                </SuffixWrapper>
              )}
              {icon && (
                <SuffixWrapper>
                  <Space w={4} />
                  {icon}
                </SuffixWrapper>
              )}
            </Row>
          </ColumnShrink>
        </Row>
      </InputContainer>
      {message && <InputHint hint={message} />}
    </>
  ) : (
    /* * * * * * * * * * * * * * * */
    /* Radix Form child component  */
    /* * * * * * * * * * * * * * * */
    <FormField name={name}>
      <InputContainer
        ref={containerRef}
        onClick={onInputFieldClicked}
        $isTextArea={!!textarea}
        $isFocused={isFocused}
        $validate={validate || hasChanged}
      >
        <Row>
          <ColumnShrink
            height={hasFixedHeight ? theme.elements.field.height : undefined}
          >
            <Label $isFloating={isFloatingLabel}>{label}</Label>
            <Row>
              {prefix && (
                <PrefixWrapper $isFloatingLabel={isFloatingLabel}>
                  {prefix}
                </PrefixWrapper>
              )}
              <Form.Control asChild>
                {textarea ? (
                  <TextArea
                    ref={inputRef}
                    data-testid={inputTestId}
                    value={localValue}
                    onChange={(e) => handleChange(e.target.value)}
                    onFocus={onFocus}
                    onKeyDownCapture={(e) => e.stopPropagation()}
                    disabled={disabled}
                  />
                ) : (
                  <Input
                    ref={inputRef}
                    data-testid={inputTestId}
                    value={localValue}
                    type={type}
                    required={required}
                    onChange={(e) => handleChange(e.target.value)}
                    onKeyDownCapture={(e) => e.stopPropagation()}
                    onFocus={onFocus}
                    disabled={disabled}
                    pattern={pattern}
                    autoComplete={autocomplete}
                  />
                )}
              </Form.Control>
              {suffix && (
                <SuffixWrapper $isFloatingLabel={isFloatingLabel}>
                  {suffix}
                </SuffixWrapper>
              )}
              {icon && (
                <SuffixWrapper>
                  <Space w={4} />
                  {icon}
                </SuffixWrapper>
              )}
              <ValidityIconWrapper>
                <Space w={4} />
                <Icon.Cross size="16px" color={theme.colorDanger}></Icon.Cross>
              </ValidityIconWrapper>
            </Row>
          </ColumnShrink>
        </Row>
      </InputContainer>
      {message && <InputHint hint={message} />}
    </FormField>
  );
};

export default InputText;
