import styled from "styled-components";
import Hide from "../../componentsLibrary/atoms/Hide";
import { ProfileData, SearchableProfile } from "../profile/lib/profileTypes";
import { VideoConferenceParticipant } from "../videoConference/types/videoConference.types";
import ScreenSharingUiNormal from "./ScreenSharing.ui.normal";
import ScreenSharingUiPreview from "./ScreenSharing.ui.preview";

export type Props = {
  activeScreenShare: MediaStream | null;
  screenSharer: VideoConferenceParticipant | null;
  userId?: string;
  screenSharerName: string | null;
  activeParticipants: VideoConferenceParticipant[];
  allParticipants: VideoConferenceParticipant[];
  localParticipant: VideoConferenceParticipant;
  nrOfParticipants: number;
  amIScreenSharer: boolean;
  playersRowHidden: boolean;
  stopScreenShare: () => void;
  startScreenShare: () => void;
  onMinimizeScreenShare: () => void;
  onMaximizeScreenShare: () => void;
  roomId: string | null;
  minimized: boolean;
  micMuted: boolean;
  allPlayers: Record<string, SearchableProfile>;
  toggleLocalAudio: () => void;
  profileData: ProfileData;
  toggleVideo?: () => void;
  openPlayerList: () => void;
  audioPlaybackRef: React.RefObject<HTMLVideoElement>;
};

const PersistantScreenShareAudio = styled.audio`
  display: none;
`;

const ScreenSharingUi: React.FC<Props> = (props) => {
  /** Why don't we use transition here?
   * Because the props are getting updated constants, and the transition
   * will update its children only when its [watched] props are updated.
   * Meaning, we would retransition the component if we want updated props
   */
  return (
    <>
      <PersistantScreenShareAudio
        ref={props.audioPlaybackRef}
        muted={props.amIScreenSharer}
        autoPlay
        playsInline
      />
      <Hide
        hide={!props.minimized}
        width="100%"
        height="100%"
        style={{ position: "absolute" }}
      >
        <ScreenSharingUiPreview {...props} />
      </Hide>
      <Hide
        hide={props.minimized}
        width="100%"
        height="100%"
        style={{ position: "absolute" }}
      >
        <ScreenSharingUiNormal {...props} />
      </Hide>
    </>
  );
};

export default ScreenSharingUi;
