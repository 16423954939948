import { useStore } from "../../app/store";
import VideoAvatarsUi from "./VideoAvatars.ui";
import { useVideoConferenceControlsContext } from "./providers/VideoConference.provider";
import { VideoConferenceParticipant } from "./types/videoConference.types";

const VideoAvatarsLogic: React.FC = () => {
  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  const { profileData } = useStore((s) => s.profile);
  const screenSharer = useStore((s) => s.videoConference.screenSharer);
  const userId = useStore((s) => s.session.visitorTokenData?.id);
  const micMuted = useStore((s) => s.userMedia.micMuted);
  const videoConferenceEnabled = useStore((s) =>
    s.videoConference.isConferenceInitialized()
  );
  const allPlayers = useStore((s) => s.gameConnection.allPlayers);
  const { self, participants, activeParticipants, nrOfPublishingParticipants } =
    useStore((s) => s.videoConference);
  const { toggleLocalAudio, toggleVideo } = useVideoConferenceControlsContext();

  const roomId = useStore((s) => s.gameConnection.roomId);
  if (!videoConferenceEnabled) {
    return null;
  }

  return (
    <VideoAvatarsUi
      nrOfParticipants={nrOfPublishingParticipants()}
      localParticipant={self() as VideoConferenceParticipant}
      openPanel={openPanel}
      closePanel={closePanel}
      micMuted={micMuted}
      userId={userId}
      screenSharer={screenSharer}
      profileData={profileData}
      toggleVideo={toggleVideo}
      openPlayerList={() => openPanel("social/players")}
      players={allPlayers}
      roomId={roomId}
      toggleLocalAudio={toggleLocalAudio}
      activeParticipants={activeParticipants}
      allParticipants={Object.values(participants)}
    />
  );
};

export default VideoAvatarsLogic;
