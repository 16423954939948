import { useMemo } from "react";
import { AvatarResponseDto } from "@journee-live/gyarados/axios/esm";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";
import { useText } from "../../language/language.hook";

type Props = {
  avatarsOptions?: AvatarResponseDto[];
};

const ProfileTitle: React.FC<Props> = ({ avatarsOptions = [] }) => {
  const t = useText();
  const subTitle = useMemo(() => {
    const hasRpm = avatarsOptions.some(
      (avatar) => avatar.avatarId === "readyplayerme"
    );

    if (avatarsOptions.length === 1) {
      if (hasRpm) return t("profile_subtitle_rpm");
      return "";
    } else {
      if (hasRpm) return t("profile_subtitle_premade_and_rpm");
      return t("profile_subtitle_premade");
    }
  }, [avatarsOptions, t]);

  return (
    <>
      <Typo.Title>{t("profile_title")}</Typo.Title>
      <Space h={2} />
      {subTitle && (
        <Typo.Body style={{ textAlign: "center" }}>{subTitle}</Typo.Body>
      )}
      <Space h={5} />
    </>
  );
};

export default ProfileTitle;
