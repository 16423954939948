import { SliceCreator } from "../../app/store";

export interface UserMediaState {
  selectedMicrophone: MediaDeviceInfo | null;
  selectedSpeakers: MediaDeviceInfo | null;
  selectedCamera: MediaDeviceInfo | null;
  audioInputDevices: MediaDeviceInfo[];
  audioOutputDevices: MediaDeviceInfo[];
  videoInputDevices: MediaDeviceInfo[];
  muted: boolean;
  micMuted: boolean;
  webcamMuted: boolean;
  noDevices: boolean;
  permissions: {
    camera: boolean;
    microphone: boolean;
  };
  toRestore: {
    muted?: boolean;
  };
  videoInputFeedError: boolean;
  noiseBackgroundCancellationEnabled?: boolean;
  setSelectedSpeakers: (speakers: MediaDeviceInfo | null) => void;
  setSelectedMicrophone: (microphone: MediaDeviceInfo | null) => void;
  setSelectedCamera: (camera: MediaDeviceInfo | null) => void;
  setPermissions: (
    permissions: Partial<{
      camera: boolean;
      microphone: boolean;
    }>
  ) => void;
  setAudioMuted: (muted: boolean) => void;
  toggleBackgroundNoiseEnabled: () => void;
  setMicMuted: (muted: boolean) => void;
  setWebcamMuted: (muted: boolean) => void;
  setDeniedPermissions: (noDevices: boolean) => void;
  setAudioInputDevices: (devices: MediaDeviceInfo[]) => void;
  setAudioOutputDevices: (devices: MediaDeviceInfo[]) => void;
  setVideoInputDevices: (devices: MediaDeviceInfo[]) => void;
  setVideoCameraFeedError: (error: boolean) => void;
}

const sliceName = "userMedia";

export interface State {
  userMedia: UserMediaState;
}

export const createUserMediaSlice: SliceCreator<State> = (set) => ({
  userMedia: {
    selectedMicrophone: null,
    selectedSpeakers: null,
    selectedCamera: null,
    audioInputDevices: [],
    audioOutputDevices: [],
    videoInputDevices: [],
    permissions: {
      camera: false,
      microphone: false,
    },
    videoInputFeedError: false,
    muted: false,
    micMuted: false,
    webcamMuted: false,
    noDevices: false,
    toRestore: {
      muted: undefined,
    },
    noiseBackgroundCancellationEnabled: true,
    setVideoCameraFeedError: (error: boolean) =>
      set((state) => {
        state.userMedia.videoInputFeedError = error;
      }),
    setSelectedCamera: (camera: MediaDeviceInfo | null) =>
      set((state) => {
        state.userMedia.selectedCamera = camera;
      }),
    setPermissions: (permissions) =>
      set((state) => {
        state.userMedia.permissions = {
          ...state.userMedia.permissions,
          ...permissions,
        };
      }),
    setSelectedSpeakers: (speakers: MediaDeviceInfo | null) =>
      set(
        (state) => {
          state.userMedia.selectedSpeakers = speakers;
        },
        false,
        sliceName + "/setSelectedSpeakers"
      ),
    setSelectedMicrophone: (microphone: MediaDeviceInfo | null) =>
      set(
        (state) => {
          state.userMedia.selectedMicrophone = microphone;
        },
        false,
        sliceName + "/setSelectedMicrophone"
      ),
    setAudioInputDevices: (devices: MediaDeviceInfo[]) =>
      set(
        (state) => {
          if (devices.length === 0) {
            state.userMedia.noDevices = true;
          }
          state.userMedia.audioInputDevices = devices;
        },
        false,
        sliceName + "/setAudioInputDevices"
      ),
    setAudioOutputDevices: (devices: MediaDeviceInfo[]) =>
      set(
        (state) => {
          state.userMedia.audioOutputDevices = devices;
        },
        false,
        sliceName + "/setAudioOutputDevices"
      ),
    setVideoInputDevices: (devices: MediaDeviceInfo[]) =>
      set((state) => {
        state.userMedia.videoInputDevices = devices;
      }),

    setAudioMuted: (muted: boolean) =>
      set((state) => {
        state.userMedia.muted = muted;
      }),
    toggleBackgroundNoiseEnabled: () =>
      set((state) => {
        state.userMedia.noiseBackgroundCancellationEnabled =
          !state.userMedia.noiseBackgroundCancellationEnabled;
      }),

    setMicMuted: (muted: boolean) =>
      set((state) => {
        state.userMedia.micMuted = muted;
      }),
    setWebcamMuted: (muted: boolean) =>
      set((state) => {
        state.userMedia.webcamMuted = muted;
      }),
    setDeniedPermissions: (noDevices: boolean) =>
      set((state) => {
        state.userMedia.noDevices = noDevices;
      }),
  },
});
