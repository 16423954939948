import { useEffect } from "react";
import { useEnvironmentContext } from "../EnvironmentDataProvider";
import { getFitStreamInViewport } from "./gameConnectionUtils";
import { debouncedPlayerSizeChanged } from "./webrtc/helpers/inputs";
import { killWebRtcPlayer, onVideoSizeUpdate } from "./webrtc/webRtcConnection";

const useScreenResize = (
  playerElement: HTMLDivElement | null,
  videoElement: HTMLVideoElement | null,
  gameIsReady: boolean,
  hasVideoLoaded: boolean
) => {
  const { environment } = useEnvironmentContext();

  useEffect(() => {
    if (!playerElement) return;
    if (!videoElement) return;
    if (!gameIsReady) return;

    const fitStreamInViewPort = getFitStreamInViewport(
      environment?.fitViewport
    );

    // To go further, we need video size which will come only after a media file for the <video> element will be ready.
    // Video size is required for aspect ratio calculation in `setupNormalizeAndQuantize()`.
    // Normalization should be set before registering mouse events.
    if (
      !hasVideoLoaded ||
      !videoElement?.videoHeight ||
      !videoElement?.videoWidth
    )
      return;
    const onResize = () => {
      debouncedPlayerSizeChanged(videoElement);
      onVideoSizeUpdate(
        playerElement,
        videoElement?.videoWidth || 1280,
        videoElement?.videoHeight || 720,
        fitStreamInViewPort
      );
    };
    onResize();
    window.addEventListener("resize", onResize);
    // Close WebRTC when unmounting this component.
    return () => {
      window.removeEventListener("resize", onResize);
      killWebRtcPlayer();
    };
  }, [
    playerElement,
    videoElement,
    environment?.fitViewport,
    gameIsReady,
    hasVideoLoaded,
  ]);
};

export default useScreenResize;
