/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";
import { GyaradosApp, GyaradosSDK } from "@journee-live/gyarados";
import { HttpStatusCode } from "@journee-live/mew";
import useSWR from "swr";
import { ENVIRONMENT_DATA_REFRESH_INTERVAL_MS } from "../../common/constants/configs.constant";
import { isMobile } from "../../common/constants/flags.constant";
import { getImageUrl } from "../../common/util/gyarados.util";
import { EmojiName } from "../../componentsLibrary/atoms/Emoji";
import { MapPoint } from "../../domains/map/Map.logic";
import { useEnvironmentContext } from "../EnvironmentDataProvider";
import { getEnvironmentBySlug } from "../lib/gyarados";

export const useGetEnvironmentBySlug = (
  environmentSlug: string | undefined,
  organisationSlug?: string | undefined,
  locale?: string | undefined
) => {
  const fetcher = () =>
    getEnvironmentBySlug(environmentSlug, organisationSlug, locale);

  return useSWR<
    GyaradosSDK.EnvironmentConfigResponseDto | null,
    undefined,
    GyaradosApp
  >(["getEnvironmentBySlug"], fetcher, {
    shouldRetryOnError: (error: any) => {
      if (error?.["isAxiosError"]) {
        if (error?.response?.status === HttpStatusCode.NotFound) {
          return false;
        }
      }
    },
    refreshInterval: ENVIRONMENT_DATA_REFRESH_INTERVAL_MS,
    keepPreviousData: true,
  });
};

export const useGetCurrentAuthMode = () => {
  const { environment } = useEnvironmentContext();
  switch (environment.login) {
    case "email-only":
      return "password";
    case "sso":
      return "sso";
    case "anonymous":
    default:
      return "open";
  }
};

export const useAvailableEmojis = () => {
  /* When we have all reactions icons, use the code below
    to get the emojis list from the environment conifg */
  const {
    environment: { emojis },
  } = useEnvironmentContext();
  const enabledReactionsList: string[] = [];

  for (const [emoji, isEnabled] of Object.entries(emojis)) {
    if (isEnabled) enabledReactionsList.push(emoji);
  }

  return enabledReactionsList as EmojiName[];
};

export const useShowPlayerList = () => {
  const { environment } = useEnvironmentContext();
  return (
    environment.showPlayerList &&
    (!isMobile || environment.showPlayerListMobile)
  );
};

export const useCurrentLoginLogo = () => {
  const { environment } = useEnvironmentContext();
  return environment.logo;
};

export const useBodyFont = () => {
  const { environment } = useEnvironmentContext();
  return {
    bodyFontFamily: environment.fontMain?.familyName,
    bodyFontRegularLink: environment.fontMain?.linkRegular,
    bodyFontBoldLink: environment.fontMain?.linkBold,
  };
};
export const useDisplayFont = () => {
  const { environment } = useEnvironmentContext();
  return {
    displayFontFamily: environment.fontDisplay?.familyName,
    displayFontRegularLink: environment.fontDisplay?.linkRegular,
  };
};

export const useCurrentExperienceLogo = () => {
  const { environment } = useEnvironmentContext();
  return environment.topLeftLogo;
};

export const useCurrentHelpScreenImage = () => {
  const { environment } = useEnvironmentContext();
  return environment.helpScreenImage;
};

export const useCurrentLoginBackgroundImage = () => {
  const { environment } = useEnvironmentContext();
  return environment.splashscreen;
};

export const useCurrentLoginBackgroundVideo = () => {
  const { environment } = useEnvironmentContext();
  return environment.queueVideo;
};

export const useIsDarkMode = () => {
  const { environment } = useEnvironmentContext();
  return environment.themePresets.mode === "dark";
};

export const useGetPopupBySlug = (slug?: string) => {
  const { environment } = useEnvironmentContext();
  return environment?.actionPanels?.find((it) => it.actionPanelId === slug);
};

export const useGetPopupByMintId = (mintId?: string) => {
  const { environment } = useEnvironmentContext();
  return environment?.actionPanels?.find((it) => it.mintEventId === mintId);
};

export const useGetInfoCardBySlug = (slug?: string) => {
  const { environment } = useEnvironmentContext();
  return environment?.infoCards?.find((it) => it.infoCardId === slug);
};

export const useGetLocales = () => {
  const { environment } = useEnvironmentContext();
  return environment?.locales;
};

export const useGetHintBySlug = (slug?: string) => {
  const { environment } = useEnvironmentContext();
  return environment?.hints?.find((it) => it.hintId === slug);
};

export const useGetQuestBySlug = (slug?: string) => {
  const { environment } = useEnvironmentContext();
  return environment?.quests?.find((it) => it.questId === slug);
};

export const useCurrentMap = () => {
  const { environment } = useEnvironmentContext();

  return useMemo(
    () => ({
      imgRatio: environment.map
        ? (environment.map.height || 0) / (environment.map.width || 0)
        : 0,
      imgUrl: environment.map ? getImageUrl(environment.map) : undefined,
      imgHeight: environment.map?.height || 0,
      imgWidth: environment.map?.width || 0,
      points: (environment.mapPoints || []).map(
        (point): MapPoint => ({
          name: point.name,
          left: parseInt(point.positionX.toString()),
          top: parseInt(point.positionY.toString()),
          spawnPoint: point.spawnPoint || undefined,
          title: point.title || "",
          description: point.description || "",
          label: point.name || "",
          image: point.image ? getImageUrl(point.image) : undefined,
          regionId: point.regionId || undefined,
        })
      ),
    }),
    [environment.map, environment.mapPoints]
  );
};

export const useCurrentChatSettings = () => {
  const { environment } = useEnvironmentContext();
  const { disableChat, textChatOnMobile } = environment;
  return { textChatOnDesktop: !disableChat, textChatOnMobile };
};

export const useGetCurrentLevelVideoBySlug = (slug?: string) => {
  const { environment } = useEnvironmentContext();
  return environment?.levelVideos?.find(
    (levelVideo) => levelVideo.levelId === slug
  );
};

export const useGetPollBySlug = (slug?: string) => {
  const { environment } = useEnvironmentContext();
  return environment?.polls?.find((poll) => poll.pollId === slug);
};

export const useCurrentEnvironmentTheme = () => {
  const { environment } = useEnvironmentContext();
  return {
    presets: environment.themePresets,
    overrides: environment.themeOverrides,
  };
};

export const useGetQuests = () => {
  const { environment } = useEnvironmentContext();
  return environment?.quests;
};
