import { useTheme } from "styled-components";
import { useCurrentExperienceLogo } from "../../app/hooks/gyarados.hook";
import {
  LOGO_MAX_HEIGHT,
  LOGO_MAX_WIDTH,
} from "../../common/constants/configs.constant";
import { getImageUrl } from "../../common/util/gyarados.util";
import Logo from "../../componentsLibrary/molecules/Logo";

const TopLogoPanelUi: React.FC = () => {
  const logo = useCurrentExperienceLogo();
  const theme = useTheme();
  const logoUrl = getImageUrl(logo, LOGO_MAX_WIDTH, LOGO_MAX_HEIGHT);

  return logoUrl ? (
    <Logo logoUrl={logoUrl} height={theme.topLogoHeight} />
  ) : null;
};

export default TopLogoPanelUi;
