import { SvgProps } from "../../types/svg";

const MountainSunFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 5.53125C14 4.40949 14.895 3.5 16 3.5C17.105 3.5 18 4.40949 18 5.53125C18 6.65352 17.105 7.5625 16 7.5625C14.895 7.5625 14 6.65352 14 5.53125ZM2 15.0807C2 14.8141 2.0741 14.5525 2.21377 14.3266L7.6325 5.55689C7.7975 5.28801 8.0875 5.125 8.4 5.125C8.7125 5.125 9.0025 5.28801 9.17 5.55689L12.3175 10.6525L13.5225 8.64668C13.6025 8.4791 13.805 8.375 14 8.375C14.195 8.375 14.375 8.4791 14.4775 8.64668L17.7775 14.1412C17.9225 14.3824 18 14.6643 18 14.9486C18 15.8043 17.315 16.5 16.4725 16.5H3.39775C2.62575 16.5 2 15.8424 2 15.0807Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default MountainSunFilledIcon;
