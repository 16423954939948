import { styled } from "styled-components";

export const TopCenterBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-left: auto;
  margin-right: auto;
  margin-top: max(var(--safe-margin-top), 16px);
`;
