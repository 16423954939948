import styled, { css, keyframes, useTheme } from "styled-components";
import { steps } from "../../app/style/theme";
import { grayscaleInvert } from "../../common/util/color";
import {
  IconDescriptor,
  UNSYNC_SECONDS,
  lookupTable,
} from "./HeaderBadgeIconConst";

const rippling = keyframes`
  0% {
    transform: scale(0.1);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }`;

/* This animation receives the translation values from the class to make it dynamic and not repeat the code for each direction 
the values from the class */
const ripplingIcon = keyframes`
0% {
    transform:  translate(var(--x1), var(--y1)) scale(0.1);
    opacity: 1;
    filter: blur(0)
  }
  100% {
    transform: translate(var(--x2), var(--y2)) scale(1);
    opacity: 0;
    filter: blur(1px)
  }
`;

const Wrapper = styled.div`
  position: relative;
  pointer-events: none;
`;

const CircleWrapper = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  z-index: -1;
`;

const Circle = styled.div<{ $color?: string; $lineWidth?: number }>`
  border-radius: 9999px;
  flex-shrink: 0;
  border: ${(p) => p.$lineWidth || 1}px solid
    ${(p) => p.$color || p.theme.colorAbove5};
  width: 300px;
  height: 300px;
  opacity: 0;
  animation: ${rippling} 12s linear 6s infinite;
  &.first {
    animation: ${rippling} 12s linear 6s infinite;
  }
  &.second {
    animation: ${rippling} 12s linear 0s infinite;
  }
`;

const AnimatedImage = styled.img<{
  $x1: number;
  $x2: number;
  $y1: number;
  $y2: number;
  $startTimer: number;
}>`
  opacity: 0;
  border-radius: ${steps.borderRadius.b20};
  width: 40px;
  height: 40px;
  --x1: ${(p) => `${p.$x1}%`};
  --x2: ${(p) => `${p.$x2}%`};
  --y1: ${(p) => `${p.$y1}%`};
  --y2: ${(p) => `${p.$y2}%`};
  &.animate {
    animation: ${ripplingIcon} 12s linear
      ${(p) => (p.$startTimer >= 0 ? `${p.$startTimer}s` : "6s")} 1;
  }
  &.animateImmediate {
    animation: ${ripplingIcon} 12s linear 0s 1;
  }
`;

const Box = styled.div<{
  $color?: string;
  $backgroundColor?: string;
  $noBackground?: boolean;
  $noShadow?: boolean;
  $width?: string;
  $height?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  width: ${(p) => (p.$width ? p.$width : "72px")};
  height: ${(p) => (p.$height ? p.$height : "72px")};
  color: ${(p) => p.$color || p.theme.colorAbove5};
  background: ${(p) => p.$backgroundColor || p.theme.colorBelow1};
  ${(p) =>
    p.$noBackground &&
    css`
      background: transparent;
    `}
  ${(p) =>
    p.$noShadow
      ? css`
          filter: none;
        `
      : css`
          filter: drop-shadow(-20px -28px 32px ${p.theme.colorShadow})
            drop-shadow(0px 1px 2px ${p.theme.colorShadow});
        `}
`;

export type Props = {
  children?: React.ReactNode;
  color?: "default" | "inverted" | "brand" | string;
  rippleColor?: string;
  rippleLineWidth?: number;
  noShadow?: boolean;
  noBackground?: boolean;
  icons?: IconDescriptor[];
  width?: string;
  height?: string;
};

const HeaderBadge: React.FC<Props> = ({
  children,
  color,
  rippleLineWidth,
  noBackground,
  noShadow,
  rippleColor,
  icons = [],
  width,
  height,
}) => {
  const theme = useTheme();

  let above = theme.colorAbove5;
  let below = theme.colorBelow1;

  if (color === "inverted") {
    above = grayscaleInvert(above);
    below = grayscaleInvert(below);
  }

  let backgroundColor;
  switch (color) {
    case "inverted":
    case "default":
      backgroundColor = below;
      break;
    case "brand":
      backgroundColor = theme.colorBelowBrand;
      break;
    default:
      backgroundColor = color;
  }

  return (
    <Wrapper>
      <CircleWrapper>
        <Circle
          $lineWidth={rippleLineWidth}
          $color={rippleColor ?? above}
          className="first"
        />
      </CircleWrapper>
      <CircleWrapper>
        <Circle
          $lineWidth={rippleLineWidth}
          $color={rippleColor ?? above}
          className="second"
        />
      </CircleWrapper>

      {icons.map((icon, index) => (
        <CircleWrapper key={icon.name + index}>
          <AnimatedImage
            $x1={lookupTable[icon.direction].x1}
            $x2={lookupTable[icon.direction].x2}
            $y1={lookupTable[icon.direction].y1}
            $y2={lookupTable[icon.direction].y2}
            $startTimer={index * 6}
            onAnimationEnd={(el) => {
              (el.target as HTMLImageElement).classList.remove("animate");
              (el.target as HTMLImageElement).classList.remove(
                "animateImmediate"
              );

              setTimeout(
                () => {
                  // We have to start the animation instantly after the unsync, this means we cannot use the initial value of the animation time start.
                  (el.target as HTMLImageElement).classList.add(
                    "animateImmediate"
                  );
                },
                1000 * UNSYNC_SECONDS * Math.floor(icons.length / 2)
              );
            }}
            src={icon.src}
            className="animate"
          />
        </CircleWrapper>
      ))}
      <Box
        $color={color === "brand" ? theme.colorAboveBrand : above}
        $backgroundColor={backgroundColor}
        $noBackground={noBackground}
        $noShadow={noShadow}
        $width={width}
        $height={height}
      >
        {children}
      </Box>
    </Wrapper>
  );
};

export default HeaderBadge;
