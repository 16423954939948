import { styled } from "styled-components";
import { useStore } from "../../app/store";
import { useIsLandscape, useIsSmallScreen } from "../../common/hooks/ui";
import Transition from "../../componentsLibrary/atoms/Transition";
import { BaseLayout } from "../_layout/PanelsLayout";
import PollLogic from "./Poll.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0px;
  top: 0px;
`;

const PollPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.poll.visible);
  const slug = useStore((s) => s.layout.panels.poll.slug);

  const isSmallScreen = useIsSmallScreen();
  const isLandscape = useIsLandscape();
  return (
    <Layout id="panel-poll">
      <Transition
        height="100%"
        watch={[visible]}
        delay={200}
        slideIn={isSmallScreen && !isLandscape ? "down" : undefined}
        slideOut={isSmallScreen && !isLandscape ? "down" : undefined}
        justify="center"
        align="center"
      >
        {visible && <PollLogic slug={slug} />}
      </Transition>
    </Layout>
  );
};

export default PollPanel;
