import Column from "../../componentsLibrary/atoms/Column";
import Space from "../../componentsLibrary/atoms/Space";
import Typo from "../../componentsLibrary/atoms/Typo";
import CardPartialPortrait from "../../componentsLibrary/molecules/CardPartialPortrait";
import { Props } from "./Language.ui";
import LanguageCardHeader from "./component/LanguageCardHeader";
import LanguageCardList from "./component/LanguageCardList";
import { useText } from "./language.hook";

const LanguagePanelSmallPortraitUi: React.FC<Props> = ({
  handleDismiss,
  locales,
  currentLocale,
  onLanguageSelected,
}) => {
  const t = useText();

  return (
    <CardPartialPortrait
      padding="0px"
      topSection={
        <>
          <Space h={7} />
          <LanguageCardHeader />
          <Space h={4} />
          <Column align="center">
            <Typo.Title>{t("settings_select_language_title")}</Typo.Title>
            <Space h={2} />
            <Typo.Body>{t("settings_select_language_description")}</Typo.Body>
            <Space h={4} />
          </Column>
        </>
      }
      onClose={handleDismiss}
    >
      <LanguageCardList
        locales={locales}
        currentLocale={currentLocale}
        onLanguageSelected={onLanguageSelected}
      />
      <Space h={8} />
    </CardPartialPortrait>
  );
};

export default LanguagePanelSmallPortraitUi;
