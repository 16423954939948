import { SvgProps } from "../../types/svg";

const CircleFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 4C6.68001 4 4.00001 6.68 4.00001 10C4.00001 13.32 6.68001 16 10 16C13.32 16 16 13.32 16 10C16 6.68 13.32 4 10 4Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default CircleFilledIcon;
