import upperFirst from "lodash/upperFirst";
import { PanelTestIds } from "../../common/constants/testIds.constant";
import ButtonText from "./ButtonText";

type Props = {
  bright?: boolean;
  openLanguagePanel?: () => void;
  locales?: string[];
  selectedLocaleCode?: string;
};

const LanguageSelectionButton: React.FC<Props> = ({
  bright,
  selectedLocaleCode,
  locales,
  openLanguagePanel,
}) => {
  const onLanguageClick = () => {
    openLanguagePanel?.();
  };

  if (!locales?.length) return null;

  return (
    <ButtonText
      onClick={onLanguageClick}
      bright={bright}
      testId={PanelTestIds.settings.common.selectLanguageBtn}
    >
      {selectedLocaleCode && upperFirst(getLocaleName(selectedLocaleCode))}
    </ButtonText>
  );
};

export const getLocaleName = (code: string) =>
  new Intl.DisplayNames(code, {
    type: "language",
    languageDisplay: "standard",
    style: "short",
  }).of(code);

export default LanguageSelectionButton;
