import { SvgProps } from "../../types/svg";

const LayerPlusFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.5 0a.75.75 0 0 1 .75.75v1h1a.75.75 0 1 1 0 1.5h-1v1c0 .416-.334.75-.75.75a.75.75 0 0 1-.75-.75v-1h-1a.75.75 0 1 1 0-1.5h1v-1A.75.75 0 0 1 13.5 0m2.066 7.319a.74.74 0 0 1 .434.653.77.77 0 0 1-.434.71l-6.832 3.156a1.75 1.75 0 0 1-1.468 0L.436 8.68A.77.77 0 0 1 0 7.971a.74.74 0 0 1 .435-.652l6.83-3.157a1.75 1.75 0 0 1 1.47 0zm-6.413 5.425 4.75-2.194 1.663.769a.74.74 0 0 1 .434.653.77.77 0 0 1-.434.71l-6.832 3.156a1.75 1.75 0 0 1-1.468 0L.436 12.68A.77.77 0 0 1 0 11.97a.74.74 0 0 1 .435-.652l1.663-.769 4.749 2.194a2.73 2.73 0 0 0 2.306 0"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default LayerPlusFilledIcon;
