import { useEffect } from "react";
import { useGetLoginPath } from "../../app/hooks/routing.hook";
import { useStore } from "../../app/store";
import LogoutPageUi from "./LogoutPage.ui";

const LogoutRoute: React.FC = () => {
  const path = useGetLoginPath();
  const setVisitorToken = useStore((s) => s.session.setVisitorToken);

  useEffect(() => {
    setVisitorToken("");
    window.location.href = path;
  });

  return <LogoutPageUi />;
};

export default LogoutRoute;
