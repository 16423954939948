import {
  ButtonHTMLAttributes,
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  forwardRef,
} from "react";
import styled, { css, keyframes } from "styled-components";
import { steps } from "../../app/style/theme";
import { PrefixWith$ } from "../../types/typescript";
import Excite, { ExciteProps } from "./Excite";
import { getSafeStepsSpacing } from "./util/ui";

const pulse = keyframes`
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  60% {
    transform: scale3d(1, 1, 1);
  }
`;

const Button = styled.button<PrefixWith$<PropsClickable>>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  pointer-events: all;
  user-select: none;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};

  box-sizing: border-box;
  width: ${(p) => p.$width || "auto"};
  height: ${(p) => p.$height || "30px"};
  padding: ${(p) => p.$padding || "5px 12px"};
  max-width: ${(p) => p.$maxWidth || "none"};
  color: ${(p) => p.$color || p.theme.colorAboveRoot};
  background-color: ${(p) => p.$backgroundColor || p.theme.colorAbove0};
  background: ${(p) => p.$background};
  border-radius: ${(p) => p.$borderRadius || steps.borderRadius.b10};

  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  backdrop-filter: ${(p) => p.$backdropFilter ?? "none"};
  ${(p) =>
    p.$border &&
    css`
      border: ${p.$border};
    `};

  text-align: center;
  text-transform: ${(p) => p.$textTransform ?? ""};

  white-space: nowrap;
  overflow-wrap: break-word;
  overflow: ${(p) => (p.$overflowHidden ? "hidden" : "visible")};

  // This is a polyfill for the flexbox gap property which has not the best support.
  // https://caniuse.com/flexbox-gap
  > * + * {
    ${(p) => p.$gap && `margin-left: ${getSafeStepsSpacing(p.$gap)};`}
  }

  ${(p) =>
    p.$animation === "pulse" &&
    css`
      animation-name: ${pulse};
      animation-delay: 1s;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.25, 0, 0, 1);
    `}

  transition:
    color ${(p) => p.$transitionMs || 200}ms,
    opacity ${(p) => p.$transitionMs || 200}ms,
    background-color ${(p) => p.$transitionMs || 200}ms,
    width ${(p) => p.$transitionMs || 200}ms;

  .noTouch &:hover {
    ${(p) =>
      !p.disabled &&
      css`
        color: ${p.$hoverColor || ""};
        background-color: ${p.$hoverBackground || ""};
        border-color: ${p.$hoverBorderColor || ""};
        opacity: ${p.$hoverOpacity || ""};
        text-decoration: ${p.$hoverTextDecoration || ""};
        &::after {
          opacity: ${p.$hoverBoxShadowOpacity || 1};
        }
      `}
  }

  // To override global.css focus css
  &:focus:not(:focus-visible) {
    ${(p) => p.$css && { ...p.$css }}
  }

  ${(p) =>
    p.disabled &&
    css`
      color: ${p.$disabledColor || ""};
      background-color: ${p.$disabledBackground || ""};
      border-color: ${p.$disabledBorderColor || ""};
      opacity: ${p.$disabledOpacity || 0.6};
    `}

  ${(p) => p.$css && { ...p.$css }}

   /*
    Optimize rendering with pseudo https://tobiasahlin.com/blog/how-to-animate-box-shadow/
    It also protects from the override focused-button-shadow-override coming from global.css.
   */
   ${(p) =>
    p.$boxShadow &&
    css`
      &::after {
        content: "";
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        z-index: -1;
        inline-size: 100%;
        block-size: 100%;
        box-shadow: ${p.$boxShadow};
        opacity: ${p.$boxShadowOpacity || 0};
        border-radius: ${p.$borderRadius};
        transition: ${p.$transitionMs || 200}ms ease-in-out;
      }
    `}
`;

export type PropsClickable = {
  disabled?: boolean;
  testId?: string;
  buttonType?: ButtonHTMLAttributes<HTMLButtonElement>["type"];

  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];
  padding?: React.CSSProperties["padding"];
  maxWidth?: React.CSSProperties["maxWidth"];

  /** Props for the inner Excite components which adds transform animations to the hover and clicks. */
  excite?: ExciteProps;
  animation?: "pulse";
  overflowHidden?: boolean;
  gap?: number;
  buttonProps?: React.ComponentPropsWithoutRef<"button">;

  style?: React.CSSProperties;
  css?: CSSProperties;

  color?: React.CSSProperties["color"];
  backgroundColor?: React.CSSProperties["backgroundColor"];
  background?: React.CSSProperties["background"];
  borderRadius?: React.CSSProperties["borderRadius"];
  backdropFilter?: React.CSSProperties["backdropFilter"];
  border?: React.CSSProperties["border"];
  textTransform?: React.CSSProperties["textTransform"];
  boxShadow?: React.CSSProperties["boxShadow"];
  boxShadowOpacity?: React.CSSProperties["opacity"];

  transitionMs?: number;

  hoverColor?: React.CSSProperties["color"];
  hoverBackground?: React.CSSProperties["background"];
  hoverBorderColor?: React.CSSProperties["borderColor"];
  hoverOpacity?: React.CSSProperties["opacity"];
  hoverTextDecoration?: React.CSSProperties["textDecoration"];
  hoverBoxShadowOpacity?: React.CSSProperties["opacity"];

  disabledColor?: React.CSSProperties["color"];
  disabledBackground?: React.CSSProperties["background"];
  disabledBorderColor?: React.CSSProperties["borderColor"];
  disabledOpacity?: React.CSSProperties["opacity"];

  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;

  children?: ReactNode;
};

const Clickable = forwardRef<HTMLButtonElement, PropsClickable>(
  ({ children, excite, buttonProps, ...props }, ref) => {
    return (
      <Excite {...excite} disabled={props.disabled}>
        <Button
          type={props.buttonType}
          style={props.style}
          $width={props.width}
          $transitionMs={props.transitionMs}
          $height={props.height}
          $padding={props.padding}
          $maxWidth={props.maxWidth}
          $color={props.color}
          $backgroundColor={props.backgroundColor}
          $background={props.background}
          $backdropFilter={props.backdropFilter}
          $border={props.border}
          $borderRadius={props.borderRadius}
          disabled={props.disabled}
          $textTransform={props.textTransform}
          $animation={props.animation}
          $overflowHidden={props.overflowHidden}
          $hoverColor={props.hoverColor}
          $hoverBackground={props.hoverBackground}
          $hoverBorderColor={props.hoverBorderColor}
          $hoverOpacity={props.hoverOpacity}
          $disabledColor={props.disabledColor}
          $hoverTextDecoration={props.hoverTextDecoration}
          $boxShadow={props.boxShadow}
          $boxShadowOpacity={props.boxShadowOpacity}
          $hoverBoxShadowOpacity={props.hoverBoxShadowOpacity}
          $disabledBackground={props.disabledBackground}
          $disabledBorderColor={props.disabledBorderColor}
          $disabledOpacity={props.disabledOpacity}
          $gap={props.gap}
          $css={props.css}
          ref={ref}
          data-testid={props.testId}
          onClick={props.onClick}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
          {...buttonProps}
        >
          {children}
        </Button>
      </Excite>
    );
  }
);

Clickable.displayName = "Clickable";
export default Clickable;
