import { useRef } from "react";
import { PanelTestIds } from "../../common/constants/testIds.constant";
import CardFullPortrait from "../../componentsLibrary/molecules/CardFullPortrait";
import { Props } from "./Profile.ui";
import SmallProfileForm from "./component/SmallProfileForm";

const ProfileUiPortrait: React.FC<Props> = ({
  data,
  settings,
  onChange,
  avatarsOptions,
  isReadyPlayerMeEditorOpen,
  setIsReadyPlayerMeEditorOpen,
  setCustomAvatarUrl,
  hasConfirmed,
  setHasConfirmed,
  hasReadyPlayerMe,
  isMissingAvatar,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <CardFullPortrait
      ref={ref}
      noPadding
      hideScrollButton
      testId={PanelTestIds.profile.portrait.root}
    >
      <SmallProfileForm
        hasReadyPlayerMe={hasReadyPlayerMe}
        setCustomAvatarUrl={setCustomAvatarUrl}
        data={data}
        settings={settings}
        onChange={onChange}
        avatarsOptions={avatarsOptions}
        isReadyPlayerMeEditorOpen={isReadyPlayerMeEditorOpen}
        setIsReadyPlayerMeEditorOpen={setIsReadyPlayerMeEditorOpen}
        hasConfirmed={hasConfirmed}
        setHasConfirmed={setHasConfirmed}
        isMissingAvatar={isMissingAvatar}
      />
    </CardFullPortrait>
  );
};

export default ProfileUiPortrait;
