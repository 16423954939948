import { MouseEventHandler, useState } from "react";
import styled, { useTheme } from "styled-components";
import { steps } from "../../app/style/theme";
import { isTouch } from "../../common/constants/flags.constant";
import Clickable from "../atoms/Clickable";
import Column from "../atoms/Column";
import Excite from "../atoms/Excite";
import Icon, { IconName, BaseIconProps as IconProps } from "../atoms/Icon";
import Image from "../atoms/Image";
import Row from "../atoms/Row";
import Space from "../atoms/Space";
import Typo from "../atoms/Typo";

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`;

interface CommonProps {
  width?: string;
  testId?: string;
  height?: React.CSSProperties["height"];
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  backgroundColor?: React.CSSProperties["color"];
  padding?: React.CSSProperties["padding"];
}

type ConditionalProps =
  | {
      label: string;
      icon: IconName;
      iconProps?: IconProps;
      title?: never;
      description?: never;
      imageSrc?: never;
      status?: never;
    }
  | {
      label?: never;
      icon?: never;
      iconProps?: never;
      title: string;
      description: string;
      imageSrc: string;
      status?: (hovering: boolean) => JSX.Element;
    };

export type Props = CommonProps & ConditionalProps;

const ButtonCard: React.FC<Props> = ({
  disabled,
  onClick,
  testId,
  label,
  icon,
  iconProps,
  backgroundColor,
  width = "100%",
  height = "80px",
  title,
  description,
  imageSrc,
  status,
  padding = "6px 24px",
}) => {
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState(false);
  let content;

  if (icon) {
    const IconComp = Icon[icon];
    content = (
      <LabelWrapper>
        <Row>
          <IconComp
            color={isHovering ? theme.colorAboveBrand : theme.colorAbove4}
            style={{ width: "32px" }}
            {...iconProps}
          />
          <Space w={5} />
          <Typo.Subtitle color="inherit">{label}</Typo.Subtitle>
        </Row>
        <Icon.Chevron rotate="90deg" inheritColor size="16px" />
      </LabelWrapper>
    );
  }

  if (title) {
    content = (
      <Row>
        <Image
          styleOverrides={{
            borderRadius: steps.borderRadius.b10,
          }}
          src={imageSrc}
          width="64px"
          height="64px"
          transparentBorder
          cover
        />
        <Space w={3} />
        <Column width="60%">
          <Typo.Label
            style={{
              fontSize: steps.font.f50.size,
              lineHeight: steps.font.f50.lineHight,
              whiteSpace: "nowrap",
              width: "calc(100%)",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "left",
            }}
            color={isHovering ? theme.colorBelow2 : theme.colorAbove4}
          >
            {title}
          </Typo.Label>
          <Space w={1} />
          <Typo.Label
            style={{
              fontSize: steps.font.f10.size,
              lineHeight: steps.font.f10.lineHight,
              maxWidth: "220px",
              overflow: "clip",
              textOverflow: "ellipsis",
            }}
            color={isHovering ? theme.colorBelow0 : theme.colorAbove2}
          >
            {description}
          </Typo.Label>
        </Column>
        {status && status(isHovering)}
      </Row>
    );
  }

  return (
    <Excite
      hoverResize="1.1"
      disabled={isTouch || disabled}
      height={height}
      width="100%"
    >
      <Clickable
        testId={testId}
        excite={{ width: "100%" }}
        width={width}
        height={height}
        onClick={onClick}
        padding={padding}
        onMouseEnter={() => {
          if (!disabled) setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
        disabled={disabled}
        borderRadius={theme.radiusSmall}
        color={theme.colorAbove3}
        backgroundColor={backgroundColor ?? theme.colorAbove0}
        hoverColor={theme.colorAboveBrand}
        hoverBackground={theme.colorBelowBrand}
      >
        {content}
      </Clickable>
    </Excite>
  );
};

export default ButtonCard;
