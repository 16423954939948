import { useEffect } from "react";
import { useStore } from "../../../app/store";
import { useText } from "../../language/language.hook";
import { PropsLoginPageUi } from "../LoginPageUi";

const useInstanceAvailabilityCheckStep = (
  setLoginPageProps: (props: PropsLoginPageUi) => void
) => {
  const currentStep = useStore((s) => s.userFlow.currentStep);
  const completeStep = useStore((s) => s.userFlow.completeStep);
  const queueStatus = useStore((s) => s.gameConnection.queueStatus);

  const isQueueStatusChecked = queueStatus.checked === true;
  const isExperienceUnavailable = queueStatus.available === false;
  const t = useText();

  useEffect(() => {
    if (currentStep !== "login:availability") return;
    if (!isQueueStatusChecked) return;
    if (isExperienceUnavailable) return;
    completeStep("login:availability");
  }, [
    currentStep,
    completeStep,
    isExperienceUnavailable,
    isQueueStatusChecked,
  ]);

  useEffect(() => {
    if (currentStep !== "login:availability") return;
    if (isQueueStatusChecked && isExperienceUnavailable) {
      setLoginPageProps({
        title: "",
        descriptionHeader: "warning",
        description: t("queue_unavailable"),
        isMainButtonVisible: false,
      });
    }
  }, [
    currentStep,
    isExperienceUnavailable,
    isQueueStatusChecked,
    setLoginPageProps,
    t,
  ]);
};

export default useInstanceAvailabilityCheckStep;
