import styled from "styled-components";
import { PanelTestIds } from "../../../common/constants/testIds.constant";
import Row from "../../../componentsLibrary/atoms/Row";
import ButtonText from "../../../componentsLibrary/molecules/ButtonText";
import LanguageSelectionButton from "../../../componentsLibrary/molecules/LanguageSelectionButton";
import { useText } from "../../language/language.hook";

const Link = styled(ButtonText)`
  padding: 0 12px;
  height: 16px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
type Props = {
  links?: { text: string; url: string }[];
  selectedLocaleCode?: string;
  onShowStats?: () => void;
  openLanguagePanel: () => void;
  openReportPanel: () => void;
  locales?: string[];
  children?: React.ReactNode;
};

const BottomBar: React.FC<Props> = ({
  links,
  onShowStats,
  selectedLocaleCode,
  openLanguagePanel,
  openReportPanel,
  locales,
  children,
}) => {
  const t = useText();

  return (
    <Wrapper>
      {children}
      <Row
        align="center"
        justify="center"
        testId={PanelTestIds.settings.common.bottomBar}
        flexWrap
        style={{
          padding: "8px 0",
        }}
      >
        <LanguageSelectionButton
          openLanguagePanel={openLanguagePanel}
          locales={locales}
          selectedLocaleCode={selectedLocaleCode}
        />
        <a href="https://journee.live/imprint" target="_blank" rel="noreferrer">
          <Link>{t("corporate_information")}</Link>
        </a>
        <a href="https://journee.live/privacy" target="_blank" rel="noreferrer">
          <Link>{t("platform_privacy_policy")}</Link>
        </a>
        {Boolean(links?.length) &&
          links?.map((link, id) => (
            <a
              key={`link_${id}`}
              href={link.url}
              target="_blank"
              rel="noreferrer"
            >
              <Link>{link.text}</Link>
            </a>
          ))}
        <Link onClick={openReportPanel}>{t("report_button")}</Link>
        {onShowStats && (
          <Link onClick={onShowStats}>{t("show_stats_panel")}</Link>
        )}
      </Row>
    </Wrapper>
  );
};

export default BottomBar;
