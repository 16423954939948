import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  DEFAULT_FEATURE_FLAGS,
  FeatureFlag,
} from "../../common/constants/configs.constant";

export const useDevOptionsContext = () => {
  const context = useContext(DevOptionsContext);
  if (context === undefined) {
    throw new Error(
      "useDevOptionsContext must be used within a DevOptionsProvider"
    );
  }
  return context;
};

export type DevOptionsContextType = {
  featureFlags: Record<FeatureFlag, boolean>;
  setFeatureFlags: (flags: Partial<Record<FeatureFlag, boolean>>) => void;
};

const DevOptionsContext = createContext<DevOptionsContextType | undefined>({
  featureFlags: {
    ...DEFAULT_FEATURE_FLAGS,
  },
  setFeatureFlags: () => {},
});

type Props = {
  children: React.ReactNode;
};

const DevOptionsProvider: React.FC<Props> = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState<
    Record<FeatureFlag, boolean>
  >({
    ...DEFAULT_FEATURE_FLAGS,
  });
  const _setFeatureFlags = useCallback(
    (flags: Partial<Record<FeatureFlag, boolean>>) => {
      setFeatureFlags((prev) => ({
        ...prev,
        ...flags,
      }));
    },
    []
  );

  return (
    <DevOptionsContext.Provider
      value={useMemo(
        () => ({
          featureFlags,
          setFeatureFlags: _setFeatureFlags,
        }),
        [featureFlags, _setFeatureFlags]
      )}
    >
      {children}
    </DevOptionsContext.Provider>
  );
};

export default DevOptionsProvider;
