import { useEnvironmentContext } from "../../../app/EnvironmentDataProvider";
import { useIsLandscape, useIsSmallScreen } from "../../../common/hooks/ui";
import Button from "../../../componentsLibrary/atoms/Button";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";
import { useText } from "../../language/language.hook";
import { getSSOUrl } from "../loginUtils";

type AuthSSOFormProps = {
  error?: React.ReactNode | null;
};

const AuthSSOForm: React.FC<AuthSSOFormProps> = ({ error }) => {
  const t = useText();
  const { environment } = useEnvironmentContext();
  const isSmallScreen = useIsSmallScreen();
  const isLandscape = useIsLandscape();
  const isPortrait = isSmallScreen && !isLandscape;

  return (
    <>
      <Typo.Title>{t("login_sso_title")}</Typo.Title>
      <Space h={2} />
      <Typo.Body
        style={{
          padding: !isSmallScreen ? "0 43px" : "inherit",
          textAlign: !isSmallScreen || isPortrait ? "center" : "inherit",
        }}
      >
        {t("login_sso_description")}
      </Typo.Body>
      {error}
      <Space h={5} />
      {environment.ssoProviders.map((provider, idx) => {
        return (
          <>
            <Button.Primary
              key={provider.id}
              onClick={() => {
                window.analytics?.track("login", {
                  type: "login",
                  name: "sso",
                });
                window.location.href = getSSOUrl(environment.id, provider.id);
              }}
            >
              {t("login_sso_button", { provider: provider.displayName })}
            </Button.Primary>
            {idx < environment.ssoProviders.length - 1 && <Space h={2} />}
          </>
        );
      })}
    </>
  );
};

export default AuthSSOForm;
