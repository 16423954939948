import { css, styled, useTheme } from "styled-components";
import { useAccount, useDisconnect } from "wagmi";
import { useStore } from "../../../app/store";
import { steps } from "../../../app/style/theme";
import { useIsLandscape, useIsSmallScreen } from "../../../common/hooks/ui";
import Button from "../../../componentsLibrary/atoms/Button";
import Column from "../../../componentsLibrary/atoms/Column";
import HeaderBadge from "../../../componentsLibrary/atoms/HeaderBadge";
import { IconDescriptor } from "../../../componentsLibrary/atoms/HeaderBadgeIconConst";
import Icon from "../../../componentsLibrary/atoms/Icon";
import Row from "../../../componentsLibrary/atoms/Row";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";
import { useText } from "../../language/language.hook";

const Content = styled.div<{ $rightPadding?: boolean }>`
  ${(p) =>
    p.$rightPadding &&
    css`
      padding-right: max(var(--safe-margin-right), 64px);
    `}
`;

const PaddedColumn = styled(Column)`
  padding: 0 30px;
  text-align: center;
`;

const WalletAccountBox = styled.div`
  max-height: ${steps.elementHeight.e30};
  display: flex;
  justify-content: center;
  margin: 0 6px;
  padding: 14px 0;
  width: auto;
  border: ${({ theme }) => theme.borderWidth} solid;
  border-color: ${({ theme }) => theme.borderColor};
  border-radius: ${({ theme }) => theme.radiusTiny};
`;

const CheckIcon = styled(Icon.Check)`
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: ${({ theme }) => theme.colorSuccess};
  border-radius: ${steps.borderRadius.b100};
  padding: 5px;
`;

type Props = {
  onClose: () => void;
};

const SettingsWalletConnectUi: React.FC<Props> = ({ onClose }) => {
  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  const t = useText();
  const theme = useTheme();
  const { disconnect } = useDisconnect();
  const isSmallScreen = useIsSmallScreen();
  const isLandscape = useIsLandscape();

  const account = useAccount();

  const icons: IconDescriptor[] = [
    {
      name: "metamask",
      src: "/assets/metamask.webp",
      direction: "deg0",
    },
    {
      name: "rainbow",
      src: "/assets/rainbowWallet.webp",
      direction: "deg45",
    },
    {
      name: "trustWallet",
      src: "/assets/trustWallet.webp",
      direction: "deg180",
    },
    {
      name: "walletConnect",
      src: "/assets/walletConnect.webp",
      direction: "deg135",
    },
  ];

  return (
    <Content $rightPadding={isLandscape && isSmallScreen}>
      <Row height="100%" width="100%" align="center" justify="center">
        <HeaderBadge icons={icons}>
          <>
            {account.isConnected && account.address && (
              <CheckIcon size="16px" color={theme.colorBelow2} />
            )}
            <Icon.Wallet inheritColor size="32px" />
          </>
        </HeaderBadge>
      </Row>
      <Space h={4} />
      {!account.isConnected && !account.address ? (
        <PaddedColumn width="auto" align="center">
          <Typo.Title>{t("settings_connect_wallet_title")}</Typo.Title>
          <Space h={2} />
          <Typo.Body>{t("settings_connect_wallet_description")}</Typo.Body>
          <Space h={6} />
          <Button.Primary
            onClick={() => {
              closePanel("settings/walletconnect");
              openPanel("walletConnect");
            }}
          >
            {t("settings_connect_wallet_cta")}
          </Button.Primary>
          <Space h={4} />
          <Button.Subtle
            onClick={() => {
              onClose();
            }}
          >
            {t("settings_connect_wallet_later_cta")}
          </Button.Subtle>
        </PaddedColumn>
      ) : (
        <>
          <PaddedColumn width="auto" align="center">
            <Typo.Title>
              {t("settings_connect_wallet_connected_title")}
            </Typo.Title>
            {/* 24px */}
            <Space h={5} />
          </PaddedColumn>
          <WalletAccountBox>
            <Typo.Label
              size={isSmallScreen ? steps.font.f10.size : steps.font.f20.size}
              weight="normal"
              fontFamily="Courier"
            >
              {account.address}
            </Typo.Label>
          </WalletAccountBox>
          {/* 24px */}
          <PaddedColumn width="auto" align="center">
            <Space h={5} />
            <Button.Primary onClick={() => disconnect()}>
              {t("settings_connect_wallet_disconnect_cta")}
            </Button.Primary>
            <Space h={8} />
          </PaddedColumn>
        </>
      )}
    </Content>
  );
};

export default SettingsWalletConnectUi;
