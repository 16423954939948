import { SvgProps } from "../../types/svg";

const CloudFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.125 11.7188C16.1079 11.7188 16.0942 11.7255 16.0806 11.7255C16.1113 11.5845 16.125 11.4395 16.125 11.2891C16.125 10.1021 15.1461 9.14062 13.9375 9.14062C13.2643 9.14062 12.6729 9.44275 12.2695 9.91272C11.7609 8.94995 10.7437 8.28125 9.5625 8.28125C7.86992 8.28125 6.5 9.62671 6.5 11.2891C6.5 11.4838 6.52051 11.6718 6.55811 11.8597C5.51016 12.1968 4.75 13.1555 4.75 14.2969C4.75 15.7202 5.92578 16.875 7.375 16.875H16.125C17.5742 16.875 18.75 15.7202 18.75 14.2969C18.75 12.8735 17.5742 11.7188 16.125 11.7188ZM9.5625 7.42188C10.6768 7.42188 11.7125 7.88513 12.4445 8.67065C12.7146 8.51959 13.0051 8.41888 13.3091 8.3551C13.4234 8.06372 13.5 7.75488 13.5 7.42188C13.5 5.99854 12.3242 4.84375 10.875 4.84375C10.4889 4.84375 10.1263 4.93103 9.79492 5.07874C9.36016 3.93738 8.25 3.125 6.9375 3.125C5.39531 3.125 4.12656 4.24971 3.9193 5.70984C3.90563 5.70984 3.89195 5.70312 3.875 5.70312C2.42578 5.70312 1.25 6.85791 1.25 8.28125C1.25 9.70459 2.42578 10.8594 3.875 10.8594H5.6693C5.89297 8.93115 7.5418 7.42188 9.5625 7.42188Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default CloudFilledIcon;
