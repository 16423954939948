import { SliceCreator } from "../../app/store";

export type UiState = {
  fullscreen: {
    isFullscreen: boolean;
    toggleFullscreenFlag: () => void;
  };
};

type State = {
  ui: UiState;
};

export const createUiSlice: SliceCreator<State> = (set) => ({
  ui: {
    fullscreen: {
      isFullscreen: false,
      toggleFullscreenFlag: () =>
        set((state) => {
          state.ui.fullscreen.isFullscreen = !state.ui.fullscreen.isFullscreen;
        }),
    },
  },
});
