import { ReactNode, useMemo } from "react";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { useStore } from "../../app/store";
import ActionElementsUi from "./ActionElements.ui";
import ActionElementButton from "./components/ActionElementButton";
import ActionElementIconButton from "./components/ActionElementIconButton";
import ActionElementIconMenu from "./components/ActionElementIconMenu";
import ActionElementImageMenu from "./components/ActionElementImageMenu";

const ActionElementsLogic: React.FC = () => {
  const { environment } = useEnvironmentContext();
  const actionElementsState = useStore(
    (s) => s.actionElements.actionElementsState
  );
  const { topCenterElements, topRightElements, bottomCenterElements } =
    useMemo(() => {
      const topCenterElements: ReactNode[] = [];
      const topRightElements: ReactNode[] = [];
      const bottomCenterElements: ReactNode[] = [];
      // Sort action elements by order
      const actionElements = [...environment.actionElements].sort(
        (a, b) => a.order - b.order
      );
      for (const actionElement of actionElements) {
        const state = actionElementsState[actionElement.actionElementId];
        // Skip hidden elements
        if (state?.hidden) continue;

        let element: ReactNode;
        switch (actionElement.type) {
          case "image-menu":
            element = (
              <ActionElementImageMenu
                key={actionElement.actionElementId}
                actionElementConfig={actionElement}
                state={state}
              />
            );
            break;
          case "button":
            element = (
              <ActionElementButton
                key={actionElement.actionElementId}
                actionElementConfig={actionElement}
              />
            );
            break;
          case "icon-button":
            element = (
              <ActionElementIconButton
                key={actionElement.actionElementId}
                actionElementConfig={actionElement}
              />
            );
            break;
          case "icon-menu":
            element = (
              <ActionElementIconMenu
                key={actionElement.actionElementId}
                actionElementConfig={actionElement}
                state={state}
              />
            );
            break;
          default:
            break;
        }
        // Skip elements that are not buttons or selectors
        if (!element) continue;
        if (actionElement.position === "top-center") {
          topCenterElements.push(element);
        } else if (actionElement.position === "top-right") {
          topRightElements.push(element);
        } else if (actionElement.position === "bottom-center") {
          bottomCenterElements.push(element);
        }
      }
      return { topCenterElements, topRightElements, bottomCenterElements };
    }, [environment.actionElements, actionElementsState]);

  return (
    <ActionElementsUi
      topCenterElements={topCenterElements}
      topRightElements={topRightElements}
      bottomCenterElements={bottomCenterElements}
    />
  );
};

export default ActionElementsLogic;
