import { SvgProps } from "../../types/svg";

const SeatFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 4.488c0 .203.041.403.121.59L2.517 6H10a.999.999 0 1 1 0 2H3.347l.456 1H13a1 1 0 0 1 .8 1.6l-1.35 1.8a1.5 1.5 0 0 1-1.2.6h-3v1.5h3c.416 0 .75.334.75.75s-.334.75-.75.75h-7.5a.75.75 0 0 1-.75-.75c0-.416.334-.75.75-.75h3V13H5.319a3 3 0 0 1-2.758-1.819L.283 5.866A3.5 3.5 0 0 1 0 4.488V1a1 1 0 0 1 2 0z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default SeatFilledIcon;
