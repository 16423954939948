import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useEnvironmentContext } from "../../../app/EnvironmentDataProvider";
import { getQueryStrings } from "../../../app/routing/routingUtils";
import { useStore } from "../../../app/store";
import { DEFAULT_LOCALE_CODE } from "../lib/i18nInit";
import { Locale, Locales } from "../lib/locales.constant";

type Props = {
  children?: ReactNode;
};

const LanguageProvider: React.FC<Props> = ({ children }) => {
  const { i18n, ready } = useTranslation();
  const { environment } = useEnvironmentContext();
  const { lang: queryRequestedLocale } = getQueryStrings();
  const { selectedLocaleCode, setSelectedLocaleCode } = useStore((s) => s.i18n);
  const browserPreferredLocale = getBrowserPreferredLocale();
  const desiredLocale =
    queryRequestedLocale ?? selectedLocaleCode ?? browserPreferredLocale;

  useEffect(() => {
    if (!ready) return;
    if (!environment) return;
    (async () => {
      const locale = validateLocale(desiredLocale, environment?.locales);

      setSelectedLocaleCode(locale);
      await i18n.changeLanguage(locale);
    })();
  }, [
    desiredLocale,
    setSelectedLocaleCode,
    ready,
    environment,
    i18n.changeLanguage,
    i18n,
  ]);

  return children;
};

export const validateLocale = (
  unsafeLocale: string | undefined | null,
  enabledLocales: string[]
): Locale => {
  if (!enabledLocales.length) return DEFAULT_LOCALE_CODE;
  if (unsafeLocale) {
    // check for an exact match
    if (enabledLocales.includes(unsafeLocale)) return unsafeLocale as Locale;
    // is an input is a locale code in a form like en-CA or en_CA
    const safeLocaleMatch = unsafeLocale.match(/([\w]{2})([-_]([\w]{2}))?/);
    if (safeLocaleMatch && safeLocaleMatch.length > 1) {
      // check for a default translation for a language without a country
      const safeLocale = safeLocaleMatch[1];
      if (enabledLocales.includes(safeLocale)) return safeLocale as Locale;
      // check for a locale with the same language, but different country
      for (const locale of enabledLocales) {
        if (locale.startsWith(`${safeLocale}-`)) return locale as Locale;
      }
    }
  }
  // if locale isn't enabled, then return a default locale for the environment
  return enabledLocales[0] as Locale;
};

export const getBrowserPreferredLocale = (): string | null => {
  for (const browserLocale of navigator.languages) {
    if (browserLocale in Locales) return browserLocale;
    else {
      // check for a default translation for a language without a country
      const languageCode = browserLocale.substring(0, 2);
      if (languageCode in Locales) return languageCode;
      // check for a locale with the same language, but different country
      for (const locale in Locales) {
        if (locale.startsWith(`${languageCode}-`)) return locale;
      }
    }
  }
  return null;
};

export default LanguageProvider;
