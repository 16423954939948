import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useEnvironmentContext } from "../../../../app/EnvironmentDataProvider";
import { useStore } from "../../../../app/store";
import Transition from "../../../../componentsLibrary/atoms/Transition";
import { useVideoConferenceControlsContext } from "../../../videoConference/providers/VideoConference.provider";
import { VideoConferenceState } from "../../../videoConference/types/videoConference.types";
import SettingsVideoPageUi from "./SettingsVideoPage.ui";
import SettingsVideoPreviewLogic from "./SettingsVideoPreview.logic";

type Props = {
  onGoBack: () => void;
};

const StyledTransition = styled(Transition)`
  /** Style Transition's AnimatedChild */
  & .transition-entered {
    block-size: unset;
  }
`;

const SettingsVideoPageLogic: React.FC<Props> = ({ onGoBack }) => {
  const {
    environment: { voiceVideoReactions },
  } = useEnvironmentContext();
  const videoConferenceState = useStore((s) => s.videoConference.state);
  const { goToDevicePreview, joinConference } =
    useVideoConferenceControlsContext();
  const joinedVideoConference =
    videoConferenceState === VideoConferenceState.CONFERENCE_PREVIEW;
  const onReloadPage = () => window.location.reload();
  const conferenceInitialized = useStore((s) =>
    s.videoConference.isConferenceInitialized()
  );
  const [changeHeight, setChangeHeight] = useState(joinedVideoConference);
  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);

  let content;

  useEffect(() => {
    if (voiceVideoReactions) {
      if (videoConferenceState === VideoConferenceState.JOINED) {
        openPanel("actionBar/reactionsBar");
      } else {
        closePanel("actionBar/reactionsBar");
      }
    }
  }, [videoConferenceState, closePanel, openPanel, voiceVideoReactions]);

  if (
    videoConferenceState === VideoConferenceState.CONFERENCE_PREVIEW ||
    videoConferenceState === VideoConferenceState.JOINED
  ) {
    content = <SettingsVideoPreviewLogic />;
  } else {
    content = (
      <SettingsVideoPageUi
        videoConferenceState={videoConferenceState}
        onEnableLater={onGoBack}
        onReloadPage={onReloadPage}
        onJoinAsListener={joinConference}
        onAskForPermission={goToDevicePreview}
      />
    );
  }

  // Delay the height change for better transition
  useEffect(() => {
    setTimeout(() => {
      setChangeHeight(conferenceInitialized || joinedVideoConference);
    }, 300);
  }, [conferenceInitialized, joinedVideoConference]);

  return (
    <StyledTransition
      height={(changeHeight ? 530 : 340) + "px"}
      fade
      watch={[joinedVideoConference, videoConferenceState]}
    >
      {content}
    </StyledTransition>
  );
};

export default SettingsVideoPageLogic;
