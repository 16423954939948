import { useRef, useState } from "react";
import { GyaradosAxios } from "@journee-live/gyarados";
import * as Sentry from "@sentry/react";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { Errors } from "../../app/monitoring/tracking/errors";
import { useStore } from "../../app/store";
import { useVideoConferenceControlsContext } from "../videoConference/providers/VideoConference.provider";
import { getSanitizedSnapshot } from "../videoConference/utils/stateMachineSnapshot";
import ReportUi from "./Report.ui";
import { useVisitorReport } from "./hooks/reportHooks";
import { getLocalISOString } from "./lib/reportUtils";

const ReportLogic: React.FC = () => {
  const { environment } = useEnvironmentContext();
  const isConferenceEnabled = environment.voiceVideoOn;
  const visitorToken = useStore((s) => s.session.visitorToken);
  const report = useVisitorReport();
  const formRef = useRef<HTMLFormElement>(null);
  const closePanel = useStore((s) => s.layout.closePanel);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { machineRef } = useVideoConferenceControlsContext();

  const visitorReportsApi = new GyaradosAxios.VisitorReportsApi(
    undefined,
    import.meta.env.VITE_GYARADOS_URL
  );

  const handleSubmit = async () => {
    setIsLoading(true);
    const formData = new FormData(formRef.current || undefined);
    const issues = formData.getAll("issue") as string[];
    const comment = formData.get("comment") as string;
    const rating = parseInt(formData.get("rating") as string) || 0;
    if (issues.includes("issue-video-conference")) {
      Sentry.captureException(new Error(Errors.VIDEO_CONFERENCE_ERROR), {
        extra: {
          videoConferenceSnapshot: getSanitizedSnapshot(machineRef),
          environmentId: environment?.id,
        },
      });
    }
    if (visitorToken && environment?.id) {
      try {
        await visitorReportsApi.visitorReportsControllerCreate(
          {
            environmentId: environment.id,
            body: {
              timestamp: getLocalISOString(new Date()),
              ...report,
              rating,
              issues,
              comment,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${visitorToken}`,
            },
          }
        );
        closePanel("report");
        setError(null);
      } catch (error) {
        // TODO: Add types for AxiosError
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setError((error as any)?.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <ReportUi
      formRef={formRef}
      isLoading={isLoading}
      error={error}
      onSubmit={handleSubmit}
      onClose={() => closePanel("report")}
      isConferenceEnabled={isConferenceEnabled}
    />
  );
};

export default ReportLogic;
