import { Form } from "@radix-ui/react-form";
import Checkbox from "../../componentsLibrary/atoms/Checkbox";
import Column from "../../componentsLibrary/atoms/Column";
import InputText from "../../componentsLibrary/atoms/InputText";
import { useText } from "../language/language.hook";

type Props = {
  formRef: React.RefObject<HTMLFormElement>;
  isConferenceEnabled: boolean;
};

const ReportForm: React.FC<Props> = ({ formRef, isConferenceEnabled }) => {
  const t = useText();

  return (
    <Form ref={formRef}>
      <Column gap={2} align="stretch">
        <Checkbox
          name="issue"
          value="issue-video"
          label={t("report_issue_video")}
          checked={false}
        />
        <Checkbox
          name="issue"
          value="issue-input-lag"
          label={t("report_issue_input_lag")}
          checked={false}
        />
        {isConferenceEnabled && (
          <Checkbox
            value="issue-video-conference"
            name="issue"
            checked={false}
            label={t("report_issue_video_chat")}
          />
        )}
        <Checkbox
          name="issue"
          value="issue-video-freezes"
          label={t("report_issue_video_freezes")}
          checked={false}
        />
        <Checkbox
          name="issue"
          value="issue-bug"
          label={t("report_issue_bug")}
          checked={false}
        />
        <InputText name="comment" label={t("report_comment")} textarea />
      </Column>
    </Form>
  );
};

export default ReportForm;
