import FullscreenPanZoom from "../../componentsLibrary/atoms/FullscreenPanZoom";
import { Props } from "./Map.ui";
import MapCardUiLandscape from "./components/MapCard.ui.landscape";
import MapCardUiPreview from "./components/MapCard.ui.preview";
import MapPointUi from "./components/MapPoint.ui";

const MapUiLandscape: React.FC<Props> = ({
  onClose,
  preloadedImage,
  points,
  onSelectPoint,
  selectedPoint,
  activeRegion,
  showCard,
  onCloseCard,
  onTeleport,
  previewMode,
  setPreviewMode,
}) => {
  const cardProps = {
    selectedPoint,
    isCurrentLocation: activeRegion === selectedPoint.regionId,
    onClose: onCloseCard,
    onTeleport,
  };

  return (
    <>
      <FullscreenPanZoom
        image={preloadedImage}
        onClose={onClose}
        minScale={0.4}
        initialZoomedId={activeRegion}
      >
        {points.map((point) => (
          <MapPointUi
            key={point.name}
            point={point}
            selectedPoint={selectedPoint}
            activeRegion={activeRegion}
            onSelectPoint={onSelectPoint}
            onTeleport={() => onTeleport(selectedPoint)}
            isSmallScreen
          />
        ))}
      </FullscreenPanZoom>
      <MapCardUiPreview
        {...cardProps}
        onExpandClick={() => setPreviewMode?.(false)}
        isLandscape
        show={showCard && previewMode}
      />
      <MapCardUiLandscape {...cardProps} show={showCard && !previewMode} />
    </>
  );
};

export default MapUiLandscape;
