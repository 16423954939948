import { styled, useTheme } from "styled-components";
import { PanelTestIds } from "../../common/constants/testIds.constant";
import ColorPicker from "../../componentsLibrary/atoms/ColorPicker";
import Column from "../../componentsLibrary/atoms/Column";
import HeaderBadge from "../../componentsLibrary/atoms/HeaderBadge";
import Hide from "../../componentsLibrary/atoms/Hide";
import Icon from "../../componentsLibrary/atoms/Icon";
import Row from "../../componentsLibrary/atoms/Row";
import Space from "../../componentsLibrary/atoms/Space";
import Spatial from "../../componentsLibrary/atoms/Spatial";
import Typo from "../../componentsLibrary/atoms/Typo";
import Card from "../../componentsLibrary/molecules/Card";
import { useText } from "../language/language.hook";
import { Props } from "./Profile.ui";
import AvatarSelectorUi from "./component/AvatarSelectorUi";
import ProfileBottomSection from "./component/ProfileBottomSection";
import ProfileFormFields from "./component/ProfileFormFields";
import ProfileTitle from "./component/ProfileTitle";
import ReadyPlayerMeEditor from "./component/ReadyPlayerMeEditor";
import { PROFILE_MAX_HEIGHT } from "./lib/constants";

const ColorPickerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-280px - 50%), -50%);
  z-index: 1;
`;

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  pointer-events: none;
  > * {
    pointer-events: all;
  }
`;

const BottomWrapper = styled.div`
  position: fixed;
  bottom: 48px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const ProfilePanelUiNormal: React.FC<Props> = ({
  data,
  settings,
  onChange,
  avatarsOptions = [],
  isReadyPlayerMeEditorOpen,
  setIsReadyPlayerMeEditorOpen,
  setCustomAvatarUrl,
  setHasConfirmed,
  hasConfirmed,
  hasReadyPlayerMe,
  isMissingAvatar,
}) => {
  const t = useText();
  const theme = useTheme();
  const isRpmOnly =
    avatarsOptions.length === 1 &&
    avatarsOptions[0].avatarId === "readyplayerme";
  const showAvatarSelector = isRpmOnly || avatarsOptions.length > 1;
  const padding = `0 ${isRpmOnly ? 43 : 7}px`;

  const currentAvatar = avatarsOptions.find(
    (options) => options.avatarId === data.avatarId
  );
  const showAvatarColorPicker =
    settings.fields.has("avatarColor") && !currentAvatar?.singleColor;

  return (
    <Wrapper>
      <Spatial rim glare mouseZoom mouseTiltX mouseTiltY dragMotion>
        <Card
          testId={PanelTestIds.profile.normal.root}
          maxHeight={PROFILE_MAX_HEIGHT + "px"}
          height={
            isReadyPlayerMeEditorOpen ? PROFILE_MAX_HEIGHT + "px" : undefined
          }
          hideScrollButton
          hideScrollSpace
        >
          {isReadyPlayerMeEditorOpen ? null : (
            <>
              <Space h={8} />
              <Row width="100%" align="center" justify="center">
                <HeaderBadge>
                  <Icon.Bust inheritColor size="40px" />
                </HeaderBadge>
              </Row>
            </>
          )}
          <Column
            align="center"
            justify="flex-end"
            style={{
              padding,
              boxSizing: "border-box",
              userSelect: "none",
              WebkitUserSelect: "none",
            }}
          >
            {hasReadyPlayerMe && (
              <ReadyPlayerMeEditor
                setCustomAvatarUrl={setCustomAvatarUrl}
                closeRpmEditor={() => setIsReadyPlayerMeEditorOpen(false)}
                customAvatarUrl={data.customAvatarUrl}
                open={isReadyPlayerMeEditorOpen}
              />
            )}
            {!isReadyPlayerMeEditorOpen && (
              <>
                <ProfileTitle avatarsOptions={avatarsOptions} />
                <ProfileFormFields
                  data={data}
                  onChange={onChange}
                  settings={settings}
                  hasConfirmed={hasConfirmed}
                />
                {showAvatarSelector && (
                  <>
                    <AvatarSelectorUi
                      initialAvatarId={data.avatarId}
                      options={avatarsOptions}
                      onSelect={(value) => onChange("avatarId", value)}
                      openReadyPlayerMeEditor={() =>
                        setIsReadyPlayerMeEditorOpen(true)
                      }
                    />
                    <Space h={4} />
                    {isMissingAvatar && (
                      <Typo.Body
                        color={theme.colorDanger}
                        testId={PanelTestIds.profile.common.avatarErrorMessage}
                      >
                        {t("profile_rpm_avatar_not_selected")}
                      </Typo.Body>
                    )}
                  </>
                )}
              </>
            )}
            <Space h={10} />
            <Space h={10} />
            <BottomWrapper>
              <ProfileBottomSection
                isReadyPlayerMeEditorOpen={isReadyPlayerMeEditorOpen}
                setIsReadyPlayerMeEditorOpen={setIsReadyPlayerMeEditorOpen}
                setHasConfirmed={setHasConfirmed}
              />
            </BottomWrapper>
          </Column>
        </Card>
      </Spatial>
      {
        <ColorPickerWrapper>
          <Hide hide={!showAvatarColorPicker}>
            <ColorPicker
              bright
              name="avatarColor"
              direction="vertical"
              color={data.avatarColor}
              onChange={(value) => {
                onChange("avatarColor", value);
              }}
            />
          </Hide>
        </ColorPickerWrapper>
      }
    </Wrapper>
  );
};

export default ProfilePanelUiNormal;
