import { useEffect, useMemo } from "react";
import { Shared } from "@journee-live/mew";
import { useEnvironmentContext } from "../../../app/EnvironmentDataProvider";
import { useStore } from "../../../app/store";
import { useText } from "../../language/language.hook";
import { PropsLoginPageUi } from "../LoginPageUi";
import { useValidateOpeningTimes } from "../loginHooks";
import { isValidVisitorTokenData } from "../loginUtils";

const useOpeningTimesCheckStep = (
  setLoginPageProps: (props: PropsLoginPageUi) => void
) => {
  const { environmentId } = useEnvironmentContext();
  const currentStep = useStore((s) => s.userFlow.currentStep);
  const completeStep = useStore((s) => s.userFlow.completeStep);
  const visitorTokenData = useStore((s) => s.session.visitorTokenData);
  const t = useText();

  const { isOpen, nextEvent, previousEvent } = useValidateOpeningTimes();
  // If the experience is closed, we check if the visitor is a moderator or admin and can access outside of opening times.
  const canAccessAsAModerator = useMemo(() => {
    if (!isValidVisitorTokenData(visitorTokenData, environmentId)) return false;
    // If the visitor has token and his role isn't of a visitor he is either moderator or admin.
    return visitorTokenData.rol !== Shared.VisitorRoles.VISITOR;
  }, [visitorTokenData, environmentId]);

  useEffect(() => {
    if (currentStep !== "login:check-opening-times") return;
    if (isOpen) {
      completeStep("login:check-opening-times");
      window.analytics?.track("flow", {
        type: "flow",
        name: "enabled",
      });
    }
  }, [isOpen, completeStep, currentStep]);

  let text = "";
  if (nextEvent)
    text = t("opening_times_next_started_at", {
      time:
        new Date(nextEvent.from).toLocaleDateString() +
        " - " +
        new Date(nextEvent.from).toLocaleTimeString(),
    });
  else if (previousEvent)
    text = t("opening_times_ended_at", {
      time:
        new Date(previousEvent.to).toLocaleDateString() +
        " - " +
        new Date(previousEvent.to).toLocaleTimeString(),
    });
  useEffect(() => {
    const handleOpen = () => {
      if (!canAccessAsAModerator) return;
      completeStep("login:check-opening-times");
      window.analytics?.track("flow", {
        type: "flow",
        name: "enabled",
      });
    };
    if (currentStep !== "login:check-opening-times") return;
    if (isOpen) return;
    setLoginPageProps({
      title: "",
      description: text,
      isMainButtonVisible: false,
      isSecondaryButtonVisible: canAccessAsAModerator,
      secondaryButtonLabel: t("opening_times_enter_as_moderator"),
      onSecondaryButtonClick: handleOpen,
    });
  }, [
    canAccessAsAModerator,
    completeStep,
    currentStep,
    isOpen,
    setLoginPageProps,
    t,
    text,
  ]);
};

export default useOpeningTimesCheckStep;
