import { SvgProps } from "../../types/svg";

const MoonFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.384 13.325a4.694 4.694 0 0 1-5.578-4.612 4.71 4.71 0 0 1 2.375-4.091.28.28 0 0 0-.087-.52C3.397 3.42 0 6.25 0 10c0 3.313 2.68 6 5.994 6a5.96 5.96 0 0 0 4.653-2.22c.169-.202-.013-.505-.263-.455M9 1.972l.388.93c.02.039.07.07.112.07a.14.14 0 0 0 .112-.07l.388-.93.93-.388c.039.009.07-.041.07-.112a.14.14 0 0 0-.07-.112L10 .972l-.388-.93a.14.14 0 0 0-.112-.07.14.14 0 0 0-.112.07L9 .971l-.931.416a.14.14 0 0 0-.07.112.14.14 0 0 0 .07.112zm6.863 4.806L14 6l-.777-1.863c-.042-.08-.13-.165-.223-.165a.25.25 0 0 0-.223.137L12 6l-1.863.778A.26.26 0 0 0 10 7c0 .094.055.18.137.223L12 8l.777 1.863A.25.25 0 0 0 13 10c.094 0 .18-.055.223-.137L14 8l1.863-.777a.249.249 0 0 0 0-.445"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default MoonFilledIcon;
