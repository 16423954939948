import { ReactNode } from "react";
import { useReflow } from "../../../common/hooks/ui";
import { PanelName } from "../../_layout/panels.types";
import MediaShareUiNormal from "./MediaShare.ui.normal";
import MediaShareUiSmall from "./MediaShare.ui.small";
import { ShareButtonData } from "./components/ShareButton";

export type Props = {
  shareButtons?: ShareButtonData[];
  preview?: ReactNode;
  isLoading?: boolean;
  warningMessage?: string | null;
  errorMessage?: string | null;
  infoMessage?: string | null;
  onClose?: () => void;
  onBack?: () => void;
  previousPanel?: PanelName;
  hideTabs?: boolean;
};

const MediaShareUi: React.FC<Props> = (props) => {
  const render = useReflow({
    normal: <MediaShareUiNormal {...props} />,
    small: <MediaShareUiSmall {...props} />,
  });

  return render;
};

export default MediaShareUi;
