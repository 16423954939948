import { useTheme } from "styled-components";
import { steps } from "../../../../app/style/theme";
import { PanelTestIds } from "../../../../common/constants/testIds.constant";
import Button from "../../../../componentsLibrary/atoms/Button";
import Column from "../../../../componentsLibrary/atoms/Column";
import HeaderBadge from "../../../../componentsLibrary/atoms/HeaderBadge";
import Icon from "../../../../componentsLibrary/atoms/Icon";
import Row from "../../../../componentsLibrary/atoms/Row";
import Space from "../../../../componentsLibrary/atoms/Space";
import Typo from "../../../../componentsLibrary/atoms/Typo";
import { useText } from "../../../language/language.hook";
import { VideoConferenceState } from "../../../videoConference/types/videoConference.types";
import { Props } from "./SettingsVideoPage.ui";

const SettingsVideoPageNormal: React.FC<Props> = ({
  onAskForPermission,
  onEnableLater,
  videoConferenceState,
}) => {
  const t = useText();
  const theme = useTheme();
  const isLoading =
    videoConferenceState !== VideoConferenceState.SESSION_INITIALIZED;

  let buttonText;
  switch (videoConferenceState) {
    case VideoConferenceState.PERMISSION_SETUP:
      buttonText = t("settings_video_media_permissions_awaiting");
      break;
    case VideoConferenceState.DEVICE_SETUP:
      buttonText = t("settings_video_media_permissions_awaiting");
      break;
    default:
      buttonText = t("settings_device_preview");
      break;
  }

  return (
    <Column
      justify="center"
      align="center"
      testId={PanelTestIds.settings.normal.videoConferenceLandingPanel}
    >
      <Row height="100%" width="100%" align="center" justify="center">
        <HeaderBadge color={theme.colorGradient.purpleBlue.value}>
          <Icon.VideoOnThin color={theme.colorBelow1} size="40px" />
        </HeaderBadge>
      </Row>
      <Space h={4} />
      <Column align="center">
        <Typo.Title align="center">{t("settings_video_chat_title")}</Typo.Title>
        <Space h={2} />
        <Typo.Body align="center" style={{ padding: `0 ${steps.spacing[8]}` }}>
          {t("settings_video_chat_description")}
        </Typo.Body>
      </Column>
      <Space h={6} />
      <Button.Primary
        onClick={onAskForPermission}
        disabled={isLoading}
        iconLeft={isLoading && <Icon.SpinningAnimated size={"16px"} />}
        testId={PanelTestIds.settings.common.prepareToJoinButton}
      >
        {buttonText}
      </Button.Primary>
      <Space h={3} />
      <Button.Subtle onClick={onEnableLater}>
        <Typo.Label weight="normal" align="center">
          {t("settings_enable_later")}
        </Typo.Label>
      </Button.Subtle>
    </Column>
  );
};

export default SettingsVideoPageNormal;
