import { memo } from "react";
import styled from "styled-components";
import { defaultTheme } from "../../app/style/theme";
import JourneeLogo from "../../assets/svg/JourneeLogo";
import Button from "../../componentsLibrary/atoms/Button";
import Column from "../../componentsLibrary/atoms/Column";
import HeaderBadge from "../../componentsLibrary/atoms/HeaderBadge";
import Icon from "../../componentsLibrary/atoms/Icon";
import Space from "../../componentsLibrary/atoms/Space";
import Typo from "../../componentsLibrary/atoms/Typo";
import ButtonText from "../../componentsLibrary/molecules/ButtonText";
import { useText } from "../language/language.hook";
import SpacePageUi from "./SpacePageUi";

const JourneeLink = styled.a`
  position: absolute;
  top: 25px;
`;

export type SocketDisconnectPageProps = {
  title?: string;
  description?: string;
  onReportButtonClick?: () => void;
  children?: React.ReactNode;
};

const SocketDisconnectPageUi: React.FC<SocketDisconnectPageProps> = ({
  title,
  description,
  onReportButtonClick,
  children,
}) => {
  const t = useText();
  return (
    <SpacePageUi>
      <JourneeLink href="https://www.journee.live">
        <JourneeLogo />
      </JourneeLink>
      {children}
      <Column
        justify="center"
        align="center"
        gap={4}
        width="600px"
        textAlign="center"
      >
        <HeaderBadge
          rippleLineWidth={5}
          noBackground
          rippleColor={defaultTheme.colorAboveBrand}
        >
          <Icon.Warning color={defaultTheme.colorAboveBrand} size="60px" />
        </HeaderBadge>
        <Typo.Title color={defaultTheme.colorAboveBrand}>
          {title ?? t("socket_disconnect_title_default")}
        </Typo.Title>
        <Typo.Body color={defaultTheme.colorAboveBrand} size="17px">
          {description ?? t("socket_disconnect_description_default")}
        </Typo.Body>

        <div style={{ position: "absolute", bottom: "0px" }}>
          <Button.Bright onClick={() => window.location.reload()}>
            {t("queue_entering_slow_retry")}
          </Button.Bright>

          <Space h={8} />
          {onReportButtonClick && (
            <ButtonText bright onClick={onReportButtonClick}>
              {t("report_button")}
            </ButtonText>
          )}
          <Space h={4} />
        </div>
      </Column>
    </SpacePageUi>
  );
};

export default memo(SocketDisconnectPageUi);
