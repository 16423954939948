import { useCallback, useEffect, useRef, useState } from "react";
import { css, styled, useTheme } from "styled-components";
import { useStore } from "../../app/store";
import { steps, zIndex } from "../../app/style/theme";
import { curtainDownPositionSeconds } from "../../common/constants/configs.constant";
import { isIos } from "../../common/constants/flags.constant";
import { monitorAutoplayFailures } from "../../common/hooks/useCanPlayVideoHook";
import useLatest from "../../common/hooks/useLatest";
import { grayscaleInvert } from "../../common/util/color";
import Button from "../../componentsLibrary/atoms/Button";
import Hide from "../../componentsLibrary/atoms/Hide";
import Icon from "../../componentsLibrary/atoms/Icon";
import Space from "../../componentsLibrary/atoms/Space";
import Typo from "../../componentsLibrary/atoms/Typo";
import { useVideoPlayerContext } from "../_experience/VideoPlayer.provider";
import { useText } from "../language/language.hook";
import { Props } from "./FullscreenVideo.ui";
import { hasAudio } from "./FullscreenVideo.utils";
import useVideoPlayerListeners from "./hooks/useVideoPlayerListeners";

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 24px;
  left: 24px;
`;

const VideoBackgroundCurtain = styled.div<{
  $iOS: boolean;
  $isCurtainLifted: boolean;
}>`
  inline-size: 100vw;
  block-size: 100dvh;
  box-sizing: border-box;
  background-color: black;
  z-index: ${(p) =>
    p.$isCurtainLifted ? zIndex.background : zIndex.overExperience};
  ${(p) =>
    p.$iOS &&
    css`
      position: relative;
      left: calc(0px - env(safe-area-inset-left));
    `}
`;

const VideoContainer = styled.div<{ $isContained?: boolean }>`
  inline-size: 100vw;
  block-size: 100dvh;
  box-sizing: border-box;
  & video {
    inline-size: 100%;
    block-size: 100%;

    min-inline-size: 50px;
    min-block-size: 50px;
    object-fit: ${(p) => (p.$isContained ? "contain" : "cover")};

    &::-webkit-media-controls-start-playback-button {
      display: none;
    }
    &::-webkit-media-controls {
      display: none;
    }
  }
`;

const Img = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: ${zIndex.background};
`;

const CenterContainer = styled.div<{ $bottom?: number }>`
  position: absolute;
  left: 50%;
  ${(p) =>
    p.$bottom
      ? css`
          inset-block-end: ${p.$bottom}px;
        `
      : css`
          inset-block-start: 50%;
        `};
  transform: translate(-50%, -50%);
  z-index: ${zIndex.popupMenues};
`;

const Fullscreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const SkipButtonWrapper = styled.div`
  position: absolute;
  z-index: ${zIndex.extras};
  left: 0;
  bottom: ${steps.spacing[9]};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

/**
 * TODO: The stream should be stopped when the video is playing, to save bandwidth and don't overlap the audio.
 */
const FullscreenVideoUiNormal: React.FC<Props> = ({
  isGameMuted,
  videoURL,
  backgroundImageUrl,
  showingPlayer,
  showSkipButton,
  onSkipClick,
  onError,
  isContained,
  handleVideoEnded,
}) => {
  const theme = useTheme();
  const { VideoPlayer } = useVideoPlayerContext();
  if (!VideoPlayer) {
    throw new Error("Video Player is not defined");
  }

  const t = useText();
  const [videoContainer, setVideoContainer] = useState<HTMLDivElement | null>(
    null
  );
  const [videoCannotPlay, setVideoCannotPlay] = useState<boolean>(false);
  const [videoIsLoading, setVideoIsLoading] = useState<boolean>(false);
  const [hasLoadedData, setHasLoadedData] = useState<boolean>(false);
  const [videoIsMuted, setVideoIsMuted] = useState<boolean>(
    Boolean(isGameMuted)
  );
  const [hasEndedOnce, setHasEndedOnce] = useState<boolean>(false);
  const [isCurtainLifted, setIsCurtainLifted] = useState<boolean>(false);
  const setVideoIsEnding = useStore(
    (s) => s.loadLevelController.setVideoIsEnding
  );
  const setVideoHasEndedOnce = useStore(
    (s) => s.loadLevelController.setVideoHasEndedOnce
  );
  const setVideoError = useStore((s) => s.loadLevelController.setVideoError);
  VideoPlayer.muted = videoIsMuted;
  const isLevelVideoControllerActive = useStore(
    (s) => s.loadLevelController.levelIsLoading
  );
  const [hideMuteButton, setHideMuteButton] = useState(false);

  const curtainLifter = useRef<number | undefined>(undefined);

  useEffect(() => {
    return () => handleVideoEnded();
  }, [handleVideoEnded]);

  useEffect(() => {
    if (!showingPlayer) return;
    if (isCurtainLifted) return;

    curtainLifter.current = window.setTimeout(() => {
      setIsCurtainLifted(true);
    }, curtainDownPositionSeconds * 1000);
  }, [showingPlayer, isCurtainLifted]);

  const onVideoPlayerError = useCallback(() => {
    setVideoCannotPlay(true);
    setVideoIsLoading(false);
    if (!isLevelVideoControllerActive) {
      onError?.();
    }
  }, [
    isLevelVideoControllerActive,
    setVideoCannotPlay,
    onError,
    setVideoIsLoading,
  ]);
  const latestOnError = useLatest(onVideoPlayerError);

  // Inform the legacy handler that the video has ended
  const onVideoLoop = useCallback(() => {
    setVideoIsEnding(false);
  }, [setVideoIsEnding]);

  // Monitor For stalled videos.
  useEffect(() => {
    if (!VideoPlayer) return;
    const monitoring = monitorAutoplayFailures(VideoPlayer, () => {
      setVideoError(true);
      latestOnError.current?.();
    });
    return () => {
      monitoring.stop();
    };
  }, [latestOnError, VideoPlayer, setVideoError]);

  // Mute on Game Mute
  useEffect(() => {
    setVideoIsMuted(Boolean(isGameMuted));
  }, [isGameMuted]);

  // When the video container is mounted appends the Video player coming from the context
  useEffect(() => {
    if (!videoContainer) return;
    if (!VideoPlayer) return;
    if (videoContainer.firstChild instanceof HTMLVideoElement) return;
    videoContainer.appendChild(VideoPlayer);
  }, [VideoPlayer, videoContainer]);

  // Load Source into video
  useEffect(() => {
    if (!videoURL) return;
    if (VideoPlayer.firstChild instanceof HTMLSourceElement) return;
    if (!showingPlayer) return;
    // Catch  bad video links
    const source = document.createElement("source");
    source.src = videoURL || "";
    source.onerror = () => {
      setVideoCannotPlay(true);
      latestOnError.current?.();
      setVideoError(true);
    };
    VideoPlayer.appendChild(source);
    VideoPlayer.load();
    VideoPlayer.play();
  }, [VideoPlayer, videoURL, latestOnError, showingPlayer, setVideoError]);

  /**
   * Reset The Video Player State e.g
   * - Remove the source
   * - Pause the video
   * - Set the video as not loading
   * - Set the video as not ending
   * - Set the video as not ended once
   */
  useEffect(() => {
    if (!showingPlayer) {
      if (VideoPlayer.firstChild instanceof HTMLSourceElement) {
        VideoPlayer.removeChild(VideoPlayer.firstChild);
      }
      VideoPlayer.pause();
      setVideoIsLoading(false);
      setHasLoadedData(false);
      setVideoCannotPlay(false);
      setHasEndedOnce(false);
      setIsCurtainLifted(false);
      setVideoIsMuted(Boolean(isGameMuted));
    }
  }, [VideoPlayer, showingPlayer, videoURL, isGameMuted]);

  useEffect(() => {
    if (
      videoCannotPlay &&
      VideoPlayer.firstChild instanceof HTMLSourceElement
    ) {
      VideoPlayer.removeChild(VideoPlayer.firstChild);
    }
  }, [VideoPlayer, videoCannotPlay]);

  // Ended callback - Plays the video again when it ends and reset the legacy video is ending
  const onEnded = useCallback(() => {
    VideoPlayer.play();
    onVideoLoop();
  }, [VideoPlayer, onVideoLoop]);

  /**
   * Time Update Event Listener and callback
   * - Communicates to the legacy handler that the video is ending
   * - Communicates to the legacy handler that the video has ended once
   * - Sets the video has ended once locally this is used to hide the loading animation when the video loops
   * and triggers a waiting event even though is not loading anymore.
   */
  const lastTimeRef = useRef<number | null>(null);
  const timeUpdate = useCallback(() => {
    const leftVideoTimePercentage =
      (VideoPlayer.duration - VideoPlayer.currentTime) / VideoPlayer.duration;
    const isFinished =
      lastTimeRef.current !== null &&
      VideoPlayer.currentTime <= lastTimeRef.current;
    if (isFinished) {
      setVideoIsEnding(true);
      setVideoHasEndedOnce(true);
    }
    if (leftVideoTimePercentage <= 0.03) setHasEndedOnce(true);
    lastTimeRef.current = VideoPlayer.currentTime;
  }, [VideoPlayer, setVideoIsEnding, setVideoHasEndedOnce, setHasEndedOnce]);

  // Waiting callback - shows the loading animation when the video is not playing
  const onWait = useCallback(() => {
    !hasEndedOnce && setVideoIsLoading(true);
  }, [setVideoIsLoading, hasEndedOnce]);

  // LoadStart callback - Shows as well the loading animation when the video starts loading for the first time
  const onLoadStart = useCallback(() => {
    setVideoIsLoading(true);
  }, [setVideoIsLoading]);

  // Playing callback - Removes the loading animation when the video is playing
  const onPlaying = useCallback(() => {
    setHasLoadedData(true);
    setVideoIsLoading(false);
  }, [setHasLoadedData, setVideoIsLoading]);

  // LoadData callback - Changes the loading animation color when the first frame is loaded
  const onLoadedData = useCallback(() => {
    setHasLoadedData(true);
    setHideMuteButton(!hasAudio(VideoPlayer));
  }, [setHasLoadedData, setHideMuteButton, VideoPlayer]);

  // CanPlay callback - Sets the video initial load play state so the black background is not shown
  const onCanPlay = useCallback(() => {
    clearTimeout(curtainLifter.current);
    setIsCurtainLifted(true);
  }, [curtainLifter]);

  useVideoPlayerListeners(
    VideoPlayer,
    onWait,
    onLoadStart,
    onPlaying,
    onLoadedData,
    onVideoPlayerError,
    onEnded,
    timeUpdate,
    onCanPlay
  );

  return (
    <>
      <VideoBackgroundCurtain $isCurtainLifted={isCurtainLifted} $iOS={isIos} />
      <SkipButtonWrapper>
        <Hide hide={!showSkipButton} speed={300}>
          <Button.Glass onClick={onSkipClick}>
            {t("cinematic_view_skip_button")}
          </Button.Glass>
        </Hide>
      </SkipButtonWrapper>

      {videoCannotPlay && isCurtainLifted && (
        <CenterContainer $bottom={96}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Icon.Warning color={grayscaleInvert(theme.colorAbove3)} />
            <Space h={3} />
            <Typo.Body color={grayscaleInvert(theme.colorAbove5)}>
              {t("video_error")}
            </Typo.Body>
          </div>
        </CenterContainer>
      )}
      {videoIsLoading && isCurtainLifted && (
        <CenterContainer>
          <Icon.RipplesAnimated
            color={hasLoadedData ? "black" : "white"}
            strokeWidth="3"
            size="50px"
          />
        </CenterContainer>
      )}

      <Fullscreen>
        <Hide hide={!isCurtainLifted} width="100%" height="100%" speed={300}>
          <VideoContainer
            ref={(el) => setVideoContainer(el)}
            $isContained={isContained}
          >
            <Img src={backgroundImageUrl} />
          </VideoContainer>
        </Hide>
      </Fullscreen>

      {!videoCannotPlay && !hideMuteButton && (
        <ButtonWrapper>
          <Button.Glass circular onClick={() => setVideoIsMuted(!videoIsMuted)}>
            {videoIsMuted ? (
              <Icon.VolumeOff inheritColor size="20px" />
            ) : (
              <Icon.Volume inheritColor size="20px" />
            )}
          </Button.Glass>
        </ButtonWrapper>
      )}
    </>
  );
};

export default FullscreenVideoUiNormal;
