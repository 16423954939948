import styled from "styled-components";
import { useStore } from "../../app/store";
import { useIsSmallScreen } from "../../common/hooks/ui";
import Transition from "../../componentsLibrary/atoms/Transition";
import { BaseLayout } from "../_layout/PanelsLayout";
import QuestLogic from "./Quest.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0px;
  top: 0px;
`;

const QuestPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.quest.visible);
  const slug = useStore((s) => s.layout.panels.quest.slug);
  const isSmallscreen = useIsSmallScreen();

  return (
    <Layout id="panel-quest">
      <Transition
        height="100%"
        width="100%"
        watch={[visible]}
        delay={200}
        slideIn={isSmallscreen ? undefined : "right"}
        slideOut={isSmallscreen ? undefined : "right"}
        justify="flex-end"
        align="center"
      >
        {visible && <QuestLogic slug={slug} />}
      </Transition>
    </Layout>
  );
};

export default QuestPanel;
