import { useEffect, useMemo, useRef, useState } from "react";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { getInstanceInfoFromUrl } from "../../app/gameConnection/gameConnectionUtils";
import { useStore } from "../../app/store";
import { VideoConferenceState } from "../videoConference/types/videoConference.types";
import { VideoConferenceStatusText } from "../videoConference/videoConferenceSlice";
import StatsUi from "./Stats.ui";
import StatsValue from "./component/StatsValue";
import { StatEntry } from "./lib/statsTypes";
import {
  isFiniteNumber,
  toDurationString,
  toLocalDateTimeString,
} from "./lib/statsUtils";

const StatsLogic: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.stats.visible);
  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  const stats = useStore((s) => s.gameConnection.streamingStats);
  const videoEncoderQP = useStore((s) => s.gameConnection.videoEncoderQP);
  const perfStats = useStore((s) => s.gameConnection.performanceStats);
  const instanceUrl = useStore((s) => s.gameConnection.instanceUrl);
  const videoConference = useStore((s) => s.videoConference);
  const userMedia = useStore((s) => s.userMedia);
  const instanceInfo = useMemo(
    () => getInstanceInfoFromUrl(instanceUrl ?? ""),
    [instanceUrl]
  );
  const roomId = useStore((s) => s.gameConnection.roomId);
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);
  const { environment } = useEnvironmentContext();

  const localTime = useRef<Date>();

  useEffect(() => {
    const interval = visible
      ? window.setInterval(() => {
          localTime.current = new Date();
        }, 1000)
      : undefined;
    return () => {
      if (interval) window.clearInterval(interval);
    };
  }, [visible]);

  // TODO: Store level loading durations
  // useEffect(() => {
  //   if (showing) console.table(levelsDuration);
  // }, [levelsDuration, showing]);

  // Calculate duration of run
  const durationSeconds =
    ((stats?.timestamp ?? 0) - (stats?.timestampStart ?? 0)) / 1000;

  const isVideoConferenceDisabled =
    videoConference.state === VideoConferenceState.DISCONNECTED;

  const entries = useMemo<StatEntry[]>(
    () => [
      {
        label: "ID",
        hint: "The ID of the server session.",
        value: (
          <StatsValue value={instanceInfo.instanceId} copiable squeezed={16} />
        ),
      },
      {
        label: "Environment",
        hint: "The ID of the environment this session is running in.",
        advanced: true,
        value: <StatsValue value={environment?.id} copiable squeezed={16} />,
      },
      {
        label: "Duration",
        hint: "Current session duration.",
        advanced: true,
        value: toDurationString(durationSeconds),
      },
      {
        label: "Local time",
        hint: "The local time of the browser.",
        value: toLocalDateTimeString(localTime.current),
      },
      {
        label: "Resolution",
        hint: "Incoming video stream resolution.",
        hidden:
          !isFiniteNumber(stats?.frameHeight) ||
          !isFiniteNumber(stats?.frameWidth),
        advanced: true,
        value: `${stats?.frameWidth}x${stats?.frameHeight}`,
      },
      {
        label: "Bitrate (min-max)",
        hint: "Incoming video stream bitrate. Depends on network connection and camera movement (static scenes hover around 2'000-3'000 values).",
        hidden: !isFiniteNumber(stats?.bitrate),
        value: (
          <>
            <StatsValue value={(stats?.bitrate ?? NaN) / 1000} fixed={1} />
            {" ("}
            <StatsValue value={(stats?.lowBitrate ?? NaN) / 1000} fixed={1} />
            {"-"}
            <StatsValue value={(stats?.highBitrate ?? NaN) / 1000} />
            {") Mbps"}
          </>
        ),
      },
      {
        label: "FPS (min-max)",
        hint: "Incoming video stream FPS. Lower values indicate issues with performance or poor network conditions.",
        hidden: !isFiniteNumber(stats?.framerate),
        value: (
          <>
            <StatsValue value={stats?.framerate} low={0} high={50} />
            {" ("}
            <StatsValue value={stats?.lowFramerate} low={0} high={45} />
            {"-"}
            <StatsValue value={stats?.highFramerate} low={0} high={55} />
            {") FPS"}
          </>
        ),
      },
      {
        label: "Frames dropped",
        hint: "Total amount of incoming video frames dropped. Steadily increasing value may indicate network connectivity issues.",
        hidden: !isFiniteNumber(stats?.framesDropped),
        advanced: true,
        value: <StatsValue value={stats?.framesDropped} />,
      },
      {
        label: "Freeze count",
        hint: `Video freezes count. Instant (last second) vs session average.`,
        hidden:
          !isFiniteNumber(stats?.currentFreezeCount) &&
          !isFiniteNumber(stats?.sessionFreezeCount),
        advanced: true,
        value: (
          <>
            {stats?.currentFreezeCount}
            {" (av. "}
            {stats?.sessionFreezeCount}
            {")"}
          </>
        ),
      },
      {
        label: "Freeze (%)",
        hint: "Video % time spent frozen. Instant (last second) vs session average.",
        hidden: !isFiniteNumber(stats?.currentFreezeDurationPercent),
        advanced: true,
        value: (
          <>
            <StatsValue
              value={stats?.currentFreezeDurationPercent}
              minimize
              low={5.0}
              high={15.0}
              fixed={1}
            />
            {" %"}
            {" (av. "}
            <StatsValue
              value={
                ((stats?.sessionTotalFreezesDuration ?? NaN) /
                  durationSeconds) *
                100
              }
              minimize
              low={5.0}
              high={15.0}
              fixed={1}
            />
            {")"}
          </>
        ),
      },
      {
        label: "Latency",
        hint: "Network roundtrip latency in milliseconds. The perceived game interaction latency is always larger than this value.",
        hidden: !isFiniteNumber(stats?.currentRoundTripTime),
        value: (
          <>
            <StatsValue
              value={stats?.currentRoundTripTime}
              low={50}
              high={90}
              minimize
              unit="ms"
            />
            {` (${instanceInfo.region})`}
          </>
        ),
      },
      {
        label: "Latency jitter",
        hint: "Change in the amount of network latency",
        hidden: !isFiniteNumber(stats?.currentJitterBufferDelay),
        // I'm currently leaving jitter by default, because it might be
        // relevant to the current pixelation issues of the streamer.
        advanced: false,
        value: (
          <StatsValue
            value={stats?.currentJitterBufferDelay}
            minimize
            low={40}
            high={70}
            unit="ms"
          />
        ),
      },
      {
        label: "Processing delay",
        hint: "The time it takes for the client to process a received video frame. Instant (last second) vs session average.",
        hidden: !isFiniteNumber(stats?.currentProcessingDelay),
        advanced: true,
        value: (
          <>
            <StatsValue value={stats?.currentProcessingDelay} />
            {" ms (av. "}
            <StatsValue value={stats?.sessionAvgProcessingDelay} />
            {")"}
          </>
        ),
      },
      {
        label: "Decoding delay",
        hint: "The time it takes for the client to just decode a video frame. Instant (last second) vs session average.",
        hidden:
          !isFiniteNumber(stats?.currentDecodeDelay) ||
          !isFiniteNumber(stats?.sessionAvgDecodingDelay),
        advanced: true,
        value: (
          <>
            <StatsValue
              value={stats?.currentDecodeDelay}
              minimize
              low={16}
              high={33}
              fixed={1}
            />
            {" ms (av. "}
            <StatsValue
              value={stats?.sessionAvgDecodingDelay}
              minimize
              low={16}
              high={33}
              fixed={1}
            />
            {")"}
          </>
        ),
      },
      {
        label: "Packet loss",
        hint: "Percentage of network packets lost. Instant (last second) vs session average.",
        hidden:
          !isFiniteNumber(stats?.currentPacketLostPercent) ||
          !isFiniteNumber(stats?.packetsLost),
        value: (
          <>
            <StatsValue
              value={stats?.currentPacketLostPercent}
              minimize
              low={1.0}
              high={5.0}
              fixed={1}
            />
            {" % (av. "}
            <StatsValue
              value={stats?.packetsLost}
              minimize
              low={1.0}
              high={5.0}
              fixed={1}
            />
            {")"}
          </>
        ),
      },
      {
        // Values (35 and 26) according to Epic
        label: "Network co.",
        hint: "Estimated network connection quality. Values greater than 35 indicate bad connectivity; less than 27 — good connection.",
        hidden: !isFiniteNumber(videoEncoderQP),
        advanced: true,
        value:
          videoEncoderQP == null
            ? "N/A"
            : `${
                videoEncoderQP > 35
                  ? "Bad"
                  : videoEncoderQP > 26
                    ? "Spotty"
                    : "Good"
              } (${videoEncoderQP})`,
      },
      {
        label: "App FPS",
        hint: "Frames rendered per second by the application",
        hidden: !isFiniteNumber(perfStats?.fps),
        value: (
          <StatsValue value={perfStats?.fps} low={0} high={50} unit="FPS" />
        ),
      },
      {
        label: "App CPU - GPU",
        hint: "Application instance CPU and GPU load. Heavier computation or rendering will increase these values and lower frame rates.",
        hidden:
          !isFiniteNumber(perfStats?.cpuUsage) ||
          !isFiniteNumber(perfStats?.gpuUsage),
        value: (
          <>
            <StatsValue
              value={perfStats?.cpuUsage}
              low={80}
              high={90}
              minimize
            />
            {" %"}
            {" - "}
            <StatsValue
              value={perfStats?.gpuUsage}
              low={80}
              high={90}
              minimize
            />
            {" %"}
          </>
        ),
      },
      {
        label: "Multiplayer room",
        hint: "Multiplayer Room Session id in which the visitor is connected",
        advanced: true,
        value: (
          <StatsValue value={roomId ?? undefined} copiable squeezed={16} />
        ),
      },
      {
        label: "Video Conferencing Status",
        hint: "Current Video Conference Voice status.",
        advanced: true,
        value: VideoConferenceStatusText[videoConference.state],
      },
      {
        label: "Self Mute Audio",
        hint: "Whether the current user is muted.",
        advanced: true,
        hidden: isVideoConferenceDisabled,
        value: userMedia.micMuted ? "Yes" : "No",
      },
      {
        label: "Self Disable Webcam",
        hint: "Whether the current user has disabled webcam.",
        advanced: true,
        hidden: isVideoConferenceDisabled,
        value: userMedia.webcamMuted ? "Yes" : "No",
      },

      // {
      //   label: "Region Voice Enabled",
      //   hint: "Whether region voice is enabled.",
      //   advanced: true,
      //   value: VoxeetSDK.conference.spatialAudioStyle ? "Yes" : "No",
      // },
    ],
    [
      durationSeconds,
      environment?.id,
      instanceInfo,
      perfStats?.cpuUsage,
      perfStats?.fps,
      perfStats?.gpuUsage,
      stats?.bitrate,
      stats?.currentDecodeDelay,
      stats?.currentFreezeCount,
      stats?.currentFreezeDurationPercent,
      stats?.currentJitterBufferDelay,
      stats?.currentPacketLostPercent,
      stats?.currentProcessingDelay,
      stats?.currentRoundTripTime,
      stats?.frameHeight,
      stats?.frameWidth,
      stats?.framerate,
      stats?.framesDropped,
      stats?.highBitrate,
      stats?.highFramerate,
      stats?.lowBitrate,
      stats?.lowFramerate,
      stats?.packetsLost,
      stats?.sessionAvgDecodingDelay,
      stats?.sessionAvgProcessingDelay,
      stats?.sessionFreezeCount,
      stats?.sessionTotalFreezesDuration,
      videoEncoderQP,
      videoConference.state,
      userMedia.micMuted,
      userMedia.webcamMuted,
      isVideoConferenceDisabled,
      roomId,
    ]
  );

  return (
    <StatsUi
      isAdvancedMode={isAdvancedMode}
      onToggleAdvancedMode={() => setIsAdvancedMode(!isAdvancedMode)}
      onClose={() => closePanel("stats")}
      onReport={() => openPanel("report")}
      entries={entries}
    />
  );
};

export default StatsLogic;
