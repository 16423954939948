import { ReactNode } from "react";
import { useReflow } from "../../common/hooks/ui";
import ActionElementsUiNormal from "./ActionElements.ui.normal";
import ActionElementsUiSmall from "./ActionElements.ui.small";

export type Props = {
  topCenterElements: ReactNode[];
  topRightElements: ReactNode[];
  bottomCenterElements: ReactNode[];
};

const ActionElementsUi: React.FC<Props> = (props) => {
  return useReflow({
    normal: <ActionElementsUiNormal {...props} />,
    small: <ActionElementsUiSmall {...props} />,
  });
};

export default ActionElementsUi;
