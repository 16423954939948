import { useRef } from "react";
import { GyaradosSDK } from "@journee-live/gyarados";
import loadable from "@loadable/component";
import { useEnvironmentContext } from "../../../app/EnvironmentDataProvider";
import { useStore } from "../../../app/store";
import MilicastScreenShareProvider from "../lib/milicastScreenShareProvider";
import VonageScreenShareProvider from "../lib/vonageScreenShareProvider";

const VideoConference = loadable(() => import("./VideoConference.provider"));
type Props = {
  children: React.ReactNode;
};

const VideoConferenceProviderLazy: React.FC<Props> = ({ children }) => {
  const {
    environment: { voiceVideoOn, screenShareProvider },
  } = useEnvironmentContext();
  const { visitorTokenData, visitorToken } = useStore((s) => s.session);

  const screenShareProviderRef = useRef<
    MilicastScreenShareProvider | VonageScreenShareProvider
  >(
    screenShareProvider ===
      GyaradosSDK.EnvironmentDtoScreenShareProviderEnum.Vonage
      ? new VonageScreenShareProvider()
      : new MilicastScreenShareProvider()
  );

  if (
    !voiceVideoOn ||
    !visitorTokenData ||
    !visitorToken ||
    !screenShareProviderRef.current ||
    !visitorTokenData
  )
    return children;
  return (
    <VideoConference
      visitorToken={visitorToken}
      visitorTokenData={visitorTokenData}
      screenShareProvider={screenShareProviderRef.current}
    >
      {children}
    </VideoConference>
  );
};

export default VideoConferenceProviderLazy;
