import { styled } from "styled-components";
import { useStore } from "../../../../app/store";
import Button from "../../../../componentsLibrary/atoms/Button";
import FadeIn from "../../../../componentsLibrary/atoms/FadeIn";
import Icon from "../../../../componentsLibrary/atoms/Icon";
import Row from "../../../../componentsLibrary/atoms/Row";
import { useVideoConferenceControlsContext } from "../../../videoConference/providers/VideoConference.provider";

const BottomControlBar = styled.div`
  background: ${(p) => p.theme.colorBelow1};
  display: flex;
  padding: 16px 22px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 22px;
`;

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const PlayerListControlBar: React.FC = () => {
  const { toggleLocalAudio, toggleVideo, startScreenShare, stopScreenShare } =
    useVideoConferenceControlsContext();

  const micMuted = useStore((s) => s.userMedia.micMuted);
  const webcamMuted = useStore((s) => s.userMedia.webcamMuted);
  const amIScreenSharer = useStore((s) => s.videoConference.amIScreenSharer());
  const conferenceInitialized = useStore((s) =>
    s.videoConference.isConferenceInitialized()
  );
  const activeScreenShare = useStore(
    (s) => s.videoConference.activeScreenShare
  );

  // If I am not already presenting, but somebody else is we want to disable screen sharing
  const screenSharingDisabled =
    (!amIScreenSharer && Boolean(activeScreenShare)) || !conferenceInitialized;

  const onScreenShareClick = async () => {
    if (activeScreenShare) {
      await stopScreenShare();
    } else {
      await startScreenShare();
    }
  };

  return (
    <FadeIn>
      <BottomControlBar>
        <ControlsWrapper>
          <Row gap={1}>
            <Button.Purple
              circular
              onClick={toggleLocalAudio}
              silent={micMuted}
              override={{
                width: "64px",
                height: "32px",
              }}
              gap={1}
              iconLeft={
                micMuted ? (
                  <Icon.MicrophoneOffThin inheritColor size="18px" />
                ) : (
                  <Icon.MicrophoneOnThin inheritColor size="18px" />
                )
              }
            >
              {micMuted ? "Off" : "On"}
            </Button.Purple>
            <Button.Purple
              circular
              silent={webcamMuted}
              onClick={toggleVideo}
              override={{
                width: "70px",
                height: "32px",
              }}
              gap={1}
              iconLeft={
                webcamMuted ? (
                  <Icon.VideoOff inheritColor size="18px" />
                ) : (
                  <Icon.VideoOn inheritColor size="18px" />
                )
              }
            >
              {webcamMuted ? "Off" : "On"}
            </Button.Purple>
          </Row>
          <Button.Purple
            circular
            silent={!activeScreenShare}
            disabled={screenSharingDisabled}
            onClick={onScreenShareClick}
            override={{
              width: "32px",
              height: "32px",
            }}
          >
            <Icon.ScreenShare inheritColor size="18px" />
          </Button.Purple>
        </ControlsWrapper>
      </BottomControlBar>
    </FadeIn>
  );
};

export default PlayerListControlBar;
