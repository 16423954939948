import { useTheme } from "styled-components";
import Hide from "../../../componentsLibrary/atoms/Hide";
import Typo from "../../../componentsLibrary/atoms/Typo";
import Logo from "../../../componentsLibrary/molecules/Logo";
import HintUi from "../../hint/Hint.ui";
import { useText } from "../../language/language.hook";
import ViewFinderGrid from "./ViewfinderGrid";
import { ErrorWrapper, HintWrapper, LogoWrapper } from "./Wrappers";

type Props = {
  logoUrl?: string;
  error?: string;
  hideUi?: boolean;
  hideTabs?: boolean;
  small?: boolean;
};

const CommonMediaCaptureUi: React.FC<Props> = ({
  logoUrl,
  error,
  hideUi,
  hideTabs,
  small,
}) => {
  const theme = useTheme();
  const t = useText();

  return (
    <>
      <Hide
        hide={hideUi}
        height="100%"
        width="100%"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <ViewFinderGrid />
      </Hide>
      <HintWrapper $small={small} $hideTabs={hideTabs}>
        <Hide
          hide={hideUi}
          width="100%"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <HintUi content={t("media_capture_move_camera")} noAnimation />
        </Hide>
      </HintWrapper>
      {error && (
        <ErrorWrapper>
          <Typo.Body color={theme.colorDanger}>{error}</Typo.Body>
        </ErrorWrapper>
      )}
      {logoUrl && (
        <LogoWrapper>
          <Logo logoUrl={logoUrl} />
        </LogoWrapper>
      )}
    </>
  );
};

export default CommonMediaCaptureUi;
