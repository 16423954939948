import { styled } from "styled-components";
import { PanelTestIds } from "../../common/constants/testIds.constant";
import { useIsSmallScreen } from "../../common/hooks/ui";
import Icon from "../../componentsLibrary/atoms/Icon";
import Row from "../../componentsLibrary/atoms/Row";
import DynamicButton from "../../componentsLibrary/molecules/DynamicButton";
import EmojisButton from "./EmojisButton";
import MovementButton from "./MovementButton";
import SocialButton from "./SocialButton";

const FlexSplitter = styled.div`
  flex-grow: 1;
`;

type Props = {
  showSocialButton?: boolean;
  onSocialClick?: () => void;
  showPhotoButton?: boolean;
  onPhotoClick?: () => void;
  showMapButton?: boolean;
  onMapClick?: () => void;
  showEmojisButton?: boolean;
  showMovementsButton?: boolean;
  showSettingsButton?: boolean;
  onSettingsClick?: () => void;
  showQuestButton?: boolean;
  onQuestClick?: () => void;
  focused?: boolean;
  unseenMessagesCount: number;
};

const ActionBarPanelUi: React.FC<Props> = ({
  showSocialButton,
  onSocialClick,
  showPhotoButton,
  onPhotoClick,
  showMapButton,
  onMapClick,
  showEmojisButton,
  showMovementsButton,
  showSettingsButton,
  onSettingsClick,
  showQuestButton,
  onQuestClick,
  focused,
  unseenMessagesCount,
}) => {
  const isSmallScreen = useIsSmallScreen();
  return (
    <Row
      testId={PanelTestIds.actionBar.root}
      width="100%"
      height="100%"
      justify="space-between"
      align="center"
      gap={2}
    >
      {showSocialButton && (
        <SocialButton
          unseenMessagesCount={unseenMessagesCount}
          focused={focused}
          onSocialClick={onSocialClick}
        />
      )}
      {showMovementsButton && <MovementButton focused={focused} />}
      {showEmojisButton && <EmojisButton focused={focused} />}
      {isSmallScreen ? null : <FlexSplitter />}
      {showPhotoButton && (
        <DynamicButton focused={focused} onClick={onPhotoClick}>
          <Icon.CameraFilled inheritColor size="20px" />
        </DynamicButton>
      )}
      {showQuestButton && (
        <DynamicButton focused={focused} onClick={onQuestClick}>
          <Icon.CircleStar inheritColor size="20px" />
        </DynamicButton>
      )}
      {showMapButton && (
        <DynamicButton focused={focused} onClick={onMapClick}>
          <Icon.MapFilled inheritColor size="20px" />
        </DynamicButton>
      )}
      {showSettingsButton && (
        <DynamicButton
          testId={PanelTestIds.actionBar.settings}
          focused={focused}
          onClick={onSettingsClick}
        >
          <Icon.GearFilled inheritColor size="20px" />
        </DynamicButton>
      )}
    </Row>
  );
};

export default ActionBarPanelUi;
