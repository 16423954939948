import { useState } from "react";
import styled, { css } from "styled-components";
import { isMobile } from "../../common/constants/flags.constant";
import Icon from "./Icon";
import Row from "./Row";

const StyledStar = styled(Icon.StarFilled)<{
  $filled?: boolean;
  $hovered?: boolean;
}>`
  cursor: pointer;
  color: ${(p) => (p.$filled ? p.theme.colorAbove5 : p.theme.colorAbove1)};
  transition: color 0.2s ease-in;
  ${(p) =>
    !p.$filled &&
    p.$hovered &&
    css`
      color: ${p.theme.colorAbove4};
    `}
`;

const StyledRow = styled(Row)`
  transition: ease-in all 0.3s;
  padding: 0.5rem 0 0 0;
  input {
    display: none;
  }
`;

export type Props = {
  testId?: string;
  initialRating?: number;
  onChange?: (rating: number) => void;
};
// YES, this can be done with Pure CSS, but it's not worth it.
// https://css-tricks.com/star-ratings/
// We need to have some sort of internal state anyway, so I am handling hovering with JS as well
// If you really want to do it with CSS, there are some hacks with :before pseudos, but transitions don't work well.

const StarsRating: React.FC<Props> = ({
  initialRating = 0,
  onChange,
  testId,
}) => {
  const [ratingValue, setRatingValue] = useState(initialRating);
  const [hoveringValue, setHoveringValue] = useState<number | null>(null);
  const onStarClick = (nextValue: number) => {
    setRatingValue(nextValue);
    onChange?.(nextValue);
  };
  return (
    <StyledRow gap={4} testId={testId}>
      {Array.from({ length: 5 }, (_, i) => (
        <StyledStar
          size="32px"
          inheritColor
          onMouseEnter={() => {
            // We don't want focused state on mobile
            if (isMobile) return;
            setHoveringValue(i + 1);
          }}
          onMouseLeave={() => {
            if (isMobile) return;
            setHoveringValue(null);
          }}
          $hovered={hoveringValue !== null && i < hoveringValue}
          $filled={!!ratingValue && i < ratingValue}
          onClick={() => onStarClick(i + 1)}
          key={i}
        />
      ))}
      <input type="number" readOnly name={"stars-rating"} value={ratingValue} />
    </StyledRow>
  );
};

export default StarsRating;
