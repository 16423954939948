import { getRandomColorWithAlpha } from "../../../common/util/color";
import { ProfileData } from "./profileTypes";

export const defaultProfileData: ProfileData = {
  firstName: "",
  lastName: "",
  email: "",
  city: "",
  avatarColor: getRandomColorWithAlpha(),
  avatarId: "",
  customAvatarUrl: "",
  rpmAvatarId: "",
  customAvatarPreviewImgUrl: "",
  company: "",
  orgCode: "",
  country: "",
  website: "",
  twitter: "",
  xing: "",
  instagram: "",
  linkedin: "",
  facebook: "",
  userEmail: "",
  msTeamsEmail: "",
  guestEmail: "",
  age: 0,
  environment: "",
  jobTitle: "",
  roomId: "",
  playerId: 0,
  walletId: "",
};

export const EmptyAvatarData = {
  avatarId: "",
  avatarImage: "",
  id: "",
  createdAt: "",
  updatedAt: "",
  deactivated: false,
  partitionId: 0,
  organisationId: 0,
  projectId: 0,
  environmentId: "",
  name: "",
  available: false,
  isMobile: false,
  sortIndex: 0,
};

export const PROFILE_MAX_HEIGHT = 680;
