import { useWindowSize } from "../../common/hooks/ui";
import FullscreenPanZoom from "../../componentsLibrary/atoms/FullscreenPanZoom";
import { Props } from "./Map.ui";
import MapCardUiPortrait from "./components/MapCard.ui.portrait";
import MapCardUiPreview from "./components/MapCard.ui.preview";
import MapPointUi from "./components/MapPoint.ui";

const MapUiSmall: React.FC<Props> = ({
  onClose,
  preloadedImage,
  imgWidth,
  points,
  onSelectPoint,
  selectedPoint,
  activeRegion,
  showCard,
  onCloseCard,
  onTeleport,
  previewMode,
  setPreviewMode,
}) => {
  const { width } = useWindowSize();
  const fitToWindowScale = width / imgWidth;
  const cardProps = {
    selectedPoint,
    isCurrentLocation: activeRegion === selectedPoint.regionId,
    onClose: onCloseCard,
    onTeleport,
  };
  // In the CMS, we advise to use 1920 x 1080 px dimension for the map.
  // Larger images will have a default scale to make the zoom animation smoother.
  const initialScale = imgWidth > 1440 ? 0.5 : fitToWindowScale;

  return (
    <>
      <FullscreenPanZoom
        image={preloadedImage}
        onClose={onClose}
        minScale={fitToWindowScale}
        initialScale={initialScale}
        initialZoomedId={activeRegion}
      >
        {points.map((point) => (
          <MapPointUi
            key={point.name}
            point={point}
            selectedPoint={selectedPoint}
            activeRegion={activeRegion}
            onSelectPoint={onSelectPoint}
            onTeleport={onTeleport}
            isSmallScreen
          />
        ))}
      </FullscreenPanZoom>
      <MapCardUiPreview
        {...cardProps}
        onExpandClick={() => setPreviewMode?.(false)}
        show={showCard && previewMode}
      />
      <MapCardUiPortrait {...cardProps} show={showCard && !previewMode} />
    </>
  );
};

export default MapUiSmall;
