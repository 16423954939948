import { useEffect, useState } from "react";
import { useEnvironmentContext } from "../../../app/EnvironmentDataProvider";
import { useStore } from "../../../app/store";
import { useText } from "../../language/language.hook";
import { PropsLoginPageUi } from "../LoginPageUi";

const useQueueCheckStep = (
  setLoginPageProps: (props: PropsLoginPageUi) => void,
  userEarlyClick?: boolean
) => {
  const currentStep = useStore((s) => s.userFlow.currentStep);
  const completeStep = useStore((s) => s.userFlow.completeStep);
  const queueStatus = useStore((s) => s.gameConnection.queueStatus);
  const gameIsReady = useStore((s) => s.gameConnection.gameIsReady);
  const isExperienceUnavailable = queueStatus.available === false;
  const t = useText();
  const {
    environment: { title, description },
  } = useEnvironmentContext();

  const [showStatus, setShowStatus] = useState(false);
  const [isEnteringSlow, setIsEnteringSlow] = useState(false);

  // We delay the showing of the queue status after the queue becomes
  // visible (after the user first click) as the experience might load meanwhile.
  useEffect(() => {
    let id: undefined | number;
    if (userEarlyClick) id = window.setTimeout(() => setShowStatus(true), 2000);
    return () => {
      if (id) clearTimeout(id);
    };
  }, [userEarlyClick]);

  // In case we are stuck in the entering phase for too long, we assume the instance is faulty
  useEffect(() => {
    let id: number | undefined;
    if (queueStatus.success && queueStatus.index === 0) {
      id = window.setTimeout(() => setIsEnteringSlow(true), 30000);
    }
    return () => {
      clearTimeout(id);
    };
  }, [queueStatus.index, queueStatus.success, setIsEnteringSlow]);

  useEffect(() => {
    if (currentStep !== "login:queue-and-connect") return;
    if (!queueStatus.success) return;
    if (isExperienceUnavailable) return;
    if (!gameIsReady) return;

    completeStep("login:queue-and-connect");
  }, [
    currentStep,
    completeStep,
    gameIsReady,
    isExperienceUnavailable,
    queueStatus.success,
  ]);

  useEffect(() => {
    if (currentStep !== "login:queue-and-connect") return;
    if (userEarlyClick) {
      let status = "";
      switch (true) {
        // Do not show status is experience is unavailable
        case isExperienceUnavailable:
          break;
        // No status when delaying
        case !showStatus:
          break;
        case queueStatus.index === 0:
          // We are either entering or we are next in line depending on the success flag
          status = queueStatus.success
            ? t("queue_entering")
            : t("queue_you_ll_be_next");
          break;
        case queueStatus.index === 1:
          status = t("queue_people_one_in_front");
          break;
        default:
          status = t("queue_people_many_in_front", {
            people: queueStatus.index,
          });
          break;
      }

      let warning = "";
      switch (true) {
        // No warning when delaying
        case !showStatus:
          break;
        // Warn if experience becomes unavailable
        // This can happen when all instances shut down while a user is waiting in the queue
        case queueStatus.checked && isExperienceUnavailable:
          warning = t("queue_unavailable");
          break;
        // Warn when game is taking too long to start
        case isEnteringSlow:
          warning = t("queue_entering_slow");
          break;
        default:
          break;
      }

      setLoginPageProps({
        isMainButtonVisible: true,
        isMainButtonLoading: true,
        isMainButtonFollowingMouse: false,
        mainButtonLabel: status,
        ...(Boolean(warning) && {
          descriptionHeader: "warning",
          description: warning,
        }),
        ...(isEnteringSlow && {
          isSecondaryButtonVisible: true,
          secondaryButtonLabel: t("queue_entering_slow_retry"),
          onSecondaryButtonClick: () => {
            window.location.reload();
          },
        }),
      });
    } else {
      setLoginPageProps({
        title: title ?? undefined,
        description: description ?? undefined,
        isMainButtonVisible: true,
        isMainButtonFollowingMouse: false,
      });
    }
  }, [
    currentStep,
    description,
    isEnteringSlow,
    isExperienceUnavailable,
    queueStatus.checked,
    queueStatus.index,
    queueStatus.success,
    setLoginPageProps,
    showStatus,
    t,
    title,
    userEarlyClick,
  ]);
};

export default useQueueCheckStep;
