import { styled } from "styled-components";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";

const Content = styled.div`
  padding-right: max(var(--safe-margin-right), 64px);
`;

type Props = {
  description?: string | null;
};

const SettingsAboutPageUi: React.FC<Props> = ({ description }) => {
  return (
    <Content>
      {description && <Typo.Body>{description}</Typo.Body>}
      <Space h={5} />
    </Content>
  );
};

export default SettingsAboutPageUi;
