import { styled } from "styled-components";
import { useStore } from "../../app/store";
import { zIndex } from "../../app/style/theme";
import Hide from "../../componentsLibrary/atoms/Hide";
import ReportLogic from "./Report.logic";
import ReportLogicPersistent from "./Report.logic.persistent";

const Layout = styled.div`
  position: fixed;
  inset: 0px;
  width: max-content;
  height: max-content;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  z-index: ${zIndex.popupMenues};
`;

const ReportPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.report.visible);

  return (
    <Layout id="layout-report">
      <Hide hide={!visible} unMount>
        <ReportLogic />
      </Hide>
      <ReportLogicPersistent isVisible={!!visible} />
    </Layout>
  );
};

export default ReportPanel;
