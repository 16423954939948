import { useEffect } from "react";
import { styled } from "styled-components";
import { useStore } from "../../app/store";
import { BaseLayout } from "../_layout/PanelsLayout";
import AttendeeBarLogic from "./attendeeBar/AttendeeBar.logic";
import ReactionsBar from "./component/ReactionsBar";
import PresenterBarLogic from "./presenterBar/PresenterBar.logic";

const Layout = styled(BaseLayout)<{ $margin: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - ${(p) => p.$margin});
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const ReactionsWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: -48px;
  height: 50px;
`;

const PresentationBarPanel: React.FC = () => {
  const visible = useStore((s) =>
    Boolean(s.layout.panels.presentationBar.visible)
  );
  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  const isConferenceInitialized = useStore((s) =>
    s.videoConference.isConferenceInitialized()
  );
  const amIThePresenter = useStore((s) => s.videoConference.amIPresenter());
  const isActionElementsVisible = useStore(
    (s) => s.layout.panels.actionElements.visible
  );

  useEffect(() => {
    if (isConferenceInitialized) {
      openPanel("presentationBar");
    } else {
      closePanel("presentationBar");
    }
  }, [isConferenceInitialized, openPanel, closePanel]);

  const attendeeBarVisible = visible && !amIThePresenter;
  const presenterBarVisible = visible && amIThePresenter;
  const reactionsBarVisible = visible;
  return (
    <Layout
      id="panel-presentationBar"
      $margin={isActionElementsVisible ? "16px" : "48px"}
    >
      <PresenterBarLogic isOpen={presenterBarVisible} />
      <AttendeeBarLogic isOpen={attendeeBarVisible} />
      <ReactionsWrapper>
        {reactionsBarVisible && <ReactionsBar />}
      </ReactionsWrapper>
    </Layout>
  );
};

export default PresentationBarPanel;
