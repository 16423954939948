import { useTheme } from "styled-components";
import { PanelTestIds } from "../../../common/constants/testIds.constant";
import { INDICATOR_SIZE } from "../../../domains/videoConference/lib/videoAvatars.constants";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import SpeakingDancingBars from "../../atoms/SpeakingDancingBars";

type Props = {
  isSpeaking: boolean;
  isMuted: boolean;
  micHidden: boolean;
  size?: number;
  toggleMute: () => void;
};

const SpeakingWidget: React.FC<Props> = ({
  isSpeaking,
  isMuted,
  micHidden,
  toggleMute,
  size = INDICATOR_SIZE.SMALL,
}) => {
  const theme = useTheme();

  let content;
  switch (true) {
    case isSpeaking:
      content = (
        <Button.Purple
          testId={
            PanelTestIds.videoAvatars.videoAvatarCircle.speakingIndication
          }
          circular
          opaque
          onClick={toggleMute}
          override={{
            width: `${size}px`,
            height: `${size}px`,
          }}
        >
          <SpeakingDancingBars size={size} />
        </Button.Purple>
      );
      break;
    case !micHidden: {
      const micSize = size * 0.7;
      content = isMuted ? (
        <Button.Glass
          circular
          opaque
          onClick={toggleMute}
          override={{
            width: `${size - INDICATOR_SIZE.OFFSET}px`, // workaround for Button.Glass
            height: `${size - INDICATOR_SIZE.OFFSET}px`,
          }}
        >
          <Icon.MicrophoneOffFilled
            size={`${micSize}px`}
            color={theme.colorAbove2}
          />
        </Button.Glass>
      ) : (
        <Button.Purple
          circular
          opaque
          onClick={toggleMute}
          override={{
            width: `${size}px`,
            height: `${size}px`,
          }}
        >
          <Icon.MicrophoneOnFilled size={`${micSize * 0.8}px`} inheritColor />
        </Button.Purple>
      );
      break;
    }
    default:
      content = null;
      break;
  }

  return content;
};

export default SpeakingWidget;
