import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { styled } from "styled-components";
import { useCurrentMap } from "../../app/hooks/gyarados.hook";
import { useStore } from "../../app/store";
import Overlay from "../../componentsLibrary/molecules/Overlay";
import MapLogic from "./Map.logic";

const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const MapPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.map.visible);
  const closePanel = useStore((s) => s.layout.closePanel);
  const [lastActiveMappableRegion, setLastActiveMappableRegion] =
    useState<string>("");

  return (
    <Layout id="panel-map">
      <Overlay
        open={visible}
        onClose={() => closePanel("map")}
        id="panel-map"
        watch={lastActiveMappableRegion}
      >
        <MapLogic lastActiveRegion={lastActiveMappableRegion} />
      </Overlay>
      <MapLogicPersistent setActiveRegion={setLastActiveMappableRegion} />
    </Layout>
  );
};

const MapLogicPersistent: React.FC<{
  setActiveRegion: Dispatch<SetStateAction<string>>;
}> = ({ setActiveRegion }) => {
  const activeRegion = useStore((s) => s.map.activeRegion);
  const { points } = useCurrentMap();

  useEffect(() => {
    if (!activeRegion) return;
    const regionPoint = points?.find((p) => p.regionId === activeRegion);
    if (!regionPoint) return;
    if (!regionPoint.regionId) return;

    setActiveRegion(regionPoint.regionId);
  }, [activeRegion, points, setActiveRegion]);
  return <></>;
};

export default MapPanel;
