import { useEffect } from "react";
import { GyaradosSDK } from "@journee-live/gyarados";
import { styled } from "styled-components";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { useStore } from "../../app/store";
import Hide from "../../componentsLibrary/atoms/Hide";
import { BaseLayout } from "../_layout/PanelsLayout";
import ScreenSharingLogic from "./ScreenSharing.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ScreenSharingPanel: React.FC = () => {
  const setPanelExtraOptions = useStore((s) => s.layout.setPanelExtraOptions);
  const screenSharingVisible = useStore(
    (s) => s.layout.panels.screenSharing.visible
  );
  const {
    environment: { screenShareProvider },
  } = useEnvironmentContext();
  const isMilicast =
    screenShareProvider ===
    GyaradosSDK.EnvironmentDtoScreenShareProviderEnum.Dolby;
  const isConferenceInitialized = useStore((s) =>
    s.videoConference.isConferenceInitialized()
  );
  const settingsPanelVisible = useStore(
    (state) => state.layout.panels.settings.visible
  );
  const amIScreenSharer = useStore((state) =>
    state.videoConference.amIScreenSharer()
  );
  const screenSharer = useStore((state) => state.videoConference.screenSharer);
  const minimized = useStore(
    (s) => s.layout.panels.screenSharing.options?.minimized
  );
  const openPanel = useStore((state) => state.layout.openPanel);
  const closePanel = useStore((state) => state.layout.closePanel);

  const hasPresenter = isConferenceInitialized && Boolean(screenSharer);
  const visible =
    hasPresenter &&
    screenSharingVisible &&
    // We manually check settings panel because it is in a different zone
    (!minimized || (minimized && !settingsPanelVisible));

  useEffect(() => {
    if (screenSharer) {
      closePanel("settings");
      openPanel("screenSharing");
      setPanelExtraOptions("screenSharing", {
        minimized: amIScreenSharer || isMilicast,
      });
    } else {
      closePanel("screenSharing");
    }
  }, [
    openPanel,
    closePanel,
    setPanelExtraOptions,
    amIScreenSharer,
    isMilicast,
    screenSharer,
  ]);

  return (
    <Layout id="panel-screenSharing">
      <Hide hide={!visible} height="100%" width="100%" speed={500}>
        <ScreenSharingLogic />
      </Hide>
    </Layout>
  );
};

export default ScreenSharingPanel;
