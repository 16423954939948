import { useEffect, useRef, useState } from "react";
import { useStore } from "../../../../app/store";
import useConferenceErrors from "../../../../common/hooks/useConferenceErrors";
import { getLocalPlayerName } from "../../../profile/profileSlice";
import { useVideoConferenceControlsContext } from "../../../videoConference/providers/VideoConference.provider";
import SettingsVideoPreviewUi from "./SettingsVideoPreview.ui";
import { stopAudioStream, stopVideoStream } from "./util/stream";

const SettingsVideoPreviewLogic: React.FC = () => {
  const {
    toggleLocalAudio,
    toggleVideo,
    toggleScreenShare,
    refreshMediaDevices,
    mediaDeviceService,
    leaveConference,
    joinConference,
  } = useVideoConferenceControlsContext();
  const [localVideoStream, setLocalVideoStream] = useState<MediaStream | null>(
    null
  );
  const [localAudioStreams, setLocalAudioStreams] =
    useState<MediaStream | null>(null);
  const { profileData } = useStore((s) => s.profile);
  const roomId = useStore((s) => s.gameConnection.roomId);
  const conferenceInitialized = useStore((s) =>
    s.videoConference.isConferenceInitialized()
  );
  const screenSharer = useStore((s) => s.videoConference.screenSharer);
  const {
    videoInputDevices,
    audioInputDevices,
    audioOutputDevices,
    selectedMicrophone,
    selectedCamera,
    selectedSpeakers,
    setVideoCameraFeedError,
  } = useStore((s) => s.userMedia);

  const conferenceErrors = useConferenceErrors();
  const amIScreenSharer = useStore((s) => s.videoConference.amIScreenSharer());
  const activeScreenShare = useStore(
    (s) => s.videoConference.activeScreenShare
  );
  const micMuted = useStore((s) => s.userMedia.micMuted);
  const webcamMuted = useStore((s) => s.userMedia.webcamMuted);

  useEffect(() => {
    const unsubscribe =
      mediaDeviceService.listenForDeviceChanges(refreshMediaDevices);
    return () => {
      unsubscribe();
    };
  }, [mediaDeviceService, refreshMediaDevices]);

  const name = getLocalPlayerName(profileData);
  const avatarColor = profileData?.avatarColor;

  const localAudioStreamsRef = useRef(localAudioStreams);
  localAudioStreamsRef.current = localAudioStreams;

  useEffect(() => {
    (async () => {
      if (selectedMicrophone) {
        const stream = await window.navigator.mediaDevices.getUserMedia({
          audio: { deviceId: selectedMicrophone.deviceId },
        });
        setLocalAudioStreams(stream);
      }
    })();
    return () => {
      if (selectedMicrophone?.deviceId && localAudioStreamsRef.current) {
        stopAudioStream(localAudioStreamsRef.current);
      }
    };
  }, [selectedMicrophone]);

  const localVideoStreamRef = useRef(localVideoStream);
  localVideoStreamRef.current = localVideoStream;
  const gettingStreamRef = useRef(false);
  useEffect(() => {
    (async () => {
      if (selectedCamera && !webcamMuted && !gettingStreamRef.current) {
        try {
          gettingStreamRef.current = true;
          const stream = await window.navigator.mediaDevices.getUserMedia({
            video: { deviceId: selectedCamera.deviceId },
          });
          setLocalVideoStream(stream);
          setVideoCameraFeedError(false);
        } catch (e) {
          setLocalVideoStream(null);
          setVideoCameraFeedError(true);
        }
      } else if (selectedCamera && webcamMuted && localVideoStreamRef.current) {
        stopVideoStream(localVideoStreamRef.current);
        setLocalVideoStream(null);
      }
      gettingStreamRef.current = false;
    })();

    return () => {
      if (selectedCamera?.deviceId && localVideoStreamRef.current) {
        stopVideoStream(localVideoStreamRef.current);
      }
    };
  }, [selectedCamera, setVideoCameraFeedError, webcamMuted]);

  return (
    <SettingsVideoPreviewUi
      conferenceInitialized={conferenceInitialized}
      localAudioStream={localAudioStreams}
      localCameraStream={localVideoStream}
      onJoinConference={joinConference}
      onLeaveConference={leaveConference}
      conferenceErrors={conferenceErrors}
      activeScreenShare={!!activeScreenShare}
      toggleLocalAudio={toggleLocalAudio}
      toggleVideo={toggleVideo}
      toggleScreenShare={toggleScreenShare}
      micMuted={micMuted}
      amIScreenSharer={amIScreenSharer}
      webcamMuted={webcamMuted}
      screenSharer={screenSharer}
      playerName={name}
      avatarColor={avatarColor}
      roomId={roomId}
      selectedAudioInputDevice={selectedMicrophone}
      selectedAudioOutputDevice={selectedSpeakers}
      selectedVideoInputDevice={selectedCamera}
      audioInputDevices={audioInputDevices}
      audioOutputDevices={audioOutputDevices}
      videoInputDevices={videoInputDevices}
    />
  );
};

export default SettingsVideoPreviewLogic;
