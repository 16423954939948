import { useEffect } from "react";
import { styled } from "styled-components";
import { useStore } from "../../app/store";
import Hide from "../../componentsLibrary/atoms/Hide";
import { BaseLayout } from "../_layout/PanelsLayout";
import DevOptionsLogic from "./DevOptions.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  top: 40px;
  right: 40px;
  max-width: 416px;
`;

const DevOptionsPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.devOptions.visible);
  const closePanel = useStore((s) => s.layout.closePanel);
  const openPanel = useStore((s) => s.layout.openPanel);

  // Open the panel through keyboard shortcut ALP+P
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "KeyO" && event.altKey && !event.ctrlKey) {
        if (visible) {
          closePanel("devOptions");
        } else {
          openPanel("devOptions");
        }
      }
    };
    window.addEventListener("keydown", listener);
    return () => window.removeEventListener("keydown", listener);
  }, [visible, closePanel, openPanel]);

  return (
    <Layout id="panel-devOptions">
      <Hide hide={!visible} width="100%" height="100%" unMount>
        <DevOptionsLogic />
      </Hide>
    </Layout>
  );
};

export default DevOptionsPanel;
