import { useEffect, useState } from "react";
import { css, keyframes, styled } from "styled-components";
import { emit } from "../../common/util/bus";
import Icon from "./Icon";

const pulse = keyframes`
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
`;

const Container = styled.div<{
  $width: React.CSSProperties["width"];
  $height: React.CSSProperties["height"];
}>`
  width: ${(p) => p.$width};
  height: ${(p) => p.$height};
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const Placeholder = styled.div<{ $show: boolean; $invisible?: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  display: flex;
  left: 0px;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.colorAbove0};
  border-radius: ${(p) => p.theme.radiusSmall};
  overflow: hidden;
  opacity: ${(p) => (p.$show ? 1 : 0)};
  transition: opacity 0.4s ${(p) => (p.$show ? "0s" : "0.4s")} ease-in-out;
  ${(p) => p.$invisible && `opacity: 0`};
`;

const Loader = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 0;
  animation: ${pulse} 1.8s ease-in-out infinite;
`;

const ImageWrapper = styled.div<{
  $show: boolean;
  $slowShow: boolean;
  $transparentBorder: boolean;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(p) => (p.$show ? 1 : 0)};
  ${(p) => p.$slowShow && `transition: opacity 0.4s ease-in-out;`}
  ${(p) =>
    p.$transparentBorder &&
    css`
      &::after {
        content: "";
        position: absolute;
        inset: 0;
        inset-block-end: 0;
        inset-inline: 0;
        inset-inline-end: 0;
        border: 1px solid ${p.theme.colorAbove0};
        border-radius: ${p.theme.radiusTiny};
      }
    `}
`;

const ImageElement = styled.img<{ $visible: boolean; $cover?: boolean }>`
  visibility: ${(p) => (p.$visible ? "visible" : "hidden")};
  object-fit: ${(p) => (p.$cover ? "cover" : "contain")};
  width: 100%;
  height: 100%;

  user-select: none;
  user-drag: none;
  touch-callout: none;
`;

export type Props = {
  src: string;
  alt?: string;
  testId?: string;
  cover?: boolean;
  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];
  /** Makes the error placeholder invisible and the loading placeholder less intrusive. */
  minimalPlaceholder?: boolean;
  styleOverrides?: React.CSSProperties;
  transparentBorder?: boolean;
};

const Image: React.FC<Props> = ({
  src,
  width,
  height,
  cover,
  testId,
  alt,
  minimalPlaceholder,
  styleOverrides,
  transparentBorder,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [longLoad, setLongLoad] = useState(false);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  // Reset the states if the image changes.
  useEffect(() => {
    setFirstRender(true);
    setLoaded(false);
    setError(false);
    setLongLoad(false);
  }, [src]);

  useEffect(() => {
    let id: number | undefined;
    if (!id)
      id = window.setTimeout(() => {
        if (!loaded) setLongLoad(true);
      }, 200);
    setFirstRender(false);

    return () => {
      if (id) clearTimeout(id);
    };
  }, [loaded]);

  useEffect(() => {
    if (error) {
      emit("errorReport", {
        type: "IMAGE_COULDN_NOT_LOAD",
        signature: src,
        payload: {
          imageSource: src,
        },
      });
    }
  }, [error, src]);

  return (
    <Container
      $width={width || "100%"}
      $height={height || "100%"}
      data-testid={testId}
    >
      <Placeholder
        $show={(!firstRender && !loaded) || error}
        $invisible={minimalPlaceholder}
        style={styleOverrides}
      >
        {error && <Icon.QuestionMark size="30px" strokeWidth="3px" />}
        {!error &&
          !loaded &&
          (minimalPlaceholder ? <Icon.RipplesAnimated /> : <Loader />)}
      </Placeholder>

      <ImageWrapper
        $transparentBorder={Boolean(transparentBorder)}
        $show={loaded}
        $slowShow={longLoad}
      >
        {!error && (
          <ImageElement
            $cover={cover}
            $visible={loaded}
            loading="lazy"
            alt={alt}
            src={src}
            style={styleOverrides}
            onError={() => setError(true)}
            onLoad={() => setLoaded(true)}
            draggable="false"
          />
        )}
      </ImageWrapper>
    </Container>
  );
};

export default Image;
