import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { USE_SENTRY_IN_DEVELOPMENT } from "../../../common/constants/configs.constant";
import { useEnvironmentContext } from "../../EnvironmentDataProvider";
import { SentryTags } from "./sentryTags";

export const initializeSentry = () => {
  if (import.meta.env.MODE !== "development" || USE_SENTRY_IN_DEVELOPMENT) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      release: __APP_VERSION__,
      environment: getSentryEnvironment(),
      normalizeDepth: 7,
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: [
            "services.go.journee.live",
            "share.journee.live",
            // To instances
            /[a-z][a-z]-[a-z]+-[0-9]-i-[A-Za-z0-9]+\.[a-z0-9]+\.go\.journee\.live/,
            // To per-env Lambda functions.
            /api[a-z0-9]+\.go\.journee\.live/,
          ],
        }),
        // Add more logging flags in flagsReducer if needed.
        new CaptureConsole({ levels: ["error"] }),
      ],
      tracesSampleRate: USE_SENTRY_IN_DEVELOPMENT ? 1 : 0.1,
      sampleRate: 1,
      // From: https://github.com/getsentry/sentry/issues/12676#issuecomment-533538114
      ignoreErrors: [
        "Network request failed",
        "Failed to fetch",
        "NetworkError",
        "Network Error",
        "Error: Network Error",
        "Request aborted",
        "Error: Request aborted",
      ],
    });

    // TODO: add logrocket reference to sentry
    //   getLogRocketSessionURL()
    //     .then((sessionURL) => {
    //       Sentry.setContext("LogRocket", {
    //         sessionURL,
    //       });
    //     })
    //     .catch(() => null);
  }
};

/** Extra initalization for sentry after the environment is know. */
export const useSentryWithEnvironment = (disabled?: boolean) => {
  const { environmentId } = useEnvironmentContext();
  if (disabled) return;
  Sentry.setTag(SentryTags.ENVIRONMENT_ID, environmentId);
};
// Infer the current stage from current ENV Vars.
const getSentryEnvironment = (): "staging" | "production" | "development" => {
  switch (true) {
    case import.meta.env.MODE === "production" &&
      import.meta.env.VITE_GYARADOS_URL.includes(
        "https://gyarados.go.journee.live"
      ):
      return "production";
    case import.meta.env.MODE === "development":
      return "development";
    default:
      return "staging";
  }
};
