import { useEffect, useState } from "react";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { useEndedPath } from "../../app/hooks/routing.hook";
import { useStore } from "../../app/store";
import { isIFrame } from "../../common/constants/flags.constant";
import {
  useInactivityCheck,
  useValidateOpeningTimes,
} from "../_login/loginHooks";
import EndingUi from "./Ending.ui";

const initCountDown = 20;

function redirect(url: string) {
  if (isIFrame) {
    const parent = window.top || window.parent;
    if (parent) parent.location.href = url;
  } else {
    window.location.href = url;
  }
}

const EndingLogic: React.FC = () => {
  const hasGameSessionEnded = useStore((s) => s.session.hasGameSessionEnded);
  const { environment } = useEnvironmentContext();

  const [countDown, setCountDown] = useState(initCountDown);
  const { isInactive } = useInactivityCheck();
  const { isClosing } = useValidateOpeningTimes();
  const endedUrl = useEndedPath();
  const redirectionURL = environment?.redirectionURL ?? endedUrl;
  const isEnding = isInactive || isClosing;
  const cleanupHooks = useStore((s) => s.userFlow.cleanupHooks);
  // Redirect immediately if game session has ended
  useEffect(() => {
    if (!hasGameSessionEnded) return;
    redirect(redirectionURL);
  }, [hasGameSessionEnded, redirectionURL]);

  // Use the countdown if either inactivity or event ending is triggered
  useEffect(() => {
    if (!isEnding) return;

    const interval = window.setInterval(async () => {
      if (isEnding && countDown > 0) {
        setCountDown(countDown - 1);
      } else if (isEnding) {
        Object.keys(cleanupHooks).forEach((hook) => {
          cleanupHooks[hook]();
        });
        redirect(redirectionURL);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [cleanupHooks, isEnding, countDown, redirectionURL]);

  useEffect(() => {
    if (!isEnding) setCountDown(initCountDown);
  }, [isEnding]);

  return isEnding ? (
    <EndingUi
      isInactive={isInactive}
      isClosing={isClosing}
      countDown={countDown}
    />
  ) : null;
};

export default EndingLogic;
