import { styled } from "styled-components";
import { steps } from "../../app/style/theme";
import CardContentAlignment from "../../componentsLibrary/atoms/CardContentAlignment";
import HeaderBadge from "../../componentsLibrary/atoms/HeaderBadge";
import Icon from "../../componentsLibrary/atoms/Icon";
import Row from "../../componentsLibrary/atoms/Row";
import Space from "../../componentsLibrary/atoms/Space";
import Typo from "../../componentsLibrary/atoms/Typo";
import CardFullLandscape from "../../componentsLibrary/molecules/CardFullLandscape";
import { useText } from "../language/language.hook";
import { NudgeRight } from "../poll/Poll.ui.landscape";

const Padding = styled.div`
  padding-left: ${steps.spacing[6]};
  padding-right: max(var(--safe-margin-right), 64px);
`;

const RestrictionUiLandscape: React.FC = () => {
  const t = useText();
  return (
    <CardFullLandscape
      topSection={
        <NudgeRight>
          <Row height="100%" width="100%" align="center" justify="center">
            <HeaderBadge>
              <Icon.CircleInfo inheritColor size="40px" />
            </HeaderBadge>
          </Row>
        </NudgeRight>
      }
    >
      <Padding>
        <Space h={9} />
        <CardContentAlignment>
          <Typo.Title align="left">
            {t("login_error_restricted_title")}
          </Typo.Title>
          <Space h={2} />
          <Typo.Body align="left">
            {t("login_error_restricted_description")}
          </Typo.Body>
        </CardContentAlignment>
      </Padding>
    </CardFullLandscape>
  );
};

export default RestrictionUiLandscape;
