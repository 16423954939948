import { useEffect, useMemo, useState } from "react";
import debounce from "lodash/debounce";
import { getInstanceInfoFromUrl } from "../../../app/gameConnection/gameConnectionUtils";
import { useStore } from "../../../app/store";
import {
  isChrome,
  isEdge,
  isFirefox,
  isIFrame,
  isOpera,
  isSafari,
  isSocialMediaBrowser,
  isWebkit,
} from "../../../common/constants/flags.constant";
import { getOS } from "../../../common/util/os";

export const useWindowSize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    const debouncedOnResize = debounce(
      () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      },
      500,
      {
        leading: true,
        trailing: true,
      }
    );
    window.addEventListener("resize", debouncedOnResize);
    return () => window.removeEventListener("resize", debouncedOnResize);
  }, []);
  return {
    width,
    height,
  };
};

export const useVisitorReport = () => {
  const streamId = useStore((s) => s.gameConnection.streamId) ?? "";
  const instanceUrl = useStore((s) => s.gameConnection.instanceUrl) ?? "";
  const logRocketSessionId = useStore((s) => s.session.logRocketSessionId);
  const { width: screenWidth, height: screenHeight } = useWindowSize();
  const currentUrl = window.location.href;
  const orientation = window.screen?.orientation?.type ?? "";

  return useMemo(() => {
    return {
      // TODO: Add the Universal Session Id when available
      sessionId: "",
      sessionReplayData: {
        sessionId: logRocketSessionId || "",
        provider: "logrocket",
      },
      streamId,
      instance: getInstanceInfoFromUrl(instanceUrl),
      versions: {
        client: __APP_VERSION__,
      },
      agent: {
        userAgent: window.navigator.userAgent,
        currentUrl,
        os: getOS(window.navigator.userAgent, window.navigator.platform) ?? "",
        browsers: [
          isChrome ? "Chrome" : "",
          isOpera ? "Opera" : "",
          isFirefox ? "Firefox" : "",
          isEdge ? "Edge" : "",
          isSafari ? "Safari" : "",
          isWebkit ? "Webkit" : "",
          isSocialMediaBrowser ? "SocialMedia" : "",
        ].filter(Boolean),
        iframe: isIFrame,
        orientation,
        screenWidth,
        screenHeight,
      },
    };
  }, [
    screenWidth,
    screenHeight,
    instanceUrl,
    streamId,
    currentUrl,
    orientation,
    logRocketSessionId,
  ]);
};
