import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ServicesApi } from "@journee-live/mew";
import { SWRConfig } from "swr";
import "./app/gameConnection/webrtc/helpers/screencaster";
import { initializeSentry } from "./app/monitoring/tracking/sentry";
import { useSentryCollector } from "./app/monitoring/tracking/sentryColletor";
import Routing from "./app/routing/Routing";
import "./app/style/_normalize.css";
import "./app/style/global.css";
import { swrConfig } from "./common/constants/configs.constant";
import { initializeFlags } from "./common/constants/flags.constant";
import DevOptionsProvider from "./domains/_dev/DevOptionsProvider";
import DetectTouchForCss from "./domains/_experience/lib/DetectTouchForCss";
import SafeMarginVariables from "./domains/_experience/lib/SafeMarginVariables";
import { useApplyClientPersistedId } from "./domains/_login/loginHooks";
import "./domains/language/lib/i18nInit";

initializeSentry();
initializeFlags();

const App: React.FC = () => {
  ServicesApi.baseUrl = import.meta.env.VITE_SERVICES_URL;
  useSentryCollector();
  useApplyClientPersistedId();

  return (
    <StrictMode>
      <DevOptionsProvider>
        <SafeMarginVariables>
          <DetectTouchForCss />
          <HelmetProvider>
            <BrowserRouter>
              <SWRConfig value={swrConfig}>
                <Routing />
              </SWRConfig>
            </BrowserRouter>
          </HelmetProvider>
        </SafeMarginVariables>
      </DevOptionsProvider>
    </StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
