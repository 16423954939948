import { styled } from "styled-components";
import { useStore } from "../../app/store";
import { BaseLayout } from "../_layout/PanelsLayout";
import WalletConnectModalLogic from "./WalletConnectModal.logic";

const Layout = styled(BaseLayout)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
`;

const WalletConnectModalPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.walletConnect.visible);

  return (
    <Layout id="panel-walletConnect">
      {visible && <WalletConnectModalLogic />}
    </Layout>
  );
};

export default WalletConnectModalPanel;
