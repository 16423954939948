import { useMemo } from "react";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { sendGameMessage } from "../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { useStore } from "../../app/store";
import Icon, { IconName } from "../../componentsLibrary/atoms/Icon";
import { DynamicButtonMenuItem } from "../../componentsLibrary/molecules/dynamicButtonMenu/DynamicButtonMenu";

type IconComponent = (typeof Icon)[IconName];

const movementIcons = new Map<string, IconName>([
  ["walk", "PersonWalkingFilled"],
  ["fly", "PersonTeleportFilled"],
  ["hover", "PersonSlidingFilled"],
]);

const useDynamicButtonMenuMovements: () => {
  SelectedMovementIcon: React.FC<React.ComponentProps<IconComponent>>;
  movementsOptions: DynamicButtonMenuItem[];
} = () => {
  const currentMovementType = useStore(
    (state) => state.gameConnection.currentMovementType
  );

  const {
    environment: { movements },
  } = useEnvironmentContext();

  const movementsOptions: DynamicButtonMenuItem[] = useMemo(() => {
    const options: DynamicButtonMenuItem[] = [];

    if (movements.enableFly) {
      options.push({
        value: "PersonTeleport",
        tooltip: "Fly",
        component: <Icon.PersonTeleport size="24px" />,
        componentFilled: <Icon.PersonTeleportFilled size="24px" />,
        onClick: () => {
          sendGameMessage({
            type: "ActivateMovementType",
            movementType: "fly",
          });
        },
      });
    }

    if (movements.enableHoverboard) {
      options.push({
        value: "PersonSliding",
        tooltip: "Hover",
        component: <Icon.PersonSliding size="24px" />,
        componentFilled: <Icon.PersonSlidingFilled size="24px" />,
        onClick: () => {
          sendGameMessage({
            type: "ActivateMovementType",
            movementType: "hover",
          });
        },
      });
    }

    options.push({
      value: "PersonWalking",
      tooltip: "Walk",
      component: <Icon.PersonWalking size="24px" />,
      componentFilled: <Icon.PersonWalkingFilled size="24px" />,
      onClick: () => {
        sendGameMessage({
          type: "ActivateMovementType",
          movementType: "walk",
        });
      },
    });

    return options;
  }, [movements]);

  const selectedMovementIconName =
    currentMovementType && movementIcons.get(currentMovementType);
  const SelectedMovementIcon = selectedMovementIconName
    ? Icon[selectedMovementIconName]
    : Icon.PersonWalkingFilled;

  return {
    SelectedMovementIcon,
    movementsOptions,
  };
};

export default useDynamicButtonMenuMovements;
