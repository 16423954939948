import HeaderBadge from "../../../componentsLibrary/atoms/HeaderBadge";
import Icon from "../../../componentsLibrary/atoms/Icon";
import Row from "../../../componentsLibrary/atoms/Row";

const LanguageCardHeader: React.FC = () => {
  return (
    <Row height="100%" width="100%" align="center" justify="center">
      <HeaderBadge>
        <Icon.Globe inheritColor size="40px" />
      </HeaderBadge>
    </Row>
  );
};

export default LanguageCardHeader;
