import { useEffect, useState } from "react";
import { useStore } from "../../app/store";
import { logWarn } from "../util/logger";

interface BrowserHTMLElement extends HTMLElement {
  webkitRequestFullscreen?: typeof HTMLElement.prototype.requestFullscreen;
}

interface BrowserDocument extends Document {
  webkitCancelFullScreen?: typeof Document.prototype.exitFullscreen;
  webkitExitFullscreen?: typeof Document.prototype.exitFullscreen;
  webkitCurrentFullScreenElement?: BrowserHTMLElement;
  webkitFullscreenElement?: BrowserHTMLElement;
  webkitIsFullScreen: boolean;
  webkitFullscreenEnabled: boolean;
}

const checkCanFullscreen = (ele: BrowserHTMLElement): boolean => {
  const doc = document as BrowserDocument;
  // The presence of requestFullscreen covers the general
  // browser support of the feature.
  // The presence of fullscreenEnabled indicates whether the
  // current browser context supports the feature E.g. it could
  // be disabled if the parent iframe doesn't specify "allowfullscreen"
  // AND if the parent iframe is on a different origin (the latter makes
  // it unintuitive to test on localhost with our /dev/iframe/:envname page).
  // If fullscreenEnabled is undefined, we should consider
  // that fullscreen is supported (older browser).
  return (
    Boolean(ele.requestFullscreen || ele.webkitRequestFullscreen) &&
    Boolean(doc.fullscreenEnabled || doc.webkitFullscreenEnabled)
  );
};

const isCurrentlyFullscreen = (): boolean => {
  const doc = document as BrowserDocument;
  return Boolean(
    doc.fullscreenElement ||
      doc.webkitCurrentFullScreenElement ||
      doc.webkitFullscreenElement ||
      doc.webkitIsFullScreen
  );
};

const requestFullscreen = (ele: BrowserHTMLElement) => {
  if (ele.requestFullscreen) {
    ele.requestFullscreen();
  } else if (ele.webkitRequestFullscreen) {
    ele.webkitRequestFullscreen();
  } else {
    logWarn("GENERIC", "Fullscreen API is not supported.");
  }
};

const exitFullscreen = () => {
  const doc = document as BrowserDocument;
  if (doc.exitFullscreen) {
    doc.exitFullscreen();
  } else if (doc.webkitCancelFullScreen) {
    doc.webkitCancelFullScreen();
  } else if (doc.webkitExitFullscreen) {
    doc.webkitExitFullscreen();
  } else {
    logWarn("GENERIC", "Fullscreen API is not supported.");
  }
};

type Returned = {
  toggleFullscreen: () => void;
  canFullscreen: boolean;
};

const useFullscreenMode = (): Returned => {
  const docElement = document.documentElement as BrowserHTMLElement;

  const [canFullscreen, setCanFullscreen] = useState(false);
  useEffect(() => {
    setCanFullscreen(checkCanFullscreen(docElement));
  }, [docElement]);

  const toggleFullscreen = () => {
    if (!isCurrentlyFullscreen()) requestFullscreen(docElement);
    else exitFullscreen();
  };

  const { toggleFullscreenFlag } = useStore((s) => s.ui.fullscreen);
  useEffect(() => {
    const onFullscreenChange = toggleFullscreenFlag;
    document.addEventListener("fullscreenchange", toggleFullscreenFlag);
    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange);
  });

  return {
    toggleFullscreen,
    canFullscreen,
  };
};

export default useFullscreenMode;
