/* eslint-disable i18next/no-literal-string */
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { randomSaneNameGenerator } from "../../../test/unit/__mocks__/common.generators";
import useIframeAdapter from "../../common/hooks/useIframeAdapter";
import { getImageUrl } from "../../common/util/gyarados.util";
import Button from "../../componentsLibrary/atoms/Button";
import Column from "../../componentsLibrary/atoms/Column";
import Image from "../../componentsLibrary/atoms/Image";
import Markdown from "../../componentsLibrary/atoms/Markdown";
import Space from "../../componentsLibrary/atoms/Space";
import Spatial from "../../componentsLibrary/atoms/Spatial";
import Typo from "../../componentsLibrary/atoms/Typo";
import Card from "../../componentsLibrary/molecules/Card";
import { isThirdPartyMessage } from "../iframe/IframeAdapter";

const StyledWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
/** A page to test the iframe dev component. THIS IS SUPPOSED TO WORK ONLY INSIDE IFRAME */

const IframeDevComponentExample = () => {
  const [contentFromIframe, setContentFromIframe] = useState<string>(
    "This will be replaced by the content from the iframe"
  );
  const { sendMessageToParent, subscribeToParentMessages } = useIframeAdapter();

  const parentMessageReceiver = useCallback(
    (message: CustomEvent) => {
      if (isThirdPartyMessage(message)) return;
      if (!message.detail?.message) return;
      setContentFromIframe(message.detail.message);
    },
    [setContentFromIframe]
  );
  const parentMessageSender = () => {
    const randomName = randomSaneNameGenerator();
    sendMessageToParent?.(
      `Hello from iframe with random message: ${randomName}`
    );
  };

  useEffect(() => {
    subscribeToParentMessages?.(parentMessageReceiver);
  }, [parentMessageReceiver, subscribeToParentMessages]);

  return (
    <StyledWrapper>
      <Spatial rim glare dragMotion mouseTiltX mouseTiltY mouseZoom>
        <Card
          topSection={
            <Image
              cover
              src={
                getImageUrl(
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Vevey_with_Lake_of_Geneva_and_mountains.jpg/2880px-Vevey_with_Lake_of_Geneva_and_mountains.jpg",
                  700,
                  700
                ) as string
              }
              width={"100%"}
              height="200px"
            />
          }
          topSectionHeight="200px"
          width="260px"
        >
          <Column>
            <Typo.Title>Example interactive card</Typo.Title>
            <Space h={2} />
            <Markdown
              content="This is an example interactive card that will send events to parent iframe."
              textAlign="left"
            />
            <Space h={5} />
            <Markdown content={contentFromIframe} textAlign="left" />

            <Button.Secondary onClick={parentMessageSender}>
              Send message to parent
            </Button.Secondary>

            <Space h={2} />
          </Column>
        </Card>
      </Spatial>
    </StyledWrapper>
  );
};

export default IframeDevComponentExample;
