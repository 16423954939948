import { useEffect, useState } from "react";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import EnvironmentDisconnectHandler from "../../app/EnvironmentDisconnectHandler.core";
import { useIdentifyLogRocketUser } from "../../app/monitoring/tracking/logrocket";
import { useStore } from "../../app/store";
import LoginPage from "../_login/LoginPage";
import LoginVideoBackground from "../_login/LoginVideoBackground";
import FullscreenHelper from "../_login/component/SwipeUpHelper/FullscreenHelper";
import EndingPanel from "../endingPanel/Ending.logic";
import { useCopywriting } from "../language/language.hook";
import MapImageContextProvider from "../map/providers/MapImage.provider";
import ReactionsProvider from "../presentationBar/providers/Reactions.provider";
import SocketDisconnectPageUi from "../utilityPages/SocketDisconnectPageUi";
import VideoConferenceProviderLazy from "../videoConference/providers/VideoConference.provider.lazy";
import Web3ProviderLazy from "../web3Wallet/providers/Web3.provider.lazy";
import GamePlayer from "./GamePlayer";
import PanelSystem from "./PanelSystem";
import VideoPlayerContextProvider from "./VideoPlayer.provider";

const ExperienceRoute: React.FC = () => {
  const completeStep = useStore((s) => s.userFlow.completeStep);
  const registerCleanupHook = useStore((s) => s.userFlow.registerCleanupHook);
  const { environment } = useEnvironmentContext();

  const [mountLogin, setMountLogin] = useState(true);

  useEffect(() => {
    registerCleanupHook("removeLoginPage", () => setMountLogin(false));
  }, [registerCleanupHook]);
  useIdentifyLogRocketUser();
  useCopywriting();

  useEffect(() => {
    if (environment) {
      completeStep("load-environment");
    }
  }, [environment, completeStep]);

  return (
    <EnvironmentDisconnectHandler displayPage={SocketDisconnectPageUi}>
      <VideoPlayerContextProvider>
        <MapImageContextProvider>
          <Web3ProviderLazy>
            <LoginVideoBackground />
            <EndingPanel />
            <VideoConferenceProviderLazy>
              <ReactionsProvider>
                <PanelSystem />
              </ReactionsProvider>
            </VideoConferenceProviderLazy>
            <FullscreenHelper>
              <GamePlayer />
            </FullscreenHelper>
            {mountLogin && <LoginPage onDone={() => setMountLogin(false)} />}
          </Web3ProviderLazy>
        </MapImageContextProvider>
      </VideoPlayerContextProvider>
    </EnvironmentDisconnectHandler>
  );
};

export default ExperienceRoute;
