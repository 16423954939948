import styled from "styled-components";
import Space from "../../componentsLibrary/atoms/Space";
import Spatial from "../../componentsLibrary/atoms/Spatial";
import Card from "../../componentsLibrary/molecules/Card";
import { useText } from "../language/language.hook";
import { Props } from "./Quest.ui";
import QuestFilter from "./component/QuestFilter";
import QuestHeader from "./component/QuestHeader";
import QuestList from "./component/QuestList";

const PaddingWrapper = styled.div`
  padding: 0px 16px;
`;

const QuestUiNormal: React.FC<Props> = ({
  handleDismiss,
  buttonFilters,
  isLoadingQuests,
  quests,
}) => {
  const t = useText();

  return (
    <Spatial tiltX={-6} rim glare mouseZoom mouseTiltX mouseTiltY dragMotion>
      <Card
        testId={"normal"}
        height="610px"
        width="418px"
        hideScrollSpace
        hideScrollButton
        onClose={handleDismiss}
      >
        <PaddingWrapper>
          <QuestHeader
            title={t("quest_panel_title")}
            description={t("quest_panel_description")}
          />
          <Space h={4} />
          <QuestFilter buttonFilters={buttonFilters} />
          <Space h={4} />
          <QuestList isLoadingQuests={isLoadingQuests} quests={quests} />
        </PaddingWrapper>
        <Space h={6} />
      </Card>
    </Spatial>
  );
};

export default QuestUiNormal;
