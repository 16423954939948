import { useReflow } from "../../common/hooks/ui";
import RestrictionUiLandscape from "./Restriction.ui.landscape";
import RestrictionUiNormal from "./Restriction.ui.normal";
import RestrictionUiPortrait from "./Restriction.ui.portrait";

const RestrictionUi: React.FC = () => {
  return useReflow({
    normal: <RestrictionUiNormal />,
    portrait: <RestrictionUiPortrait />,
    landscape: <RestrictionUiLandscape />,
  });
};

export default RestrictionUi;
