import { GyaradosSDK } from "@journee-live/gyarados";
import useFullscreenMode from "../../../common/hooks/useFullscreenMode";
import { SettingName } from "../subpages/home/SettingsHomePage.ui";

const useHiddenSettings = (
  environment: GyaradosSDK.EnvironmentConfigResponseDto
) => {
  const { canFullscreen } = useFullscreenMode();
  const hidden: SettingName[] = [];
  if (!environment.voiceOn) hidden.push("mic");
  if (!environment.voiceVideoOn) hidden.push("video");
  if (!canFullscreen) hidden.push("fullscreen");
  if (!environment.description) hidden.push("about");
  if (!environment.enableWeb3) hidden.push("walletConnect");

  return { hidden };
};

export default useHiddenSettings;
