import { useCallback, useEffect } from "react";
import { TypesenseClient } from "@journee-live/mew/default/script/lib/default/types/typesense/typesenseTypes";
import Typesense from "typesense";
import { useEnvironmentContext } from "../../../../app/EnvironmentDataProvider";
import { removeEmailsFromString } from "../../../../app/monitoring/tracking/sanitize";
import { useStore } from "../../../../app/store";
import { logInfo, logWarn } from "../../../../common/util/logger";
import { SearchableProfile } from "../../../profile/lib/profileTypes";

let typesenseClient: TypesenseClient;
let collectionName: string;

const useSearchPlayerList = () => {
  const visitorToken = useStore((s) => s.session.visitorToken);
  const isLoggedIn = useStore((s) =>
    s.userFlow.isStepReached("login:start-action")
  );
  const { environment } = useEnvironmentContext();

  useEffect(() => {
    if (isLoggedIn && visitorToken && environment.id) {
      if (typesenseClient && collectionName) {
        return;
      }
      fetch(
        `${import.meta.env.VITE_GYARALESS_URL}/profiles/${
          environment.id
        }/search`,
        {
          headers: {
            Authorization: `Bearer ${visitorToken}`,
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          typesenseClient = new Typesense.Client({
            nodes: response.nodes,
            apiKey: response.key,
            connectionTimeoutSeconds: 2,
          }) as unknown as TypesenseClient;
          collectionName = response.collectionName;
        })
        .catch((error) => {
          logWarn(
            "GENERIC",
            "Error: Could not get users' profiles list context. This could impact certain features like the Player Search."
          );
          logWarn(
            "GENERIC",
            removeEmailsFromString(
              error?.message || error.response?.data?.message
            )
          );
        });
    }
  }, [environment.id, visitorToken, isLoggedIn]);

  const searchPlayerList = useCallback(async (searchValue: string) => {
    if (typesenseClient && collectionName) {
      try {
        const response = await typesenseClient
          .collections<SearchableProfile>(collectionName)
          .documents()
          .search({
            q: searchValue,
            query_by:
              "name, email, country, city, company, jobTitle, twitter, linkedin, instagram, facebook, xing",
            filter_by: `updated_at:>${Math.floor(Date.now() / 1000) - 15}`,
          });
        return response.hits;
      } catch (error: unknown) {
        if (error instanceof Error) {
          logInfo(
            "GENERIC",
            "Error: Player list: Could find user.",
            searchValue
          );
          logInfo("GENERIC", removeEmailsFromString(error?.message));
        }
      }
    }
  }, []);

  const findByUserId = useCallback(async (userId: string) => {
    if (typesenseClient && collectionName) {
      try {
        const response = await typesenseClient
          .collections<SearchableProfile>(collectionName)
          .documents()
          .search({
            q: "*",
            filter_by: `userId:${userId}`,
          });
        return response.hits;
      } catch (error: unknown) {
        if (error instanceof Error) {
          logInfo("GENERIC", "Error: Player list: Could find user.");
          logInfo("GENERIC", removeEmailsFromString(error?.message));
        }
      }
    }
  }, []);

  const findManyPlayers = useCallback(
    async (params: {
      userIds?: string[];
      roomId?: string;
      playerIds?: number[];
    }) => {
      const { userIds, roomId, playerIds } = params;
      if (typesenseClient && collectionName) {
        try {
          const filterMap = {
            userId: userIds ? `userId:[${userIds.join(",")}]` : "",
            roomId: roomId ? `photonRoomId:${roomId}` : "",
            playerId: playerIds
              ? `photonPlayerId:[${playerIds.join(",")}]`
              : "",
          };

          const filterBy = Object.values(filterMap)
            .filter((filter) => filter)
            .join(" && ");

          const response = await typesenseClient
            .collections<SearchableProfile>(collectionName)
            .documents()
            .search({
              q: "*",
              filter_by: filterBy,
            });
          return response.hits;
        } catch (error: unknown) {
          if (error instanceof Error) {
            logInfo("GENERIC", "Error: Player list: Could find user.");
            logInfo("GENERIC", removeEmailsFromString(error?.message));
          }
        }
      }
    },
    []
  );

  return {
    findByUserId,
    searchPlayerList,
    findManyPlayers,
  };
};

export default useSearchPlayerList;
