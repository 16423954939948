import { MouseEventHandler } from "react";
import { styled } from "styled-components";
import Button from "../../../componentsLibrary/atoms/Button";
import Hide from "../../../componentsLibrary/atoms/Hide";
import { useText } from "../../language/language.hook";

export const CustomAvatarButtonWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
`;

type Props = {
  hide: boolean;
  testId?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const CustomAvatarButton: React.FC<Props> = ({ hide, testId, onClick }) => {
  const t = useText();

  return (
    <CustomAvatarButtonWrapper>
      <Hide hide={hide} shiftY="20px">
        <Button.Bright onClick={onClick} testId={testId} buttonType="button">
          {t("profile_rpm_customize_button")}
        </Button.Bright>
      </Hide>
    </CustomAvatarButtonWrapper>
  );
};

export default CustomAvatarButton;
