import React from "react";
import HintUi from "../../../hint/Hint.ui";
import { useText } from "../../../language/language.hook";
import { HintWrapper } from "../../components/Wrappers";

type Props = {
  isPhotoShare: boolean;
  isSmall?: boolean;
  hideTabs?: boolean;
};

const ReadyHint: React.FC<Props> = ({ isPhotoShare, isSmall, hideTabs }) => {
  const t = useText();

  return (
    <HintWrapper $small={isSmall} $hideTabs={hideTabs}>
      <HintUi
        content={
          isPhotoShare
            ? t("media_share_ready_photo")
            : t("media_share_ready_video")
        }
        noAnimation
      />
    </HintWrapper>
  );
};

export default ReadyHint;
