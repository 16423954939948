import { useNavigate } from "react-router";
import { useGetLoginPath } from "../../app/hooks/routing.hook";
import Button from "../../componentsLibrary/atoms/Button";
import Column from "../../componentsLibrary/atoms/Column";
import HeaderBadge from "../../componentsLibrary/atoms/HeaderBadge";
import Icon from "../../componentsLibrary/atoms/Icon";
import Space from "../../componentsLibrary/atoms/Space";
import Typo from "../../componentsLibrary/atoms/Typo";
import { useText } from "../language/language.hook";
import SpacePageUi from "../utilityPages/SpacePageUi";

const EndSessionPageUi: React.FC = () => {
  const navigate = useNavigate();
  const loginPath = useGetLoginPath();
  const t = useText();

  return (
    <SpacePageUi>
      <Column
        textAlign="center"
        justify="center"
        align="center"
        gap={4}
        width="600px"
      >
        <HeaderBadge rippleLineWidth={5} noBackground color="inverted">
          <Icon.PersonWalking inheritColor />
        </HeaderBadge>
        <Typo.Title color="white">{t("end_session_ended")}</Typo.Title>
        <Typo.Body color="white" size="17px">
          {t("end_session_description")}
        </Typo.Body>
        <Space h={2} />
        <Button.Bright
          onClick={() => {
            navigate(loginPath, {
              relative: "path",
            });
            navigate(0); // refresh
          }}
        >
          {t("end_session_back_to_start")}
        </Button.Bright>
      </Column>
    </SpacePageUi>
  );
};

export default EndSessionPageUi;
