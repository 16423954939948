import { generatePath, useParams } from "react-router-dom";
import { mapDomainToEnvironment } from "../../common/constants/configs.constant";
import { PATH } from "../lib/paths";

/**
 * Returns the environment and organisation slugs for a custom domain.
 */
export const getEnvironmentSlugFromDomain = (): {
  envSlug?: string;
  orgSlug?: string;
} => {
  return mapDomainToEnvironment.get(window.location.hostname) || {};
};

export const useEnvironmentPath = () => {
  const param = useParams();
  return param.envSlug
    ? { orgSlug: param.orgSlug, envSlug: param.envSlug }
    : getEnvironmentSlugFromDomain();
};

export const useGetLogoutPath = () => {
  const { envSlug, orgSlug } = useEnvironmentPath();
  return generatePath(PATH.logout, {
    orgSlug: orgSlug ?? null,
    envSlug: envSlug ?? null,
  });
};

export const useEndedPath = () => {
  const { envSlug, orgSlug } = useEnvironmentPath();
  return generatePath(PATH.ended, {
    orgSlug: orgSlug ?? null,
    envSlug: envSlug ?? null,
  });
};

export const useGetLoginPath = () => {
  const { envSlug, orgSlug } = useEnvironmentPath();
  return generatePath(PATH.experience, {
    orgSlug: orgSlug ?? null,
    envSlug: envSlug ?? null,
  });
};

export const useGetDevControlsPath = () => {
  const { envSlug, orgSlug } = useEnvironmentPath();
  if (envSlug)
    return generatePath(PATH.devtoolsEnvironment, {
      orgSlug: orgSlug ?? null,
      envSlug: envSlug ?? null,
    });
  else return generatePath(PATH.devtools);
};

export const useGetIframeTestControlsPath = () => {
  const { envSlug, orgSlug } = useEnvironmentPath();
  if (envSlug)
    return generatePath(PATH.iframeTestEnvironment, {
      orgSlug: orgSlug ?? null,
      envSlug: envSlug ?? null,
    });
  else return generatePath(PATH.devtools);
};

export const useGetDevPath = () => {
  const { envSlug, orgSlug } = useEnvironmentPath();
  return generatePath(PATH.devExperience, {
    orgSlug: orgSlug ?? null,
    envSlug: envSlug ?? null,
  });
};

export const useGetTalebookPath = () => {
  const { envSlug, orgSlug } = useEnvironmentPath();
  return generatePath(PATH.talebookCatalog, {
    orgSlug: orgSlug ?? null,
    envSlug: envSlug ?? null,
  });
};

export const useGetIframePath = () => {
  const { envSlug, orgSlug } = useEnvironmentPath();
  return generatePath(PATH.iframeDevExperience, {
    orgSlug: orgSlug ?? null,
    envSlug: envSlug ?? null,
  });
};
