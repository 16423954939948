import { useReflow } from "../../common/hooks/ui";
import EndingUiNormal from "./Ending.ui.normal";
import EndingUiSmall from "./Ending.ui.small";

export type Props = {
  isInactive: boolean;
  isClosing: boolean;
  countDown: number;
};

const EndingUi: React.FC<Props> = (props) => {
  return useReflow({
    normal: <EndingUiNormal {...props} />,
    small: <EndingUiSmall {...props} />,
  });
};

export default EndingUi;
