import { VideoConferenceStateType } from "../../../videoConference/types/videoConference.types";
import SettingsVideoNoDevicesNormal from "./SettingsVideoNoDevices.normal";
import SettingsVideoPageNormal from "./SettingsVideoPage.normal";
import SettingsVideoPermissionsBlockedNormal from "./SettingsVideoPermissionsBlocked.normal";

export type Props = {
  onJoinAsListener: () => void;
  onReloadPage: () => void;
  videoConferenceState: VideoConferenceStateType;
  onAskForPermission: () => void;
  onEnableLater: () => void;
};

const SettingsVideoPageUi: React.FC<Props> = (props) => {
  switch (props.videoConferenceState) {
    case "BLOCKED_PERMISSIONS":
      return <SettingsVideoPermissionsBlockedNormal {...props} />;
    case "NO_DEVICES":
      return <SettingsVideoNoDevicesNormal {...props} />;
    case "PERMISSION_SETUP":
    case "DEVICE_SETUP":
    default:
      return <SettingsVideoPageNormal {...props} />;
  }
};

export default SettingsVideoPageUi;
