import { GameColor } from "../../../../../app/gameConnection/messages/sharedDataTypes";
import { useReflow } from "../../../../../common/hooks/ui";
import { VideoConferenceParticipant } from "../../../../videoConference/types/videoConference.types";
import FullPlayerProfileUiNormal from "./FullPlayerProfile.ui.normal";
import { AvailableSocials } from "./PlayerProfile.ui";

export type Props = {
  playerName: string;
  playerTitle?: string;
  playerCompany?: string;
  avatarId?: string;
  isStillConnected: boolean;
  avatarColor: GameColor;
  socials?: AvailableSocials;
  playerKey: string;
  participant: VideoConferenceParticipant | null;
  onTeleport: () => void;
  onPrevious: () => void;
};

const FullPlayerProfileUi: React.FC<Props> = (props) => {
  return useReflow({
    landscape: <FullPlayerProfileUiNormal {...props} />,
    portrait: <FullPlayerProfileUiNormal {...props} />,
    normal: <FullPlayerProfileUiNormal {...props} />,
  });
};

export default FullPlayerProfileUi;
