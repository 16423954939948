import { SvgProps } from "../../types/svg";

const WheelIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 10C2.5 5.85742 5.85742 2.5 10 2.5C14.1426 2.5 17.5 5.85742 17.5 10C17.5 14.1426 14.1426 17.5 10 17.5C5.85742 17.5 2.5 14.1426 2.5 10ZM7.44824 10.9375H4.45264C4.84785 13.293 6.70703 15.1533 9.0625 15.5459V13.0146L7.44824 10.9375ZM10.9375 13.0146V15.5459C13.293 15.1533 15.1533 13.293 15.5459 10.9375H12.5518L10.9375 13.0146ZM12.8125 9.0625H15.5459C15.1006 6.40234 12.7861 4.375 10 4.375C7.21387 4.375 4.89912 6.40234 4.45264 9.0625H7.1875L7.39844 8.64355C7.55664 8.32422 7.88184 8.125 8.23633 8.125H11.7637C12.1182 8.125 12.4434 8.32422 12.6016 8.64355L12.8125 9.0625Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default WheelIcon;
