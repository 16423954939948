import { Fragment } from "react";
import { styled } from "styled-components";
import { steps } from "../../../../../app/style/theme";
import Space from "../../../../../componentsLibrary/atoms/Space";
import Typo from "../../../../../componentsLibrary/atoms/Typo";
import { useControls } from "../controlHooks";

const ScreenPlaceholder = styled.div<{ image?: string }>`
  width: 100%;
  height: 168px;
  ${(p) =>
    p.image &&
    `background-image: url(${p.image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  `}
  border: 1px solid ${(p) => p.theme.colorAbove1};
  border-radius: ${steps.borderRadius.b30};
  position: relative;
`;

const ButtonsLine = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  > *:not(:first-child) {
    margin-inline-start: 4px;
  }
`;

const ButtonLabels = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  > *:not(:first-child) {
    margin-inline-start: 1px;
  }
`;

const ButtonsSchema: React.FC = () => {
  const { touchButtons, image } = useControls();

  return (
    <>
      <ScreenPlaceholder image={image}>
        <ButtonsLine>{touchButtons.map((button) => button.icon)}</ButtonsLine>
      </ScreenPlaceholder>
      <Space h={1} />
      <ButtonLabels>
        {touchButtons.map((button, buttonIndex) => (
          <Fragment key={buttonIndex}>
            {buttonIndex !== 0 ? <Typo.Body>&bull;</Typo.Body> : null}
            <Typo.Body>{button.label}</Typo.Body>
          </Fragment>
        ))}
      </ButtonLabels>
    </>
  );
};

export default ButtonsSchema;
