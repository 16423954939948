import { ActionElementDto } from "@journee-live/gyarados/axios/esm";
import { useTheme } from "styled-components";
import { sendGameMessage } from "../../../app/gameConnection/webrtc/webRtcMessageHandlers";
import Button from "../../../componentsLibrary/atoms/Button";
import { IconName } from "../../../componentsLibrary/atoms/Icon";
import Typo from "../../../componentsLibrary/atoms/Typo";
import ActionElementIcon from "./ActionElementIcon";

export const ActionElementButton: React.FC<{
  actionElementConfig: ActionElementDto;
}> = ({ actionElementConfig }) => {
  const theme = useTheme();
  return (
    <Button.Glass
      onClick={() => {
        sendGameMessage({
          type: "ActionElementEvent",
          actionElementId: actionElementConfig.actionElementId,
          event: "click",
        });
      }}
      override={{ height: "32px", color: theme.colorAbove4 }}
      iconLeft={
        <ActionElementIcon
          name={actionElementConfig.icon as IconName}
          size="20px"
        />
      }
    >
      <Typo.Body
        weight={600}
        style={{ marginLeft: "4px" }}
        color={theme.colorAbove4}
      >
        {actionElementConfig.name}
      </Typo.Body>
    </Button.Glass>
  );
};

export default ActionElementButton;
