import { ReactNode, useEffect, useRef } from "react";
import { debounce, isEqual } from "lodash";
import { logError } from "../common/util/logger";
import { ProfileData } from "../domains/profile/lib/profileTypes";
import { parseProfileForGame } from "../domains/profile/util/profile";
import { useEnvironmentContext } from "./EnvironmentDataProvider";
import { sendGameMessage } from "./gameConnection/webrtc/webRtcMessageHandlers";
import { removeEmailsFromString } from "./monitoring/tracking/sanitize";
import { useStore } from "./store";

async function sendProfileData(
  environmentId: string,
  visitorToken: string,
  profileData: ProfileData,
  roomId: string,
  playerId: number
) {
  sendGameMessage({
    type: "BusinessCard",
    ...parseProfileForGame(profileData),
  });

  const response = await fetch(
    `${import.meta.env.VITE_GYARALESS_URL}/profiles/${environmentId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${visitorToken}`,
      },
      body: JSON.stringify({
        // TODO: Change this bloody end-point's schema
        businessCard: profileData,
        photonRoomId: roomId,
        photonPlayerId: playerId,
        updated: true,
      }),
    }
  );

  if (!response.ok) {
    const error = await response.json();
    logError(
      "GENERIC",
      "Upserting visitor profile.",
      removeEmailsFromString(error?.message || error.response?.data?.message)
    );
  }
}

const debouncedSendProfileData = debounce(sendProfileData, 200, {
  leading: false,
  trailing: true,
});

const ProfileWrapper: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { environmentId } = useEnvironmentContext();
  const visitorToken = useStore((s) => s.session.visitorToken);
  const profileData = useStore((s) => s.profile.profileData);
  const roomId = useStore((s) => s.gameConnection.roomId);
  const playerId = useStore((s) => s.gameConnection.playerId);
  const previousProfileData = useRef({});

  useEffect(() => {
    if (!visitorToken) return;
    if (!roomId || !playerId) return;

    if (!isEqual(profileData, previousProfileData.current)) {
      debouncedSendProfileData(
        environmentId,
        visitorToken,
        profileData,
        roomId,
        playerId
      );
      previousProfileData.current = profileData;
    }
  }, [profileData, environmentId, visitorToken, roomId, playerId]);

  return <>{children}</>;
};

export default ProfileWrapper;
