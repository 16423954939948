import { styled } from "styled-components";
import { steps } from "../../app/style/theme";
import CardContentAlignment from "../../componentsLibrary/atoms/CardContentAlignment";
import Space from "../../componentsLibrary/atoms/Space";
import CardFullLandscape from "../../componentsLibrary/molecules/CardFullLandscape";
import { Props } from "./Poll.ui";
import useGetPollElements from "./hooks/useGetPollElements";

// We need this padding in order for shadows to be visible
// Otherwise, the shadow is clipped by the Scroll component
const PollContainer = styled.div`
  padding-left: 32px;
  padding-right: max(var(--safe-margin-right), 64px);
`;

// Because of the padding, we need to nudge the Header Component to the right, so it looks centered
export const NudgeRight = styled.div`
  margin-left: ${steps.spacing[6]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const PollUiNormal: React.FC<Props> = ({
  handleDismiss,
  poll,
  testId,
  ...rest
}) => {
  const isLeftAlign = true;

  const { Header, Body, Button, Title, Subtitle, ErrorMessage } =
    useGetPollElements({ poll, handleDismiss, isLeftAlign, ...rest });
  return (
    <CardFullLandscape
      testId={testId}
      topSection={<NudgeRight>{Header}</NudgeRight>}
      onClose={poll?.dismissible ? handleDismiss : undefined}
    >
      <Space h={9} />
      <CardContentAlignment>
        <PollContainer>
          {Title}
          <Space h={5} />
          {Subtitle}
          {Body}
          {Button}
          <Space h={2} />
          {ErrorMessage}
        </PollContainer>
      </CardContentAlignment>
    </CardFullLandscape>
  );
};

export default PollUiNormal;
