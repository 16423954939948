/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { FromGameMessage } from "../../app/gameConnection/messages/fromGameMessages";
import IframeAdapter from "../../domains/iframe/IframeAdapter";
import { isIFrame } from "../constants/flags.constant";

const useIframeAdapter = () => {
  const [iframeAdapterApi, setIframeAdapterApi] = useState<{
    iframeAdapterInstance: IframeAdapter | null;
    sendMessageToParent: ((data: any) => void) | null;
    sendGameMessageToParent: ((gameMessage: FromGameMessage) => void) | null;
    subscribeToParentMessages:
      | ((handler: (message: CustomEvent) => void) => {
          unsubscribeFromParentMessages: () => void;
        })
      | null;
  }>({
    iframeAdapterInstance: null,
    sendMessageToParent: null,
    sendGameMessageToParent: null,
    subscribeToParentMessages: null,
  });

  useEffect(() => {
    if (!isIFrame) return;
    const iframeAdapterInstance = IframeAdapter.getInstance();
    setIframeAdapterApi({
      iframeAdapterInstance,
      sendMessageToParent: iframeAdapterInstance.sendMessageToParent,
      sendGameMessageToParent: iframeAdapterInstance.sendGameMessageToParent,
      subscribeToParentMessages:
        iframeAdapterInstance.subscribeToParentMessages,
    });
  }, []);

  return {
    iframeAdapterInstance: iframeAdapterApi.iframeAdapterInstance,
    sendMessageToParent: iframeAdapterApi.sendMessageToParent,
    sendGameMessageToParent: iframeAdapterApi.sendGameMessageToParent,
    subscribeToParentMessages: iframeAdapterApi.subscribeToParentMessages,
  };
};

export default useIframeAdapter;
