import styled from "styled-components";
import { PanelTestIds } from "../../../../common/constants/testIds.constant";
import Button from "../../../../componentsLibrary/atoms/Button";
import Space from "../../../../componentsLibrary/atoms/Space";
import { useText } from "../../../language/language.hook";
import { Props } from "./SettingsVideoPreview.ui";
import VideoPreview from "./component/VideoPreview";

const BottomWrapper = styled.div`
  position: fixed;
  bottom: 64px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const SettingsVideoPreviewNormal: React.FC<Props> = (props) => {
  const t = useText();
  const { conferenceInitialized, onJoinConference, onLeaveConference } = props;

  return (
    <VideoPreview
      {...props}
      buttonComponent={
        <>
          <Space h={10} />
          <Space h={6} />
          <BottomWrapper>
            {!conferenceInitialized && (
              <Button.Primary
                large
                onClick={onJoinConference}
                testId={PanelTestIds.settings.common.joinVideoConferenceButton}
              >
                {t("settings_join_video_chat")}
              </Button.Primary>
            )}
            {conferenceInitialized && (
              <Button.Primary
                large
                onClick={onLeaveConference}
                testId={PanelTestIds.settings.common.leaveVideoConferenceButton}
              >
                {t("settings_leave_video_chat")}
              </Button.Primary>
            )}
          </BottomWrapper>
        </>
      }
    />
  );
};

export default SettingsVideoPreviewNormal;
