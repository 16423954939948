import { useMemo, useState } from "react";
import * as RTooltip from "@radix-ui/react-tooltip";
import styled from "styled-components";
import { useAnimatedShowHideState } from "../../common/hooks/ui";
import Hide from "../atoms/Hide";
import Typo from "../atoms/Typo";

const Box = styled.div`
  height: 24px;
  border-radius: ${(p) => p.theme.radiusTiny};
  padding: 4px 8px;
  color: ${(p) => p.theme.colorAbove4};
  background-color: ${(p) => p.theme.colorBelow1};
  backdrop-filter: blur(${(p) => p.theme.blurLight});
  user-select: none;
  display: flex;
  align-items: center;
`;

export type Props = {
  children?: React.ReactNode | React.ReactNode[];
  label: React.ReactNode;
  side?: "top" | "right" | "bottom" | "left";
  offset?: number;
  /** Milliseconds. */
  delay?: number;
  /** If ou want to manually provvide a Radix Tooltip Provider. */
  skipProvider?: boolean;
};

const Tooltip: React.FC<Props> = ({
  children,
  label,
  side = "right",
  delay = 500,
  offset = 5,
  skipProvider,
}) => {
  const [open, setOpen] = useState(false);
  const { animation, startHiding } = useAnimatedShowHideState({
    currentState: open ? "show" : "hide",
    hidingAnimationDuration: 300,
    onHideDone: () => {
      setOpen(false);
    },
  });

  const content = useMemo(
    () => (
      <RTooltip.Root
        open={open}
        onOpenChange={(val) => {
          if (!val) startHiding();
          else setOpen(true);
        }}
      >
        <RTooltip.Trigger asChild>{children}</RTooltip.Trigger>
        <RTooltip.Portal>
          <RTooltip.Content forceMount side={side} sideOffset={offset}>
            <Hide hide={animation === "hide"} speed={300}>
              <TooltipUi label={label} />
            </Hide>
          </RTooltip.Content>
        </RTooltip.Portal>
      </RTooltip.Root>
    ),
    [animation, children, label, offset, open, side, startHiding]
  );

  return skipProvider ? (
    content
  ) : (
    <RTooltip.Provider delayDuration={delay}>{content}</RTooltip.Provider>
  );
};

type PropsUi = {
  label: React.ReactNode;
};

export const TooltipUi: React.FC<PropsUi> = ({ label }) => {
  return (
    <Box>
      <Typo.Body color="inherit" size="11px">
        {label}
      </Typo.Body>
    </Box>
  );
};

export default Tooltip;
