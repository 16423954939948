import { PanelTestIds } from "../../common/constants/testIds.constant";
import { getImageUrl } from "../../common/util/gyarados.util";
import CardImage from "../../componentsLibrary/atoms/CardImage";
import Column from "../../componentsLibrary/atoms/Column";
import Markdown from "../../componentsLibrary/atoms/Markdown";
import Row from "../../componentsLibrary/atoms/Row";
import Space from "../../componentsLibrary/atoms/Space";
import Typo from "../../componentsLibrary/atoms/Typo";
import CardPartialPortrait from "../../componentsLibrary/molecules/CardPartialPortrait";
import { Props } from "./InfoCard.ui";
import InfoCardActions, {
  getActionBooleans,
} from "./component/InfoCardActions";

const InfoCardUiPortrait: React.FC<Props> = ({
  data,
  onButtonClick,
  onSecondaryButtonClick,
  handleDismiss,
  testId,
}) => {
  const { image, title, description } = data;
  const { hasActions } = getActionBooleans(data);
  return (
    <CardPartialPortrait
      testId={testId}
      topSectionHeight={image ? "200px" : "100px"}
      topSection={
        <CardImage
          testId={
            image
              ? PanelTestIds.infoCard.image
              : PanelTestIds.infoCard.iconPortrait
          }
          src={getImageUrl(image, 700, 700)}
          width="100%"
          height="200px"
          styleOverrides={{ borderRadius: "0px" }}
          orientation="portrait"
        />
      }
      onClose={handleDismiss}
      bottomSection={
        <>
          {hasActions && (
            <Row width="100%" height="80px" align="center" justify="center">
              <InfoCardActions
                data={data}
                onButtonClick={onButtonClick}
                onSecondaryButtonClick={onSecondaryButtonClick}
              />
            </Row>
          )}
          <Space h={6} />
        </>
      }
      brightCloseButton={Boolean(image)}
    >
      <Column gap={3} align="center">
        {title && (
          <Typo.Title testId={PanelTestIds.infoCard.title} align="center">
            {title}
          </Typo.Title>
        )}
        {description && <Markdown content={description} textAlign="center" />}
      </Column>
    </CardPartialPortrait>
  );
};

export default InfoCardUiPortrait;
