import { useEffect } from "react";
import { useStore } from "../../../app/store";
import { PropsLoginPageUi } from "../LoginPageUi";
import { useValidatePreExistingTokens } from "../loginHooks";

const useAuthCheckStep = (
  setLoginPageProps: (props: PropsLoginPageUi) => void
) => {
  const currentStep = useStore((s) => s.userFlow.currentStep);
  const completeStep = useStore((s) => s.userFlow.completeStep);
  const setVisitorToken = useStore((s) => s.session.setVisitorToken);
  const existingToken = useStore((s) => s.session.visitorToken);
  const { token, isLoading, error, isRestricted } =
    useValidatePreExistingTokens(existingToken);

  useEffect(() => {
    if (currentStep !== "login:auth-check") return;
    if (!isLoading) {
      if (token) {
        setVisitorToken(token);
      } else {
        setVisitorToken("");
      }
      if (!error && !isRestricted) completeStep("login:auth-check");
    }
  }, [
    token,
    isLoading,
    error,
    completeStep,
    setVisitorToken,
    currentStep,
    isRestricted,
  ]);

  useEffect(() => {
    if (currentStep !== "login:auth-check") return;
    if (!error && !isRestricted) return;
    setLoginPageProps({
      title: "",
      ...(Boolean(error) && {
        descriptionHeader: "warning",
        description: error?.message,
      }),
      isMainButtonVisible: false,
      isRestricted,
    });
  }, [currentStep, error, setLoginPageProps, isRestricted]);
};

export default useAuthCheckStep;
