import { styled } from "styled-components";

export const BottomCenterBar = styled.div`
  border-radius: ${(p) => p.theme.radiusFull};
  background-color: ${(p) => p.theme.colorBelow1};
  backdrop-filter: blur(${(p) => p.theme.blurLight});
  padding: 2px;
  position: relative;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  gap: 2px;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: max(var(--safe-margin-bottom), 80px);
`;
