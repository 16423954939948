import { t } from "i18next";
import { PanelTestIds } from "../../../common/constants/testIds.constant";
import Button from "../../../componentsLibrary/atoms/Button";
import { Props } from "../Popup.ui";

export const getButtons = ({
  popup,
  link,
  handleTeleport,
  handleExternalLink,
  onClose,
}: Props) => {
  const hasSpawnPoint = typeof popup?.spawnPoint === "number";
  const hasExternalUrl = link ?? popup?.externalUrl;

  const buttons = [];
  if (hasSpawnPoint) {
    buttons.push(
      <Button.Primary
        onClick={handleTeleport}
        key="spawn-button"
        testId={PanelTestIds.popup.buttons.spawnPoint}
      >
        {popup.buttonText || t("action_panel_lets_go")}
      </Button.Primary>
    );
  }
  if (hasExternalUrl) {
    buttons.push(
      <Button.Primary
        onClick={handleExternalLink}
        testId={PanelTestIds.popup.buttons.externalUrl}
        key="external-url"
      >
        {popup?.buttonText}
      </Button.Primary>
    );
  }
  if (popup?.dismissible) {
    if (hasSpawnPoint || hasExternalUrl) {
      buttons.push(
        <Button.Secondary
          onClick={onClose}
          large
          key="dismiss-text"
          testId={PanelTestIds.popup.buttons.dismissBtn}
        >
          {popup.dismissButtonText || t("action_panel_back")}
        </Button.Secondary>
      );
    } else {
      buttons.push(
        <Button.Primary
          onClick={onClose}
          key="dismiss-text"
          testId={PanelTestIds.popup.buttons.dismissBtn}
        >
          {popup.dismissButtonText || t("action_panel_back")}
        </Button.Primary>
      );
    }
  }

  return buttons;
};
