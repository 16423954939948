import { styled } from "styled-components";
import Clickable from "../../componentsLibrary/atoms/Clickable";
import Glass from "../../componentsLibrary/atoms/Glass";
import Row from "../../componentsLibrary/atoms/Row";
import Space from "../../componentsLibrary/atoms/Space";
import Spatial from "../../componentsLibrary/atoms/Spatial";
import Typo from "../../componentsLibrary/atoms/Typo";
import CloseButton from "../../componentsLibrary/molecules/CloseButton";
import { StatEntry } from "./lib/statsTypes";

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 1rem;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0.5rem;
  box-sizing: border-box;
  padding: 0 1.5rem;
  font-size: ${(p) => p.theme.t};
  gap: 1rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Tr = styled.tr`
  position: relative;
  padding: 0 0.5em;
  white-space: nowrap;

  td {
    vertical-align: middle;
    position: relative;
    z-index: 1;

    &:first-child {
      padding-inline-start: 0.2rem;
    }

    &:last-child {
      padding: 0 0.2rem 0 1em;
    }

    svg path {
      stroke: "inherit";
      stroke-width: 1.5;
    }
  }
`;

const TableRow: React.FC<{
  label: string;
  title?: string;
  hidden?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}> = ({ label, title, children, hidden, onClick, style }) => {
  return hidden ? null : (
    <Tr title={title} onClick={onClick} style={style}>
      <td>
        <Typo.Body>{label}</Typo.Body>
      </td>
      <td>
        <Typo.Body>{children}</Typo.Body>
      </td>
    </Tr>
  );
};

type Props = {
  entries: StatEntry[];
  onClose?: () => void;
  isAdvancedMode?: boolean;
  onToggleAdvancedMode?: () => void;
  onReport: () => void;
};

/* eslint-disable i18next/no-literal-string */
const StatsUi: React.FC<Props> = ({
  entries,
  onClose,
  isAdvancedMode,
  onToggleAdvancedMode,
  onReport,
}) => {
  return (
    <Spatial dragMotion rim glare>
      <Glass>
        <CloseButtonWrapper>
          <CloseButton onClick={onClose} />
        </CloseButtonWrapper>
        <Space h={4} />
        <Row gap={2}>
          <Typo.Title>Statistics</Typo.Title>
        </Row>
        <Space h={4} />
        <Table>
          <tbody>
            {entries.map((entry, index) => (
              <TableRow
                key={index}
                label={entry.label}
                title={entry.hint}
                hidden={entry.hidden || (!isAdvancedMode && entry.advanced)}
                style={{ cursor: "help" }}
              >
                {entry.value}
              </TableRow>
            ))}
          </tbody>
        </Table>
        <Footer>
          <FooterButton onClick={onReport}>Report an Issue</FooterButton>
          <FooterButton onClick={onToggleAdvancedMode}>
            {isAdvancedMode ? "Less" : "More..."}
          </FooterButton>
        </Footer>
      </Glass>
    </Spatial>
  );
};

const FooterButton: React.FC<{
  children: React.ReactNode;
  onClick?: () => void;
}> = ({ onClick, children }) => {
  return (
    <Clickable backgroundColor="transparent" padding="0" onClick={onClick}>
      <Typo.Label>{children}</Typo.Label>
    </Clickable>
  );
};

export default StatsUi;
