import { useEffect, useRef, useState } from "react";
import { isFirefox } from "../common/constants/flags.constant";

/**
 * Fix for the bug https://bugzilla.mozilla.org/show_bug.cgi?id=1861895
 * In summary, firefox has a bug where it renders the stream from webrtc using the h.264 codec,
 * it renders the stream with shuffled frames, which creates a jittery effect.
 * This hook is used to detect if the user is using firefox and if the user is using a nvidia graphics card.
 *
 * you can also look into the documents code/firefox-glitch.md
 */
export const useFirefoxFix = () => {
  const [vendor, setVendor] = useState<string | null>(null);
  const [renderer, setRenderer] = useState<string | null>(null);
  const [applyFilter, setApplyFilter] = useState<boolean>(false);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (!canvasRef.current) return;
    if (!isFirefox) {
      canvasRef.current.remove();
      return;
    }
    const canvas = canvasRef.current;

    const gl = canvas.getContext("webgl");

    if (!gl) return;
    const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
    if (!debugInfo) return;
    //set vendor and renderer to store
    setVendor(gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL));
    setRenderer(gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL));
    // remove canvas
    canvasRef.current.remove();
  }, [canvasRef]);

  useEffect(() => {
    if (!isFirefox || !vendor || !renderer) return;
    // check if vendor and renderer are nvidia and firefox
    if (
      vendor.toLowerCase().includes("nvidia") &&
      renderer.toLowerCase().includes("geforce gtx")
    ) {
      setApplyFilter(true);
    }
  }, [vendor, renderer]);

  return { canvasRef, applyFilter };
};
