import { Route, Routes } from "react-router-dom";
import loadable from "@loadable/component";
import { mapDomainToEnvironment } from "../../common/constants/configs.constant";
import IframeDevComponentExample from "../../domains/_dev/IframeDevComponentExample";
import IframeMessageTester from "../../domains/_dev/IframeMessageTester";
import EndSessionPageUi from "../../domains/_ending/EndSessionPage.ui";
import LogoutRoute from "../../domains/_ending/Logout.route";
import ExperienceRoute from "../../domains/_experience/Experience.route";
import { PATH } from "../lib/paths";
import WithEnvironment from "./WithEnvironment";
import DataExternalRoute from "./routes/DataExternalRoute";
import RootRoute from "./routes/RootRoute";

const WithEnvironmentWithDebug = loadable(
  () => import("./WithEnvironmentWithDebug")
);
const AiWebcamDemoPage = loadable(
  () => import("../../domains/aiWebcamDemo/AiWebcamDemoPage")
);
const WithDebug = loadable(() => import("./WithDebug"));
const DevtoolsSender = loadable(
  () => import("../../domains/_dev/DevtoolsSender")
);
const DevExperienceRoute = loadable(
  () => import("../../domains/_dev/DevExperience.route")
);
const DevRootRoute = loadable(() => import("../../domains/_dev/DevRoot.route"));
const IframeDevRoute = loadable(
  () => import("../../domains/_dev/IframDev.route")
);
const TalebookRouteWrapper = loadable(
  () => import("../../common/taleBook/TalebookPlugin"),
  {
    resolveComponent: (module) => module.TalebookRouteWrapper,
  }
);

const EnvironmentSelectionRoute = loadable(
  () => import("../../common/taleBook/TalebookPlugin"),
  {
    resolveComponent: (module) => module.EnvironmentSelectionRoute,
  }
);

const isCustomDomain = mapDomainToEnvironment.has(window.location.hostname);
const isLabsOrStagingRoute = [
  "labs.journee.live",
  "labs.journee.space",
  "web.app",
  "localhost",
].some((domain) => window.location.hostname.includes(domain));

function Routing() {
  return (
    <Routes>
      {isLabsOrStagingRoute && (
        <Route
          path={PATH.aiWebcamDemoLabs}
          element={
            <WithDebug>
              <AiWebcamDemoPage />
            </WithDebug>
          }
        />
      )}

      <Route
        path={PATH.root}
        element={
          isCustomDomain ? (
            <WithEnvironmentWithDebug>
              <ExperienceRoute />
            </WithEnvironmentWithDebug>
          ) : (
            <WithDebug>
              <RootRoute />
            </WithDebug>
          )
        }
      />

      <Route
        path={PATH.logout}
        element={
          <WithDebug>
            <LogoutRoute />
          </WithDebug>
        }
      />

      <Route
        path={PATH.ended}
        element={
          <WithDebug>
            <EndSessionPageUi />
          </WithDebug>
        }
      />

      <Route
        path={PATH.experience}
        element={
          <WithEnvironmentWithDebug>
            <ExperienceRoute />
          </WithEnvironmentWithDebug>
        }
      />

      <Route
        path={PATH.localExperience}
        element={
          <WithEnvironmentWithDebug>
            <ExperienceRoute />
          </WithEnvironmentWithDebug>
        }
      />

      <Route
        path={PATH.dev}
        element={
          <WithDebug>
            <DevRootRoute />
          </WithDebug>
        }
      />

      <Route
        path={PATH.devExperience}
        element={
          <WithEnvironmentWithDebug>
            <DevExperienceRoute />
          </WithEnvironmentWithDebug>
        }
      />
      <Route
        path={PATH.iframeDevComponentExample}
        element={
          <WithEnvironmentWithDebug>
            <IframeDevComponentExample />
          </WithEnvironmentWithDebug>
        }
      />

      <Route
        path={PATH.iframeDevExperience}
        element={
          <WithEnvironmentWithDebug>
            <IframeDevRoute />
          </WithEnvironmentWithDebug>
        }
      />

      <Route path={PATH.devtools} element={<DevtoolsSender />} />
      <Route
        path={PATH.devtoolsEnvironment}
        element={
          <WithEnvironment>
            <DevtoolsSender />
          </WithEnvironment>
        }
      />

      <Route
        path={PATH.iframeTestEnvironment}
        element={
          <WithEnvironmentWithDebug>
            <DevExperienceRoute />
            <IframeMessageTester />
          </WithEnvironmentWithDebug>
        }
      />

      <Route path={PATH.dataExternal} element={<DataExternalRoute />} />

      <Route
        path={PATH.talebook}
        element={
          <WithDebug>
            <EnvironmentSelectionRoute />
          </WithDebug>
        }
      />

      <Route
        path={PATH.talebookCatalog}
        element={
          <WithEnvironmentWithDebug>
            <TalebookRouteWrapper />
          </WithEnvironmentWithDebug>
        }
      />

      <Route
        path={PATH.talebookPreview}
        element={
          <WithEnvironmentWithDebug>
            <TalebookRouteWrapper previewMode />
          </WithEnvironmentWithDebug>
        }
      />
    </Routes>
  );
}

export default Routing;
