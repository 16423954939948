import Overlay from "../../componentsLibrary/molecules/Overlay";
import RestrictionPanelUi from "./Restriction.ui";

const RestrictionPanel: React.FC = () => {
  return (
    <Overlay id="layout-restriction" open hasBackgroundBlur>
      <RestrictionPanelUi />
    </Overlay>
  );
};

export default RestrictionPanel;
