import { SvgProps } from "../../types/svg";

const VideoFilledIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6667 5.875V14.125C12.6667 14.8844 12.0697 15.5 11.3333 15.5H3.33333C2.59694 15.5 2 14.8844 2 14.125V5.875C2 5.1156 2.59694 4.5 3.33333 4.5H11.3333C12.0694 4.5 12.6667 5.1156 12.6667 5.875ZM18 6.31901V13.6781C18 14.4086 17.1897 14.8351 16.6003 14.4169L13.5556 12.2544V7.74557L16.6 5.58109C17.1917 5.16258 18 5.59141 18 6.31901Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default VideoFilledIcon;
