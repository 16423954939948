import styled, { useTheme } from "styled-components";
import Row from "../../../componentsLibrary/atoms/Row";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";

const Cell = styled.td`
  vertical-align: top;
  border: 1px solid ${(p) => p.theme.colorAbove0};
  padding: 10px;
`;

const BoldCell = styled(Cell)`
  color: ${(p) => p.theme.colorAbove5};
`;

const Header = styled.th`
  width: 20%;
  vertical-align: top;
  border: 1px solid ${(p) => p.theme.colorAbove0};
  color: ${(p) => p.theme.colorAbove5};
  text-align: start;
  padding: 10px 10px 20px 10px;
  background-color: ${(p) => p.theme.colorAbove0};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${(p) => p.theme.colorAbove0};
`;

/* eslint-disable i18next/no-literal-string */
const DataExternalPageUi: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      {" "}
      <Row width="100%" justify="center">
        <Typo.Title>Privacy Notice: EYVerse – Workforce Hub</Typo.Title>
      </Row>
      <Space h={6} />
      <Segment
        title="1. Introduction"
        content={
          <p>
            This Privacy Notice is intended to describe the practices EY follows
            in relation to the EYVerse – Workforce Hub (‘Tool’) with respect to
            the privacy of all individuals whose personal data is processed and
            stored in the Tool.
          </p>
        }
      />
      <Segment
        title="2. Who manages the Tool?"
        content={
          <>
            <p>
              “EY” refers to one or more of the member firms of Ernst & Young
              Global Limited (“EYG”), each of which is a separate legal entity
              and can act as a data controller in its own right. The entity that
              is acting as data controller by providing the Tool on which your
              personal data will be processed and stored is Ernst & Young LLP.
            </p>
            <p>
              The personal data you provide in the Tool is shared by EY with one
              or more member firms of EYG (see “Who can access your information”
              section below).
            </p>
            <p>The Tool is hosted on servers in Frankfurt, Germany.</p>
          </>
        }
      />
      <Segment
        title="3. Why do we need your information?"
        content={
          <>
            <p>
              The purpose of gathering the data is to provision access to the
              Journee platform, where users are invited into a virtual space to
              interact with content and other users. This could be either a
              digital twin (virtual replica of a location) or a generic space.
            </p>
            <p style={{ color: theme.colorAbove5 }}>
              Your personal data processed in the Tool is used as follows:{" "}
              <br />
              To provision access to the Journee platform, allowing users to
              interact with the experience.
            </p>
            <p style={{ color: theme.colorAbove5 }}>
              EY relies on the following basis to legitimise the processing of
              your personal data in the Tool: legitimate interests pursued by
              the data controller. The specific legitimate interest is
              conducting client engagements.
            </p>
            <p>The provision of your personal data to EY is optional.</p>
          </>
        }
      />
      <Segment
        title="4. What type of personal data is processed in the Tool?"
        content={
          <>
            <p>
              The Tool processes these personal data categories: <br />
              First name, last name, email, location, user name (country only)
              and grade title.
            </p>

            <p>This data is sourced from the client.</p>
          </>
        }
      />
      <Segment
        title="5. Sensitive Personal Data"
        content={
          <>
            <p>
              Sensitive personal data reveals your racial or ethnic origin,
              political opinions, religious or philosophical beliefs, trade
              union membership, genetic data, biometric data, data concerning
              health or data concerning sex life or sexual orientation.
            </p>
            <p>
              EY does not intentionally collect any sensitive personal data from
              you via the Tool. There is no intention to process such
              information.
            </p>
          </>
        }
      />
      <Segment
        title="6. Who can access your information?"
        content={
          <>
            <p>
              Your personal data is accessed in the Tool by the following
              persons/teams:
            </p>

            <Table>
              <tr>
                <Header>USER GROUP</Header>
                <Header>LOCATION</Header>
                <Header>PURPOSE</Header>
                <Header>ACCESS</Header>
                <Header>AMOUNT</Header>
              </tr>
              <tr>
                <BoldCell>Group name</BoldCell>
                <BoldCell>Identify country </BoldCell>
                <BoldCell>The action that the group performs</BoldCell>
                <BoldCell>
                  Identify whether access is read, update, add
                </BoldCell>
                <BoldCell>
                  Provide the approx. number of users with access
                </BoldCell>
              </tr>
              <tr>
                <Cell>Moderators</Cell>
                <Cell>UKI, Germany, France</Cell>
                <Cell>
                  Moderate the events, make sure the experiences run as planned,
                  manage the users and access, comms and other activities
                </Cell>
                <Cell>Read, update, add</Cell>

                <Cell>5-10</Cell>
              </tr>
              <tr>
                <Cell>Platform admins</Cell>
                <Cell>UKI, Germany, France</Cell>
                <Cell>
                  Make sure the platform runs as expected, manage different
                  environments within the platform, take care of any
                  modifications, ensure integrity of the platform
                </Cell>
                <Cell>Read, update, add</Cell>
                <Cell>2-3</Cell>
              </tr>
              <tr>
                <Cell>General users</Cell>
                <Cell>Global</Cell>
                <Cell>
                  Access the environment to interact with content and other
                  users
                </Cell>
                <Cell>Read</Cell>
                <Cell>1-500</Cell>
              </tr>
            </Table>

            <p>
              The access rights detailed above involves transferring personal
              data in various jurisdictions (including jurisdictions outside the
              European Union) in which EY operates (EY office locations are
              listed at{" "}
              <a href="https://www.ey.com/ourlocations">
                www.ey.com/ourlocations
              </a>
              ). EY will process your personal data in the Tool in accordance
              with applicable law and professional regulations in your
              jurisdiction. Transfers of personal data within the EY network are
              governed by EY’s Binding Corporate Rules (
              <a href="https://www.ey.com/bcr">www.ey.com/bcr</a>).
            </p>
          </>
        }
      />
      <Segment
        title="7. Data retention"
        content={
          <>
            <p>
              In relation to the retention of personal data in the Tool, data
              will be stored for the duration of an experience (access to the
              virtual platform). Once an experience ends, data will be stored
              for four weeks thereafter.
            </p>
            <p>
              Your personal data will be retained in compliance with privacy
              laws and regulations.
            </p>
            <p>
              After the end of the data retention period or when the purpose for
              processing (see question 3 above) is complete, your personal data
              will be deleted.
            </p>
          </>
        }
      />
      <Segment
        title="8. Security"
        content={
          <p>
            EY is committed to making sure your personal data is secure. To
            prevent unauthorized access or disclosure, EY has technical and
            organizational measures to safeguard and secure your personal data.
            All EY personnel and third parties EY engages to process your
            personal data are obliged to respect your data’s confidentiality.
          </p>
        }
      />
      <Segment
        title="9. Controlling your personal data"
        content={
          <>
            <p>
              EY will not transfer your personal data to third parties (other
              than any external parties referred to in section 6 above) unless
              we have your permission or are required by law to do so.
            </p>
            <p>
              You are legally entitled to request details of EY’s personal data
              about you.
            </p>
            <p>
              To confirm whether your personal data is processed in the Tool or
              to access your personal data in the the Tool, contact your usual
              EY representative or email your request to
              global.data.protection@ey.com.
            </p>
          </>
        }
      />
      <Segment
        title="10. Rectification, erasure, restriction of processing or data
              portability"
        content={
          <p>
            You can confirm your personal data is accurate and current. You can
            request rectification, erasure, restriction of processing or a
            readily portable copy of your personal data by contacting your usual
            EY representative or by sending an e-mail to
            global.data.protection@ey.com
          </p>
        }
      />
      <Segment
        title="11. Complaints"
        content={
          <>
            <p>
              If you are concerned about an alleged breach of privacy law or any
              other regulation, contact EY’s Global Privacy Officer, Office of
              the General Counsel, 6 More London Place, London, SE1 2DA, United
              Kingdom or via email at global.data.protection@ey.com or via your
              usual EY representative. An EY Privacy Officer will investigate
              your complaint and provide information about how it will be
              handled and resolved.
            </p>
            <p>
              If you are not satisfied with how EY resolved your complaint, you
              have the right to complain to your country’s data protection
              authority. You can also refer the matter to a court of competent
              jurisdiction.
            </p>
          </>
        }
      />
      <Segment
        title="12. Contact us"
        content={
          <p>
            If you have additional questions or concerns, contact your usual EY
            representative or email global.data.protection@ey.com.
          </p>
        }
      />
    </>
  );
};

export default DataExternalPageUi;

type SegmentProps = {
  title: string;
  content: React.ReactNode;
};

const Segment: React.FC<SegmentProps> = ({ title, content }) => {
  return (
    <>
      <Typo.Title size="20px">{title}</Typo.Title>
      <Typo.Body size="17px">{content}</Typo.Body>
    </>
  );
};
