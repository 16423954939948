import { css, styled, useTheme } from "styled-components";
import { steps } from "../../../../app/style/theme";
import Button from "../../../../componentsLibrary/atoms/Button";
import FadeIn from "../../../../componentsLibrary/atoms/FadeIn";
import Icon from "../../../../componentsLibrary/atoms/Icon";
import Row from "../../../../componentsLibrary/atoms/Row";

const Wrapper = styled.div<{ $isSmallscreenLandscape: boolean }>`
  height: 40px;
  ${(p) =>
    p.$isSmallscreenLandscape
      ? css`
          padding-right: 5%;
          padding-left: 5%;
          margin-right: max(var(--safe-margin-right), 64px);
          margin-left: max(var(--safe-margin-left), 64px);
        `
      : css`
          padding-right: 16px;
          padding-left: 8px;
          padding-bottom: 8px;
          padding-top: 4px;
        `};
`;

const TextInput = styled.input`
  height: 100%;
  border: none;
  padding: 0px;
  background-color: transparent;
  flex-grow: 2;
  margin: 0 10px;

  &,
  &::placeholder {
    font-size: ${steps.font.f20.size};
    font-family: ${(p) => p.theme.fontMain};
    font-weight: ${steps.fontWeight.medium};
    color: ${(p) => p.theme.colorAbove3};
  }
`;

export type Props = {
  onSend?: () => void;
  value: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  isSmallscreenLandscape: boolean;
};

const TextChatInputUi: React.FC<Props> = ({
  onSend,
  value,
  onChange,
  placeholder,
  isSmallscreenLandscape,
}) => {
  const theme = useTheme();
  const handleOnPressEnter = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      onSend?.();
    }
  };

  // TODO: check blur behaviour on mobile
  // const inputRef = useRef<HTMLInputElement>(null);
  // Blurs the input on Listens to the Player element click event and blurs the input.
  // useEffect(() => {
  //   const inputElement = chatInputRef.current;
  //   if (inputElement) {
  //     window.addEventListener("Player_Element_Clicked", () => {
  //       inputElement.blur();
  //     });
  //   }
  //   return () => {
  //     if (inputElement) {
  //       window.removeEventListener("Player_Element_Clicked", () => {});
  //     }
  //   };
  // }, []);
  const hasText = value.length > 0;
  return (
    <FadeIn>
      <Wrapper $isSmallscreenLandscape={isSmallscreenLandscape}>
        <Row align="center" justify="space-between">
          <TextInput
            type="text"
            onKeyDown={handleOnPressEnter}
            // ref={inputRef}
            value={value}
            onChange={(it) => onChange?.(it.target.value)}
            placeholder={placeholder}
          />
          <Button.Primary
            circular
            disabled={!hasText}
            onClick={onSend}
            override={{
              color: hasText ? theme.colorAboveBrand : theme.colorBelowBrand,
              width: "32px",
              height: "32px",
            }}
          >
            <Icon.Chevron rotate="90deg" inheritColor size="16px" />
          </Button.Primary>
        </Row>
      </Wrapper>
    </FadeIn>
  );
};

export default TextChatInputUi;
