import Column from "../../componentsLibrary/atoms/Column";
import Space from "../../componentsLibrary/atoms/Space";
import CardFullLandscape from "../../componentsLibrary/molecules/CardFullLandscape";
import { useText } from "../language/language.hook";
import { Props } from "./Quest.ui";
import QuestFilter from "./component/QuestFilter";
import QuestHeader from "./component/QuestHeader";
import QuestList from "./component/QuestList";

const QuestUiLandscape: React.FC<Props> = ({
  handleDismiss,
  buttonFilters,
  isLoadingQuests,
  quests,
}) => {
  const t = useText();
  return (
    <CardFullLandscape
      testId={"normal"}
      noPadding
      hideScrollButton
      onClose={handleDismiss}
    >
      <QuestHeader
        title={t("quest_panel_title")}
        description={t("quest_panel_description")}
      />
      <Space h={4} />
      <QuestFilter buttonFilters={buttonFilters} />
      <Space h={4} />
      <Column justify="center" align="center" width="100%">
        <Column width="380px">
          <QuestList isLoadingQuests={isLoadingQuests} quests={quests} />
          <Space h={6} />
        </Column>
      </Column>
    </CardFullLandscape>
  );
};

export default QuestUiLandscape;
