import { ReactNode, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { zIndex } from "../../app/style/theme";
import Hide from "../atoms/Hide";
import Transition from "../atoms/Transition";

const WrapperAbsolute = styled.div<{ $zIndex?: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${(p) =>
    p.$zIndex &&
    css`
      z-index: ${p.$zIndex};
    `}
`;

const Background = styled.div<{ $dark?: boolean; $blur?: boolean }>`
  position: absolute;
  width: 100vw;
  height: 100dvh;
  top: 0px;
  left: 0px;
  pointer-events: all;
  background-color: ${(p) => (p.$dark ? "#0000008b" : "transparent")};

  ${(p) =>
    p.$blur &&
    css`
      backdrop-filter: blur(15px);
      background-color: "transparent";
    `}
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  > * {
    pointer-events: all;
  }
`;

export type Props = {
  open?: boolean;
  /** Transition the content on change. Uses the <Transition> component API.*/
  watch?: unknown;
  id?: string;
  children: ReactNode;
  noTransitionOnMount?: boolean;
  onClose?: () => void;
  noOverlayDarken?: boolean;
  noOverlayClosing?: boolean;
  noEscClosing?: boolean;
  hasBackgroundBlur?: boolean;
  wrapperStyle?: React.CSSProperties | undefined;
  zIndex?: number;
};

const Overlay: React.FC<Props> = ({
  open,
  watch,
  id,
  children,
  onClose,
  noOverlayDarken,
  noOverlayClosing,
  noEscClosing,
  hasBackgroundBlur,
  wrapperStyle,
  noTransitionOnMount,
  zIndex: zIndexProp = zIndex.overlays,
}) => {
  // We need to heavily memoize the overlay background because it should not
  // re-render (thus transition) if the popup changes.

  const memoClose = useMemo(() => onClose, [onClose]);
  const memoBackground = useMemo(
    () =>
      open ? (
        <Background
          $dark={!noOverlayDarken}
          $blur={hasBackgroundBlur}
          onClick={() => !noOverlayClosing && memoClose?.()}
          id="what"
        />
      ) : null,
    [open, noOverlayDarken, hasBackgroundBlur, noOverlayClosing, memoClose]
  );

  // Listen for the "esc" keystroke and open the debug controls on a popup.
  useEffect(() => {
    if (noEscClosing) return;
    const listener = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose?.();
      }
    };
    window.addEventListener("keydown", listener);
    return () => window.removeEventListener("keydown", listener);
  }, [noEscClosing, onClose]);

  return (
    <WrapperAbsolute id={id} $zIndex={zIndexProp}>
      <WrapperAbsolute>
        <Hide hide={!open} height="100dvh">
          {memoBackground}
        </Hide>
      </WrapperAbsolute>
      <WrapperAbsolute>
        <Transition
          watch={[open, watch]}
          noTransitionOnMount={noTransitionOnMount}
          delay={300}
          height="100dvh"
          zIndex={zIndexProp + 1}
        >
          <ContentWrapper style={wrapperStyle}>
            {open && <>{children}</>}
          </ContentWrapper>
        </Transition>
      </WrapperAbsolute>
    </WrapperAbsolute>
  );
};

export default Overlay;
