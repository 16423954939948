import { CSSProperties } from "react";
import {
  AvatarCreator,
  AvatarCreatorConfig,
  AvatarExportedEvent,
} from "@readyplayerme/react-avatar-creator";
import { t } from "i18next";
import styled from "styled-components";
import { useEnvironmentContext } from "../../../app/EnvironmentDataProvider";
import { sendGameMessage } from "../../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { steps } from "../../../app/style/theme";
import { isMobile, isTablet } from "../../../common/constants/flags.constant";
import { PanelTestIds } from "../../../common/constants/testIds.constant";
import { useIsSmallScreen } from "../../../common/hooks/ui";
import { log } from "../../../common/util/logger";
import Space from "../../../componentsLibrary/atoms/Space";
import { getRpmSubdomain } from "../util/profile";

const config: AvatarCreatorConfig = {
  bodyType: "fullbody",
  quickStart: false,
};
const AbsoluteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const getStyle = (open: boolean, isSmallScreen: boolean): CSSProperties => {
  return {
    border: "none",
    width: "100%",
    height: isSmallScreen ? "85vh" : isTablet ? "60vh" : "530px",
    borderRadius: steps.borderRadius.b30,
    visibility: open ? "visible" : "hidden",
    position: open ? "relative" : "absolute",
  };
};

type Props = {
  customAvatarUrl: string;
  open: boolean;
  closeRpmEditor: () => void;
  setCustomAvatarUrl: (url: string) => void;
};

/**
 * Handles the ReadyPlayerMe integration to create custom avatars.
 * Go to https://miro.com/app/board/uXjVOQ629tE=/ (Password: RPMintegration)
 * to see the communication design.
 */
const ReadyPlayerMeEditor: React.FC<Props> = ({
  customAvatarUrl,
  open,
  closeRpmEditor,
  setCustomAvatarUrl,
}) => {
  const { environment } = useEnvironmentContext();
  const subdomain = getRpmSubdomain(environment.customRpmUrl) ?? "journee";
  const isSmallScreen = useIsSmallScreen();

  const handleOnAvatarExported = (event: AvatarExportedEvent) => {
    const url = event.data.url;
    sendGameMessage({
      type: "LoadExternalAsset",
      uri: url,
      provider: "readyplayerme",
      intent: "customAvatar",
    });

    // Update the store so profileData and ReadyPlayerMeEditor
    // are always in sync even without saving the profile.
    setCustomAvatarUrl(url);
    // TODO: convert this hint in some sort of notification
    log("GENERIC", t("profile_rpm_avatar_download"));
    closeRpmEditor();
  };

  return (
    <AbsoluteWrapper>
      <input
        data-testid={PanelTestIds.profile.common.rpmAvatarUrlInput}
        name="customAvatarUrl"
        type="hidden"
        value={customAvatarUrl}
      />
      {!isMobile && <Space h={3} />}
      <AvatarCreator
        subdomain={subdomain}
        config={config}
        style={getStyle(open, isSmallScreen)}
        onAvatarExported={handleOnAvatarExported}
      />
    </AbsoluteWrapper>
  );
};

export default ReadyPlayerMeEditor;
