import { RefObject, useEffect } from "react";
import { isMobile } from "../constants/flags.constant";

type Handler = (event: MouseEvent | TouchEvent) => void;

const event = isMobile ? "touchstart" : "click";

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler
): void {
  useEffect(() => {
    const el = ref?.current;
    const onClickOutside = (event: MouseEvent | TouchEvent) => {
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };
    document.addEventListener(event, onClickOutside);
    return () => {
      document.removeEventListener(event, onClickOutside);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
